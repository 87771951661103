<template>
    <!-- mobile menu -->
    <div class="relative mobile">
        <div
            class="mobilenav"
            style="font-size: 25px; cursor: pointer; color: #84818a; z-index: 10000000"
            @click.prevent="openNav"
        >
            &#9776;
        </div>

        <div>
            <div
                ref="mySidenav"
                id="mySidenav"
                class="sidenav"
                style="background: #f2f6fc; height: 100vh; position: fixed; padding-bottom: 2rem; z-index: 99999"
            >
                <span class="closebtn ph4 pv5" style="cursor: pointer" @click.prevent="closeNav">&times;</span>
                <!-- <div class="ph4 pv3" style="background-color: #e9edfc; cursor: pointer">
                    <a href="https://simplebks.com" target="_blank">
                        <div class="pb3">
                            <img src="../assets/images/simple-logo-mobile.svg" alt="www.simplebks.com" />
                        </div>
                    </a> -->
                <!-- <router-link :to="{ name: 'AddTransaction' }" style="color: #ffffff; position: relative; z-index: 999">
                        <div class="tc" style="background-color: #132c8c; padding: 16px; border-radius: 4px">
                            Add New Record
                        </div>
                    </router-link> -->
                <!-- </div> -->

                <div class="pb5 pt5 mob-menu">
                    <!-- <router-link
                        v-if="role === 'owner' || role === 'admin'"
                        class="flex items-center ph4 pt3"
                        active-class="active"
                        :to="{ name: 'GetStarted' }"
                    >
                        <img :src="require('@/assets/images/store-in.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Get Started</div>
                    </router-link> -->

                    <router-link class="flex items-center ph4 pb3 pt3" active-class="active" :to="{ name: 'Dashboard' }">
                        <img :src="require('@/assets/images/Dashboard-active.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Dashboard</div>
                    </router-link>
                    <router-link class="flex items-center ph4 pb3 pt1" active-class="active" :to="{ name: 'SalesAndInvoicing' }">
                        <img :src="require('@/assets/images/newInvoicing.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Sales & Invoicing</div>
                    </router-link>
                    <router-link class="flex items-center ph4 pb3 pt1" active-class="active" :to="{ name: 'Expenses' }">
                        <img :src="require('@/assets/images/expenses.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Expenses</div>
                    </router-link>
                    <router-link class="flex items-center ph4 pb3 pt1" active-class="active" :to="{ name: 'Contacts' }">
                        <img :src="require('@/assets/images/contact.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Contacts</div>
                    </router-link>
                    <router-link
                        class="flex items-center ph4 pb3 pt1"
                        active-class="active"
                        :to="{ name: 'ProductsAndServices' }"
                    >
                        <img :src="require('@/assets/images/product.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Products & Services</div>
                    </router-link>
                    <!-- <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(51)"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/transactions') }"
                        active-class="active"
                        :to="{ name: 'Transactions' }"
                    >
                        <img :src="require('@/assets/images/Transactions.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Transactions</div>
                    </router-link> -->
                    <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(142)"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/production') }"
                        active-class="active"
                        :to="{ name: 'ProductionNew' }"
                    >
                        <img src="../assets/images/production.svg" style="width: 15px" alt="" />
                        <div class="pl3">Production</div>
                    </router-link>
                    <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(50)"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/accounting') }"
                        active-class="active"
                        :to="{ name: 'Accounting' }"
                    >
                        <img :src="require('@/assets/images/accounting.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Accounting</div>
                    </router-link>
                    <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(51)"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/transactions') }"
                        active-class="active"
                        :to="{ name: 'Transactions' }"
                    >
                        <img :src="require('@/assets/images/Transactions.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Transactions</div>
                    </router-link>
                    <!-- <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(50)"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/inventories') }"
                        active-class="active"
                        :to="{ name: 'ProductsSummary' }"
                    >
                        <img :src="require('@/assets/images/inventory.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Inventory</div>
                    </router-link> -->
                    <!-- <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(9)"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/services') }"
                        active-class="active"
                        :to="{ name: 'Services' }"
                    >
                        <img :src="require('@/assets/images/service-svgrepo-com.svg')" style="width: 20px" alt="" />
                        <div class="pl3">Services</div>
                    </router-link> -->

                    <!-- <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(13)"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/entities') }"
                        active-class="active"
                        :to="{ name: 'Entities' }"
                    >
                        <img :src="require('@/assets/images/entity.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Customers</div>
                    </router-link>

                    <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(21)"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/invoice') }"
                        active-class="active"
                        :to="{ name: 'InvoiceSummary' }"
                    >
                        <img :src="require('@/assets/images/invoice-svgrepo-com.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Invoicing</div>
                    </router-link>
                    <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(12)"
                        class="flex items-center ph4 pb3 pt1"
                        active-class="active"
                        :to="{ name: 'PnR' }"
                    >
                        <img :src="require('@/assets/images/payables.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Debtors & Creditors</div>
                    </router-link> -->
                    <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(141)"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/pos') }"
                        active-class="active"
                        :to="{ name: 'Pos' }"
                    >
                        <img src="../assets/images/point.svg" style="width: 15px" alt="" />
                        <div class="pl3">Point of Sale</div>
                    </router-link>
                    <!-- 
                    <router-link
                        v-if="role === 'owner' || role === 'admin'"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/asset-management') }"
                        active-class="active"
                        :to="{ name: 'AssetManagement' }"
                    >
                        <img src="../assets/images/point.svg" style="width: 15px" alt="" />
                        <div class="pl3">Asset Management</div>
                    </router-link> -->
                    <router-link
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(15)"
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/reports') }"
                        active-class="active"
                        :to="{ name: 'Reports' }"
                    >
                        <img :src="require('@/assets/images/report.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Report</div>
                    </router-link>
                    <!-- <router-link class="flex items-center ph4 pb3 pt1" active-class="active" to="#">-->
                    <!--    <img src="../assets/images/insight.svg" style="width: 15px" alt="" />-->
                    <!--    <div class="pl3">Insights</div>-->
                    <!-- </router-link>-->
                    <router-link
                        class="flex items-center ph4 pb3 pt1"
                        :class="{ active: $route.fullPath.startsWith('/settings') }"
                        active-class="active"
                        :to="{ name: 'SettingNew' }"
                    >
                        <img :src="require('@/assets/images/settings.svg')" style="width: 15px" alt="" />
                        <div class="pl3">Settings</div>
                    </router-link>
                </div>

                <!-- <div style="cursor: pointer" ref="mobileMenuRef" :style="{ backgroundImage: 'url(' + require('@/assets/images/bg-btn.png') + ')' }">
                    <div class="dropdownx w-100" style="position:relative">
                        <div
                            class="dropdown-toggle"
                            role="button"
                            id="dropdownMenuLink"
                            :data-bs-toggle="state.showDropdown ? 'dropdown': 'dropdownx'"
                            aria-expanded="false"
                        >
                            <div class="flexx justify-between items-center pv2">
                                <div class="flex justify-between items-center">
                                    <router-link :to="{ name: 'BusinessProfile' }" class="flex justify-between items-center ph3x b f5">
                                        <div class="flex justify-center items-center" style="background: rgb(215, 222, 250);
                                            height: 50px;
                                            width: 50px;
                                            margin: 0 10px 0 20px;
                                            border-radius: 50%;
                                            font-size: 22px;
                                            color: rgb(19, 44, 140);"
                                        >
                                            <img v-if="orgImage" :src="orgImage" alt=""
                                                style="object-position: center; object-fit: cover; width: 50px; height: 50px;"
                                                class="rounded-circle" />
                                            <span v-else>{{ orgInitials }}</span>
                                        </div>
                                        <div class="f5" style="font-weight: bold; font-size: 14px; color: rgb(19, 44, 140)">
                                            {{ userData?.org }}
                                        </div>
                                    </router-link>
                                    <div class="pr2">
                                        <font-awesome-icon 
                                            icon="fa fa-chevron-down" 
                                            style="font-size:1.5em; color:#132c8c" 
                                            v-tippy="'Add new business/branch'" 
                                            @click="state.showDropdown = !state.showDropdown"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul 
                            :class="state.showDropdown ? 'dropdown-menu show': 'dropdown-menu'" 
                            aria-labelledby="dropdownMenuLink" 
                            :style="state.showDropdown ? 'width: 100%; position: absolute; inset: auto 0px 0px auto; margin: 0px; transform: translate3d(0px, -62px, 0px);' : 'width:100%;z-index:10000'"
                        >
                            <li v-for="business in businesses" :key="business._id">
                                <div class="flex justify-start items-center" @click="switchBusiness(business.org)">
                                    <img :src="'https://ui-avatars.com/api/?name=' + business.name + '&size=24&rounded=true'" alt="bi" />
                                    <a href="#" class="dropdown-itemx ml2"> {{ business.name }} </a>
                                </div>
                                
                            </li>
                            <li v-if="!userData.isSubBusiness && (role === 'owner' || role === 'admin')">
                                <a href="#" class="flex justify-start items-center pa2 pointer" @click.prevent="addBusiness()">
                                    <font-awesome-icon 
                                        icon="fa fa-plus" 
                                        style="font-size:1.2em; color:#132c8c" 
                                    />
                                    <span class="dropdown-itemx ml2"> Add Business </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
                <!-- select business option custom styling -->
            </div>
        </div>
    </div>
    <!-- mobile menu -->
</template>

<script>
import { computed, onMounted, ref, watch, reactive } from 'vue'
import { useStore } from 'vuex'
import bgBtn from '@/assets/images/bg-btn.png'

export default {
    name: 'MobileMenu',

    setup() {
        const store = useStore()
        const userData = computed(() => store?.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const getStarted = computed(() => store?.state?.Settings?.getStarted)
        const myPlan = computed(() => store?.state?.Settings?.paymentPlan)
        const businesses = computed(() => store?.state?.Settings?.businesses)

        const initialState = {
            showDropdown: false,
        }

        const state = reactive(initialState)

        const mySidenav = ref(null)
        const mobileMenuRef = ref(null)
        const menuFooter = ref(null)

        function openNav() {
            mySidenav.value.style.width = '300px'
            mobileMenuRef.value.className = 'footer-mobile'
            // so I can monitor this action
            store.dispatch('Alert/setShowMenu', true)
        }

        function closeNav() {
            mySidenav.value.style.width = '0'
            if (mobileMenuRef.value.classList.contains('footer-mobile')) {
                mobileMenuRef.value.classList.remove('footer-mobile')
            }
            store.dispatch('Alert/setShowMenu', false)
        }

        const triggerGetStarted = () => {
            onMounted(() => store.dispatch('Settings/getGetStarted'))
        }

        watch(
            () => role.value,
            // eslint-disable-next-line
            (curr, prev) => {
                if (curr === 'owner' || curr === 'admin') {
                    triggerGetStarted()
                }
            }
        )

        const switchBusiness = (orgId) => {
            store.dispatch('Settings/switchBusiness', orgId).then(() => {
                state.showDropdown = !state.showDropdown
                store.dispatch('PnRModule/getStats')
                getAllTransactions()
                getGraphOverview(state.initialOverviewData)
                getTodayOverviewStat()
                getRecentTransactions(5)
                getTopCustomers(5)
                getTopProducts(5)
                getTopExpenses({
                    limit: 5,
                    startDate: formatDate(state.start, 'YYYY-MM-DD'),
                    endDate: formatDate(state.end, 'YYYY-MM-DD'),
                })
                getTopEvents(5)
            })
        }

        const addBusiness = async () => {
            if (myPlan.value?.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `Multi-Business feature is only available for Paid plans`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                })
                return
            }

            if (businesses.value?.length >= (myPlan.value?.businesses || 1)) {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `You have exhausted your business limit, please upgrade.`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                })
                return
            }

            await router.replace({ name: 'AddBusiness' })
        }

        onMounted(() => {
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getBusinesses')
        })

        return {
            state,
            role,
            rolePermissions,
            getStarted,
            bgBtn,
            userData,
            openNav,
            closeNav,
            orgInitials,
            mySidenav,
            menuFooter,
            orgImage,
            mobileMenuRef,
            businesses,
            switchBusiness,
            myPlan,
            addBusiness,
        }
    },
}
</script>

<style scoped>
.pv2 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}
.footer-mobile {
    /*position: absolute;*/
    /* bottom: -101px; */
    bottom: 0;
    /*width: 100%;*/
    position: fixed;
    width: 300px;
    animation: footerUserOrgAnim 1s forwards;
}
.mob-menu a {
    margin: 25px 0;
}
@keyframes footerUserOrgAnim {
    0% {
        bottom: -100px;
    }

    100% {
        bottom: 0;
    }
}
</style>
