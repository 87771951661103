<template>
    <app-wrapper>
        <template v-slot:child>
            <TransactionImportModal :closeModal="() => toggleTransactionImportModal(false)" />
            <!-- Main Box -->
            <div class="flex justify-between">
                <!-- Right box -->
                <div class="">
                    <div class="pa4-l pa3">
                        <div>
                            <h2 class="" style="color: #132c8c; font-size: 18px">Expenses</h2>
                            <p class="w-60-l lh-copy black">Keeping track of your spending.</p>
                        </div>

                        <div class="mt4 black" style="font-weight: 500">
                            <span class="bg-washed-yellow ph2">Tip: Click on card to perform specify actions </span>
                        </div>

                        <div class="mt4">
                            <div class="flex flex-wrap" style="gap: 15px">
                                <router-link
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(24)"
                                    :to="{ name: 'CreateExpenseTransaction', query: { type: 'outflows', direct: true } }"
                                    @click="changeBusinessType('expenses')"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/rev-expense.svg" style="width: 60px" alt="" />
                                    <h3>Expenses</h3>
                                    <p class="lh-copy">
                                        This is where you record all the money you spend on various things, like bills, supplies,
                                        or services.
                                    </p>
                                </router-link>
                                <div
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(24)"
                                    @click="expensesPrepayment()"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/Prepayment.svg" style="width: 60px" alt="" />
                                    <h3>Prepayments</h3>
                                    <p class="lh-copy">
                                        Use this feature to record upfront payments for expenses that cover multiple months into
                                        the future. It's handy when you pay a lump sum immediately for a service that extends over
                                        several upcoming months, such as rent or subscriptions.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                    </span>
                                </div>
                                <router-link
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(25)"
                                    :to="'#'"
                                    @click="quickPurchases()"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-purchase.svg" style="width: 60px" alt="" />
                                    <h3>Quick Purchases</h3>
                                    <p class="lh-copy">
                                        Just like "Quick Sales," this is a speedy way to record expenses without a lot of steps.
                                        Useful for those on-the-fly purchases.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                    </span>
                                </router-link>
                                <div
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(4)"
                                    @click="filterTxnByExpenses()"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/Cashbook.svg" style="width: 60px" alt="" />
                                    <h3>Expense Journal</h3>
                                    <p class="lh-copy">
                                        A digital ledger to track all your expenses. It's like a diary for your spending.
                                    </p>
                                </div>
                                <router-link
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(17)"
                                    :to="'#'"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    @click="routeTo('ExpenseReport')"
                                >
                                    <img src="@/assets/images/Expense-report.svg" style="width: 60px" alt="" />
                                    <h3>Expense Report</h3>
                                    <p class="lh-copy">
                                        A summary of all your expenses. It helps you see where your money is going and make
                                        informed financial decisions.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                    </span>
                                </router-link>
                                <div
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(52)"
                                    @click="bulkExpenseUpload()"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-upload.svg" style="width: 60px" alt="" />
                                    <h3>Bulk Expense Upload</h3>
                                    <p class="lh-copy">
                                        Similar to "Bulk Sales Upload," this is a shortcut to add a bunch of expense data all at
                                        once. Handy for managing many expenses efficiently.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import AppWrapper from '../../layout/AppWrapper.vue'
import TransactionImportModal from '@/views/Transactions/widgets/TransactionImportModal'
export default {
    name: 'Expenses',
    components: { AppWrapper, TransactionImportModal },
    setup() {
        const router = useRouter()
        const store = useStore()
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const plan = computed(() => store.state?.Settings?.paymentPlan)

        const checkFreePlan = () => {
            if (plan.value.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `This feature is not available on free plan. Upgrade to unlock!`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                })
                return true
            }

            return false
        }

        const changeBusinessType = (type) => {
            store.dispatch('DashboardStats/getBusinessType', type)
        }

        const expensesPrepayment = () => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            router.push({ path: '/transactions/create-expense', query: { type: 'outflows', prepayment: true } })
            changeBusinessType('expenses')

        }
        const filterTxnByExpenses = () => {
            router.push({ path: '/transactions', query: { limit: 25, type: 'expenses', name: 'Expense-Journal' } })

       }
        const toggleTransactionImportModal = (status) => {
            store.dispatch('Transaction/toggleTransactionImportModal', status)
        }

        const quickPurchases = () => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            router.push({ path: '/transactions/create-purchase', query: { type: 'outflows', category: 'purchases' } })
            changeBusinessType('purchases')

        }

        const bulkExpenseUpload = () => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            toggleTransactionImportModal(true)
        }

        const routeTo = (path) => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            router.push({ name: path })
        }

        onMounted(() => {
            store.dispatch('Settings/getPlan')
        })

        return {
            changeBusinessType,
            filterTxnByExpenses,
            toggleTransactionImportModal,
            expensesPrepayment,
            rolePermissions,
            role,
            plan,
            bulkExpenseUpload,
            quickPurchases,
            checkFreePlan,
            routeTo,
        }
    },
}
</script>
<style>
.menu-card {
    width: 23.5%;
    margin-bottom: 20px;
}

.menu-card:hover {
    background-color: rgb(233, 237, 252);
    cursor: pointer;
}

/* mobile navigation */

@media screen and (max-width: 959px) {
    .menu-card {
        width: 48.5%;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 600px) {
    .menu-card {
        width: 100%;
        margin-bottom: 20px;
    }
}
</style>
