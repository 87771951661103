<template>
    <div
        v-if="
            $route.name === 'Dashboard' ||
            $route.name === 'Transactions' ||
            $route.name === 'ProductsSummary' ||
            $route.name === 'Products' ||
            $route.name === 'Services' ||
            $route.name === 'Entities' ||
            $route.name === 'InvoiceSummary' ||
            $route.name === 'PnR' ||
            $route.name === 'Production' ||
            $route.name === 'RawMaterial' ||
            $route.name === 'Reports' ||
            $route.name === 'ProfitAndLoss' ||
            $route.name === 'SalesReport' ||
            $route.name === 'ExpenseReport' ||
            $route.name === 'InventoryReport' ||
            $route.name === 'SettingNew' ||
            $route.name === 'PaymentPlans' ||
            $route.name === 'ProductionNew' ||
            $route.name === 'SalesAndInvoicing' ||
            $route.name === 'Expenses' ||
            $route.name === 'Contacts' ||
            $route.name === 'ProductsAndServices' ||
            $route.name === 'TrialBalance' ||
            $route.name === 'TrialAccount' ||
            $route.name === 'EditJournal' ||
            $route.fullPath.startsWith('/accounting') ||
            $route.fullPath.startsWith('/asset-management') ||
            $route.fullPath.startsWith('/budget') ||
            $route.fullPath.startsWith('/price-adjust') ||
            $route.fullPath.startsWith('/reports') ||
            $route.fullPath.startsWith('/quotations') ||
            $route.fullPath.startsWith('/inventories')


        "
    >
        <div id="container-floating">
            <CreateProduct
                :closeModal="() => toggleCreateProduct({ show: false, cat: 'PRODUCT' })"
                :transactionCategory="state.type || 'PRODUCT'"
            />
            <div class="floating-menus-wrapper" v-if="isOpen">
                <div
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(23)"
                    class="nd6 nds flex justify-start items-center"
                >
                    <router-link :to="{ name: 'AddTransactionNew' }" @click="changeBusinessType('sales')" class="w-100"
                        >Sell</router-link
                    >
                </div>

                <div
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(25)"
                    class="nd5 nds flex justify-start items-center"
                >
                    <router-link :to="{ name: 'AddTransactionNew' }" @click="changeBusinessType('purchases')" class="w-100"
                        >Buy</router-link
                    >
                </div>

                <div
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(24)"
                    class="nd4 nds flex justify-start items-center"
                >
                    <router-link :to="{ name: 'AddTransactionNew' }" @click="changeBusinessType('expenses')" class="w-100"
                        >Expenses</router-link
                    >
                </div>

                <div
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(32)"
                    class="nd3 nds flex justify-start items-center"
                >
                    <span @click.prevent="toggleCreateProduct({ show: true, cat: 'PRODUCT' })" class="w-100">Products</span>
                </div>

                <div
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(33)"
                    class="nd2 nds flex justify-start items-center"
                >
                    <span @click.prevent="toggleCreateProduct({ show: true, cat: 'SERVICE' })" class="w-100">Services</span>
                </div>

                <div
                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(31)"
                    class="nd1 nds flex justify-start items-center"
                >
                    <router-link :to="{ name: 'InvoiceNew' }" class="w-100">Invoice</router-link>
                </div>
            </div>

            <div id="floating-button">
                <p class="plus">+ Add</p>
                <p class="cancel" :class="isOpen === false" @click="toggleFloatingButton()">
                    {{ isOpen === false ? '+ Add' : 'x Close' }}
                </p>
                <!-- <p :class="isOpen === false ? 'test' : 'cancel'" @click="toggleFloatingButton()">
                    {{ isOpen === false ? '+' : 'x' }}
                </p> -->
            </div>
        </div>
    </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import CreateProduct from '@/components/CreateProduct'
export default {
    name: 'FloatingActionButtons',
    components: { CreateProduct },
    
    setup() {
        const isOpen = ref(false)
        const store = useStore()
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)

        const toggleFloatingButton = () => {
            isOpen.value = !isOpen.value
        }

        const state = reactive({
            type: 'PRODUCT'
        })

        const changeBusinessType = type => {
            store.dispatch('DashboardStats/getBusinessType', type)
        }

        const toggleCreateProduct = payload => {
            state.type = payload?.cat || 'PRODUCT'
            //console.log(payload)
            store.dispatch('Inventory/showCreateProduct', payload)
        }

        return {
            state,
            changeBusinessType,
            toggleCreateProduct,
            role,
            rolePermissions,
            isOpen,
            toggleFloatingButton
        }
    }
}
</script>

<style>
.testing {
    font-size: 38px !important;
}

@media screen and (max-width: 1261px) {
    #presentation {
        width: 480px;
        height: 120px;
        padding: 20px;
        margin: auto;
        background: #fff;
        margin-top: 10px;
        box-shadow: 0 3px 15px -5px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
        border-radius: 10px;
    }

    #presentation:hover {
        box-shadow: 0 12px 28px -5px rgba(0, 0, 0, 0.13);
        transition: all 0.3s;
        transform: translateZ(10px);
    }

    #floating-button {
        width: 75px;
        height: 45px;
        /* height: 55px; */
        border-radius: 10%;
        /* border-radius: 50%; */
        background: #132c8c;
        position: fixed;
        bottom: 80px;
        right: 30px;
        padding: 13px;
        cursor: pointer;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
         /* to hide the button */
        display: none;
    }

    .plus {
        color: white;
        position: absolute;
        top: 0;
        display: block;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        padding: 0;
        margin: 0;
        line-height: 45px;
        /* line-height: 55px; */
        /* font-size: 38px; */
        font-size: 18px;
        font-family: 'Roboto';
        /* font-weight: 300; */
        animation: plus-out 0.3s;
        transition: all 0.3s;
    }

    #container-floating {
        position: fixed;
        width: 70px;
        height: 70px;
        bottom: 30px;
        right: 30px;
        /* z-index: 50px; */
    }

    #container-floating:hover {
        height: 400px;
        width: 90px;
        padding: 30px;
    }

    #container-floating:hover .plus {
        animation: plus-in 0.15s linear;
        animation-fill-mode: forwards;
    }

    .cancel {
        color: white;
        position: absolute;
        top: 0;
        display: block;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        padding: 0;
        opacity: 0;
        margin: 0;
        /* line-height: 50px; */
        line-height: 45px;
        /* font-size: 22px; */
        font-size: 18px;
        transform: rotateZ(-70deg);
        transition: all 0.3s;
        animation: cancel-out 0.3s;
    }

    #container-floating:hover .cancel {
        animation: cancel-in 0.2s;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
    }

    @keyframes cancel-in {
        from {
            opacity: 0;
            transform: rotateZ(-70deg);
        }
        to {
            opacity: 1;
            transform: rotateZ(0deg);
        }
    }

    @keyframes cancel-out {
        from {
            opacity: 1;
            transform: rotateZ(0deg);
        }
        to {
            opacity: 0;
            transform: rotateZ(-70deg);
        }
    }

    @keyframes plus-in {
        from {
            opacity: 1;
            transform: rotateZ(0deg);
        }
        to {
            opacity: 0;
            transform: rotateZ(180deg);
        }
    }

    @keyframes plus-out {
        from {
            opacity: 0;
            transform: rotateZ(180deg);
        }
        to {
            opacity: 1;
            transform: rotateZ(0deg);
        }
    }

    .nds {
        width: 140px;
        height: 30px;
        padding: 10px;
        position: fixed;
        /* z-index: 300; */
        transform: scale(0);
        cursor: pointer;
    }

    .nd1 {
        background: #fff;
        color: #666;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        right: 40px;
        bottom: 130px;
        animation-delay: 0.35s;
        animation: bounce-out-nds 0.45s linear;
        animation-fill-mode: forwards;
    }

    .nd2 {
        background: #fff;
        color: #666;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        right: 40px;
        bottom: 160px;
        animation-delay: 0.3s;
        animation: bounce-out-nds 0.3s linear;
        animation-fill-mode: forwards;
    }

    .nd3 {
        background: #fff;
        color: #666;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        right: 40px;
        bottom: 190px;
        animation-delay: 0.25s;
        animation: bounce-out-nds 0.25s linear;
        animation-fill-mode: forwards;
    }

    .nd4 {
        background: #fff;
        color: #666;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        right: 40px;
        bottom: 220px;
        animation-delay: 0.2s;
        animation: bounce-out-nds 0.2s linear;
        animation-fill-mode: forwards;
    }

    .nd5 {
        background: #fff;
        color: #666;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        right: 40px;
        bottom: 250px;
        animation-delay: 0.15s;
        animation: bounce-out-nds 0.15s linear;
        animation-fill-mode: forwards;
    }

    .nd6 {
        background: #fff;
        color: #666;
        box-shadow: rgba(0, 0, 0, 0.24) 0px -3px 8px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        right: 40px;
        bottom: 280px;
        animation-delay: 0.1s;
        animation: bounce-out-nds 0.1s linear;
        animation-fill-mode: forwards;
    }

    @keyframes bounce-nds {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes bounce-out-nds {
        from {
            opacity: 1;
            transform: scale(1);
        }
        to {
            opacity: 0;
            transform: scale(0);
        }
    }

    #container-floating:hover .nds {
        animation: bounce-nds 0.1s linear;
        animation-fill-mode: forwards;
    }

    #container-floating:hover .nd2 {
        animation-delay: 0.08s;
    }
    #container-floating:hover .nd3 {
        animation-delay: 0.15s;
    }
    #container-floating:hover .nd5 {
        animation-delay: 0.2s;
    }
}
</style>
