<template>
    <div>
        <div class="loader box ml5-l" data-v-04a0d67a="" style="opacity: 1">
            <div
                class="mdl-spinner mdl-js-spinner is-active is-upgraded"
                style="margin: auto; width: 50px; height: 50px"
                data-upgraded=",MaterialSpinner"
                data-v-04a0d67a=""
            >
                <div class="mdl-spinner__layer mdl-spinner__layer-1" data-v-04a0d67a="">
                    <div class="mdl-spinner__circle-clipper mdl-spinner__left" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                    <div class="mdl-spinner__gap-patch" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                    <div class="mdl-spinner__circle-clipper mdl-spinner__right" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                </div>
                <div class="mdl-spinner__layer mdl-spinner__layer-2" data-v-04a0d67a="">
                    <div class="mdl-spinner__circle-clipper mdl-spinner__left" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                    <div class="mdl-spinner__gap-patch" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                    <div class="mdl-spinner__circle-clipper mdl-spinner__right" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                </div>
                <div class="mdl-spinner__layer mdl-spinner__layer-3" data-v-04a0d67a="">
                    <div class="mdl-spinner__circle-clipper mdl-spinner__left" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                    <div class="mdl-spinner__gap-patch" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                    <div class="mdl-spinner__circle-clipper mdl-spinner__right" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                </div>
                <div class="mdl-spinner__layer mdl-spinner__layer-4" data-v-04a0d67a="">
                    <div class="mdl-spinner__circle-clipper mdl-spinner__left" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                    <div class="mdl-spinner__gap-patch" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                    <div class="mdl-spinner__circle-clipper mdl-spinner__right" data-v-04a0d67a="">
                        <div class="mdl-spinner__circle" data-v-04a0d67a=""></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="page" style="/*height: calc(100vh - 30px);*/ margin-bottom: 20px; margin-left: 110vw">
        <!-- Colored FAB button with ripple -->
    </div>
</template>

<script>
export default {
    name: 'Spinner',
}

/*eslint-disable*/
</script>

<style scoped>
.mdl-spinner {
    display: inline-block;
    position: relative;
    width: 28px;
    height: 28px;
}
.mdl-spinner:not(.is-upgraded).is-active:after {
    content: 'Loading...';
}
.mdl-spinner.is-upgraded.is-active {
    -webkit-animation: mdl-spinner__container-rotate 1568.23529412ms linear infinite;
    animation: mdl-spinner__container-rotate 1568.23529412ms linear infinite;
}

@-webkit-keyframes mdl-spinner__container-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes mdl-spinner__container-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.mdl-spinner__layer {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.mdl-spinner__layer-1 {
    border-color: rgb(66, 165, 245);
}
.mdl-spinner--single-color .mdl-spinner__layer-1 {
    border-color: rgb(63, 81, 181);
}
.mdl-spinner.is-active .mdl-spinner__layer-1 {
    -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-2 {
    border-color: rgb(244, 67, 54);
}
.mdl-spinner--single-color .mdl-spinner__layer-2 {
    border-color: rgb(63, 81, 181);
}
.mdl-spinner.is-active .mdl-spinner__layer-2 {
    -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-3 {
    border-color: rgb(253, 216, 53);
}
.mdl-spinner--single-color .mdl-spinner__layer-3 {
    border-color: rgb(63, 81, 181);
}
.mdl-spinner.is-active .mdl-spinner__layer-3 {
    -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-4 {
    border-color: rgb(76, 175, 80);
}
.mdl-spinner--single-color .mdl-spinner__layer-4 {
    border-color: rgb(63, 81, 181);
}
.mdl-spinner.is-active .mdl-spinner__layer-4 {
    -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@-webkit-keyframes mdl-spinner__fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }
    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }
    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }
    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }
    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@keyframes mdl-spinner__fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }
    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }
    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }
    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }
    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

/**
* HACK: Even though the intention is to have the current .mdl-spinner__layer-N
* at `opacity: 1`, we set it to `opacity: 0.99` instead since this forces Chrome
* to do proper subpixel rendering for the elements being animated. This is
* especially visible in Chrome 39 on Ubuntu 14.04. See:
*
* - https://github.com/Polymer/paper-spinner/issues/9
* - https://code.google.com/p/chromium/issues/detail?id=436255
*/
@-webkit-keyframes mdl-spinner__layer-1-fade-in-out {
    from {
        opacity: 0.99;
    }
    25% {
        opacity: 0.99;
    }
    26% {
        opacity: 0;
    }
    89% {
        opacity: 0;
    }
    90% {
        opacity: 0.99;
    }
    100% {
        opacity: 0.99;
    }
}
@keyframes mdl-spinner__layer-1-fade-in-out {
    from {
        opacity: 0.99;
    }
    25% {
        opacity: 0.99;
    }
    26% {
        opacity: 0;
    }
    89% {
        opacity: 0;
    }
    90% {
        opacity: 0.99;
    }
    100% {
        opacity: 0.99;
    }
}

@-webkit-keyframes mdl-spinner__layer-2-fade-in-out {
    from {
        opacity: 0;
    }
    15% {
        opacity: 0;
    }
    25% {
        opacity: 0.99;
    }
    50% {
        opacity: 0.99;
    }
    51% {
        opacity: 0;
    }
}

@keyframes mdl-spinner__layer-2-fade-in-out {
    from {
        opacity: 0;
    }
    15% {
        opacity: 0;
    }
    25% {
        opacity: 0.99;
    }
    50% {
        opacity: 0.99;
    }
    51% {
        opacity: 0;
    }
}

@-webkit-keyframes mdl-spinner__layer-3-fade-in-out {
    from {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 0.99;
    }
    75% {
        opacity: 0.99;
    }
    76% {
        opacity: 0;
    }
}

@keyframes mdl-spinner__layer-3-fade-in-out {
    from {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 0.99;
    }
    75% {
        opacity: 0.99;
    }
    76% {
        opacity: 0;
    }
}

@-webkit-keyframes mdl-spinner__layer-4-fade-in-out {
    from {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    75% {
        opacity: 0.99;
    }
    90% {
        opacity: 0.99;
    }
    100% {
        opacity: 0;
    }
}

@keyframes mdl-spinner__layer-4-fade-in-out {
    from {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    75% {
        opacity: 0.99;
    }
    90% {
        opacity: 0.99;
    }
    100% {
        opacity: 0;
    }
}

/**
* Patch the gap that appear between the two adjacent
* div.mdl-spinner__circle-clipper while the spinner is rotating
* (appears on Chrome 38, Safari 7.1, and IE 11).
*
* Update: the gap no longer appears on Chrome when .mdl-spinner__layer-N's
* opacity is 0.99, but still does on Safari and IE.
*/
.mdl-spinner__gap-patch {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 45%;
    width: 10%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
}
.mdl-spinner__gap-patch .mdl-spinner__circle {
    width: 1000%;
    left: -450%;
}

.mdl-spinner__circle-clipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
}
.mdl-spinner__circle-clipper .mdl-spinner__circle {
    width: 200%;
}

.mdl-spinner__circle {
    box-sizing: border-box;
    height: 100%;
    border-width: 3px;
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.mdl-spinner__left .mdl-spinner__circle {
    border-right-color: transparent !important;
    -webkit-transform: rotate(129deg);
    transform: rotate(129deg);
}
.mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
    -webkit-animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdl-spinner__right .mdl-spinner__circle {
    left: -100%;
    border-left-color: transparent !important;
    -webkit-transform: rotate(-129deg);
    transform: rotate(-129deg);
}
.mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
    -webkit-animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@-webkit-keyframes mdl-spinner__left-spin {
    from {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@keyframes mdl-spinner__left-spin {
    from {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@-webkit-keyframes mdl-spinner__right-spin {
    from {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}

@keyframes mdl-spinner__right-spin {
    from {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v111/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

body {
    --theme-color: #6610f2;
    --sec-thm-cl: #6610f250;
}
body.light {
    --bg-col: #fff;
    --bg-col-sc: rgb(223, 223, 223);
    --shd-c: #efefef;
    --txt-c: #000;
}
body.dark {
    --bg-col: #151525;
    --bg-col-sc: #151010;
    --shd-c: #151515;
    --txt-c: #fff;
}
html,
body {
    margin: 0;
    padding: 0;
    background: var(--bg-col);
}
*:not(.material-icons) {
    font-family: 'Gowun Dodum', sans-serif !important;
}

* {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
div.app {
    max-width: 900px;
    width: 100%;
    overflow: hidden;
    transition: 0.3s;
    position: absolute;
    top: 0;
    overflow: hidden;
    height: auto;
    max-height: calc(100vh + 60px);
}

div.menu-nav-bar {
    border-bottom: 1px solid var(--theme-color);
    color: var(--txt-c);
    width: calc(100% - 30px);
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0px 15px 0px 15px;
    transition: 0.3s;
}
.ico {
    display: inline-flex;
    text-align: center;
    vertical-align: middle;
}
div.menu-nav-bar button {
    margin-top: 10px;
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--txt-c);
}
div.page {
    /* width: calc(100% - 10px); */
    /* height: calc(100vh - 81px); */
    margin-top: 10px;
    /*background: var(--bg-col);*/
    /*background: red;*/
    border-radius: 15px;
    margin-left: 5px;
    margin-bottom: 10px;
    transition: 0.5s;
    overflow: scroll;
}
div.page::-webkit-scrollbar {
    width: 0px;
}

div.box {
    box-shadow: 0 0 0 3px var(--theme-color);
}
.pg-but.active-tab {
    border-bottom: 3px solid var(--theme-color);
    transition: 0.4s;
}
.formal {
    background: #151525;
    color: white;
    border: 1px solid white;
}
div.loader {
    height: 150px;
    width: 150px;
    border-radius: 15px;
    position: absolute;
    top: calc(50vh - 75px);
    left: calc(50vw - 75px);
    display: flex;
    vertical-align: middle;
    justify-content: center;
    animation: 2s loader infinite;
    transition: 0.3s;
    opacity: 0;
    background: var(--bg-col);
    /*background: rgb(233, 237, 252);*/
    border: 1px solid var(--txt-c);
}
@keyframes loader {
    10% {
        box-shadow: 0 0 0 3px #6610f2;
    }
    50% {
        box-shadow: 0 0 0 3px var(--bg-col);
    }
    100% {
        box-shadow: 0 0 0 3px #6610f2;
    }
}
button:disabled {
    color: transparent !important;
}
.bottom_up_slide {
    position: fixed;
    bottom: 50px;
    left: calc(50vw - 150px);
    border: 2px solid #fffc95;
    background: #80acff;
    color: white;
    padding: 5px;
    border-radius: 5px;
}
</style>
