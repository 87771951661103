import { Axios } from '@/utils/axiosConfig'
import Router from '@/router'
import store from '../index'
import { paginationOptions } from '@/utils/lib'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import router from '../../router'

const state = {
    message: '',
    showChangePlan: false,
    showChangePasswordPage: false,
    isAuthenticated: false,
    disabledButton: false,
    adminUserData: JSON.parse(localStorage.getItem('authy')),
    loginLoading: false,
    loadingState: false,
    axiosAdminConfig: {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            accept: 'application/json'
        }
    },
    partners: [],
    business: {},
    allAccountBiz: [],
    getPartnerDetails: {},
    dashboardUsers: '',
    dashboardActiveUsers: '',
    dashboardTransaction: '',
    dashboardInvoice: '',
    dashboardProducts: '',
    dashboardEntities: '',
    dashboardServices: '',
    dashboardPayingCustomers: '',
    dashboardPlans: '',
    dashboardLogin: '',
    returnStatus: true,
    accountDetails: {},
    accountDetailsLastPaymentDate: {},
    getpnlreport: {},
    getSalesReport: {},
    getBalanceSheetReport: {},
    getInventoryReport: {},
    getExpenseReport: {},
    getBusinessExpenses: {},
    getBusinessInvoice: {},
    getBusinessProduct: {},
    getBusinessTransaction: {},
    getBusinessEnttities: {},
    getBusinessService: {},
    showUserBulkInviteModal: false,
    businessInvites: [],
    businessesUsers: [],
    businessUsers: [],
    businessActivities: [],
    allBusinessesActivities: [],
}

const mutations = {
    SET_SHOW_CHANGE_PLAN(state, payload = false) {
        state.showChangePlan = payload
    },
    GET_ADMIN_USER_DATA(state, payload) {
        state.adminUserData = payload
    },
    SET_ADMIN_LOGIN_LOADING(state, loginLoading) {
        state.loginLoading = loginLoading
    },
    SET_ADMIN_STATE_LOADING(state, loadingState) {
        state.loadingState = loadingState
    },
    SET_AUTH_MESSAGE(state, payload) {
        state.message = payload
    },
    GET_ALL_PARTNERS(state, partners) {
        state.partners = partners
    },
    GET_DASHBOARD_USER_DATA(state, dashboardUsers) {
        state.dashboardUsers = dashboardUsers
    },
    GET_DASHBOARD_ACTIVE_USER_DATA(state, dashboardActiveUsers) {
        state.dashboardActiveUsers = dashboardActiveUsers
    },
    GET_DASHBOARD_TRANSACATION_DATA(state, dashboardTransaction) {
        state.dashboardTransaction = dashboardTransaction
    },
    GET_DASHBOARD_INVOICE_DATA(state, dashboardInvoice) {
        state.dashboardInvoice = dashboardInvoice
    },
    GET_DASHBOARD_PRODUCT_DATA(state, dashboardProducts) {
        state.dashboardProducts = dashboardProducts
    },
    GET_DASHBOARD_ENTITY_DATA(state, dashboardEntities) {
        state.dashboardEntities = dashboardEntities
    },
    GET_DASHBOARD_SERVICE_DATA(state, dashboardServices) {
        state.dashboardServices = dashboardServices
    },
    GET_DASHBOARD_PAYING_CUSTOMERS_DATA(state, data) {
        state.dashboardPayingCustomers = data
    },
    GET_PARTNER_BUSINESS_DATA(state, business) {
        state.business = business
    },
    GET_ALL_BUSINESSES_ACTIVITIES(state, data) {
        state.allBusinessesActivities = data
    },
    GET_BUSINESS_ACTIVITIES(state, data) {
        state.businessActivities = data
    },
    GET_PARTNER_DETAILS_DATA(state, getPartnerDetails) {
        state.getPartnerDetails = getPartnerDetails
    },
    GET_PARTNER_PLANS_DATA(state, dashboardPlans) {
        state.dashboardPlans = dashboardPlans
    },
    GET_PARTNER_LOGIN_DATA(state, dashboardLogin) {
        state.dashboardLogin = dashboardLogin
    },
    DISABLE_SUBMIT_BUTTON(state, disabledButton) {
        state.disabledButton = disabledButton
    },
    GET_ACCOUNT_DETAILS(state, accountDetails) {
        state.accountDetails = accountDetails
    },
    GET_ACCOUNT_LAST_PAYMENT_DATA(state, accountDetailsLastPaymentDate) {
        state.accountDetailsLastPaymentDate = accountDetailsLastPaymentDate
    },
    GET_ADMIN_LIST_ALL_BUSINESS_DATA(state, allAccountBiz) {
        state.allAccountBiz = allAccountBiz
    },
    GET_P_N_L_REPORT_DATA(state, getpnlreport) {
        state.getpnlreport = getpnlreport
    },
    GET_SALES_REPORT(state, getSalesReport) {
        state.getSalesReport = getSalesReport
    },
    GET_BALANCE_SHEET_REPORT(state, getBalanceSheetReport) {
        state.getBalanceSheetReport = getBalanceSheetReport
    },
    GET_INVENTORY_SHEET_REPORT(state, getInventoryReport) {
        state.getInventoryReport = getInventoryReport
    },
    GET_EXPENSE_REPORT(state, getExpenseReport) {
        state.getExpenseReport = getExpenseReport
    },
    GET_BUSINESS_TRANSACTION_DATA(state, getBusinessTransaction) {
        state.getBusinessTransaction = getBusinessTransaction
    },
    GET_BUSINESS_INVOICE_DATA(state, getBusinessInvoice) {
        state.getBusinessInvoice = getBusinessInvoice
    },
    GET_BUSINESS_PRODUCTS_DATA(state, getBusinessProduct) {
        state.getBusinessProduct = getBusinessProduct
    },
    GET_BUSINESS_ENTITIES_DATA(state, getBusinessEnttities) {
        state.getBusinessEnttities = getBusinessEnttities
    },
    GET_BUSINESS_SERVICE_DATA(state, getBusinessService) {
        state.getBusinessService = getBusinessService
    },

    SET_SHOW_USER_BULK_INVITE_MODAL(state) {
        state.showUserBulkInviteModal = !state.showUserBulkInviteModal
    },

    GET_ALL_BUSINESSES_INVITES(state, invites) {
        state.businessInvites = invites
    },

    GET_ALL_BUSINESSES_USERS(state, users) {
        state.businessesUsers = users
    },

    GET_BUSINESS_USERS(state, users) {
        state.businessUsers = users
    },
}

const actions = {
    // login
    Login: async ({ commit }, formData) => {
        commit('SET_ADMIN_LOGIN_LOADING', true)

        return Axios.post('/api/v1/admin/auth/login', formData)
            .then(async ({ data }) => {
                // store token to local storage
                localStorage.setItem('token', data.tokens.access.token)
                // store user data to local storage
                localStorage.setItem('authy', JSON.stringify(data.user))

                commit('SET_ADMIN_LOGIN_LOADING', false)

                window.location.href = '/admin/dashboard'

                // Router.push('/admin/dashboard')
            })
            .catch(async err => {
                commit('SET_ADMIN_LOGIN_LOADING', false)

                await store.dispatch('Alert/setAlert', { message: 'Invalid Login details', status: '' })
                commit('SET_LOADING', false)
            })
    },
    // logout
    logout: () => {
        localStorage.clear()

        window.location.href = '/admin/login'
    },
    toggleChangePlan: ({ commit }, payload) => {
        commit('SET_SHOW_CHANGE_PLAN', payload)
    },
    // get partners
    getPartners: async ({ commit }, payload) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners?${paginationOptions(payload)}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('GET_ALL_PARTNERS: ', data)
                commit('GET_ALL_PARTNERS', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                // console.log(err.response.data.message)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get partner details data
    getPartnerDetailsData: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log(data)
                commit('GET_PARTNER_DETAILS_DATA', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get each partner
    getPartnerBusiness: async ({ commit }, { id, payload }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/businesses?${paginationOptions(payload)}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('biz', data)
                commit('GET_PARTNER_BUSINESS_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
                return data
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // create partner
    createPartner: async ({ commit }, formData) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.post('/api/v1/admin/partners', formData, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('SET_ADMIN_STATE_LOADING', false)

                Router.push('/admin/setting')
            })
            .catch(async err => {
                // console.log(message)

                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get dashboard data starts
    getDahsboardUserData: async ({ commit }, { id, query }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/stats/users?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('userdata', data)
                commit('GET_DASHBOARD_USER_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get dashboard active user data
    getDahsboardActiveUserData: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/stats/active-users`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('userdata', data)
                commit('GET_DASHBOARD_ACTIVE_USER_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get dashboard transaction data
    getDahsboardTransactionData: async ({ commit }, { id, query }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/stats/transactions?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_TRANSACATION_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get dashboard invoice data
    getDahsboardInvoiceData: async ({ commit }, { id, query }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/stats/invoices?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_INVOICE_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get dashboard product data
    getDahsboardProductData: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/stats/products`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_PRODUCT_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get dashboard entity data
    getDahsboardEntityData: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/stats/entities`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_ENTITY_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get dashboard service data
    getDahsboardServiceData: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/stats/services`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_SERVICE_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    // get dashboard paying customer data
    getDahsboardPayingCustomerData: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/stats/paying-customers`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_PAYING_CUSTOMERS_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get plans of users
    getDahsboardPlansData: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/stats/plans`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_PARTNER_PLANS_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get login of users
    getDahsboardLoginData: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/partners/${id}/stats/logins`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_PARTNER_LOGIN_DATA', data.today)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get dashboard all business data
    getAccountAllBusinessData: async ({ commit }, payload) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses?${paginationOptions(payload)}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('getAccountAllBusinessData: ', data)
                commit('GET_PARTNER_BUSINESS_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    // get all businesses activities
    getAllActivities: async ({ commit }, payload) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/activities?${paginationOptions(payload)}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_ALL_BUSINESSES_ACTIVITIES', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    // get business activities
    getAccountActivities: async ({ commit }, data) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${data.id}/activities?${paginationOptions(data.payload)}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_BUSINESS_ACTIVITIES', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    // get admin user data
    getDahsboardAdminUserData: async ({ commit }, query) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/stats/users?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('userdata', data)
                commit('GET_DASHBOARD_USER_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get admin dashboard data
    getDahsboardAdminActiveUserData: async ({ commit }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/stats/active-users`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('userdata', data)
                commit('GET_DASHBOARD_ACTIVE_USER_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get admin transaction
    getDahsboardAdminTransactionData: async ({ commit }, query) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/stats/transactions?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_TRANSACATION_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get admin invoice
    getDahsboardAdminInvoiceData: async ({ commit }, query) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/stats/invoices?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_INVOICE_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get admin product
    getDahsboardAdminProductData: async ({ commit }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/stats/products`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_PRODUCT_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get admin entity
    getDahsboardAdminEntityData: async ({ commit }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/stats/entities`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_ENTITY_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get service data
    getDahsboardAdminServiceData: async ({ commit }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/stats/services`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_SERVICE_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get service data
    getDahsboardAdminPayingCustomerData: async ({ commit }, query) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        console.log('QUERY: ', query)
        return Axios.get(`/api/v1/admin/stats/paying-customers?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_DASHBOARD_PAYING_CUSTOMERS_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get plan data
    getDahsboardAdminPlansData: async ({ commit }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/stats/plans`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('plans', data)
                commit('GET_PARTNER_PLANS_DATA', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    // get login data
    getDahsboardAdminLoginData: async ({ commit }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/stats/logins`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('login', data.today)
                commit('GET_PARTNER_LOGIN_DATA', data.today)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    // invite staff
    submitInviteStaff: async ({ commit }, { id, payload }) => {
        commit('DISABLE_SUBMIT_BUTTON', true)
        return Axios.post(`/api/v1/admin/partners/${id}/teams/invite`, payload, state.axiosAdminConfig)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: data.success
                })

                commit('DISABLE_SUBMIT_BUTTON', false)
                return (state.returnStatus = true)
            })
            .catch(async err => {
                state.returnStatus = true
                // console.log(err.response, 'responseerrr')
                commit('DISABLE_SUBMIT_BUTTON', false)

                await store.dispatch('Alert/setAlert', { message: err.response.data.message, status: '' })
                return (state.returnStatus = false)
            })
    },

    joinTeamUsingInvite: async ({ commit }, { id, payload, hash }) => {
        commit('DISABLE_SUBMIT_BUTTON', true)
        return Axios.post(`/api/v1/admin/partners/${id}/teams/join?hash=${hash}`, payload, state.axiosAdminConfig)
            .then(async ({ data }) => {
                console.log('teams invite', data)

                await store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: data.success
                })

                router.push({ name: 'AdminLogin' })

                await store.dispatch('Alert/setAlert', {
                    message: 'You have completed your registration, you may now login',
                    status: true
                })

                commit('DISABLE_SUBMIT_BUTTON', false)
                // return (state.returnStatus = true)
            })
            .catch(async err => {
                state.returnStatus = true
                // console.log(err.response, 'responseerrr')
                commit('DISABLE_SUBMIT_BUTTON', false)

                await store.dispatch('Alert/setAlert', { message: err.response.data.message, status: '' })
                // return (state.returnStatus = false)
            })
    },

    getAccountBusinessDetails: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                console.log('all biz account data', data)
                commit('GET_ACCOUNT_DETAILS', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    getBusinessLastPaymentDate: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/last-payment`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('all biz account data', data)
                commit('GET_ACCOUNT_LAST_PAYMENT_DATA', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    // reports starts
    getBusinessPnLReport: async ({ commit }, { id, query }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/pnl-report?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_P_N_L_REPORT_DATA', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    getSalesReport: async ({ commit }, { id, query }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/sales-report?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('report data', data)
                commit('GET_SALES_REPORT', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    getBalanceSheetReport: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/balance-sheet-report`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('report data', data)
                commit('GET_BALANCE_SHEET_REPORT', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    getInventorySheetReport: async ({ commit }, { id, query }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/inventory-report?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('inventory data', data.data.result)
                commit('GET_INVENTORY_SHEET_REPORT', data.data.result)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    getExpenseReport: async ({ commit }, { id, query }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/expense-report?${query}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('report data', data.data)
                commit('GET_EXPENSE_REPORT', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    // Reactivate and activate
    deActivateBusiness: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.patch(`/api/v1/admin/businesses/${id}/deactivate`, {}, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('activate', data)
                store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: true
                })

                await store.dispatch('Admin/getAccountBusinessDetails', id)

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
    reActivateBusiness: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.patch(`/api/v1/admin/businesses/${id}/reactivate`, {}, state.axiosAdminConfig)
            .then(async ({ data }) => {
                // console.log('activate', data)
                store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: true
                })

                await store.dispatch('Admin/getAccountBusinessDetails', id)

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    cloneBusiness: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.post(`/api/v1/admin/businesses/${id}/clone`, {}, state.axiosAdminConfig)
            .then(async ({ data }) => {
                console.log('clone', data)

                store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: true
                })

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    updateBusiness: async ({ commit }, { id, payload }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.patch(`/api/v1/admin/businesses/${id}`, payload, state.axiosAdminConfig)
            .then(async ({ data }) => {
                store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: true
                })

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    deactivateBizPlan: async ({ commit }, { id, planId }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.post(`/api/v1/admin/businesses/${id}/plan/${planId}/deactivate`, {}, state.axiosAdminConfig)
            .then(async ({ data }) => {
                store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: true
                })
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    changeBizPlan: async ({ commit }, { id, payload }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.post(`/api/v1/admin/businesses/${id}/change-plan`, payload, state.axiosAdminConfig)
            .then(async ({ data }) => {
                store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: true
                })
                commit('SET_ADMIN_STATE_LOADING', false)

                return data
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    // business details card
    fetchBusinessTransaction: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/stats/transactions`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                console.log('new biz trans', data)
                commit('GET_BUSINESS_TRANSACTION_DATA', data)

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                // console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    fetchBusinessInvoice: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/stats/invoices`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_BUSINESS_INVOICE_DATA', data)

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                // console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    fetchBusinessProduct: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/stats/products`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_BUSINESS_PRODUCTS_DATA', data)

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                // console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    fetchBusinessEntities: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/stats/entities`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_BUSINESS_ENTITIES_DATA', data)

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                // console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    fetchBusinessServices: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/stats/services`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_BUSINESS_SERVICE_DATA', data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    toggleBulkInviteModal: ({ commit }) => {
        commit('SET_SHOW_USER_BULK_INVITE_MODAL')
    },

    downloadUsersInviteTemplate: async () => {
        return Axios.get('/api/v1/admin/users-invite-template/download', state.axiosAdminConfig)
            .then(async ({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                return data
            })
    },

    uploadUsersInvite: async (_, payload) => {
        return Axios.post('/api/v1/admin/upload-users-invite', payload, state.axiosAdminConfig)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                // console.log(err.response)
                return data
            })
    },

    verifyAdminInvite: (_, payload) => {
        const { email, hash } = payload
        const query = `?email=${email}&hash=${hash}`

        return Axios.get(`/api/v1/admin/business/invite${query}`)
            .then(async ({ data }) => {
                return data
            })
            .catch(async err => {
                await store.dispatch('Alert/setAlert', {
                    message: err?.response?.data?.message || err?.message,
                    status: err?.response?.data?.success
                })

                return Router.replace({ name: 'Error404' })
            })
    },

    inviteSignup: (_, payload) => {
        const { email, hash } = payload?.query
        const query = `?email=${email}&hash=${hash}`
        const formData = payload?.formData

        return Axios.post(`/api/v1/admin/business/invite${query}`, formData)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success || false })
                if (data?.success) {
                    await store.dispatch('Auth/getUserData').then(async () => {
                        store.commit('Auth/SET_AUTH_STATUS', true)
                        const isAuth = store?.state.Auth.isAuthenticated
                        if (isAuth) {
                            await Router.replace({ name: 'OnBoarding' })
                        }
                    })
                }
                
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            })
    },

    // get all businesses invites
    getBusinessInvites: async ({ commit }, payload) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/invites?${paginationOptions(payload)}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_ALL_BUSINESSES_INVITES', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    // get all businesses users
    getBusinessesUsers: async ({ commit }, payload) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/users?${paginationOptions(payload)}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_ALL_BUSINESSES_USERS', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    // get single business users
    getBusinessUsers: async ({ commit }, id, payload) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.get(`/api/v1/admin/businesses/${id}/users?${paginationOptions(payload)}`, state.axiosAdminConfig)
            .then(async ({ data }) => {
                commit('GET_BUSINESS_USERS', data.data)
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    sendInvoiceReminder: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.post(`/api/v1/admin/businesses/${id}/invoice-reminder-test`, {}, state.axiosAdminConfig)
            .then(async ({ data }) => {
                store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: data.status
                })

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    sendPnlReport: async ({ commit }, id) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.post(`/api/v1/admin/businesses/${id}/pnl-monthly-report-test`, {}, state.axiosAdminConfig)
            .then(async ({ data }) => {
                store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: data.status
                })

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    sendPnlMonthlyReport: async ({ commit }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.post(`/api/v1/admin/businesses/pnl-monthly-report/run`, {}, state.axiosAdminConfig)
            .then(async ({ data }) => {
                store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: data.status
                })

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    sendWeeklyReport: async ({ commit }, orgId=null) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        const url = orgId
            ? `/api/v1/admin/businesses/weekly-account-summary/run?orgId=${orgId}`
            : `/api/v1/admin/businesses/weekly-account-summary/run`
        return Axios.post(url, {}, state.axiosAdminConfig)
            .then(async ({ data }) => {
                store.dispatch('Alert/setAlert', {
                    message: data.message,
                    status: data.status
                })

                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    runRepaymentTransactionGl: async ({ commit }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.post(`/api/v1/admin/scripts/repayment-transaction-gl-account/run`, {}, state.axiosAdminConfig)
            .then(async ({ data }) => {
                store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    runUpdateTransactionAvg: async ({ commit }) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.post(`/api/v1/admin/scripts/update-transaction-purchase-average/run`, {}, state.axiosAdminConfig)
            .then(async ({ data }) => {
                store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },

    initializeYearlyInventoryReport: async ({ commit }, payload) => {
        commit('SET_ADMIN_STATE_LOADING', true)
        return Axios.post(`/api/v1/admin/scripts/inventory/yearly-script/run`, payload, state.axiosAdminConfig)
            .then(async ({ data }) => {
                store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_ADMIN_STATE_LOADING', false)
            })
            .catch(async err => {
                console.log(err.response.data)
                if (err.response.data.message === 'Please authenticate') {
                    await store.dispatch('Admin/logout')
                } else {
                    await store.dispatch('Alert/setAlert', { message: 'Something went wrong', status: '' })
                }
                commit('SET_ADMIN_STATE_LOADING', false)
            })
    },
}

export const AdminModule = {
    namespaced: true,
    actions,
    mutations,
    state
}
