<template>
    <div>
        <CreateCategory />
        <CreateBrand />
        <VOnboardingWrapper ref="wrapper" :steps="steps2" />

        <div
            v-if="isCreateProduct"
            tabindex="0"
            @keydown.esc="closeModal"
            class="w-100 center increaseZI"
            id="add-product-form"
            :class="isCreateProduct && 'jquery-modal blocker current'"
            @click="handleOnClickOutsideModal"
        >
            <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="createProductRef">
                <div class="flex justify-between pa3 box-border-bottom">
                    <div class="f3 b">
                        {{ isServicePage === true ? 'Service' : 'Product' }}
                    </div>
                    <div>
                        <span class="pointer">
                            <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                        </span>
                    </div>
                </div>

                <div class="flex justify-center pv2">
                    <div class="flex flex-wrap justify-between items-center scrollmenux mob-arr">
                        <div class="flex pv1 ph3">
                            <div
                                @click="
                                    () => {
                                        setPage(false)
                                        setProductType('regular')
                                    }
                                "
                                :class="isServicePage === false && state.productType === 'regular' ? 'active-menu-box' : ''"
                                class="mr4 tab-cursor-pointer box-border pa2 w-30"
                            >
                                <div
                                    class="b mb2 pb1 box-border-bottom"
                                    :class="
                                        isServicePage === false && state.productType === 'regular' ? 'active-menu-box-title' : ''
                                    "
                                >
                                    Regular Product
                                </div>
                                <div>A product is a single SKU with its own inventory.</div>
                            </div>
                            <div
                                @click="
                                    () => {
                                        setPage(false)
                                        setProductType('variants')
                                    }
                                "
                                class="mr4 tab-cursor-pointer box-border pa2 w-30"
                                :class="isServicePage === false && state.productType === 'variants' ? 'active-menu-box' : ''"
                            >
                                <div
                                    class="b mb2 pb1 box-border-bottom"
                                    :class="
                                        isServicePage === false && state.productType === 'variants' ? 'active-menu-box-title' : ''
                                    "
                                >
                                    Product with variants
                                </div>
                                <div>A group of similar products with different attributes like size or color.</div>
                            </div>
                            <div
                                @click="setPage(true)"
                                :class="isServicePage === true ? 'active-menu-box' : ''"
                                class="mr4 tab-cursor-pointer box-border pa2 w-30"
                            >
                                <div
                                    class="b mb2 pb1 box-border-bottom"
                                    :class="isServicePage === true ? 'active-menu-box-title' : ''"
                                >
                                    Services
                                </div>
                                <div>A service offered by your business</div>
                            </div>
                        </div>
                    </div>
                </div>
                <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                    <div class="">
                        <div class="flex">
                            <div class="mr4" v-show="isServicePage == true">
                                <div class="pa3">
                                    <div class="row pt2">
                                        <div v-show="isServicePage == true" class="alert alert-info">
                                            Services are things we do for customers that cannot be kept in stock. We don't track
                                            inventory levels for services. You can also use this for products that you don't want
                                            to track stock levels for.
                                        </div>
                                        <div id="add-product-sku" class="w-50-l w-100 pr2-l">
                                            <div class="pv2 font-w1">
                                                <label for="sku"
                                                    >{{ isServicePage === true ? 'Service ID' : 'SKU'
                                                    }}<span class="required">*</span></label
                                                >
                                            </div>
                                            <input
                                                class="w-100"
                                                required
                                                type="text"
                                                name="sku"
                                                placeholder="sku"
                                                v-model="state.sku"
                                            />
                                        </div>
                                        <div id="barcode" class="w-50-l w-100 pl2-l" v-if="isServicePage == false">
                                            <div class="pv2 font-w1">
                                                <label for="barcode"> Barcode </label>
                                            </div>
                                            <input
                                                type="text"
                                                id="barcode"
                                                name="barcode"
                                                placeholder="0123ABC"
                                                v-model="state.barcode"
                                            />
                                            <div>
                                                <a href=""><small>Generate</small> </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex flex-wrap justify-between">
                                        <!-- Product name -->
                                        <div class="pt2 w-100 w-50-l pr2-l">
                                            <div class="pv2 font-w1">
                                                <label for="name"
                                                    >{{ isServicePage === true ? 'Service Name' : 'Product Name' }}
                                                    <span class="required">*</span></label
                                                >
                                            </div>
                                            <input
                                                required
                                                type="text"
                                                id="name"
                                                name="name"
                                                :placeholder="isServicePage !== true ? 'Product Name' : 'Service Name'"
                                                @input="onInputProdName"
                                                v-model="state.name"
                                            />
                                        </div>
                                        <div v-if="isServicePage" class="w-100 w-50-l pr2-l">
                                            <div class="pv2">
                                                <label for="size">Measurement<span class="required">*</span></label>
                                            </div>
                                            <select v-model="state.measurement" class="w-100">
                                                <option value="Unit">Unit</option>
                                                <option value="Piece">Piece</option>
                                                <option value="Kg">Kg</option>
                                                <option value="Litre">Litre</option>
                                                <option value="Dozen">Dozen</option>
                                                <option value="Carton">Carton</option>
                                                <option value="Pack">Pack</option>
                                                <option value="Portion">Portion</option>
                                                <option value="Wrap">Wrap</option>
                                                <option value="Plate">Plate</option>
                                                <option value="Bottle">Bottle</option>
                                                <option value="Cup">Cup</option>
                                                <option value="Yard">Yard</option>
                                                <option value="Pairs">Pairs</option>
                                                <option value="Hour">Hour</option>
                                                <option value="Day">Day</option>
                                                <option value="Week">Week</option>
                                                <option value="Month">Month</option>
                                                <option value="Grammes">Grammes</option>
                                            </select>
                                        </div>

                                        <!-- Product name -->
                                        <div v-if="isServicePage == false" class="pt2 w-100 w-50-l pl2-l">
                                            <div class="pv2 font-w1"><label for="name">Qty in Stock</label></div>
                                            <input
                                                required
                                                type="text"
                                                id="quantity"
                                                name="quantity"
                                                placeholder="Quantity"
                                                v-model="state.quantity"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        v-if="isServicePage == false && state.quantity < 0"
                                        class="flex flex-wrap justify-between"
                                    >
                                        <div class="pt2 w-100 w-100-l pl2-l">
                                            <div class="pv2 font-w1"><label for="accountType">Account Type</label></div>
                                            <select
                                                id="accountType"
                                                v-model="state.accountType"
                                                class="w-100"
                                                :required="state.quantity < 0"
                                            >
                                                <option value="" selected>Select account type</option>
                                                <option value="Cash">Cash</option>
                                                <option
                                                    v-for="(account, index) in bankAccounts"
                                                    v-bind:key="index"
                                                    :value="account?.aliasName || account?.bankName"
                                                >
                                                    {{ account?.aliasName || account?.bankName }}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                >Please note that if the product has existing quantity in stock, this transaction
                                                will be created as a Purchase/Buy with the Total Amount (Qty in stock x Cost
                                                Price) deducted from the balance in the account selected.</small
                                            >
                                        </div>
                                    </div>

                                    <!--  Image area-->
                                    <div
                                        class="flex flex-wrap justify-between items-center pt3"
                                        @drop.prevent="handleDragImage"
                                        @dragover.prevent
                                        @dragenter.prevent
                                    >
                                        <div class="w-20-l w-90 pb3 b f4 pb0-l box-border1">
                                            <img
                                                :src="
                                                    state?.image ||
                                                    state?.imageUrl ||
                                                    require('@/assets/images/inventory-default.png')
                                                "
                                                alt="Org Image"
                                                class="h-90 w-90 pointer"
                                                style="object-position: center; object-fit: cover"
                                                @click.prevent="handleClickUpload"
                                            />
                                        </div>
                                        <div class="box-border pa4 w-80-l w-90">
                                            <span class="dn">
                                                <input
                                                    ref="imageElRef"
                                                    type="file"
                                                    name="imageFile"
                                                    id="imageFile"
                                                    accept="image/*"
                                                    @change.prevent="handleSelectImage"
                                                />
                                            </span>
                                            <span
                                                class="font-w1 lh-copy pointer"
                                                style="color: #132c8c"
                                                @click.prevent="handleClickUpload"
                                                >Upload
                                            </span>
                                            or drag and drop your {{ isServicePage === true ? 'service' : 'product ' }} image in
                                            PNG, JPEG, JPG, GIF up to 10MB
                                        </div>
                                    </div>

                                    <div v-if="state.hasMultiBusiness && state.productType !== 'variants'" class="mt3">
                                        <div class="b mb2">
                                            <label for="create-for-other-biz">
                                                <input
                                                    id="create-for-other-biz"
                                                    type="checkbox"
                                                    v-model="state.createForOtherBiz"
                                                />
                                                Create product in other businesses
                                            </label>
                                        </div>

                                        <table v-if="state.createForOtherBiz">
                                            <thead>
                                                <tr>
                                                    <th>Check</th>
                                                    <th>Business</th>
                                                    <th>Qty in stock</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="business in state.businesses" :key="business._id">
                                                    <td><input type="checkbox" v-model="business.isSelected" /></td>
                                                    <td class="mr3">{{ business.name }}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            v-model="business.qty_in_stock"
                                                            :disabled="!business.isSelected"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <!-- textarea -->
                                    <div class="pt2" v-if="state.showMore">
                                        <div class="pv2 font-w1">
                                            <label>Description</label>
                                        </div>

                                        <textarea
                                            placeholder="Type here"
                                            v-model="state.description"
                                            name="description"
                                            id="description"
                                            cols="20"
                                            rows="5"
                                        ></textarea>
                                    </div>
                                    <!-- categories/brand -->
                                    <div class="flex pt2" v-if="state.showMore">
                                        <div class="w-50 pr2">
                                            <div class="flex flex-wrap items-center" style="gap: 0.5rem">
                                                <div class="pv2">
                                                    <label for="category">Category</label>
                                                </div>
                                                <router-link
                                                    @click.prevent="onShowCreateCategory(true)"
                                                    to="#"
                                                    class="pv2 active bold"
                                                    >+ Add Category</router-link
                                                >
                                            </div>

                                            <select name="categories" v-model="state.category" class="w-100" id="category">
                                                <option value="" selected>Please choose a category</option>
                                                <option
                                                    v-for="category of categories.data"
                                                    v-bind:key="category._id"
                                                    :value="category"
                                                >
                                                    {{ category.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="w-50 pl2">
                                            <div class="flex flex-wrap items-center" style="gap: 0.5rem">
                                                <div class="pv2"><label for="brands">Brand</label></div>
                                                <router-link
                                                    @click.prevent="onShowCreateBrand(true)"
                                                    to="#"
                                                    class="pv2 active bold"
                                                >
                                                    + Add New Brand</router-link
                                                >
                                            </div>

                                            <select name="brands" v-model="state.brand" class="w-100" id="brands">
                                                <option value="" selected>Please choose a brand</option>
                                                <option v-for="brand of brands.data" v-bind:key="brand._id" :value="brand">
                                                    {{ brand.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- size/color -->
                                    <div class="flex pt2" v-if="state.showMore && isServicePage == false">
                                        <div class="w-50 pr2">
                                            <div class="pv2"><label for="size">Size</label></div>
                                            <input
                                                type="text"
                                                v-model="state.size"
                                                placeholder="Product Size"
                                                id="size"
                                                name="size"
                                            />
                                        </div>
                                        <div class="w-50 pl2">
                                            <div class="pv2"><label for="color">Color</label></div>
                                            <input
                                                type="text"
                                                v-model="state.color"
                                                placeholder="Product Color"
                                                id="color"
                                                name="color"
                                            />
                                        </div>
                                    </div>
                                    <!-- size/color -->
                                    <div class="flex flex-wrap pt2 justify-between">
                                        <div class="w-100 w-50-l pr2-l">
                                            <div class="pv2">
                                                <label for="size"
                                                    >{{ isServicePage === false ? 'Selling price' : 'Unit Price' }}/<span>{{
                                                        state.measurement
                                                    }}</span>
                                                    <span class="required">*</span></label
                                                >
                                            </div>
                                            <input
                                                type="number"
                                                v-model="state.sellingPrice"
                                                step="any"
                                                placeholder="e.g 10000"
                                                id="sellingPrice"
                                                name="sellingPrice"
                                            />
                                        </div>
                                        <div v-if="isServicePage === false" class="w-100 w-50-l pl2-l">
                                            <div class="pv2">
                                                <label for="color">Cost price <span class="makeAstRed">*</span></label>
                                            </div>
                                            <input
                                                type="number"
                                                v-model="state.buyingPrice"
                                                step="any"
                                                placeholder="e.g 10000"
                                                id="buyingPrice"
                                                name="buyingPrice"
                                                :required="state.quantity > 0"
                                            />
                                        </div>
                                        <!-- Stock level -->
                                        <div v-if="state.showMore && isServicePage == false" class="pt2 w-100 w-100-l pl2-l">
                                            <div class="pv2 font-w1"><label for="name">Stock Level</label></div>
                                            <input
                                                required
                                                type="text"
                                                id="stockLevel"
                                                name="stockLevel"
                                                placeholder="Stock level"
                                                v-model="state.stockLevel"
                                            />
                                        </div>
                                    </div>
                                    <div class="flex flex-wrap" style="gap: 1rem">
                                        <button class="mt3" @click.prevent="toggleShowMore">
                                            {{ state.showMore ? 'Hide more ' : 'Add more ' }}fields
                                        </button>
                                        <button class="mt3" type="submit" :disabled="state.disableSubmitButton">Submit</button>
                                    </div>
                                </div>
                            </div>

                            <div v-show="isServicePage == false" class="mr4">
                                <div class="pa3">
                                    <div v-show="isServicePage == false" class="alert alert-info">
                                        Products are things you can hold and keep in stock. We keep track of how much you have in
                                        stock. If you don't want to keep track of stock levels, choose services instead.
                                    </div>

                                    <div v-if="state.productType === 'regular'" class="flex flex-wrap justify-between pt2">
                                        <div class="w-50-l w-100 pr2-l">
                                            <div class="pv2 font-w1">
                                                <label for="sku"
                                                    >{{ isServicePage === true ? 'Service ID' : 'SKU'
                                                    }}<span class="required">*</span></label
                                                >
                                            </div>
                                            <input
                                                class="w-100"
                                                required
                                                type="text"
                                                name="sku"
                                                placeholder="sku"
                                                v-model="state.sku"
                                            />
                                        </div>
                                        <div class="w-50-l w-100 pl2-l" v-if="isServicePage == false">
                                            <div class="pv2 font-w1">
                                                <label for="barcode"> Barcode </label>
                                            </div>
                                            <div
                                                v-tippy="{
                                                    content: 'This is the serial barcode on the product',
                                                    placement: 'top',
                                                    interactive: true,
                                                }"
                                            >
                                                <input
                                                    type="text"
                                                    id="barcode"
                                                    name="barcode"
                                                    placeholder="0123ABC"
                                                    v-model="state.barcode"
                                                />
                                                <div>
                                                    <a href="" class="active" @click.prevent="generateBarcodeNumber"
                                                        ><small>Generate</small></a
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex flex-wrap justify-between">
                                        <!-- Product name -->
                                        <div
                                            :class="
                                                state.productType === 'regular'
                                                    ? 'pt2 w-100 w-40-l pr2-l'
                                                    : 'pt2 w-100 w-70-l pr2-l'
                                            "
                                        >
                                            <div class="pv2 font-w1">
                                                <label for="name"
                                                    >{{ isServicePage === true ? 'Service Name' : 'Product Name' }}
                                                    <span class="required">*</span>
                                                </label>
                                            </div>
                                            <div
                                                v-tippy="
                                                    isServicePage === false
                                                        ? {
                                                              content:
                                                                  'This is the name of the product you want to sell e.g White T-shirts or Cabin Biscuits',
                                                              placement: 'top',
                                                              interactive: true,
                                                          }
                                                        : null
                                                "
                                            >
                                                <input
                                                    required
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    :placeholder="isServicePage !== true ? 'Product Name' : 'Service Name'"
                                                    @input="onInputProdName"
                                                    v-model="state.name"
                                                />
                                            </div>
                                        </div>

                                        <!-- Product name -->
                                        <div
                                            v-if="isServicePage == false && state.productType === 'regular'"
                                            class="pt2 w-100 w-30-l pl2-l"
                                        >
                                            <div class="pv2 font-w1"><label for="name">Qty in stock</label></div>
                                            <div
                                                v-tippy="{
                                                    content:
                                                        'This is the amount or number of product that you are adding to your inventory e.g 3 carton of biscuits',
                                                    placement: 'top',
                                                    interactive: true,
                                                }"
                                            >
                                                <input
                                                    required
                                                    type="text"
                                                    id="quantity"
                                                    name="quantity"
                                                    placeholder="Quantity"
                                                    v-model="state.quantity"
                                                />
                                            </div>
                                        </div>

                                        <!-- Measurement unit -->
                                        <div v-if="isServicePage == false" class="pt2 w-100 w-30-l pl2-l">
                                            <div class="pv2 font-w1"><label for="name">Measurement</label></div>
                                            <select
                                                v-model="state.measurement"
                                                class="w-100"
                                                v-tippy="{
                                                    content:
                                                        'Select the unit of measurement of the product you are inputting e.g carton for products that are in cartons',
                                                    placement: 'top',
                                                    interactive: true,
                                                }"
                                            >
                                                <option value="Unit">Unit</option>
                                                <option value="Piece">Piece</option>
                                                <option value="Kg">Kg</option>
                                                <option value="Litre">Litre</option>
                                                <option value="Dozen">Dozen</option>
                                                <option value="Carton">Carton</option>
                                                <option value="Pack">Pack</option>
                                                <option value="Portion">Portion</option>
                                                <option value="Wrap">Wrap</option>
                                                <option value="Plate">Plate</option>
                                                <option value="Bottle">Bottle</option>
                                                <option value="Cup">Cup</option>
                                                <option value="Yard">Yard</option>
                                                <option value="Pairs">Pairs</option>
                                                <option value="Hour">Hour</option>
                                                <option value="Day">Day</option>
                                                <option value="Week">Week</option>
                                                <option value="Month">Month</option>
                                                <option value="Grammes">Grammes</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div
                                        v-if="isServicePage == false && state.productType === 'regular' && state.quantity < 0"
                                        class="flex flex-wrap justify-between"
                                    >
                                        <div class="pt2 w-100 w-100-l pl2-l">
                                            <div class="pv2 font-w1"><label for="accountType">Account Type</label></div>
                                            <select
                                                id="accountType"
                                                v-model="state.accountType"
                                                class="w-100"
                                                :required="state.quantity < 0"
                                            >
                                                <option value="" selected>Select account type</option>
                                                <option value="Cash">Cash</option>
                                                <option
                                                    v-for="(account, index) in bankAccounts"
                                                    v-bind:key="index"
                                                    :value="account?.aliasName || account?.bankName"
                                                >
                                                    {{ account?.aliasName || account?.bankName }}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                >Please note that if the product has existing quantity in stock, this transaction
                                                will be created as a Purchase/Buy with the Total Amount (Qty in stock x Cost
                                                Price) deducted from the balance in the account selected.</small
                                            >
                                        </div>
                                    </div>

                                    <div v-if="state.productType === 'regular'" class="pt3">
                                        <input id="product-raw-material" type="checkbox" v-model="state.hasRawMaterial" />
                                        <label for="product-raw-material">&nbsp; Does this have Raw Material?</label>
                                    </div>

                                    <!-- Raw materials -->
                                    <div v-if="state.hasRawMaterial && state.productType === 'regular'">
                                        <p class="pt3 b">Raw Materials</p>
                                        <div
                                            v-for="(data, index) in state.rawMaterials"
                                            :key="index"
                                            class="flex flex-wrap items-center justify-between pt3"
                                        >
                                            <div class="flex flex-column products-card1">
                                                <label class="pb2">Raw Material Name</label>
                                                <v-select
                                                    label="name"
                                                    :filterable="false"
                                                    :options="state.options"
                                                    @search="onSearchRawMaterial"
                                                    v-model="data.name"
                                                    @option:selected="(item) => selectRawMaterial(data, item)"
                                                    @option:deselected="(item) => deselectRawMaterial(item)"
                                                >
                                                    <template #open-indicator="{ attributes }">
                                                        <span v-bind="attributes"></span>
                                                    </template>
                                                    <template slot="no-options"> Type to search raw material.. </template>
                                                    <template slot="option" slot-scope="option">
                                                        <div class="d-center">
                                                            {{ option.name }}
                                                        </div>
                                                    </template>
                                                    <template slot="selected-option" slot-scope="option">
                                                        <div class="selected d-center">
                                                            {{ option.name }}
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="flex flex-column products-card">
                                                <label class="pb2">Quantity</label>
                                                <CurrencyInput
                                                    class="product-input"
                                                    v-model="data.quantity"
                                                    :options="numberFormat"
                                                    placeholder="0.00"
                                                    required
                                                />
                                            </div>
                                            <div class="flex flex-column products-card">
                                                <label class="pb2">Measurement</label>
                                                <input
                                                    class="product-input"
                                                    type="text"
                                                    v-model="data.measurement"
                                                    placeholder="Length"
                                                />
                                            </div>
                                            <div class="pt4">
                                                <img
                                                    :src="require('@/assets/images/delete.svg')"
                                                    alt="Delete"
                                                    @click.stop="removeRawMaterial(index)"
                                                    style="cursor: pointer"
                                                />
                                            </div>
                                        </div>

                                        <div class="tr b pa3">
                                            <div class="pb3 box-border-bottom">
                                                <a href="" @click.prevent="addMoreRawMaterials" style="color: #132c8c">
                                                    + Add more raw material
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <!--  Image area-->
                                    <div
                                        class="flex flex-wrap justify-between items-center pt3"
                                        @drop.prevent="handleDragImage"
                                        @dragover.prevent
                                        @dragenter.prevent
                                    >
                                        <div class="w-20-l w-90 pb3 b f4 pb0-l box-border1">
                                            <img
                                                :src="
                                                    state?.image ||
                                                    state?.imageUrl ||
                                                    require('@/assets/images/inventory-default.png')
                                                "
                                                alt="Org Image"
                                                class="h-90 w-90 pointer"
                                                style="object-position: center; object-fit: cover"
                                                @click.prevent="handleClickUpload"
                                            />
                                        </div>
                                        <div class="box-border pa4 w-80-l w-90">
                                            <span class="dn">
                                                <input
                                                    ref="imageElRef"
                                                    type="file"
                                                    name="imageFile"
                                                    id="imageFile"
                                                    accept="image/*"
                                                    @change.prevent="handleSelectImage"
                                                />
                                            </span>
                                            <span
                                                class="font-w1 lh-copy pointer"
                                                style="color: #132c8c"
                                                @click.prevent="handleClickUpload"
                                                >Upload
                                            </span>
                                            or drag and drop your product image PNG, JPG, GIF up to 10MB
                                        </div>
                                    </div>

                                    <div v-if="state.productType === 'variants' && isServicePage === false" class="mv2">
                                        <div
                                            v-for="(variant, index) of state.variants"
                                            class="flex flex-wrap pt2 justify-between box-border-top box-border-bottom pv2"
                                            :key="index"
                                            style="border-width: 2px"
                                        >
                                            <div class="w-100 w-40-l pr2-l">
                                                <div class="pv2">
                                                    <label for="size">Variant Name <span class="required">*</span></label>
                                                </div>
                                                <div
                                                    v-tippy="{
                                                        content: 'This is the price you wish to sell to the customer',
                                                        placement: 'top',
                                                        interactive: true,
                                                    }"
                                                >
                                                    <input
                                                        type="text"
                                                        v-model="variant.name"
                                                        step="any"
                                                        placeholder="e.g Red"
                                                        id="variantName"
                                                        name="variantName"
                                                    />
                                                </div>
                                            </div>
                                            <div id="add-product-sku" class="w-100 w-30-l pr2-l">
                                                <div class="pv2 font-w1">
                                                    <label for="sku">SKU<span class="required">*</span></label>
                                                </div>
                                                <input
                                                    class="w-100"
                                                    required
                                                    type="text"
                                                    name="sku"
                                                    placeholder="sku"
                                                    v-model="variant.sku"
                                                />
                                            </div>
                                            <div id="barcode" class="w-100 w-30-l pl2-l">
                                                <div class="pv2 font-w1">
                                                    <label for="barcode"> Barcode </label>
                                                </div>
                                                <input
                                                    type="text"
                                                    id="barcode"
                                                    name="barcode"
                                                    placeholder="0123ABC"
                                                    v-model="variant.barcode"
                                                />
                                            </div>
                                            <div class="w-100 w-40-l pl2-l">
                                                <div class="pv2 font-w1"><label for="name">Qty in Stock</label></div>
                                                <input
                                                    required
                                                    type="text"
                                                    id="quantity"
                                                    name="quantity"
                                                    placeholder="Quantity"
                                                    v-model="variant.quantity"
                                                />
                                            </div>
                                            <div class="w-100 w-30-l pr2-l">
                                                <div class="pv2">
                                                    <label for="size"
                                                        >Selling price/<span>{{ state.measurement }}</span>
                                                        <span class="required">*</span></label
                                                    >
                                                </div>
                                                <div
                                                    v-tippy="{
                                                        content: 'This is the price you wish to sell to the customer',
                                                        placement: 'top',
                                                        interactive: true,
                                                    }"
                                                >
                                                    <input
                                                        type="number"
                                                        v-model="variant.sellingPrice"
                                                        step="any"
                                                        placeholder="e.g 10000"
                                                        id="sellingPrice"
                                                        name="sellingPrice"
                                                    />
                                                </div>
                                            </div>
                                            <div class="w-100 w-30-l pl2-l">
                                                <div class="pv2">
                                                    <label for="color"
                                                        >Cost price/<span>{{ state.measurement }}</span>
                                                        <span class="required">*</span></label
                                                    >
                                                </div>
                                                <div
                                                    v-tippy="{
                                                        content: 'This the cost of purchasing the product',
                                                        placement: 'top',
                                                        interactive: true,
                                                    }"
                                                >
                                                    <input
                                                        type="number"
                                                        v-model="variant.buyingPrice"
                                                        step="any"
                                                        placeholder="e.g 10000"
                                                        id="buyingPrice"
                                                        name="buyingPrice"
                                                        :required="variant.quantity > 0"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt2">
                                            <router-link @click.prevent="addMoreVariants" to="#" class="pv2 active bold"
                                                >+ Add Variant</router-link
                                            >
                                        </div>
                                    </div>

                                    <!-- textarea -->
                                    <div class="pt2" v-if="state.showMore">
                                        <div class="pv2 font-w1">
                                            <label>Description</label>
                                        </div>

                                        <textarea
                                            v-tippy="{
                                                content:
                                                    ' A description helps you distinguish the product from other similar products',
                                                placement: 'top',
                                                interactive: true,
                                            }"
                                            placeholder="Please enter product description"
                                            v-model="state.description"
                                            name="description"
                                            id="description"
                                            cols="20"
                                            rows="5"
                                        ></textarea>
                                    </div>
                                    <!-- categories/brand -->
                                    <div class="flex pt2" v-if="state.showMore">
                                        <div class="w-50 pr2">
                                            <div class="flex flex-wrap items-center" style="gap: 0.5rem">
                                                <div class="pv2">
                                                    <label for="category">Category</label>
                                                </div>
                                                <router-link
                                                    @click.prevent="onShowCreateCategory(true)"
                                                    to="#"
                                                    class="pv2 active bold"
                                                    >+ Add Category</router-link
                                                >
                                            </div>
                                            <select
                                                name="categories"
                                                v-model="state.category"
                                                class="w-100"
                                                id="category"
                                                v-tippy="{
                                                    content:
                                                        'Choose the category of the product e.g for Drink, category can be soft drinks',
                                                    placement: 'top',
                                                    interactive: true,
                                                }"
                                            >
                                                <option value="" selected>Please choose a category</option>
                                                <option
                                                    v-for="category of categories.data"
                                                    v-bind:key="category._id"
                                                    :value="category"
                                                >
                                                    {{ category.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="w-50 pl2">
                                            <div class="flex flex-wrap items-center" style="gap: 0.5rem">
                                                <div class="pv2"><label for="brands">Brand</label></div>
                                                <router-link
                                                    @click.prevent="onShowCreateBrand(true)"
                                                    to="#"
                                                    class="pv2 active bold"
                                                >
                                                    + Add New Brand</router-link
                                                >
                                            </div>

                                            <select
                                                name="brands"
                                                v-model="state.brand"
                                                class="w-100"
                                                id="brands"
                                                v-tippy="{
                                                    content:
                                                        'Add the brand that manufactured or produced the product e.g Coca cola',
                                                    placement: 'top',
                                                    interactive: true,
                                                }"
                                            >
                                                <option value="" selected>Please choose a brand</option>
                                                <option v-for="brand of brands.data" v-bind:key="brand._id" :value="brand">
                                                    {{ brand.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- size/color -->
                                    <div class="flex pt2" v-if="state.showMore && isServicePage == false">
                                        <div class="w-50 pr2">
                                            <div class="pv2"><label for="size">Size</label></div>
                                            <div
                                                v-tippy="{
                                                    content: 'Input the size of the product you are adding to your inventory',
                                                    placement: 'top',
                                                    interactive: true,
                                                }"
                                            >
                                                <input
                                                    type="text"
                                                    v-model="state.size"
                                                    placeholder="Product Size"
                                                    id="size"
                                                    name="size"
                                                />
                                            </div>
                                        </div>
                                        <div class="w-50 pl2">
                                            <div class="pv2"><label for="color">Color</label></div>
                                            <input
                                                type="text"
                                                v-model="state.color"
                                                placeholder="Product Color"
                                                id="color"
                                                name="color"
                                            />
                                        </div>
                                    </div>

                                    <div v-if="state.hasMultiBusiness && state.productType !== 'variants'" class="mt3">
                                        <div class="b mb2">
                                            <label for="create-for-other-biz">
                                                <input
                                                    id="create-for-other-biz"
                                                    type="checkbox"
                                                    v-model="state.createForOtherBiz"
                                                />
                                                Create product in other businesses
                                            </label>
                                        </div>
                                        <table v-if="state.createForOtherBiz">
                                            <thead>
                                                <tr>
                                                    <th>Check</th>
                                                    <th>Business</th>
                                                    <th>Qty in stock</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="business in state.businesses" :key="business._id">
                                                    <td><input type="checkbox" v-model="business.isSelected" /></td>
                                                    <td class="mr3">{{ business.name }}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            v-model="business.qty_in_stock"
                                                            :disabled="!business.isSelected"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- size/color -->
                                    <div class="flex flex-wrap pt2 justify-between">
                                        <div
                                            v-if="isServicePage === true || state.productType === 'regular'"
                                            class="w-100 w-50-l pr2-l"
                                        >
                                            <div class="pv2">
                                                <label for="size"
                                                    >{{ isServicePage === false ? 'Selling price' : 'Unit Price' }}/<span>{{
                                                        state.measurement
                                                    }}</span>
                                                    <span class="required">*</span></label
                                                >
                                            </div>
                                            <div
                                                v-tippy="
                                                    isServicePage === false
                                                        ? {
                                                              content: 'This is the price you wish to sell to the customer',
                                                              placement: 'top',
                                                              interactive: true,
                                                          }
                                                        : null
                                                "
                                            >
                                                <input
                                                    type="number"
                                                    v-model="state.sellingPrice"
                                                    step="any"
                                                    placeholder="e.g 10000"
                                                    id="sellingPrice"
                                                    name="sellingPrice"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-if="isServicePage !== true && state.productType === 'regular'"
                                            class="w-100 w-50-l pl2-l"
                                        >
                                            <div class="pv2">
                                                <label for="color"
                                                    >Cost price/<span>{{ state.measurement }}</span>
                                                    <span class="required">*</span></label
                                                >
                                            </div>
                                            <div
                                                v-tippy="{
                                                    content: 'This the cost of purchasing the product',
                                                    placement: 'top',
                                                    interactive: true,
                                                }"
                                            >
                                                <input
                                                    type="number"
                                                    v-model="state.buyingPrice"
                                                    step="any"
                                                    placeholder="e.g 10000"
                                                    id="buyingPrice"
                                                    name="buyingPrice"
                                                    :required="state.quantity > 0"
                                                />
                                            </div>
                                        </div>
                                        <!-- Stock level -->
                                        <div v-if="state.showMore && isServicePage === false" class="pt2 w-100 w-100-l pl2-l">
                                            <div class="pv2 font-w1">
                                                <label for="name">Minimum stock level/reorder level</label>
                                            </div>
                                            <input
                                                required
                                                type="text"
                                                id="stockLevel"
                                                name="stockLevel"
                                                placeholder="Stock level"
                                                v-model="state.stockLevel"
                                            />
                                        </div>
                                        <!-- Reminder -->
                                        <div v-if="state.showMore && isServicePage !== true" class="w-100 w-50-l pr2-l">
                                            <div class="pv2">
                                                <label for="expiryDate">Expiry Date</label>
                                            </div>
                                            <div
                                                v-tippy="{
                                                    content: 'The expiry date of the product if applicable',
                                                    placement: 'top',
                                                    interactive: true,
                                                }"
                                            >
                                                <input
                                                    type="date"
                                                    v-model="state.expiryDate"
                                                    step="any"
                                                    placeholder="e.g 10000"
                                                    id="expiryDate"
                                                    name="expiryDate"
                                                />
                                            </div>
                                        </div>
                                        <div v-if="state.showMore && isServicePage !== true" class="w-100 w-50-l pl2-l">
                                            <div class="pv2">
                                                <label for="expiryReminderDays"
                                                    >Remind me
                                                    {{ state.expiryReminderDays ? state.expiryReminderDays : '…' }} days to
                                                    expiry</label
                                                >
                                            </div>
                                            <div
                                                v-tippy="{
                                                    content: 'The number of days to remind you before product expires',
                                                    placement: 'top',
                                                    interactive: true,
                                                }"
                                            >
                                                <input
                                                    type="number"
                                                    v-model="state.expiryReminderDays"
                                                    step="any"
                                                    min="1"
                                                    max="100"
                                                    placeholder="e.g 10"
                                                    id="expiryReminderDays"
                                                    name="expiryReminderDays"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-wrap" style="gap: 1rem">
                                        <button class="mt3" @click.prevent="toggleShowMore">
                                            {{ state.showMore ? 'Hide more ' : 'Add more ' }}fields
                                        </button>
                                        <button class="mt3" type="submit" :disabled="state.disableSubmitButton">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
import ShortUniqueId from 'short-unique-id'
import { getNewSku } from '@/utils/lib'

import CreateBrand from '@/components/CreateBrand'
import CreateCategory from '@/components/CreateCategory'
import CurrencyInput from '@/components/CurrencyInput'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    name: 'CreateProduct',
    props: {
        productName: String,
        closeModal: Function,
        transactionCategory: String,
        variantProduct: String,
    },
    components: {
        CreateCategory,
        CreateBrand,
        CurrencyInput,
        vSelect,
        VOnboardingWrapper,
    },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true,
            },
        }
    },

    setup(props, { emit }) {
        const store = useStore()
        const route = useRoute()
        const createProductRef = ref(null)
        const imageElRef = ref(null)
        const productSku = computed(() => store?.state.Inventory.productSku)
        const isCreateProduct = computed(() => store?.state.Inventory.showCreateProduct)
        const createProductType = computed(() => store?.state.Inventory.createProductType)
        const incomingProductName = computed(() => store?.state.Inventory.incomingProductName)
        const incomingProductIndex = computed(() => store?.state.Inventory.incomingProductIndex)
        const brands = computed(() => store?.state.Inventory.brands)
        const categories = computed(() => store?.state.Inventory.categories)
        const category = computed(() => store?.state.Inventory.category)
        const brand = computed(() => store?.state.Inventory.brand)
        const createProductNotifier = computed(() => store.state.Inventory?.createProductNotifier)
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)
        const businesses = computed(() => store.state?.Settings?.businesses)
        const productNameRef = ref(null)
        const formRef = ref(null)
        const brandRef = ref(null)
        const isServicePage = ref(true)
        const theType = ref('')
        // const route = useRoute()
        // const route = useRoute()
        const createProductTourStatus = computed(() => store.state?.Settings?.createProductTourStatus)
        const createServiceTourStatus = computed(() => store.state?.Settings?.createServiceTourStatus)
        const wrapper = ref(null)
        const { start } = useVOnboarding(wrapper)
        const steps2 = [
            {
                attachTo: { element: '#add-product-sku' },
                content: {
                    title: 'SKU',
                    description: 'You can change your Stock Keeping Unit',
                },
            },
            {
                attachTo: { element: '#barcode' },
                content: {
                    title: 'Barcode',
                    description: 'Make your product searchable and seameless using barcode',
                },
            },
            {
                attachTo: { element: '#enter-item' },
                content: {
                    title: 'Select Item',
                    description: 'Enter or select the item sold',
                },
            },
            {
                attachTo: { element: '#more-items' },
                content: {
                    title: 'Add More Items',
                    description: 'Sold more than one item, add more by clicking here',
                },
            },
            {
                attachTo: { element: '#additional-cost' },
                content: {
                    title: 'Additional Cost',
                    description:
                        'You can add additional costs like delivery, commission, VAT, discount, and other fees to your sales',
                },
            },
            {
                attachTo: { element: '#add-customer' },
                content: {
                    title: 'Add Customer',
                    description: 'Select or create a customer',
                },
            },
            {
                attachTo: { element: '#total-amount' },
                content: {
                    title: 'Total Amount',
                    description: 'The total amount of the transaction',
                },
            },
            {
                attachTo: { element: '#add-date' },
                content: {
                    title: 'Enter Date',
                    description: 'Enter the date the transaction happened',
                },
            },
            {
                attachTo: { element: '#is-credit' },
                content: {
                    title: 'Is Credit Transaction?',
                    description: 'If the transaction is on credit, check this box',
                },
            },
            {
                attachTo: { element: '#create-transaction' },
                content: {
                    title: 'Create Transaction',
                    description: 'Click the create transaction button to display a modal finalize',
                },
                on: {
                    afterStep: function () {
                        stopCreateProductTour()
                    },
                },
            },
        ]

        const initialState = () => ({
            disableSubmitButton: false,
            name: '',
            sku: '',
            lastSku: '',
            barcode: '',
            isBarcodeAutoGenerated: false,
            description: '',
            measurement: 'Unit',
            quantity: 0,
            accountType: '',
            category: '',
            categoryName: '',
            brand: '',
            brandName: '',
            size: '',
            color: '',
            buyingPrice: 0,
            sellingPrice: 0,
            expiryDate: null,
            expiryReminderDays: null,
            stockLevel: 0,
            image: '',
            imageUrl: '',
            imageFile: '',
            hasRawMaterial: false,
            rawMaterials: [
                {
                    id: '',
                    name: '',
                    quantity: '',
                    price: 0,
                    measurement: 'Unit',
                },
            ],
            variants: [
                {
                    name: '',
                    quantity: 0,
                    sku: '',
                    barcode: '',
                    buyingPrice: 0,
                    sellingPrice: 0,
                    stockLevel: 0,
                },
            ],
            options: [],
            showMore: false,
            type: createProductType?.value,
            productType: 'regular',
            businesses: [],
            hasMultiBusiness: false,
            createForOtherBiz: true,
        })

        const state = reactive(initialState())

        const toggleShowMore = () => {
            state.showMore = !state.showMore
        }

        const setProductType = (type) => {
            state.productType = type
            if (type === 'variants' && state.variants.length === 1) {
                const newSku = getNewSku(state.sku)
                state.variants[0].sku = newSku
                state.lastSku = newSku
            }
        }

        watch(
            () => state.createForOtherBiz,
            (newVal) => {
                state.businesses.map((business) => (business.isSelected = newVal))
            }
        )

        // watch(
        //     () => props.variantProduct,
        //     () => {
        //         if (props.variantProduct === 'variants') {
        //             state.productType = 'variants'
        //             setProductType('variants')
        //         } else {
        //             state.productType = 'regular'
        //             setProductType('regular')
        //         }
        //         console.log('newwwwerrrr', props.variantProduct)
        //     }
        // )


        watchEffect(() => {
            //console.log("I am watched ", state.productType);
            if (props.variantProduct === 'variants') {
                state.productType = 'variants'
                setProductType(props.variantProduct)
            }  else {
                state.productType = 'regular'
                setProductType('regular')
            }
        })


        watch(
            () => productSku.value,
            () => {
                // state.sku = productSku.value
                // store.dispatch('Inventory/getProductSku', createProductType.value).then(data => {
                //     state.sku = data
                // })
            }
        )

        watch(
            () => incomingProductName.value,
            () => {
                // eslint-disable-next-line no-underscore-dangle
                state.name = incomingProductName.value
                // console.log(state.category, 'new')
            }
        )

        watch(
            () => category.value,
            () => {
                state.category = category.value
                // console.log(state.category, 'new')
            }
        )

        watch(
            () => brand.value,
            () => {
                // eslint-disable-next-line no-underscore-dangle
                state.brand = brand.value
                // console.log(brand.value, 'new')
            }
        )

        watch(
            () => isCreateProduct.value,
            () => {
                document.body.style.overflow = isCreateProduct.value ? 'hidden' : 'auto'
                isServicePage.value = store?.state.Inventory.createProductType == 'SERVICE'
                if (isCreateProduct.value === true && createProductTourStatus.value === true) {
                    start()
                }
            }
        )

        // change type
        // watch(
        //     () => createProductType.value,
        //     () => {
        //         if (theType.value === 'PRODUCT') {
        //             state.type = 'PRODUCT'
        //         } else {
        //             state.type = 'SERVICE'
        //         }
        //     }
        // )

        /* eslint-disable */
        const handleCloseModal = () => {
            // console.log('Esc key pressed.')
        }

        const onShowCreateBrand = (payload = true) => {
            store.dispatch('Inventory/showCreateBrand', payload)
        }
        const onShowCreateCategory = (payload = true) => {
            store.dispatch('Inventory/showCreateCategory', payload)
        }

        const onInputProdName = () => {
            store.dispatch('Inventory/showIncomingProductName', state.name)
        }

        const onChange = (event, stateName) => {
            if (stateName !== 'category' && stateName !== 'brand') {
                state[stateName] = event.target.value
            }
        }

        const closeCreateProductModal = () => {
            state.quantity = 0
            props.closeModal()
            store.dispatch('Inventory/showCreateProduct', { show: false, cat: createProductType.value })
        }

        const refreshList = () => {
            const query = route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null
            if (createProductType.value === 'PRODUCT') {
                store.dispatch('Inventory/getAllProducts', query)
            } else {
                store.dispatch('Inventory/getAllServices', query)
            }

            store.dispatch('Inventory/getProductSku', createProductType.value).then((data) => {
                state.sku = data
                state.lastSku = data
            })
        }

        const addMoreVariants = () => {
            state.lastSku = getNewSku(state.lastSku)
            state.variants.push({
                name: '',
                quantity: 0,
                sku: state.lastSku,
                barcode: '',
                buyingPrice: 0,
                sellingPrice: 0,
                stockLevel: 0,
            })
        }

        const addMoreRawMaterials = () => {
            state.rawMaterials.push({ name: '', quantity: '', measurement: 'Unit' })
        }

        const generateBarcodeNumber = () => {
            const min = 10000000
            const max = 99999999
            const code = Math.floor(Math.random() * (max - min + 1) + min)
            state.barcode = code
            state.isBarcodeAutoGenerated = true
        }

        const onSearchRawMaterial = (search, loading) => {
            if (search.length) {
                loading(true)
                store.dispatch('Production/searchProduction', { query: search, type: 'ALL' }).then((data) => {
                    state.options = data.products
                    loading(false)
                })
            }
        }

        const selectRawMaterial = (rawMaterial, item) => {
            rawMaterial.id = item._id
            rawMaterial.name = item.name
            rawMaterial.quantity = 0
            rawMaterial.price = item?.purchase_avg || 0
            rawMaterial.measurement = item.measurement
        }

        const deselectRawMaterial = (rawMaterial, item) => {
            rawMaterial.name = ''
            rawMaterial.quantity = ''
            rawMaterial.measurement = ''
        }
        const removeRawMaterial = (index) => {
            state.rawMaterials.splice(index, 1)
        }

        const onSubmit = () => {
            // To toggle type
            if (theType.value === 'PRODUCT') {
                state.type = 'PRODUCT'
            } else {
                state.type = 'SERVICE'
            }

            state.quantity = state.quantity || 0
            state.buyingPrice = state.buyingPrice || 0
            state.sellingPrice = state.sellingPrice || 0

            if (state.quantity) {
                if (!state.buyingPrice && createProductType.value !== 'SERVICE') {
                    state.disableSubmitButton = false
                    return store.dispatch('Alert/setAlert', { message: 'Cost price is required', status: false })
                }

                if (!state.sellingPrice && state.sellingPrice !== 0) {
                    state.disableSubmitButton = false
                    return store.dispatch('Alert/setAlert', { message: 'Selling price is required', status: false })
                }
                store.dispatch('Inventory/setTempInStockValue', state.quantity)
            }

            state.disableSubmitButton = true
            if (incomingProductName.value && !state.name) {
                state.name = productNameRef.value?.value || incomingProductName.value
            }

            //console.log(state, 'state')

            if (state.category) {
                state.categoryName = state.category?.name
                state.category = state.category?._id
            }

            if (state.brand) {
                state.brandName = state.brand?.name
                state.brand = state.brand?._id
            }

            if (state.buyingPrice || state.buyingPrice == 0) {
                state.buyingPrice = parseFloat(state.buyingPrice || 0)
            } else if (theType.value === 'PRODUCT') {
                state.disableSubmitButton = false
                return store.dispatch('Alert/setAlert', { message: 'Please enter your buying price', status: false })
            }

            if (state.sellingPrice || state.sellingPrice == 0) {
                state.sellingPrice = parseFloat(state.sellingPrice)
            } else {
                state.disableSubmitButton = false
                return store.dispatch('Alert/setAlert', { message: 'Please enter your selling price', status: false })
            }

            const { disableSubmitButton, options, ...data } = state

            const formData = new FormData()

            const dataKeys = Object.keys(data)

            dataKeys.forEach((dataKey) => {
                if (state.hasRawMaterial && dataKey === 'rawMaterials') {
                    data[dataKey] = JSON.stringify(data[dataKey])
                } else if (!state.hasRawMaterial && dataKey === 'rawMaterials') {
                    return
                }

                const excludeFields = ['variants', 'businesses', 'productType', 'hasMultiBusiness']

                if (excludeFields.includes(dataKey)) {
                    return
                }

                if (data[dataKey]) formData.append(dataKey, data[dataKey])
            })

            let variants = state.variants.filter((variant) => variant.name !== '')
            variants = variants.map((variant) => {
                if (state.category) {
                    variant.categoryName = state.category?.name
                    variant.category = state.category?._id
                }

                if (state.brand) {
                    variant.brandName = state.brand?.name
                    variant.brand = state.brand?._id
                }
                variant.type = createProductType.value
                variant.measurement = state.measurement
                return variant
            })

            if (variants.length) {
                formData.append('variants', JSON.stringify(variants))
            }

            let selectedBusinesses = state.businesses.filter((business) => business.isSelected === true)
            selectedBusinesses = selectedBusinesses.map((business) => {
                return { id: business.org, name: business.name, email: business.orgEmail, qty_in_stock: business.qty_in_stock }
            })

            if (selectedBusinesses.length) {
                formData.append('businesses', JSON.stringify(selectedBusinesses))
            }

            const newQuery = route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null
            store.dispatch('Inventory/toggleLoadingState', true)

            if (state.buyingPrice == 0) {
                formData.append('buyingPrice', 0)
            }

            if (state.sellingPrice == 0) {
                formData.append('sellingPrice', 0)
            }

            if (state.productType === 'variants') {
                formData.append('hasVariants', true)
            }

            if (state.productType === 'variants') {
                formData.append('variantsCount', state.variants.length)
            }

            store.dispatch('Inventory/createProduct', formData).then((resp) => {
                if (state.quantity) {
                    if (state.type === 'PRODUCT') {
                        const price = parseFloat(state.buyingPrice || 0)
                        const qty = parseFloat(state.quantity || 0)
                        const amount = price * qty
                        const prodTx = {
                            type: 'inflows', // 'purchases',
                            subtype: 'stock_introduced',
                            date: new Date(),
                            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
                            items: [
                                { productName: state.name, product: createProductNotifier?.value?._id, price, quantity: qty },
                            ],
                            amount: parseFloat(amount),
                        }

                        if (state.productType === 'variants') {
                            prodTx.variants = state.variants
                        }

                        store.dispatch('Transaction/createNewTransaction', prodTx).then(() => {
                            store.dispatch('Inventory/getAllProducts', newQuery)

                            store.dispatch('Inventory/toggleLoadingState', false)
                        })
                    } else {
                        const name = state.name
                        const prodTx = {
                            type: 'inflows', // 'purchases',
                            subtype: 'stock_introduced',
                            date: new Date(),
                            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
                            services: [{ name, service: createProductNotifier?.value?._id, price: state.sellingPrice }],
                            unit_price: parseFloat(state.sellingPrice),
                        }
                        store.dispatch('Transaction/createNewTransaction', prodTx)
                    }
                }

                if (resp.success) {
                    // if route is invoiceNew, to do...
                    store.dispatch('Inventory/getProductEntryBySearch', { query: state.name, type: 'BOTH' })

                    // const newQuery =
                    //     route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null

                    // store.dispatch('Inventory/getAllProducts', null)
                    const data2 = resp?.productDetails
                    data2.qty_in_stock = state.quantity
                    console.log("data2", incomingProductIndex.value)

                    data2.index = incomingProductIndex.value

                    emit('onCreated', data2)


                    // if (route.name === 'InvoiceNew') {
                    //     store.dispatch('Invoice/getNewProduct', resp.productDetails)
                    // }
                    Object.assign(state, initialState())
                    formRef.value.reset()
                    refreshList()
                    closeCreateProductModal()
                } else {
                    state.disableSubmitButton = false
                }
            })
        }

        const handleOnClickOutsideModal = (e) => {
            if (createProductRef.value && !createProductRef.value.contains(e.target)) {
                closeCreateProductModal()
            }
        }

        const handleClickUpload = () => {
            // eslint-disable-next-line
            imageElRef?.value?.click()
        }

        const handleSelectImage = (e) => {
            if (e?.target?.files?.length) {
                const file = e.target.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleDragImage = (e) => {
            if (e?.dataTransfer?.files?.length) {
                const file = e.dataTransfer.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.dataTransfer.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        // I am using this use effect to check the route name is pointed to Services or not then using it toggle isServicePage.value
        // This effect is being used for the modal tab of Service and Product.
        watchEffect(() => {
            if (route.name === 'Services') {
                isServicePage.value = true
            } else {
                isServicePage.value = false
            }
        })

        watchEffect(() => {
            if (isServicePage.value === true) {
                theType.value = 'SERVICE'
            }
            if (isServicePage.value === false) {
                theType.value = 'PRODUCT'
            }
            store.dispatch('Inventory/getProductSku', theType.value).then((data) => {
                state.sku = data
                state.lastSku = data
            })
        })

        // We are using this to toggle the service page to true or false
        const setPage = (isCurrentlyServicePage) => (isServicePage.value = isCurrentlyServicePage)

        const startCreateProductTour = () => {
            if (createProductTourStatus.value) {
                start()
            }
        }

        const stopCreateProductTour = () => {
            store.dispatch('Settings/stopCreateProductTour')
        }

        const startCreateServiceTour = () => {
            if (createServiceTourStatus.value) {
                start()
            }
        }

        const stopCreateServiceTour = () => {
            store.dispatch('Settings/stopCreateServiceTour')
        }

        const checkMultipleBusinesses = () => {
            state.hasMultiBusiness = businesses.value.length > 0
            state.businesses = businesses.value.map((business) => {
                business.isSelected = true
                business.qty_in_stock = 0
                return business
            })
        }

        onMounted(() => {
            store.dispatch('Settings/getBankAccounts')
            store.dispatch('Inventory/getAllCategories')
            store.dispatch('Inventory/getAllBrands')
            state.hasMultiBusiness = businesses.value.length > 0
            state.businesses = businesses.value.map((business) => {
                business.isSelected = true
                business.qty_in_stock = 0
                return business
            })
            // startCreateProductTour()
            // startCreateServiceTour()
        })
        watchEffect(() => {
            checkMultipleBusinesses()
        })

        return {
            state,
            onShowCreateBrand,
            onShowCreateCategory,
            isCreateProduct,
            createProductType,
            productNameRef,
            formRef,
            incomingProductName,
            brands,
            categories,
            bankAccounts,
            businesses,
            onChange,
            onInputProdName,
            addMoreRawMaterials,
            onSearchRawMaterial,
            selectRawMaterial,
            deselectRawMaterial,
            removeRawMaterial,
            addMoreVariants,
            onSubmit,
            brandRef,
            handleCloseModal,
            handleOnClickOutsideModal,
            createProductRef,
            handleClickUpload,
            handleDragImage,
            handleSelectImage,
            imageElRef,
            toggleShowMore,
            isServicePage,
            setPage,
            route,
            theType,
            wrapper,
            steps2,
            setProductType,
            generateBarcodeNumber,
            checkMultipleBusinesses,
        }
    },
}
</script>

<style scoped>
.tab-cursor-pointer {
    cursor: pointer;
}
.vs__dropdown-toggle {
    padding: 6px;
}
/* .increaseZI{
    z-index: 1000000 !important;
} */
@media screen and (max-width: 550px) {
    .mob-arr div {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 15px;
    }
}
</style>
