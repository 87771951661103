function addStyles(win, styles) {
    styles.forEach((style) => {
        let link = win.document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("type", "text/css");
        link.setAttribute("href", style);
        win.document.getElementsByTagName("head")[0].appendChild(link);
    });
}

const VueHtmlToPaper = {
    install(app, options = {}) {
        app.config.globalProperties.$htmlToPaper = (
            el,
            localOptions,
            cb = () => true
        ) => {
            let defaultName = "_blank",
                defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
                defaultReplace = true,
                defaultStyles = [];
            let {
                name = defaultName,
                specs = defaultSpecs,
                replace = defaultReplace,
                styles = defaultStyles
            } = options;

            // If has localOptions
            // TODO: improve logic
            if (!!localOptions) {
                if (localOptions.name) name = localOptions.name;
                if (localOptions.specs) specs = localOptions.specs;
                if (localOptions.replace) replace = localOptions.replace;
                if (localOptions.styles) styles = localOptions.styles;
            }

            specs = !!specs.length ? specs.join(",") : "";

            const element = window.document.getElementById(el);

            if (!element) {
                alert(`Element to print #${el} not found!`);
                return;
            }

            const url = "";
            const win = window.open(url, name, specs, replace);

            win.document.write(`
          <html>
            <head>
              <title>${window.document.title}</title>
              <style>
                @page {
                    margin: 0;
                }
                html {
                    line-height: 1.15;
                    -webkit-text-size-adjust: 100%;
                }
                body {
                    font-family: monospace, monospace, sans-serif !important;
                    -webkit-print-color-adjust: exact !important;
                    font-weight: 400;
                    font-size: 0.7em;
                    color: #000000 !important;
                }
                .dark-green, dark-red, .color-ash {
                    color: #000000 !important;
                }
                .box-border {
                    border: 1px solid #e3e8ee;
                    border-radius: 4px;
                }
                .box-border-bottom {
                    border-bottom: 1px solid #e3e8ee;
                }
                @media screen and (min-width: 60em) {
                    .w-50-l {
                        width: 50%;
                    }
                }
                .mr-auto {
                    margin-right: auto;
                }
                .center {
                    text-align: center;
                }
                .mt4 {
                    margin-top: 2rem;
                }
                .w-50 {
                    width: 50%;
                }
                .flex {
                    display: flex;
                }
                .flex-column {
                    flex-direction: column;
                }
                .justify-between {
                    justify-content: space-between;
                }
                .justify-center {
                    justify-content: center;
                }
                .items-center {
                    align-items: center;
                }
                .w-100 {
                    width: 100%;
                }
                .b {
                    font-weight: 700;
                }
                .pt3 {
                    padding-top: 1rem;
                }
                .pv1 {
                    padding-top: 0.25rem;
                    padding-bottom: 0.25rem;
                }
                .pv2 {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }
                .pv3 {
                    padding-top: .6rem;
                    padding-bottom: .6rem;
                }
                .pv4 {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
                .pr2 {
                    padding-right: 0.5rem;
                }
                .b {
                    font-weight: 700;
                }
                .mr1 {
                    margin-right: 0.25rem;
                }
                img {
                    max-width: 100%;
                    border-style: none;
                }
                .font-w3 {
                    font-weight: 800;
                }
                .font-w1 {
                    font-weight: 500;
                }
                .pa2 {
                    padding: 0.5rem;
                }
                .pa3 {
                    padding: 1rem;
                }
                .tc {
                    text-align: center;
                }
                .ttc {
                    text-transform: capitalize;
                }
                @media screen and (min-width: 60em) {
                    .pa4-l {
                        padding: 2rem;
                    }
                    .f4-l {
                        font-size: 1.15rem;
                    }
                    .pr5-l {
                        padding-right: 4rem;
                    }
                    .pl5-l {
                        padding-left: 4rem;
                    }
                    .ph4-l {
                        padding-left: 2rem;
                        padding-right: 2rem;
                    }
                }
                .border-box, a, article, aside, blockquote, body, code, dd, div, dl, dt, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, html, input[type=email], input[type=number], input[type=password], input[type=tel], input[type=text], input[type=url], legend, li, main, nav, ol, p, pre, section, table, td, textarea, th, tr, ul {
                    box-sizing: border-box;
                }
                .action-btns {
                    display: none;
                }
                .dark-green {
                    color: #137752;
                }
                .org-initials {
                    font-size: 12.5px;
                }
                .pt2 {
                    padding-top: 0.5rem;
                }
                .pl2 {
                    padding-left: 0.5rem;
                }

                .items-warapper .pv3 {
                    padding-top: 0.25rem;
                    padding-bottom: 0.25rem;
                }
                .text-danger {
                    color: #a94442;
                }

                .text-warning {
                    color: #8a6d3b;
                }

                .text-info {
                    color: #31708f;
                }

                .text-success {
                    color: #3c763d;
                }
              </style>
            </head>
            <body>
              ${element.innerHTML}
            </body>
          </html>
        `);

            addStyles(win, styles);

            setTimeout(() => {
                win.document.close();
                win.focus();
                win.print();
                win.close();
                cb();
            }, 1000);

            return true;
        };
    }
};

export default VueHtmlToPaper;