<template>
    <div class="flex flex-wrap">
        <Alert />
        <div
            class="flex items-center justify-center w-40-l w-100"
            style="background-size: contain; repeat: no-repeat"
            :style="`background-image: url(${require('@/assets/images/login-bg.jpg')})`"
        >
            <div>
                <div class="pb2 dn-l db tc">
                    <img src="../../assets/images/simplebks-logo.png" style="width: 180px" alt="simplebks-logo" />
                </div>
                
                <h3 class="tc pt3" style="font-size: 2em;">Sign Up</h3>
                <div class="tc">Your journey to a structured business and ease of accessing finance begins here</div>
                <form class="center pa5-l pa3" @submit.prevent="signUp" id="form">
                    <div class="flex flex-column pb3">
                        <label class="pb2">First Name *</label>
                        <input
                            class="form-sign"
                            v-model="state.firstName"
                            type="text"
                            name="firstName"
                            placeholder="Enter your first Name"
                            id="firstName"
                            required
                        />
                    </div>

                    <div class="flex flex-column pb3">
                        <label class="pb2">Last Name *</label>
                        <input
                            class="form-sign"
                            v-model="state.lastName"
                            type="text"
                            name="fullName"
                            placeholder="Enter your last Name"
                            id="lastName"
                            required
                        />
                    </div>

                    <div class="flex flex-column pb3">
                        <label class="pb2">Email *</label>
                        <div class="input-group">
                            <input
                                class="form-sign w-100"
                                v-model="state.email"
                                @change="validateEmail"
                                type="email"
                                name="email"
                                placeholder="Enter your email address"
                                id="email"
                                required
                            />
                            <span v-if="state.isEmailValid && !state.isValidatingEmail" class="input-group-label">
                                <font-awesome-icon icon="fa-solid fa-check" class="fa-2x text-success" />
                            </span>
                            <span v-else-if="state.isEmailValid === false && state.email !== ''" class="input-group-label">
                                <font-awesome-icon icon="fa-solid fa-remove" class="fa-2x text-danger" />
                            </span>
                            <span v-else-if="state.isValidatingEmail" class="input-group-label">
                                <font-awesome-icon icon="fa-solid fa-circle-notch" class="fa-2x fa-spin" />
                            </span>
                        </div>
                    </div>
                    
                    <div class="flex flex-column pb3">
                        <label class="pb2">Password *</label>
                        <div class="input-group">
                            <input
                                v-model="state.password"
                                class="form-sign w-100"
                                :type="passwordFieldType"
                                name="password"
                                placeholder="Enter your password"
                                id="password"
                                required
                            />
                            <span class="input-group-label">
                                <font-awesome-icon :icon="passwordFieldIcon" @click="hidePassword = !hidePassword" class="fa-2x" />
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-column pb3">
                        <label class="pb2">Referral Code <small>(Optional)</small></label>
                        <input
                            class="form-sign"
                            v-model="state.referralCode"
                            type="text"
                            name="referralCode"
                            placeholder="Referral Code"
                            id="referralCode"
                            :disabled="state.disableReferralCodeInput"
                        />
                    </div>
                    <input type="hidden" v-model="state.plan" />
                    <input type="hidden" v-model="state.period" />
                    <div v-if="!state.isEmailValid && state.isEmailValid !== null" class="flex items-center mt2 lh-copy">
                        <input 
                            id="invalidEmailConfirm" 
                            type="checkbox" 
                            v-model="state.invalidEmailConfirm"
                            :required="!state.isEmailValid && !state.invalidEmailConfirm"
                        /> 
                        <label for="invalidEmailConfirm" class="pointer"> &nbsp; We couldn't validate your email, check this box to proceed</label>
                    </div>
                    <button class="mt3 w-100 submit-btn" type="submit">
                        Signup
                    </button>
                    <div class="mt3 lh-copy">
                        By clicking Signup, you agree to our
                        <router-link :to="{ name: 'Terms' }"><b> Terms </b></router-link>and
                        <router-link :to="{ name: 'Privacy' }"><b> Privacy Policy. </b></router-link>
                    </div>

                    <div class="flex items-center justify-center mt4">
                        <google-button text="Sign Up with Google" class="w-100 btn2 pa2" />
                    </div>

                    <div class="tc mt3">
                        Have an account? <router-link to="/sign-in"><b>Login</b></router-link>
                    </div>
                </form>
            </div>
        </div>
        <div
            class="flex items-center justify-center w-60-l signup-content"
            style="background-size: cover;"
            :style="`background-image: url(${require('@/assets/images/signup-background.png')})`"
        >
            <div class="tc">
                <div class="pb2">
                    <img src="../../assets/images/simplebks-logo-white.png" style="width: 180px" alt="simplebks-logo" />
                </div>
                <img src="../../assets/images/signup-img.png" alt="" />
                <div class="tc pa3 white">
                    <p class="lh-copy mt0">
                        Welcome to Simplebks! We're here to make bookkeeping less "boo" and more "yay"!<br> Say goodbye to spreadsheets and hello to stress-free financial management.<br> So sign up now and let's get this party started! :tada:
                    </p>
                    <!-- <div class="lh-copy">
                        Aziest jordan is one of the biggest superstars to have immerged from <br />
                        the professional designer world
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, computed, onBeforeMount, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Alert from '@/components/Alert'
import GoogleButton from '@/components/GoogleButton.vue'

export default {
    name: 'SignUp',
    components: { Alert, GoogleButton },

    setup() {
        const store = useStore()
        const route = useRoute()
        const hidePassword = ref(true);
        const passwordFieldIcon = computed(() => hidePassword.value ? "fa-solid fa-eye-slash" : "fa-solid fa-eye");
        const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

        const state = reactive({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            referralCode: '',
            disableReferralCodeInput: false,
            plan: '',
            period: '',
            isEmailValid: null,
            isValidatingEmail: false,
            invalidEmailConfirm: false
        })

        const signUp = () => {
            if (!state.isEmailValid && !state.invalidEmailConfirm) {
                store.dispatch('Alert/setAlert', { message: 'You need to confirm unverified email to proceed', status: false })
                return
            }
            
            const { email, disableReferralCodeInput, isValidatingEmail, invalidEmailConfirm, ...rest } = state
            const formData = { email: email.toLocaleLowerCase(), ...rest }
            store.dispatch('Auth/signUp', formData)
        }

        const validateEmail = async () => {
            state.isEmailValid = null
            state.invalidEmailConfirm = false
            state.isValidatingEmail = true
            await store.dispatch('Auth/validateEmail', state.email)
                .then(resp => state.isEmailValid = resp)
                .finally(() => state.isValidatingEmail = false)
        }

        onBeforeMount(() => {
            const { plan, period, referralCode } = route.query
            // const plans = ['micro', 'small', 'medium']
            // const periods = ['monthly', 'yearly']
            if (referralCode) {
                localStorage.setItem('referralCode', referralCode)
            }
            // if (!plans.includes(plan) || !periods.includes(period)) {
            //     window.location = 'https://simplebks.com/pricing.html'
            // }
            state.plan = plan
            state.period = period
        })

        onMounted(() => {
            const referralCode = localStorage.getItem('referralCode')
            if (referralCode) {
                state.referralCode = referralCode
                state.disableReferralCodeInput = true
            }
        })

        return {
            state,
            signUp,
            validateEmail,
            passwordFieldIcon,
            passwordFieldType,
            hidePassword
        }
    }
}
</script>

<style>
.input-group {
    position: relative;
}
.input-group-label {
    position: absolute;
    top: 16px;
    right: 15px;
    cursor: pointer;
}
.fa-2x {
    font-size: 1.3em;
}
</style>
