import { Axios } from '@/utils/axiosConfig'
import store from '@/store'

const state = {
    tags: {},
}

const mutations = {
    SET_TAGS(state, payload) {
        state.tags = payload
    }
}

const actions = {
    getTags: ({ commit }) => {
        return Axios.get(`/api/v1/tags`)
            .then(({ data }) => {
                commit('SET_TAGS', data?.data)
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
            })
    }
}

export const TagModule = { namespaced: true, actions, mutations, state }
