import { Axios } from '@/utils/axiosConfig'
import { paginationOptions } from '@/utils/lib'
import store from '@/store'
import Router from '@/router'

const state = {
    showCreateEntity: false,
    showCreateAssetCategory: false,
    incomingEntityName: '',
    // entities: {},
    entities: null,
    entityListFromSearch: [],
    entity: {},
    entityToUpdate: null,
    incomingEntityId: '',
    entityInitials: '',
    customerCount: 0,
    archivedEntities: [],
    loading: false,
    showEntityBulkUploadModal: false,
    entityTransactions: [],
    entityInvoices: [],
    entityQuotations: [],
}

const mutations = {
    SET_LOADING_STATE(state, payload) {
        state.loading = payload
    },
    SET_SHOW_ENTITY(state, payload = false) {
        state.showCreateEntity = payload
    },
    SET_SHOW_ASSET_CATEGORY(state, payload = false) {
        console.log('SET_SHOW_ASSET_CATEGORY', payload)
        state.showCreateAssetCategory = payload
    },

    SET_ENTITY_NAME(state, payload) {
        state.incomingEntityName = payload
    },

    SET_ENTITY_FROM_SEARCH(state, payload) {
        state.entityListFromSearch = payload
    },

    SET_NEW_ENTITY_ID(state, payload = null) {
        state.incomingEntityId = payload
    },

    SET_ENTITIES(state, payload = null) {
        state.entities = payload
    },

    SET_ENTITY(state, payload = null) {
        state.entity = payload
        if (payload) {
            let fullName = payload?.entityDetails?.fullName || ''
            let company = payload?.entityDetails?.company || ''

            fullName = fullName?.trim()?.split(' ')
            company = company?.trim()?.split(' ')

            if (fullName?.length > 1) {
                const first = fullName?.[0]?.split('')?.[0]
                const second = fullName?.[1]?.split('')?.[0]
                state.entityInitials = `${first || ''} ${second || ''}`
            } else {
                const first = company?.[0]?.split('')?.[0]
                const second = company?.[0]?.split('')?.[1]
                state.entityInitials = `${first || ''} ${second || ''}`
            }

            state.entityInitials = state.entityInitials?.toUpperCase()
        }
    },

    SET_ENTITY_TO_UPDATE(state, payload = null) {
        state.entityToUpdate = payload
    },
    SET_CUSTOMER_COUNT(state, payload) {
        state.customerCount = payload
    },

    SET_ARCHIVED_ENTITIES(state, payload = null) {
        state.archivedEntities = payload
    },

    SET_SHOW_ENTITY_BULK_IMPORT_MODAL(state, payload = false) {
        state.showEntityBulkUploadModal = payload
    },

    SET_ENTITY_TRANSACTIONS(state, payload) {
        state.entityTransactions = payload
    },

    SET_ENTITY_INVOICES(state, payload) {
        state.entityInvoices = payload
    },

    SET_ENTITY_QUOTATIONS(state, payload) {
        state.entityQuotations = payload
    },
}

const actions = {
    toggleCreateEntity: ({ commit }, payload) => {
        commit('SET_SHOW_ENTITY', payload)
    },
    toggleCreateAssetCAtegory: ({ commit }, payload) => {
        commit('SET_SHOW_ASSET_CATEGORY', payload)
    },

    setEntityName: ({ commit }, payload) => {
        commit('SET_ENTITY_NAME', payload)
    },

    getEntities: async ({ commit }, payload) => {
        commit('SET_ENTITIES')
        const url = payload?.filter_by
            ? `/api/v1/entities?filter_by=${payload.filter_by}&${paginationOptions(payload)}`
            : `/api/v1/entities?${paginationOptions(payload)}`
        Axios.get(url)
            .then(({ data }) => {
                // console.log(data)
                commit('SET_ENTITIES', data)
            })
            .catch(err => {
                console.error(err.response.data)
            })
    },

    createEntity: async ({ commit }, formData) => {
        return Axios.post('/api/v1/entities', formData)
            .then(async ({ data }) => {
                // eslint-disable-next-line no-underscore-dangle
                commit('SET_NEW_ENTITY_ID', data?.entity?._id)
                commit('SET_ENTITY_NAME', data?.entity?.name)

                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                await store.dispatch('Entity/getEntities')
                return data
            })
            .catch(async err => {
                const { data } = err.response
                console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
    },

    getEntityEntryBySearch({ commit }, payload) {
        return Axios.get(`/api/v1/entities/s/${payload.query}`)
            .then(({ data }) => {
                commit('SET_ENTITY_FROM_SEARCH', data)
                return data
            })
            .catch(err => console.error(err.response.data))
    },

    setEntityIdFromExistingEntities({ commit }, existingEntityId) {
        commit('SET_NEW_ENTITY_ID', existingEntityId)
        return existingEntityId
    },

    setEntityInitials: async ({ commit }, payload) => {
        commit('SET_ENTITY', payload)
    },

    getEntity: async ({ commit }, query) => {
        let url = `/api/v1/entities/${query.id}`
        const queryPayload = `transactionStartDate=${query.startDate}&transactionEndDate=${query.endDate}`
        if (query.endDate && query.startDate) url = `${url}?${queryPayload}`
        commit('SET_ENTITY')
        Axios.get(url)
            .then(({ data }) => {
                //console.log(data, 'data')
                if (data.status === 403) {
                    store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                }
                commit('SET_ENTITY', data)
            })
            .catch(err => {
                console.error(err.response.data)
            })
    },

    getEntityToUpdate: async ({ commit }, id) => {
        commit('SET_ENTITY_TO_UPDATE')
        Axios.get(`/api/v1/entities/update/${id}`)
            .then(({ data }) => {
                commit('SET_ENTITY_TO_UPDATE', data?.entityDetails)
                // console.log(data?.entityDetails)
            })
            .catch(err => {
                console.error(err.response.data)
            })
    },

    updateEntity: async (_, payload) => {
        const { id, formData } = payload
        return Axios.patch(`/api/v1/entities/update/${id}`, formData)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                await Router.replace({ name: 'Entity', params: { id } })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                console.log(err.response)
                return data
            })
    },
    countCustomer: async ({ commit }) => {
        return Axios.get('/api/v1/entities/count/all')
            .then(({ data }) => {
                commit('SET_CUSTOMER_COUNT', data)
                return data
            })
            .catch(err => {
                console.error(err.response.data)
            })
    },

    getArchivedEntities: async ({ commit }) => {
        Axios.get(`/api/v1/entities/archived/all`)
            .then(({ data }) => {
                commit('SET_ARCHIVED_ENTITIES', data)
            })
            .catch(err => {
                console.error(err.response.data)
            })
    },

    archiveEntity: async ({ commit, dispatch }, id) => {
        commit('SET_LOADING_STATE', true)
        Axios.get(`/api/v1/entities/${id}/archive`)
            .then(async ({ data }) => {
                dispatch('getEntities')
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING_STATE', false)
            })
            .catch(async err => {
                console.error(err.response.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING_STATE', false)
            })
    },

    restoreEntity: async ({ dispatch }, id) => {
        Axios.get(`/api/v1/entities/${id}/restore`)
            .then(async ({ data }) => {
                dispatch('getArchivedEntities')
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
            .catch(async err => {
                console.error(err.response.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
    },
    
    toggleEntityBulkImportModal: ({ commit }, payload) => {
        commit('SET_SHOW_ENTITY_BULK_IMPORT_MODAL', payload)
    },

    downloadEntityTemplate: async () => {
        return Axios.get('/api/v1/entities/bulk-upload-template/download')
            .then(async ({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                return data
            })
    },

    importEntityBulkUpload: async (_, payload) => {
        return Axios.post('/api/v1/entities/bulk-upload', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                // console.log(err.response)
                return data
            })
    },

    exportEntities: async (_, payload) => {
        return Axios.get(`/api/v1/entities/export/csv?${paginationOptions(payload)}`)
            .then(async ({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                return data
            })
    },

    getEntityTransactions: async ({ commit }, payload) => {
        try {
            const { data } = await Axios.get(`/api/v1/entities/${payload.id}/transactions?${paginationOptions(payload.params)}`)
            commit('SET_ENTITY_TRANSACTIONS', data)
            if (data.status === 403) {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            }

            return data
        } catch (e) {
            console.log(e.response.data)
            return e.response.data
        }
    },

    getEntityInvoices: async ({ commit }, payload) => {
        try {
            const { data } = await Axios.get(`/api/v1/entities/${payload.id}/invoices?${paginationOptions(payload.params)}`)
            commit('SET_ENTITY_INVOICES', data)
            if (data.status === 403) {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            }

            return data
        } catch (e) {
            console.log(e.response.data)
            return e.response.data
        }
    },

    getEntityQuotations: async ({ commit }, payload) => {
        try {
            const { data } = await Axios.get(`/api/v1/entities/${payload.id}/quotations?${paginationOptions(payload.params)}`)
            commit('SET_ENTITY_QUOTATIONS', data)
            if (data.status === 403) {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            }

            return data
        } catch (e) {
            console.log(e.response.data)
            return e.response.data
        }
    },
}

export const EntityModule = {
    namespaced: true,
    actions,
    mutations,
    state
}
