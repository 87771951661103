<template>
    <!-- modal invite team -->
    <div :class="isOpen && 'jquery-modal blocker current'" @click="onClickOutsideInviteRef">
        <div class="w-90 mv3 center" :class="!isOpen ? 'modal2' : 'modal-target2'">
            <form class="box-border relative" style="background: white" ref="inviteModalRef" @submit.prevent="handleInvite">
                <router-link to="#" class="absolute top--1 right--2" @click.prevent="closeModal">
                    <img src="../assets/images/close-icon-2.png" alt="" class="w-40 h-40" />
                </router-link>
                <div class="box-border-bottom f4 b pa3 pt4">Invite Team Member</div>
                <div class="pa3 box-border-bottom">
                    <div class="flex flex-column pb3 items-start">
                        <label class="pb2 font-w1" for="inviteEmail">Email</label>
                        <input
                            v-model="state.inviteEmail"
                            id="inviteEmail"
                            type="text"
                            placeholder="Enter email"
                            class="box-border pa2 w-100"
                        />
                    </div>
                    <div class="flex flex-column items-start">
                        <label class="pb2 font-w1">Choose</label>
                        <select v-model="state.inviteRole" name="inviteRole" class="pa2 w-100" id="inviteRole" required>
                            <option value="">Select role</option>
                            <option v-for="role of state.roles" v-bind:key="role._id" :value="role">{{ role.roleName }}</option>
                        </select>
                    </div>
                    <div class="flex flex-column items-start pt3">
                        <label class="pb2 font-w1">Business</label>
                        <vue3-tags-input
                            v-model:tags="state.businesses"
                            v-model="state.business"
                            :select="true"
                            :select-items="state.allBusinesses"
                            placeholder="Select one or more business"
                            @on-select="handleSelectedBusiness"
                            @on-tags-changed="handleChangeBusiness"
                            class="w-100"
                        >
                            <template #item="{ tag }">
                                {{ tag.name }}
                            </template>
                            <template #no-data> No Data </template>
                            <template #select-item="tag">
                                {{ tag.name }}
                            </template>
                        </vue3-tags-input>
                    </div>
                </div>
                <div class="flex justify-between pa3">
                    <div class="db-l dn"></div>
                    <div>
                        <button class="btn2" @click.prevent="handleInviteModal(false, 'isInvite')">Cancel</button>
                        <button class="ml2" :disabled="state.disabledButton" type="submit">
                            {{ state.disabledButton ? 'Inviting, Please wait...' : 'Invite Member' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive, ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import Vue3TagsInput from 'vue3-tags-input'

export default {
    name: 'InviteTeamMember',
    components: { Vue3TagsInput },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        closeModal: Function,
    },

    setup(props) {
        const store = useStore()
        const customRoles = computed(() => store.state.Settings.customRoles)
        const businesses = computed(() => store?.state?.Settings?.businesses)

        const initialState = () => ({
            isInvite: false,
            inviteEmail: '',
            inviteRole: '',
            disabledButton: false,
            roles: [
                {
                    roleName: 'Business Owner',
                    _id: 'owner',
                },
            ],
            business: '',
            businesses: [],
            selectedBusinesses: [],
            allBusinesses: [],
        })

        const state = reactive(initialState())

        const handleSelectedBusiness = (business) => {
            state.businesses.push(business)
            state.selectedBusinesses.push(business.org)
        }

        const handleChangeBusiness = (businesses) => {
            state.businesses = businesses
            state.selectedBusinesses = businesses.map((business) => business.org)
        }

        const inviteModalRef = ref(null)
        // const deleteModalRef = ref(null)

        const handleInviteModal = (payload = false, modal) => {
            if (modal) {
                state[modal] = payload
            } else {
                state.isInvite = false
            }

            if (state.isInvite || state.isInvite) {
                if (!payload) {
                    Object.assign(state, initialState())
                }
            }
        }

        const onClickOutsideInviteRef = (e) => {
            if (
                // eslint-disable-next-line
                inviteModalRef.value &&
                // eslint-disable-next-line
                !inviteModalRef.value.contains(e.target)
            ) {
                props.closeModal()
            }
        }

        const handleInvite = () => {
            state.disabledButton = true

            const payload = {
                email: state.inviteEmail,
                role: state.inviteRole.roleName,
                roleId: state.inviteRole?._id,
                roleAccess: state.inviteRole?.access,
                businesses: state.selectedBusinesses,
            }

            store.dispatch('Settings/inviteTeamMember', payload).then((resp) => {
                if (resp?.success) {
                    // props.closeModal()
                    state.disabledButton = false
                    Object.assign(state, initialState())
                } else {
                    state.disabledButton = false
                }
            })
        }

        watch(
            () => customRoles.value,
            (currValue, preValue) => {
                state.roles = [...state.roles, ...currValue]
            }
        )

        onMounted(() => {
            store.dispatch('Settings/getCustomRoles')
            store.dispatch('Settings/getBusinesses', true).then((resp) => {
                if (resp.status) {
                    state.allBusinesses = resp.data
                }
            })
        })

        return {
            state,
            inviteModalRef,
            handleInvite,
            handleInviteModal,
            onClickOutsideInviteRef,
            handleSelectedBusiness,
            handleChangeBusiness,
            businesses,
        }
    },
}
</script>

<style lang="css">
.v3ti .v3ti-tag {
  background: #132c8c;
}

.v3ti .v3ti-tag .v3ti-remove-tag {
  color: #febf12;
  transition: color .3s;
}

.v3ti .v3ti-tag .v3ti-remove-tag:hover {
  color: #ffffff;
}
</style>
