<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-90 center">
                <div class="flex flex-wrap justify-between items-center pv3">
                    <div>
                        <p class="pb2 f3 b">
                            Buy Asset
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickTooltipIcon"
                                    @click="toggleTooltip = true"
                                    style="cursor: pointer"
                            /></strong>
                        </p>
                    </div>
                    <router-link :to="{ name: 'AssetManagement' }">Back</router-link>
                </div>
                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Buy Details</h4>
                        <p>
                            Provide information about the items being purchased, the quantity, the price, the delivery date, and
                            the payment terms.
                        </p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Supplier Name</label>
                                <div class="flex flex-column" :style="`position: ${state.isRelative ? 'relative' : 'revert'}`">
                                    <input
                                        autocomplete="off"
                                        class="box-borderx asset-inputxx form-sign"
                                        style="padding: 10px"
                                        type="text"
                                        name="entity"
                                        placeholder="Search for supplier name"
                                        id="entity"
                                        @input="(element) => handleEntitySearchOnChange(element.target.value)"
                                        @focusout="
                                            () => {
                                                showDropDownOnSelectEntity(false)
                                                handleIsRelative(false)
                                            }
                                        "
                                        @focusin="
                                            () => {
                                                showDropDownOnSelectEntity(true)
                                                handleIsRelative(true)
                                            }
                                        "
                                        v-model="state.supplier"
                                    />
                                    <template v-if="state.supplierDropDown && state.entity?.length">
                                        <div
                                            style="
                                                position: absolute;
                                                left: 0;
                                                top: 100%;
                                                height: auto;
                                                width: 100%;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                box-shadow: 0px 1px 4px 0px #2b59ff;
                                                z-index: 1000;
                                            "
                                            class="bg-white flex flex-column items-start"
                                        >
                                            <div
                                                class="mt2"
                                                style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                v-if="EntitySearchedList.length"
                                            >
                                                <ul class="w-100" style="margin: 0; padding: 10px 0">
                                                    <li
                                                        v-for="ent of EntitySearchedList"
                                                        v-bind:key="ent._id"
                                                        class="pointer hover-bg-black-10 pa2 db"
                                                        style="width: 100%; margin: 0"
                                                        @click.prevent="handleEntityClickFromSearch(ent)"
                                                    >
                                                        <span v-if="ent?.lastName !== undefined">
                                                            {{ `${ent?.firstName} ${ent?.lastName}` || ent?.company }}
                                                        </span>

                                                        <span v-else>
                                                            {{ `${ent?.firstName}` || ent?.company }}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-else class="pa3">Not found</div>
                                            <div
                                                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(30)"
                                                class="flex items-center"
                                                style="width: 100%; gap: 0.5rem; overflow: hidden; text-overflow: ellipsis"
                                            >
                                                <button
                                                    class="mr2x pa2"
                                                    style="
                                                        width: 100%;
                                                        overflow: hidden;
                                                        white-space: nowrap;
                                                        text-overflow: ellipsis;
                                                    "
                                                    @click.prevent="toggleCreateEntity(true)"
                                                >
                                                    <font-awesome-icon icon="fa fa-plus-circle" /> Create a new supplier
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Location</label>
                                <div class="flex flex-column">
                                    <input
                                        autocomplete="off"
                                        class="box-borderx asset-inputxx form-sign"
                                        style="padding: 10px"
                                        type="text"
                                        name="location"
                                        placeholder="Input your location"
                                        id="location"
                                        v-model="state.location"
                                    />
                                </div>
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Supplier Invoice Number</label>
                                <div class="flex flex-column">
                                    <input
                                        autocomplete="off"
                                        class="box-borderx asset-inputxx form-sign"
                                        style="padding: 10px"
                                        type="text"
                                        v-model="state.reference"
                                    />
                                </div>
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Order Number</label>
                                <div class="flex flex-column">
                                    <input
                                        autocomplete="off"
                                        class="box-borderx asset-inputxx form-sign"
                                        style="padding: 10px"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Delivery date</label>
                                <div class="flex flex-column">
                                    <input
                                        autocomplete="off"
                                        class="box-borderx asset-inputxx form-sign"
                                        style="padding: 10px"
                                        type="date"
                                        v-model="state.date"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Assets</h4>
                        <p>Choose asset(s) you want to buy</p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div class="pb3">
                            <table v-if="state.assets.length" class="table table-bordered table-striped" cellspacing="0">
                                <caption></caption>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Quantity</th>
                                        <th>Cost price ({{ $store.state.Settings?.currency || 'NGN' }})</th>
                                        <th>Total price ({{ $store.state.Settings?.currency || 'NGN' }})</th>
                                        <th>Monthly Depreciation</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(asset, index) in state.assets" :key="asset.id">
                                        <td class="vertical-align-middle">
                                            <div>{{ asset.name }}</div>
                                            <div>
                                                <small>{{ asset.tag }}</small>
                                            </div>
                                        </td>
                                        <td class="vertical-align-middle">
                                            <input type="number" class="product-input-2" v-model="asset.quantity" />
                                        </td>
                                        <td class="vertical-align-middle">
                                            <input type="number" class="product-input-2" v-model="asset.price" />
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{
                                                formatAmount(
                                                    asset.quantity * asset.price,
                                                    $store.state.Settings?.currency || 'NGN'
                                                )
                                            }}
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{ formatQuantity(asset.depreciation) }}
                                        </td>
                                        <td class="tc vertical-align-middle font-w2" width="30">
                                            <a href="" @click.prevent="removeAsset(index)" v-tippy="'Remove'">
                                                <img :src="require('@/assets/images/delete.svg')" alt="remove" />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colspan="3">Subtotal</th>
                                        <th>{{ formatAmount(state.subtotal) }}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th colspan="3">TOTAL</th>
                                        <th>{{ formatAmount(state.total) }}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="input-group">
                            <span class="input-group-label">
                                <font-awesome-icon icon="fa-solid fa-search" class="fa-2x" />
                            </span>
                            <v-select
                                label="name"
                                placeholder="Search for asset with Name or tag"
                                class="pv2"
                                :filterable="false"
                                :options="state.options"
                                @search="onSearchAsset"
                                @option:selected="(item) => selectAsset(item)"
                                @option:deselected="(item) => deselectAsset(item)"
                            >
                                <template #open-indicator="{ attributes }">
                                    <span v-bind="attributes"></span>
                                </template>
                                <template #no-options>
                                    <em style="opacity: 0.5">Type to search asset...</em>
                                </template>
                            </v-select>
                        </div>
                        <hr />

                        <div class="tr pa3">
                            <!-- add shipping-->
                            <div v-if="state.hasDelivery" class="flex justify-between pb3">
                                <div></div>
                                <div class="w-50-l w-100">
                                    <div class="flex items-center justify-between">
                                        <p>Delivery</p>

                                        <div class="box-border-bottom tr b">
                                            <router-link to="#" @click="toggleExtraCharges('hasDelivery')" style="color: #132c8c">
                                                - Remove Delivery
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="flex w-100 mt2">
                                        <div>
                                            <select
                                                style="
                                                    background-color: white;
                                                    color: #634f4f;
                                                    border: 1px solid rgb(118, 118, 118);
                                                    border-radius: 0;
                                                "
                                                class="product-input"
                                                v-model="state.deliveryType"
                                            >
                                                <option value="fixed">Fixed</option>
                                                <option value="percent">Percentage %</option>
                                            </select>
                                        </div>
                                        <div
                                            style="width: 100%"
                                            v-tippy="{
                                                content: '',
                                                placement: 'top',
                                                interactive: true,
                                            }"
                                        >
                                            <CurrencyInput
                                                name="shipping"
                                                id="shipping"
                                                :options="numberFormat"
                                                v-model="state.delivery"
                                                class="w-100 pl2 product-input"
                                                style="outline: none"
                                                placeholder="Enter amount or Percentage"
                                                aria-label=""
                                                aria-describedby=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- add discount-->
                            <div v-if="state.hasDiscount" class="flex justify-between pb3">
                                <div></div>
                                <div class="w-50-l w-100">
                                    <div class="flex items-center justify-between">
                                        <p>Discount</p>

                                        <div class="box-border-bottom tr b">
                                            <router-link to="#" @click="toggleExtraCharges('hasDiscount')" style="color: #132c8c">
                                                - Remove Discount
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="flex w-100 mt2">
                                        <div>
                                            <select
                                                style="
                                                    background-color: white;
                                                    color: #634f4f;
                                                    border: 1px solid rgb(118, 118, 118);
                                                    border-radius: 0;
                                                "
                                                class="product-input"
                                                v-model="state.discountType"
                                            >
                                                <option value="fixed">Fixed</option>
                                                <option value="percent">Percentage %</option>
                                            </select>
                                        </div>
                                        <div
                                            style="width: 100%"
                                            v-tippy="{
                                                content: '',
                                                placement: 'top',
                                                interactive: true,
                                            }"
                                        >
                                            <CurrencyInput
                                                name="discount"
                                                id="discount"
                                                :options="numberFormat"
                                                v-model="state.discount"
                                                class="w-100 pl2 product-input"
                                                style="outline: none"
                                                placeholder="Enter amount or Percentage"
                                                aria-label=""
                                                aria-describedby=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- add VAT-->
                            <div v-if="state.hasTax" class="flex justify-between pb3">
                                <div></div>
                                <div class="w-50-l w-100">
                                    <div class="flex items-center justify-between">
                                        <p>VAT</p>

                                        <div class="box-border-bottom tr b">
                                            <router-link to="#" @click="toggleExtraCharges('hasTax')" style="color: #132c8c">
                                                - Remove VAT
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="flex w-100 mt2">
                                        <div>
                                            <select
                                                style="
                                                    background-color: white;
                                                    color: #634f4f;
                                                    border: 1px solid rgb(118, 118, 118);
                                                    border-radius: 0;
                                                "
                                                class="product-input"
                                                v-model="state.taxType"
                                            >
                                                <option value="fixed">Fixed</option>
                                                <option value="percent">Percentage %</option>
                                            </select>
                                        </div>
                                        <div
                                            style="width: 100%"
                                            v-tippy="{
                                                content: '',
                                                placement: 'top',
                                                interactive: true,
                                            }"
                                        >
                                            <CurrencyInput
                                                name="vat"
                                                id="vat"
                                                :options="numberFormat"
                                                v-model="state.tax"
                                                class="w-100 pl2 product-input"
                                                style="outline: none"
                                                placeholder="Enter amount or Percentage"
                                                aria-label=""
                                                aria-describedby=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="state.assets.length"
                                id="additional-cost"
                                class="flex flex-wrap items-center justify-end pa3"
                                style="gap: 1.5rem"
                            >
                                <div v-if="!state.hasDelivery" class="b tr">
                                    <router-link to="#" @click="toggleExtraCharges('hasDelivery')" style="color: #132c8c">
                                        + Add Delivery
                                    </router-link>
                                </div>

                                <div v-if="!state.hasDiscount" class="b tr">
                                    <router-link to="#" @click="toggleExtraCharges('hasDiscount')" style="color: #132c8c">
                                        + Add discount
                                    </router-link>
                                </div>

                                <div v-if="!state.hasTax" class="b tr">
                                    <router-link to="#" @click="toggleExtraCharges('hasTax')" style="color: #132c8c">
                                        + Add VAT
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Payments</h4>
                        <p>Add payment information to this purchase order</p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div>
                            <select v-model="state.account" class="w-100" required>
                                <option value="">Select account</option>
                                <option v-for="(account, index) of state.accountDetails" :value="account?.type" :key="index">
                                    {{ account?.type }}
                                </option>
                            </select>
                        </div>
                        <div class="flex pv3 items-center">
                            <input type="checkbox" name="credit" id="credit" v-model="state.credit" />
                            <label for="credit" class="pl2">This is a credit transaction</label>
                        </div>
                        <div class="flex items-center pv3">
                            <div class="flex flex-column pr2 w-50">
                                <label class="pb2">Upfront payment</label>
                                <div
                                    v-tippy="{
                                        content: ' Input the amount the you paid for the asset order as installments',
                                        placement: 'top',
                                        interactive: true,
                                    }"
                                >
                                    <CurrencyInput
                                        class="product-input"
                                        name="initial_deposit"
                                        placeholder="0"
                                        id="initial_deposit"
                                        style="width: 100%"
                                        required
                                        :options="numberFormat"
                                        v-model="state.initial_deposit"
                                    />
                                </div>
                            </div>
                            <div class="flex flex-column w-50">
                                <label class="pb2"> Balance/Refund Due Date<span class="required">*</span></label>
                                <div
                                    v-tippy="{
                                        content:
                                            'Select the date you promised to pay the amount left as full payment for the order.',
                                        placement: 'top',
                                        interactive: true,
                                    }"
                                >
                                    <input
                                        class="product-input"
                                        type="date"
                                        name="due_date"
                                        placeholder=""
                                        id="due_date"
                                        style="width: 100%"
                                        required
                                        v-model="state.due_date"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <hr />
                <div class="flex justify-end items-center mt-3">
                    <button
                        @click="handleBuyAsset"
                        :disabled="state.isProcessing || state.supplierData.id === '' || state.assets.length <= 0"
                    >
                        <span>{{ state.isProcessing ? 'Processing...' : 'Submit' }}</span>
                    </button>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { computed, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import vSelect from 'vue-select'
import ShortUniqueId from 'short-unique-id'
import moment from 'moment-timezone'
import { formatAmount, formatQuantity } from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper'
import CurrencyInput from '@/components/CurrencyInput'
export default {
    name: 'BuyAsset',
    components: { AppWrapper, vSelect, CurrencyInput },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true,
            },
        }
    },

    setup() {
        let timeOut = null
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const incomingSupplierId = computed(() => store.state.Entity?.incomingEntityId)
        const EntitySearchedList = computed(() => store.state.Entity?.entityListFromSearch)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)

        const initialState = () => ({
            supplier: '',
            supplierData: {
                id: '',
                name: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                vat_number: '',
                tax_number: '',
                website: '',
            },
            supplierId: '',
            supplierName: '',
            supplierDropDown: false,
            isRelative: false,
            location: '',
            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
            number: '',
            date: moment().format('YYYY-MM-DD'),
            assets: [],
            subtotal: 0,
            total: 0,
            account: 'Cash',
            credit: false,
            useAdvancePayment: false,
            initial_deposit: 0,
            due_date: '',
            accountDetails: [],
            delivery: 0,
            discount: 0,
            tax: 0,
            deliveryType: 'fixed',
            discountType: 'fixed',
            taxType: 'percent',
            hasDelivery: false,
            hasDiscount: false,
            hasTax: false,
            isProcessing: false,
        })

        const state = reactive(initialState())

        // watch(accountDetails, (newValue) => {
        //     if (newValue) {
        //         state.data = Object.values(newValue)?.filter((value) => value.type !== null && parseInt(value.balance) !== 0)
        //     }
        // })

        watch(state.assets, (newValue) => {
            state.subtotal = newValue?.reduce((acc, item) => {
                return acc + item.price * item.quantity
            }, 0)
            
            state.total = calculateTotal()
        })

        watch(() => [
            state.delivery,
            state.discount,
            state.tax,
            state.deliveryType,
            state.discountType,
            state.taxType
        ], () => {
            state.total = calculateTotal()
        })

        const calculateAdditionalCharges = (type) => {
            if (state[`${type}Type`] === 'percent') {
                return (state[type] / 100) * state.subtotal
            }

            return state[type]
        }

        const calculateTotal = () => {
            const deliveryCost = calculateAdditionalCharges('delivery')
            const discountAmount = calculateAdditionalCharges('discount')
            const taxAmount = calculateAdditionalCharges('tax')
            return state.subtotal - discountAmount + deliveryCost + taxAmount
        }

        const onChangeSupplierId = () => {
            state.supplierId = incomingSupplierId.value
        }

        const handleEntitySearchOnChange = (query) => {
            state.entity = query

            clearTimeout(timeOut)

            if (query.trim().length) {
                timeOut = setTimeout(() => {
                    store.dispatch('Entity/getEntityEntryBySearch', { query })
                }, 300)
            }
        }

        const showDropDownOnSelectEntity = (dropDownState = false) => {
            if (!dropDownState) {
                return setTimeout(() => {
                    state.supplierDropDown = dropDownState
                }, 300)
            }

            state.supplierDropDown = dropDownState
        }

        const handleEntityClickFromSearch = (value) => {
            // console.log(value, 'value')

            if (value?.lastName || value?.lastName === undefined) {
                state.supplier = `${value?.firstName} ${value?.lastName || ''}` || value?.company
            } else {
                state.supplier = `${value?.firstName}` || value?.company
            }

            state.supplierName = value._id

            // update invoice customer
            if (value._id) {
                state.supplierData.id = value._id
                state.supplierData.name = value?.lastName ? `${value.firstName} ${value?.lastName}` : value.firstName
                state.supplierData.address = value.address1
                state.supplierData.city = value.city
                state.supplierData.state = value.state
                state.supplierData.zip = value.zip
                state.supplierData.country = value.country
                state.supplierData.phone = value.phone
                state.supplierData.email = value.email

                state.IsCustomerSelected = true
            }

            store.dispatch('Entity/setEntityIdFromExistingEntities', value._id).then((resp) => {
                if (resp) onChangeSupplierId()
            })
            showDropDownOnSelectEntity(false)
        }

        const toggleCreateEntity = (payload) => {
            store.dispatch('Entity/toggleCreateEntity', payload)
            if (payload && state.entity) store.dispatch('Entity/setEntityName', state.entity)

            const body = document.querySelector('body')
            if (payload) {
                body.style.overflow = 'hidden'
            } else {
                body.style.overflow = 'auto'
            }
        }

        const handleIsRelative = (value = false) => {
            setTimeout(() => {
                state.isRelative = value
            }, 300)
        }

        const toggleExtraCharges = (type) => {
            state[type] = !state[type]
        }

        const onSearchAsset = (search, loading) => {
            if (search.length) {
                loading(true)
                store.dispatch('Asset/searchAsset', { query: search }).then((data) => {
                    state.options = data.assets
                    loading(false)
                })
            }
        }

        const selectAsset = (item) => {
            const index = state.assets.findIndex((asset) => asset.name === item.name)
            if (index >= 0) {
                state.assets[index].quantity = state.assets[index].quantity + 1
            } else {
                state.assets.push({
                    name: item.name,
                    price: item?.purchasePrice || 0,
                    current_stock: item?.qty_in_stock || 0,
                    depreciation: parseFloat((((item?.purchasePrice || 0) - (item?.salvageValue || 0)) / (item?.lifespan || 0)).toFixed(2)),
                    quantity: 1,
                    salvageValue: item?.salvageValue || 0,
                    lifespan: item?.lifespan || 0,
                    tag: item?.tag || '',
                    serialNo: item?.serialNo || '',
                    id: item._id,
                })
            }

            state.options = []
            item.name = 'Search for asset with Name or tag'
        }

        const removeAsset = (index) => {
            const { assets } = state
            const assetToDelete = assets.splice(index, 1)
            state.assets = assets.filter((element) => element !== assetToDelete[0])
        }

        const deselectAsset = (asset, item) => {}

        const handleBuyAsset = () => {
            state.isProcessing = true
            const {
                accountDetails,
                isProcessing,
                entity,
                IsCustomerSelected,
                options,
                supplierId,
                supplierName,
                supplierDropDown,
                isRelative,
                ...data
            } = state
            store.dispatch('Asset/buyAsset', data).finally(() => state.isProcessing = false)
        }

        onMounted(() => {
            store.dispatch('Transaction/getAccountDetails').then((data) => {
                state.accountDetails = Object.values(data)?.filter(
                    (value) => value.type !== null && parseInt(value.balance) !== 0
                )
            })
        })

        return {
            state,
            formatAmount,
            formatQuantity,
            handleEntitySearchOnChange,
            showDropDownOnSelectEntity,
            handleEntityClickFromSearch,
            toggleCreateEntity,
            handleIsRelative,
            onSearchAsset,
            selectAsset,
            removeAsset,
            deselectAsset,
            handleBuyAsset,
            role,
            rolePermissions,
            EntitySearchedList,
            toggleExtraCharges,
        }
    },
}
</script>
