<template>
    <app-wrapper>
        <template v-slot:child>
            <EditAsset v-if="asset" :asset="asset" :closeModal="() => toggleEditAsset(false)" :assetCategory="'ASSET'" />
            <div class="w-90 center pt3 font-w1">
                <router-link :to="{ name: 'AssetManagement' }"> &lt; Asset Management </router-link>
            </div>
            
            <div class="w-90 center">
                <div class="flex flex-wrap justify-between items-center pv3 box-border-bottom">
                    <div class="pb3 pb0-l f3 b">Asset Details</div>

                    <div class="scrollmenu">
                        <button class="mr2 btn3">Archive Asset</button>

                        <router-link :to="'#'" @click.prevent="toggleEditAsset(true)">
                            <button class="btn2 ml2">Edit Asset</button>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="w-90 center flex flex-wrap pt4">
                <div class="w-25-l w-100 box-border pv4 tc">
                    <div class="flex justify-center items-center">
                        <div
                            class="flex justify-center items-center font-w3 box-border1"
                            style="
                                background: rgb(215, 222, 250);
                                height: 160px;
                                width: 160px;
                                border-radius: 50%;
                                font-size: 55px;
                                color: rgb(19, 44, 140);
                                margin-bottom: 20px;
                                overflow: hidden;
                            "
                        >
                            <img
                                src="@/assets/images/inventory-default.png"
                                class="pr1 h-100 w-100 cover"
                                style="object-position: center"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="pt3 b" style="color: #193ab8; font-size: 22px">{{ asset.name }}</div>
                </div>

                <div class="w-25-l w-100 box-border pa4">
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Asset Name</div>
                        <div>{{ asset.name }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Asset Tag</div>
                        <div>{{ asset.tag }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Group</div>
                        <div>{{ asset.group }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2 ttc" style="color: #55596ebf">Category</div>
                        <div>{{ asset.category }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Serial Number</div>
                        <div>{{ asset.serialNo }}</div>
                    </div>
                </div>

                <div class="w-25-l w-100 box-border pa4">
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Manufacturer</div>
                        <div>{{ asset.manufacturer }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Model</div>
                        <div>{{ asset.model }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Condition</div>
                        <div>{{ asset.condition }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Purchase price</div>
                        <div>{{ formatAmount(asset?.purchasePrice || 0, $store.state.Settings?.currency || 'NGN') }}</div>
                    </div>
                </div>
                <div class="w-25-l w-100 box-border pa4">
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Monthly depreciation</div>
                        <div>{{ formatAmount((asset?.purchasePrice || 0 - asset?.salvageValue || 0) / asset.lifespan, $store.state.Settings?.currency || 'NGN') }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Force sale value</div>
                        <div>{{ formatAmount(asset?.forceSaleValue || 0, $store.state.Settings?.currency || 'NGN') }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Useful life</div>
                        <div>{{ asset.lifespan }}</div>
                    </div>
                    <div class="pv2">
                        <div class="pb2" style="color: #55596ebf">Salvage value</div>
                        <div>{{ formatAmount(asset?.salvageValue || 0, $store.state.Settings?.currency || 'NGN') }}</div>
                    </div>
                </div>
            </div>

            <div v-if="asset?.description" class="w-90 center">
                <div class="pa4 box-border">
                    <div class="pb2 b">Asset Description</div>
                    <div class="lh-copy">{{ asset.description }}</div>
                </div>
            </div>

            <div class="w-90 center mt5">
                <div
                    class="flex flex-wrap justify-between items-center"
                    style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee"
                >
                    <div name="stats" id="stats" class="product-details-filter">
                        <span class="mr1"> Start date <input type="date" name="" class="input-style" /></span>
                        <span> End date <input type="date" name="" class="input-style" /></span>
                        <button class="flex items-center pa1 filter">
                            <img :src="require('@/assets/images/Reload.svg')" alt="" />
                        </button>
                    </div>
                </div>
            </div>

            <div class="scrollmenu w-90 center">
                <table class="w-100 tl mt3" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="pb3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>Date</span>
                                </span>
                            </th>
                            <th class="pb3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>ID</span>
                                </span>
                            </th>
                            <th class="pb3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>Type</span>
                                </span>
                            </th>
                            <th class="pb3 box-border-bottom tc ph3">
                                <span class="flex items-center">
                                    <span>Entity Name</span>
                                </span>
                            </th>
                            <th class="pb3 pr5 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>Description</span>
                                </span>
                            </th>
                            <th class="pb3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>Amount</span>
                                </span>
                            </th>
                            <th class="pb3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>Actions</span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="transaction in transactionLogs" :key="transaction._id">
                            <td class="pv3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>{{ formatDate(transaction.date, 'DD/MM/YYYY')}} </span>
                                </span>
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl b">
                                <router-link :to="{ name: 'Transaction', params: { id: transaction?.reference } }">
                                    <span class="flex items-center">
                                        <span>{{ transaction?.number || transaction?.reference }}</span>
                                    </span>
                                </router-link>
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span v-if="transaction.type === 'asset_buy'">Asset Buy</span>
                                    <span v-if="transaction.type === 'asset_sale'">Asset Sale</span>
                                </span>
                            </td>
                            <td class="pv3 box-border-bottom tc ph3">
                                <span class="flex items-center">
                                    <span>{{ transaction.entityName }}</span>
                                </span>
                            </td>
                            <td class="pv3 pr5 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>{{ transaction?.description || ''}}</span>
                                </span>
                            </td>
                            <td class="pv3 box-border-bottom tl">
                                <span class="flex items-center">
                                    <span>{{ formatAmount(transaction.amount, $store.state.Settings?.currency) }}</span>
                                </span>
                            </td>
                            <td class="justify-between items-center pv3 pr3 bb b--black-20 tl">
                                <router-link :to="{ name: 'Transaction', params: { id: transaction?.reference } }">
                                    <span v-tippy="'View receipt'">
                                        <img :src="require('@/assets/images/enable.svg')" class="pr2" alt=""
                                    /></span>
                                </router-link>
                                <!-- <span v-tippy="'Delete transaction'">
                                    <img :src="require('@/assets/images/delete.svg')" class="pr2" alt=""
                                /></span> -->
                                <!-- <span v-tippy="'Receive'"
                                    ><img :src="require('@/assets/images/Reload.svg')" class="pr2" alt=""
                                /></span> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { formatAmount, formatQuantity, formatDate } from '@/utils/lib'
import EditAsset from '@/components/EditAsset'
import AppWrapper from '@/layout/AppWrapper.vue'

export default {
    name: 'AssetDetails',
    components: { AppWrapper, EditAsset },
    setup() {
        const store = useStore()
        const route = useRoute()
        const asset = computed(() => store?.state.Asset.asset)
        const transactionLogs = computed(() => store?.state.Asset.transactionLogs)

        const toggleEditAsset = (status) => {
            const id = route?.params?.id
            store.dispatch('Asset/showEditAssetModal', { show: status, cat: 'Asset', id })
        }

        onMounted(() => {
            const id = route?.params?.id
            store.dispatch('Asset/getAsset', id)
            store.dispatch('Asset/getAssetTransactionLogs', id)
        })

        return {
            asset,
            transactionLogs,
            formatAmount,
            formatQuantity,
            formatDate,
            toggleEditAsset,
        }
    },
}
</script>
