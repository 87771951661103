import { Axios } from '@/utils/axiosConfig'

const state = {
    summary: {},
    businessType: 'sales',
    accountBalance: 0,
    accountDetails: {},
    overview: {},
    topCustomers: [],
    topProducts: [],
    recentTransactions: [],
    topExpenses: [],
    topEvents: [],
    loading: false,
    overviewstat: {},
}

const mutations = {
    SET_DASHBOARD_SUMMARY(state, payload) {
        state.summary = payload
    },
    SET_INITIAL_BUSINESSTYPE(state, payload) {
        state.businessType = payload
        console.log('payload', payload)
    },
    SET_ACCOUNT_BALANCE(state, payload) {
        state.accountBalance = payload
    },
    SET_ACCOUNT_DETAILS(state, payload) {
        state.accountDetails = payload
    },
    SET_TODAY_OVERVIEW(state, payload) {
        state.overview = payload
    },
    SET_TODAY_OVERVIEW_STAT(state, payload) {
        state.overviewstat = payload
    },
    SET_TOP_CUSTOMERS(state, payload) {
        state.topCustomers = payload
    },
    SET_TOP_PRODUCTS(state, payload) {
        state.topProducts = payload
    },
    SET_RECENT_TRANSACTIONS(state, payload) {
        state.recentTransactions = payload
    },
    SET_LOADING_STATE(state, payload) {
        state.loading = payload
    },
    SET_TOP_EXPENSES(state, payload) {
        state.topExpenses = payload
    },
    SET_TOP_EVENTS(state, payload) {
        state.topEvents = payload
    }
}

const actions = {
    getDashboardStats: async ({ commit }, query) => {
        const queryPayload = `filterType=${query?.filterType || ''}&startDate=${query?.start || ''}&endDate=${query?.end || ''}&business=${query?.business || ''}`

        Axios.get(`/api/v1/dashboard/summary?${queryPayload}`)
            .then(({ data }) => {
                commit('SET_DASHBOARD_SUMMARY', data)
            })
            .catch(err => console.error(err.response.data))
    },
    getBusinessType: async ({ commit }, query) => {
        commit('SET_INITIAL_BUSINESSTYPE', query)
    },
    getAccountBalance: async ({ commit }) => {
        Axios.get(`/api/v1/accounts/balancesum`)
            .then(({ data }) => {
                commit('SET_ACCOUNT_BALANCE', data)
            })
            .catch(err => console.error(err.response.data))
    },
    getAccountDetails: async ({ commit }) => {
        Axios.get(`/api/v1/accounts/balancedetails`)
            .then(({ data }) => {
                commit('SET_ACCOUNT_DETAILS', data)
            })
            .catch(err => console.error(err.response.data))
    },
    getGraphOverview: async ({ commit }, payload) => {
        commit('SET_LOADING_STATE', true)
        Axios.post(`/api/v1/dashboard/todayoverview?business=${payload.business}`, payload.data)
            .then(({ data }) => {
                commit('SET_TODAY_OVERVIEW', data)
                commit('SET_LOADING_STATE', false)
            })
            .catch(err => console.error(err.response.data))
    },
    getTodayOverview: async ({ commit }, query) => {
        commit('SET_LOADING_STATE', true)
        Axios.post(`/api/v1/dashboard/todayoverview?business=${query.business}`, {})
            .then(({ data }) => {
                commit('SET_TODAY_OVERVIEW_STAT', data)
              //  commit('SET_LOADING_STATE', false)
            })
            .catch(err => console.error(err.response.data))
    },
    getTopCustomers: async ({ commit }, payload) => {
        commit('SET_LOADING_STATE', true)
        Axios.get(`/api/v1/dashboard/topcustomers?limit=${payload.limit}&business=${payload.business}`,)
            .then(({ data }) => {
                commit('SET_TOP_CUSTOMERS', data)
            })
            .catch(err => console.error(err.response.data))
    },
    getRecentTransactions: async ({ commit }, payload) => {
        commit('SET_LOADING_STATE', true)
        Axios.get(`/api/v1/dashboard/getrecenttransactions?limit=${payload.limit}&business=${payload.business}`,)
            .then(({ data }) => {
                commit('SET_RECENT_TRANSACTIONS', data)
            })
            .catch(err => console.error(err.response.data))
    },
    getTopProducts: async ({ commit }, payload) => {
        commit('SET_LOADING_STATE', true)
        Axios.get(`/api/v1/dashboard/topproducts?limit=${payload.limit}&business=${payload.business}`)
            .then(({ data }) => {
                commit('SET_TOP_PRODUCTS', data)
            })
            .catch(err => console.error(err.response.data))
    },
    getTopExpenses: async ({ commit }, payload) => {
        commit('SET_LOADING_STATE', true)
        return Axios.get(`/api/v1/dashboard/gettopexpenses?limit=${payload.limit}&startDate=${payload.startDate}&endDate=${payload.endDate}&business=${payload.business}`)
            .then(({ data }) => {
                commit('SET_TOP_EXPENSES', data)
                return data
            })
            .catch(err => console.error(err.response.data))
    },
    getTopEvents: async ({ commit }, payload) => {
        commit('SET_LOADING_STATE', true)
        Axios.get(`/api/v1/dashboard/topevents?limit=${payload.limit}&business=${payload.business}`)
            .then(({ data }) => {
                commit('SET_TOP_EVENTS', data)
            })
            .catch(err => console.error(err.response.data))
    }
}

export const DashboardModule = {
    namespaced: true,
    actions,
    mutations,
    state
}
