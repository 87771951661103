<template>
    <!-- form modal -->
    <div
        tabindex="0"
        @keydown.esc="toggleCreateCategory()"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showCreateCategory && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-30-l w-90 pv2 center box-border" style="background: white" ref="createCategoryRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Create Category</div>
                <div>
                    <router-link to="#" @click.prevent="toggleCreateCategory()">
                        <img :src="require('@/assets/images/Close-icon.svg')" alt=" " />
                    </router-link>
                </div>
            </div>
            <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                <div class="pa3">
                    <!-- Product name -->
                    <div class="pt2">
                        <div class="pv2 font-w1"><label for="name">Category Name</label></div>
                        <input
                            required
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Enter category name"
                            v-model="state.name"
                        />
                    </div>
                    <div class="pt2">
                        <div class="pv2 font-w1">
                            <label for="name">Description</label>
                        </div>
                        <textarea
                            id="description"
                            name="description"
                            placeholder="Enter description (optional)"
                            v-model="state.description"
                        ></textarea>
                    </div>

                    <div class="flex items-center justify-center w-100">
                        <button class="mt4 w-100" type="submit" :disabled="state.disableSubmitButton">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'CreateCategory',

    setup() {
        const createCategoryRef = ref(null)
        const store = useStore()
        const showCreateProduct = computed(() => store?.state.Inventory.showCreateProduct)
        const showCreateCategory = computed(() => store?.state.Inventory.showCreateCategory)

        const state = reactive({
            disableSubmitButton: false,
            name: '',
            description: '',
        })

        const toggleCreateCategory = (payload = false) => {
            store.dispatch('Inventory/showCreateCategory', payload)
        }

        const onSubmit = () => {
            state.disableSubmitButton = true

            const { disableSubmitButton, ...data } = state

            store.dispatch('Inventory/createCategory', data).then(resp => {
                if (resp) {
                    store.dispatch('Inventory/getAllCategories')
                    state.disableSubmitButton = false
                    state.name = ''
                    state.description = ''
                    toggleCreateCategory()
                } else {
                    state.disableSubmitButton = false
                }
            })
        }

        // close when click outside modal

        const handleOnClickOutsideModal = e => {
            if (createCategoryRef.value && !createCategoryRef.value.contains(e.target)) {
                toggleCreateCategory()
            }
        }

        onMounted(() => store.dispatch('Inventory/getAllCategories'))

        return {
            state,
            showCreateProduct,
            showCreateCategory,
            toggleCreateCategory,
            onSubmit,
            createCategoryRef,
            handleOnClickOutsideModal
        }
    }
}
</script>

<style scoped></style>
