import { Axios } from '@/utils/axiosConfig'
import store from '@/store'
import Router from '@/router'

const state = {
    loading: false,
    assetsSummary: [],
    assets: [],
    asset: null,
    transactions: [],
    transactionLogs: [],
    createAssetNotifier: null,
    assetIds: [],
    assetPrices: [],
    asset: {},
    showCreateAssetModal: false,
    showEditAssetModal: false,
    assetId: null,
    rawMaterials: [],
    wipRawMaterials: [],
}

const mutations = {
    SET_LOADING_STATE(state, payload) {
        state.loading = payload
    },
    SET_ASSETS_SUMMARY(state, payload) {
        state.assetsSummary = payload
    },

    SET_ASSETS(state, payload) {
        state.assets = payload
    },

    SET_ASSET(state, payload) {
        state.asset = payload
    },

    SET_ASSET_TRANSACTIONS(state, payload) {
        state.transactions = payload
    },

    SET_ASSET_TRANSACTION_LOGS(state, payload) {
        state.transactionLogs = payload
    },

    SET_CREATE_ASSET_NOTIFIER(state, payload) {
        state.createAssetNotifier = payload
    },

    SET_NEW_ASSET_ID(state, payload) {
        if (payload?.index !== null || payload?.index !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            state.assetIds[payload?.index] = payload?.value?._id
        } else {
            // eslint-disable-next-line no-underscore-dangle
            state.assetIds.push(payload?.value?._id)
        }
    },

    SET_NEW_ASSET_PRICES(state, payload) {
        if (payload?.index !== null || payload?.index !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            state.assetPrices[payload?.index] = payload?.value
        } else {
            // eslint-disable-next-line no-underscore-dangle
            state.assetPrices.push(payload?.value)
        }

    },

    SET_ASSETS_SKU(state, payload = null) {
        state.assetSku = payload
    },

    SET_WIP_RAW_MATERIALS(state, payload) {
        state.wipRawMaterials = payload
    },

    SET_ASSET(state, payload = {}) {
        state.asset = payload
    },

    SET_ASSET_OUTPUT(state, payload) {
        state.asset.output = payload
    },

    SET_ASSET_INPUT(state, payload) {
        state.asset.input = payload
    },

    IMPACT_ASSET_INPUT(state, payload) {
        state.asset?.input?.rawMaterials?.map(item => {
            item.quantity = parseFloat(item.initial_quantity || 0) * parseFloat(payload)
        });
    },

    SET_ASSET_OUTPUT_TOTAL(state) {
        state.asset.output.total = state.asset.output.assets.reduce((prevVal, currValue) => {
            prevVal += parseInt((currValue?.quantity || 0) * (currValue?.price || 0))
            return prevVal
        }, 0)
    },

    SET_ASSET_INPUT_TOTAL(state) {
        console.log('SET_ASSET_INPUT_TOTAL: ', state)
        state.asset.input.total = state.asset.input.rawMaterials.reduce((prevVal, currValue) => {
            prevVal += parseInt((currValue?.quantity || 0) * (currValue?.price || 0))
            return prevVal
        }, 0)
    },

    SET_ASSET_ADDITIONAL_COSTS(state, payload) {
        state.asset.additionalCosts = payload
    },

    SET_ASSET_ADDITIONAL_COSTS_TOTAL(state) {
        state.asset.additionalCosts.total = state.asset.additionalCosts.items.reduce((prevVal, currValue) => {
            prevVal += parseInt((currValue?.amount || 0))
            return prevVal
        }, 0)
    },

    SET_RAW_MATERIALS(state, payload) {
        state.rawMaterials = payload
    },
    SET_SHOW_CREATE_ASSET_MODAL(state, payload = false) {
        state.showCreateAssetModal = payload
    },
    SET_SHOW_EDIT_ASSET_MODAL(state, payload) {
        state.showEditAssetModal = payload.show
        state.assetId = payload.id
    },
}

const actions = {
    showCreateAssetModal: async ({ commit, dispatch }, payload) => {
        commit('SET_SHOW_CREATE_ASSET_MODAL', payload.show)
        if (payload.show) {
            await store.dispatch('Inventory/getProductSku', payload.cat)
        }
    },

    showEditAssetModal: async ({ commit, dispatch }, payload) => {
        commit('SET_SHOW_EDIT_ASSET_MODAL', payload)
    },

    getAssets: async ({ commit }) => {
        commit('SET_LOADING_STATE', true)

        return Axios.get('/api/v1/assets')
            .then(async ({ data }) => {
                commit('SET_LOADING_STATE', false)
                commit('SET_ASSETS', data.assets)
                return data
            })
            .catch(async err => {
                const { data } = err.response
                // console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
    },

    getAssetsSummary: async ({ commit }) => {
        commit('SET_LOADING_STATE', true)

        return Axios.get('/api/v1/assets/summary')
            .then(async ({ data }) => {
                commit('SET_LOADING_STATE', false)
                commit('SET_ASSETS_SUMMARY', data.assetsSummary)
                return data
            })
            .catch(async err => {
                const { data } = err.response
                // console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
    },

    getAsset: async ({ commit }, id) => {
        commit('SET_LOADING_STATE', true)

        return Axios.get(`/api/v1/assets/${id}`)
            .then(async ({ data }) => {
                commit('SET_LOADING_STATE', false)
                commit('SET_ASSET', data.asset)
                return data
            })
            .catch(async err => {
                const { data } = err.response
                // console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
    },

    getAssetTransactions: async ({ commit }, id) => {
        commit('SET_LOADING_STATE', true)

        return Axios.get(`/api/v1/assets/transactions`)
            .then(async ({ data }) => {
                commit('SET_LOADING_STATE', false)
                commit('SET_ASSET_TRANSACTIONS', data.transactions)
                return data
            })
            .catch(async err => {
                const { data } = err.response
                // console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
    },

    getAssetTransactionLogs: async ({ commit }, id) => {
        commit('SET_LOADING_STATE', true)

        return Axios.get(`/api/v1/assets/${id}/transactions/logs`)
            .then(async ({ data }) => {
                commit('SET_LOADING_STATE', false)
                commit('SET_ASSET_TRANSACTION_LOGS', data.transactions)
                return data
            })
            .catch(async err => {
                const { data } = err.response
                // console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
    },

    createAsset: async ({ commit }, formData) => {
        commit('SET_LOADING_STATE', true)

        return Axios.post('/api/v1/assets', formData)
            .then(async ({ data }) => {
                commit('SET_LOADING_STATE', false)

                commit('SET_NEW_ASSET_ID', data?.product?.id)
                commit('SET_NEW_ASSET_PRICES', data?.product)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                commit('SET_CREATE_ASSET_NOTIFIER', data?.assetDetails)

                return data
            })
            .catch(async err => {
                const { data } = err.response
                // console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
    },

    buyAsset: async ({ commit }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/assets/buy`, payload)
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            Router.push({ name: 'AssetManagementView' })
            return data
        } catch (error) {
            const { data } = error.response
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            console.error(data)
            return data
        }
    },

    sellAsset: async ({ commit }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/assets/sell`, payload)
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            Router.push({ name: 'AssetManagementView' })
            return data
        } catch (error) {
            const { data } = error.response
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            console.error(data)
            return data
        }
    },

    updateAsset: async ({ commit }, payload) => {
        commit('SET_LOADING_STATE', true)

        return Axios.put(`/api/v1/assets/${payload.id}`, payload.formData)
            .then(async ({ data }) => {
                commit('SET_LOADING_STATE', false)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                // console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
    },
    
    searchAsset: (_, payload) => {
        let url = `/api/v1/assets/search?searchQuery=${payload.query}&type=${payload.type}`
        if (payload?.business) {
            url = `${url}&business=${payload.business}`
        }
        return Axios.get(url)
            .then(({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                console.error(data)
            })
    },

    getSku: async ({ commit }, type) => {
        commit('SET_ASSETS_SKU')
        return Axios.get(`/api/v1/assetion/sku?type=${type}`)
            .then(({ data }) => {
                commit('SET_ASSETS_SKU', data?.data)
                return data?.data
            })

            .catch(err => {
                console.error(err.response.data)
            })
    },

    archiveAsset: async ({ dispatch }, payload) => {
        Axios.get(`/api/v1/inventories/${payload.id}/archive`)
            .then(async ({ data }) => {
                dispatch('getAsset', payload.type)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
            .catch(async (err) => {
                console.error(err.response.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
    },
}

export const AssetModule = {
    namespaced: true,
    actions,
    mutations,
    state
}
