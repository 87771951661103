export default function checkPlan({ next, store }) {
    // const isAuth = store?.state.Auth.isAuthenticated
    const emailVerifiedAt = store?.state?.Auth?.userData?.emailVerifiedAt
    const user = store?.state?.Auth?.userData?.user
    const isPlanExpired = store?.state?.Auth?.userData?.isPlanExpired
    const firstTimeSubscription = store?.state?.Auth?.userData?.firstTimeSubscription
    
    if (user && (emailVerifiedAt === undefined || emailVerifiedAt === null)) {
        return next({
            name: 'VerifyEmailOTP'
        })
    } else if (user && isPlanExpired && firstTimeSubscription) {
        return next({
            name: 'CompleteOrder'
        })
    } else if (user && isPlanExpired && !firstTimeSubscription) {
        return next({
            name: 'Plan'
        })
    }

    return next()
}