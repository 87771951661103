<template>
    <!-- form modal -->

    <div
        v-if="isCreateEntity"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center pt0-l pt7"
        id="entity-form"
        :class="isCreateEntity && 'jquery-modal blocker current'"
        
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="createEntityRef">
            <div class="flex justify-between pa3 box-border-bottom pv0">
                <div class="f3 pa3 b">Create New Entity</div>
                <div>
                    <router-link to="#">
                        <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <!--      Previously div tag-->
            <form
                class="entity-form"
                @submit.prevent="submitCreateEntity"
                ref="formRef"
                :class="{ valid: !v$.$error && v$.$dirty, error: v$.$error }"
            >
                <div class="pa3">
                    <!-- full name -->
                    <div class="flex flex-wrap items-end justify-between">
                        <div class="w-50-l w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label for="firstName">First Name <span class="required">*</span></label>
                            </div>
                            <input
                                type="text"
                                ref="firstNameRef"
                                placeholder="First name"
                                id="firstName"
                                name="firstName"
                                required
                                @change="(value) => onNameChange(value, 'firstName')"
                                :value="state.firstName || ''"
                            />
                            <div class="input-errors mb2" v-for="error of v$.firstName?.$errors" :key="error.$uid">
                                <div class="error-msg">First name is required</div>
                            </div>
                        </div>
                        <div class="w-50-l w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label for="lastName">Last Name</label>
                            </div>
                            <input
                                type="text"
                                ref="lastNameRef"
                                placeholder="Last name"
                                id="lastName"
                                name="lastName"
                                @change="(value) => onNameChange(value, 'lastName')"
                                :value="state.lastName || ''"
                            />
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-between">
                        <div class="w-50-l w-100 pr2-l">
                            <div>
                                <div class="pv2 font-w1">
                                    <label for="phone">Phone Number</label>
                                </div>
                                <!-- <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="08055693214"
                                    @change="(value) => onNameChange(value, 'phone')"
                                /> -->
                                <vue-tel-input
                                    :type="'tel'"
                                    :placeholder="'Enter your phone number'"
                                    class="box-border w-100"
                                    :id="'phone'"
                                    :name="'phone'"
                                    mode="international"
                                    :defaultCountry="'NG'"
                                    v-model="state.phone"
                                ></vue-tel-input>
                                <div
                                    style="color: red"
                                    class="input-errors mb2"
                                    v-for="error of v$.phone?.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="w-50-l w-100 pr2-l stay-in-place"
                            style="position: relative"
                            :class="state.showMore && 'w-50-l'"
                        >
                            <div class="pv2 font-w1"><label for="email">Email</label></div>
                            <div class="input-group">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    @change="(value) => onNameChange(value, 'email')"
                                    placeholder="joe@yahoo.co"
                                />
                                <span v-if="state.isEmailValid && !state.isValidatingEmail" class="input-group-label">
                                    <font-awesome-icon icon="fa-solid fa-check" class="fa-2x text-success" />
                                </span>
                                <span v-else-if="state.isEmailValid === false && state.email !== ''" class="input-group-label">
                                    <font-awesome-icon icon="fa-solid fa-remove" class="fa-2x text-danger" />
                                </span>
                                <span v-else-if="state.isValidatingEmail" class="input-group-label">
                                    <font-awesome-icon icon="fa-solid fa-circle-notch" class="fa-2x fa-spin" />
                                </span>
                            </div>
                            <div class="input-errors mb2" v-for="error of v$.firstName?.$errors" :key="error.$uid">
                                <!-- <div class="error-msg">Email is required</div> -->
                            </div>
                        </div>
                    </div>
                    <!-- <div class="flex flex-wrap items-end justify-between">
                        <div class="w-50-l w-100 pr2-l">
                            <div>
                                <div class="pv2 font-w1">
                                    <label for="phone">Phone Number</label>
                                </div>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="08055693214"
                                    @change="value => onNameChange(value, 'phone')"
                                />
                                <div
                                    style="color:red"
                                    class="input-errors mb2"
                                    v-for="error of v$.phone?.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="w-50-l w-100 pr2-l" :class="state.showMore && 'w-50-l'">
                            <div class="pv2 font-w1"><label for="email">Email</label></div>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                @change="value => onNameChange(value, 'email')"
                                placeholder="joe@yahoo.co"
                            />
                            <div class="input-errors mb2" v-for="error of v$.firstName?.$errors" :key="error.$uid">
                            </div>
                        </div>
                    </div> -->
                    <!-- Company & website -->
                    <div v-if="state.showMore" class="flex flex-wrap justify-between pt2">
                        <div class="w-50-l w-100 pr2-l">
                            <div class="pv2 font-w1">
                                <label for="company">Company Name</label>
                            </div>
                            <input
                                type="text"
                                id="company"
                                placeholder="Company name"
                                name="company"
                                @change="(value) => onNameChange(value, 'company')"
                            />
                        </div>
                        <div class="w-50-l w-100 pl2-l">
                            <div class="pv2 font-w1">
                                <label for="website">Website</label>
                            </div>
                            <input
                                type="text"
                                id="website"
                                name="website"
                                @change="(value) => onNameChange(value, 'website')"
                                placeholder="www.simo.co"
                            />
                        </div>
                    </div>

                    <template v-if="state.showMore">
                        <!-- Address -->
                        <div class="pt2">
                            <div class="pv2 font-w1">
                                <label for="address1">Address</label>
                            </div>
                            <input
                                type="text"
                                id="address1"
                                name="address1"
                                @change="(value) => onNameChange(value, 'address1')"
                                placeholder="Street Address"
                            />
                            <input
                                type="text"
                                class="mt3"
                                id="address2"
                                name="address"
                                @change="(value) => onNameChange(value, 'address2')"
                                placeholder="Street Address 2"
                            />
                        </div>
                        <!-- city/state -->
                        <div class="flex pt3">
                            <div class="w-50 pr2">
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    @change="(value) => onNameChange(value, 'city')"
                                    placeholder="City"
                                />
                            </div>
                            <div class="w-50 pl2">
                                <input
                                    type="text"
                                    id="state"
                                    name="state"
                                    @change="(value) => onNameChange(value, 'state')"
                                    placeholder="State/Province"
                                />
                            </div>
                        </div>
                        <!-- zip code / country -->
                        <div class="flex pt3">
                            <div class="w-50 pr2">
                                <input
                                    type="text"
                                    placeholder="Postal/Zip Code"
                                    id="postCode"
                                    name="postCode"
                                    @change="(value) => onNameChange(value, 'postCode')"
                                />
                            </div>
                            <div class="w-50 pl2">
                                <select
                                    @change="(value) => onNameChange(value, 'country')"
                                    name="country"
                                    class="w-100"
                                    id="country"
                                >
                                    <option value="" selected>Choose Country</option>
                                    <option
                                        v-for="country in sortedCountries"
                                        :key="country.alpha2Code"
                                        :value="country.name.common"
                                    >
                                        {{ country.name.common }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- note  -->
                        <textarea
                            name="description"
                            class="mt3"
                            placeholder="Enter description if any"
                            id="description"
                            @change="(value) => onNameChange(value, 'description')"
                            cols="20"
                            rows="5"
                        ></textarea>
                    </template>

                    <div class="flex flex-wrap" style="gap: 1rem">
                        <button class="mt3" @click.prevent="toggleShowMore">
                            {{ state.showMore ? 'Hide more ' : 'Add more ' }}fields
                        </button>
                        <button class="mt3" type="submit" :disabled="state.disableSubmitButton">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import axios from 'axios'
import { required, email, numeric, minValue, maxValue } from '@vuelidate/validators'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
    name: 'CreateEntity',
    components: { VueTelInput },
    props: {
        firstName: String,
        closeModal: Function,
    },

    setup(props, { emit }) {
        const createEntityRef = ref(null)
        const formRef = ref(null)
        const firstNameRef = ref(null)
        const lastNameRef = ref(null)
        const store = useStore()
        const incomingName = computed(() => store.state.Entity?.incomingEntityName)

        const countries = ref([])
        const sortedCountries = ref([])
        const selectedCountry = ref(null)

        const allCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all')
                countries.value = response.data
            } catch (error) {
                console.error('Error fetching countries:', error)
            }
        }
        const sortCountriesAlphabetically = () => {
            sortedCountries.value = [...countries.value].sort((a, b) => a.name.common.localeCompare(b.name.common))
        }

        // Watch for changes in countries and sort when updated
        watch(countries, () => {
            sortCountriesAlphabetically()
        })

        const state = reactive({
            disableSubmitButton: false,
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            phone: '',
            website: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postCode: '',
            country: '',
            description: '',
            showMore: false,
            isEmailValid: null,
            isValidatingEmail: false,
        })

        const rules = {
            email: { email },
            phone: { minValue: minValue(6) },
            firstName: { required },
        }

        const v$ = useVuelidate(rules, state)

        const toggleShowMore = () => {
            state.showMore = !state.showMore
        }

        const isCreateEntity = computed(() => store?.state.Entity.showCreateEntity)
        watch(
            () => incomingName?.value,
            () => {
                document.body.style.overflow = isCreateEntity.value ? 'hidden' : 'auto'
            }
        )
        watch(
            () => incomingName?.value,
            (newVal, oldVal) => {
                const name = newVal.trim().split(' ')
                const firstName = name[0]
                const lastName = newVal.replace(new RegExp(firstName, 'g'), '').trim()
                state['firstName'] = firstName
                state['lastName'] = lastName
            }
        )

        const validateEmail = async () => {
            state.isEmailValid = null
            state.isValidatingEmail = true
            await store
                .dispatch('Auth/validateEmail', state.email)
                .then((resp) => (state.isEmailValid = resp))
                .finally(() => (state.isValidatingEmail = false))
        }

        const onNameChange = (value, stateName) => {
            state[stateName] = value.target.value
            if (stateName === 'email') {
                validateEmail()
            }
        }

        const closeCreateEntityModal = () => {
            store.dispatch('Entity/toggleCreateEntity', false)
            const body = document.querySelector('body')
            body.style.overflow = 'auto'
        }

        const submitCreateEntity = () => {
            if (state.email !== '' && !state.isEmailValid) {
                store.dispatch('Alert/setAlert', {
                    message: "We don't accept unverified emails. Clear email input to proceed",
                    status: false,
                })
                return
            }
            // v$.value.$touch()
            // if (v$.value.$invalid) return

            state.disableSubmitButton = true

            if (firstNameRef.value?.value) {
                state.firstName = firstNameRef.value?.value
                state.lastName = lastNameRef.value?.value
            }

            const { disableSubmitButton, isValidatingEmail, ...data } = state

            store
                .dispatch('Entity/createEntity', data)
                .then((resp) => {
                    if (resp?.success) {
                        emit('onCreated', resp?.entity)
                        state.disableSubmitButton = false
                        state.firstName = ''
                        state.lastName = ''
                        state.email = ''
                        state.company = ''
                        state.phone = ''
                        state.website = ''
                        state.address1 = ''
                        state.address2 = ''
                        state.city = ''
                        state.state = ''
                        state.postCode = ''
                        state.country = ''
                        state.description = ''
                        state.showMore = false
                        state.isEmailValid = false
                        state.isValidatingEmail = false

                        formRef.value.reset()

                        closeCreateEntityModal()
                    } else {
                        state.disableSubmitButton = false
                    }
                })
                .catch(() => {
                    state.disableSubmitButton = false
                })
        }

        const handleOnClickOutsideModal = (e) => {
            if (createEntityRef?.value && !createEntityRef?.value.contains(e.target)) {
                closeCreateEntityModal()
            }
        }
        onMounted(() => allCountries())

        return {
            state,
            submitCreateEntity,
            isCreateEntity,
            incomingName,
            onNameChange,
            firstNameRef,
            lastNameRef,
            formRef,
            createEntityRef,
            handleOnClickOutsideModal,
            toggleShowMore,
            v$,
            sortedCountries,
            selectedCountry,
        }
    },
}
</script>

<style>
.input-group {
    position: relative;
}
.input-group-label {
    position: absolute;
    top: 6px;
    right: 10px;
    cursor: pointer;
}
</style>
