// import ShortUniqueId from 'short-unique-id'
import axios from 'axios'
import { Axios } from '@/utils/axiosConfig'
import store from '@/store'
import { paginationOptions } from '@/utils/lib'
import Router from '@/router'

// const uid = new ShortUniqueId()

const state = {
    createProductNotifier: null,
    productName: '',
    showCreateBrand: false,
    showCreateCategory: false,
    showImportInventoryModal: false,
    showInventoryBulkUpdateModal: false,
    showInventoryPriceUpdateModal: false,
    showReplicateProductsModal: false,
    brandId: null,
    brand: null,
    brands: [],
    categoryId: null,
    category: null,
    categories: [],
    showCreateProduct: false,
    createProductType: '',
    incomingProductName: '',
    incomingProductIndex: null,
    productIds: [],
    productPrices: [],
    assetIds: [],
    assetPrices: [],
    // productBuyingPrices: [],
    // productSellingPrices: [],
    productEntryDetails: [],
    productEntrySearchList: [],
    assetEntrySearchList: [],
    // allProducts: [],
    allProducts: null,
    products: { data: [], page: 1, skip: 0, total: 0 },
    archivedProducts: [],
    productPages: {},
    productsSummary: [],
    productsAnalysis: [],
    stockTransfers: [],
    purchaseOrders: [],
    // productDetails: {
    //   product: {},
    //   summary: {},
    //   transactions: [],
    // },

    allServices: {},
    importedInventoryArray: null,
    productDetails: null,
    productLoading: true,
    serviceLoading: true,
    serviceDetails: null,

    productToUpdate: null,

    summary: null,
    topProductsInStock: null,
    topSellingProducts: null,
    recentSales: null,
    lowStock: null,
    productSku: null,

    tempInStock: 0,
    isSuccess: false,
    loading: false,
    inventoryGraphData: [],
    showUpdateInventoryModal: false,
    currentStockQty: 0,
    inventoryProduct: '',
    showServiceBulkUploadModal: false,
    // altLoadingState: false

    showProductVariantsModal: false,
    productIndex: null,
    productVariants: [],
}

const mutations = {
    SET_LOADING_STATE(state, payload) {
        state.loading = payload
    },
    SET_TEMP_IN_STOCK(state, payload = 0) {
        state.tempInStock = payload
    },

    SET_CREATE_PRODUCT_NOTIFIER(state, payload) {
        state.createProductNotifier = payload
    },

    SET_STOCK_TRANSFERS(state, payload) {
        state.stockTransfers = payload
    },

    SET_PURCHASE_ORDERS(state, payload) {
        state.purchaseOrders = payload
    },

    SET_SHOW_IMPORT_INVENTORY_MODAL(state) {
        state.showImportInventoryModal = !state.showImportInventoryModal
    },

    SET_SHOW_INVENTORY_BULK_UPDATE_MODAL(state) {
        state.showInventoryBulkUpdateModal = !state.showInventoryBulkUpdateModal
    },

    SET_SHOW_INVENTORY_PRICE_UPDATE_MODAL(state) {
        state.showInventoryPriceUpdateModal = !state.showInventoryPriceUpdateModal
    },

    SET_SHOW_REPLICATE_PRODUCT_MODAL(state) {
        state.showReplicateProductsModal = !state.showReplicateProductsModal
    },

    SET_IMPORTED_INVENTORY_DATA(state, payload) {
        state.importedInventoryArray = payload
    },

    SET_SHOW_CREATE_PRODUCT(state, payload) {
      state.showCreateProduct = payload?.show
      state.createProductType = payload?.cat
      state.incomingProductIndex = payload?.index
    },

    SET_PRODUCT_SKU(state, payload = null) {
        state.productSku = payload
    },

    SET_SHOW_CREATE_BRAND(state, payload = false) {
        state.showCreateBrand = payload
    },

    SET_SHOW_CREATE_CATEGORY(state, payload = false) {
        state.showCreateCategory = payload
    },

    SET_BRAND_ID(state, payload = null) {
        state.brandId = payload
    },

    SET_BRANDS(state, payload) {
        state.brands = payload
    },

    SET_BRAND(state, payload = null) {
        state.brand = payload
    },

    SET_PRODUCT_NAME(state, payload) {
        state.productName = payload
    },

    SET_CATEGORY_ID(state, payload = null) {
        // state.category = payload
        state.categoryId = payload
    },

    SET_CATEGORIES(state, payload) {
        state.categories = payload
    },

    SET_CATEGORY(state, payload = null) {
        state.category = payload
    },

    SET_INCOMING_PRODUCT_NAME(state, payload) {
        state.incomingProductName = payload
    },

    SET_NEW_PRODUCT_ENTRY_DETAILS(state, payload) {
        state.productEntryDetails.push(payload)
    },

    SET_PRODUCT_ENTRY_DETAILS_FROM_SEARCH(state, payload) {
        state.productEntrySearchList = payload
    },

    SET_ASSET_ENTRY_DETAILS_FROM_SEARCH(state, payload) {
        state.assetEntrySearchList = payload
    },

    SET_NEW_PRODUCT_ID(state, payload) {
        if (payload?.index !== null || payload?.index !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            state.productIds[payload?.index] = payload?.value?._id
        } else {
            // eslint-disable-next-line no-underscore-dangle
            state.productIds.push(payload?.value?._id)
        }
        // state.productIds.push(payload)
    },

    SET_NEW_PRODUCT_PRICES(state, payload) {
        if (payload?.index !== null || payload?.index !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            state.productPrices[payload?.index] = payload?.value
        } else {
            // eslint-disable-next-line no-underscore-dangle
            state.productPrices.push(payload?.value)
        }

        // state.productPrices.push(payload)
    },

    RESET_PRODUCT_IDS(state) {
        state.isSuccess = true
        state.productIds = []
        state.productPrices = []
    },

    UNSET_NEW_PRODUCT_ID(state, payload) {
        // const ProdIds = store?.state?.Inventory?.productIds
        // const productIdToDelete = ProdIds.splice(payload, 1)
        // state.productIds = state.productIds.filter((prodId) => prodId !== productIdToDelete[0])
        const ProdIds = store?.state?.Inventory?.productIds
        const productIdToDelete = ProdIds.splice(payload, 1)
        state.productIds = state.productIds.filter(prodId => prodId !== productIdToDelete[0])
        const prodPricing = store?.state?.Inventory?.productPrices
        const productPricesToDelete = prodPricing.splice(payload, 1)
        state.productPrices = state.productPrices.filter(
            prodPrices => JSON.stringify(prodPrices) !== JSON.stringify(productPricesToDelete[0])
        )
    },

    SET_ALL_SERVICES(state, payload) {
        state.allServices = payload
    },
    SET_ALL_PRODUCTS(state, payload) {
        state.products = payload
    },

    SET_ARCHIVED_PRODUCTS(state, payload) {
        state.archivedProducts = payload
    },

    SET_PRODUCTS_SUMMARY(state, payload) {
        state.productsSummary = payload
    },

    SET_PRODUCTS_ANALYSIS(state, payload) {
        state.productsAnalysis = payload
    },

    SET_PRODUCT_TO_UPDATE(state, payload = null) {
        state.productToUpdate = payload
    },

    SET_PRODUCT_LOADING(state, payload = true) {
        state.productLoading = payload
    },

    SET_SERVICE_LOADING(state, payload = true) {
        state.serviceLoading = payload
    },

    SET_PRODUCT(state, payload = null) {
        state.productDetails = {}
        state.productDetails.product = payload?.product
        state.productDetails.transactions = payload?.productTransactions
        // state.productDetails.summary = payload?.summary
        state.productDetails.suppliers = payload?.productSuppliers
    },

    SET_SERVICE(state, payload = null) {
        // console.log('SET_SERVICE', payload)
        state.serviceDetails = {}
        state.serviceDetails.service = payload?.service
        state.serviceDetails.transactions = payload?.serviceTransactions
        // state.serviceDetails.summary = payload?.summary
        state.serviceDetails.suppliers = payload?.serviceSuppliers
    },

    SET_INVENTORY_SUMMARY(state, payload) {
        state.summary = payload
    },

    SET_TOP_INVENTORY_IN_STOCK(state, payload) {
        state.topProductsInStock = payload
    },

    SET_TOP_SELLING_PRODUCTS(state, payload) {
        state.topSellingProducts = payload
    },

    SET_LOW_STOCKS(state, payload) {
        state.lowStock = payload
    },

    SET_RECENT_SALES(state, payload) {
        state.recentSales = payload
    },
    SET_INVENTORY_GRAPH_DATA(state, payload) {
        state.inventoryGraphData = payload
    },
    SET_SHOW_UPDATE_INVENTORY_MODAL(state, payload) {
        state.showUpdateInventoryModal = payload?.status || false
        state.currentStockQty = payload?.product?.qty_in_stock || 0
        state.inventoryProduct = payload?.product || null
    },

    SET_SHOW_SERVICE_BULK_IMPORT_MODAL(state, payload = false) {
        state.showServiceBulkUploadModal = payload
    },

    SET_SHOW_PRODUCT_VARIANT_MODAL(state, payload = false) {
        state.showProductVariantsModal = payload.status
        state.productIndex = payload.index
    },

    SET_PRODUCT_VARIANTS(state, data) {
        state.productVariants = data
    },

    SET_NEW_ASSET_ID(state, payload) {
        if (payload?.index !== null || payload?.index !== undefined) {
            state.assetIds[payload?.index] = payload?.value?._id
        } else {
            state.assetIds.push(payload?.value?._id)
        }
    },

    SET_NEW_ASSET_PRICES(state, payload) {
        if (payload?.index !== null || payload?.index !== undefined) {
            state.assetPrices[payload?.index] = payload?.value
        } else {
            state.assetPrices.push(payload?.value)
        }
    },
}

const actions = {
    setTempInStockValue({ commit }, payload) {
        commit('SET_TEMP_IN_STOCK', payload)
    },

    // setCreateProductNotifier({ commit }, payload) {
    //    commit('SET_CREATE_PRODUCT_NOTIFIER', payload)
    // },

    // This is a link from add transaction to create product page, in so as to preserve state
    setAllServices: ({ commit }, payload) => {
        commit('SET_ALL_SERVICES', payload)
    },
    setProductName: ({ commit }, payload) => {
        commit('SET_PRODUCT_NAME', payload)
    },

    toggleImportInventoryModal: ({ commit }) => {
        commit('SET_SHOW_IMPORT_INVENTORY_MODAL')
    },

    toggleInventoryBulkUpdateModal: ({ commit }) => {
        commit('SET_SHOW_INVENTORY_BULK_UPDATE_MODAL')
    },

    toggleInventoryPriceUpdateModal: ({ commit }) => {
        commit('SET_SHOW_INVENTORY_PRICE_UPDATE_MODAL')
    },

    toggleReplicateProductModal: ({ commit }) => {
        commit('SET_SHOW_REPLICATE_PRODUCT_MODAL')
    },

    showCreateProduct: ({ commit, dispatch }, payload) => {
        commit('SET_SHOW_CREATE_PRODUCT', payload)
        if (payload.show) {
            dispatch('getProductSku', payload.cat)
        }
    },

    showCreateBrand: ({ commit }, payload) => {
        commit('SET_SHOW_CREATE_BRAND', payload)
    },

    showCreateCategory: ({ commit }, payload) => {
        commit('SET_SHOW_CREATE_CATEGORY', payload)
    },

    showIncomingProductName: ({ commit }, payload) => {
        commit('SET_INCOMING_PRODUCT_NAME', payload)
    },

    getAllProducts: async ({ commit }, payload) => {
        commit('SET_ALL_PRODUCTS', null)
        commit('SET_LOADING_STATE', true)
        // console.log('PAYLOAD: ', payload)
        return Axios.get(`/api/v1/inventories?${paginationOptions(payload)}`)
            .then(({ data }) => {
                commit('SET_ALL_PRODUCTS', data)
                commit('SET_LOADING_STATE', false)
                // console.log('get all products')

                if (data.status === 403) {
                    store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                }

                return data
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    getAllServices: async ({ commit }, payload) => {
        commit('SET_ALL_PRODUCTS', null)
        // console.log(payload)
        Axios.get(`/api/v1/services?${paginationOptions(payload)}`)
            .then(({ data }) => {
                commit('SET_ALL_PRODUCTS', data)

                if (data.status === 403) {
                    store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                }
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    createProduct: async ({ commit }, formData) => {
        commit('SET_LOADING_STATE', true)

        return Axios.post('/api/v1/inventories', formData)
            .then(async ({ data }) => {
                commit('SET_LOADING_STATE', false)

                commit('SET_NEW_PRODUCT_ID', data?.product?.id)
                commit('SET_NEW_PRODUCT_PRICES', data?.product)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                // await store.dispatch('Admin/getProductEntryBySearch')

                // console.log(data?.product, 'prod')
                // dispatch('setCreateProductNotifier', uid.randomUUID(6))
                // dispatch('setCreateProductNotifier', data?.productDetails)

                commit('SET_CREATE_PRODUCT_NOTIFIER', data?.productDetails || data?.serviceDetails)

                return data
            })
            .catch(async err => {
                const { data } = err.response
                // console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
    },

    toggleLoadingState: ({ commit }, payload) => {
        commit('SET_LOADING_STATE', payload)
    },

    createBrand: async ({ commit }, formData) => {
        commit('SET_BRAND_ID')
        commit('SET_BRAND')
        return Axios.post('/api/v1/brands', formData)
            .then(async ({ data }) => {
                // eslint-disable-next-line no-underscore-dangle
                commit('SET_BRAND_ID', data?.brand?._id)
                commit('SET_BRAND', data?.brand)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                return data?.success
            })
            .catch(async err => {
                const { data } = err.response
                console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data.success
            })
    },

    getAllBrands: async ({ commit }, formData) => {
        return Axios.get('/api/v1/brands', formData)
            .then(({ data }) => {
                commit('SET_BRANDS', data)
                return data
            })

            .catch(err => console.error(err.response.data))
    },

    createCategory: async ({ commit }, formData) => {
        commit('SET_CATEGORY_ID')
        commit('SET_CATEGORY')
        return Axios.post('/api/v1/categories', formData)
            .then(async ({ data }) => {
                // eslint-disable-next-line no-underscore-dangle
                commit('SET_CATEGORY_ID', data?.category?._id)
                commit('SET_CATEGORY', data?.category)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data?.success
            })
            .catch(async err => {
                const { data } = err.response
                console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data?.success
            })
    },

    getAllCategories: async ({ commit }, formData) => {

        //console.log(`Getting all categories`, formData)
        return Axios.get('/api/v1/categories', formData)
            .then(({ data }) => {
                commit('SET_CATEGORIES', data)
                return data
            })

            .catch(err => console.error(err.response.data))
    },

    setProductIdFromExistingProducts: ({ commit }, payload) => {
        // console.log('hm', existingProduct)
        // eslint-disable-next-line no-underscore-dangle
        commit('SET_NEW_PRODUCT_ID', payload)
        commit('SET_NEW_PRODUCT_PRICES', payload)
        return payload?.value
    },

    removeProductId: async ({ commit }, payload) => {
        commit('UNSET_NEW_PRODUCT_ID', payload)
    },

    getProductEntryBySearch({ commit }, payload) {
        // check for asset payload
        if (!payload.type === 'asset_product') payload.assetCategory = 'product'
        Axios.get(
            `/api/v1/inventories/s/${payload.query}/${payload.type}/${payload.assetCategory}?transactionType=${payload?.transactionType}`
        )
            .then(({ data }) => {
                commit('SET_PRODUCT_ENTRY_DETAILS_FROM_SEARCH', data?.products)
            })

            .catch(err => console.error(err.response.data))
    },

    getAssetEntryBySearch({ commit }, payload) {
        Axios.get(`/api/v1/assets/s/${payload.query}/${payload.type}`)
            .then(({ data }) => {
                commit('SET_ASSET_ENTRY_DETAILS_FROM_SEARCH', data?.products)
            })
            .catch(err => console.error(err.response.data))
    },

    resetProductEntryBySearch({ commit }, payload) {
        commit('SET_PRODUCT_ENTRY_DETAILS_FROM_SEARCH', payload)
    },

    setProductEntryBySearch({ commit }, payload) {
        commit('SET_NEW_PRODUCT_ENTRY_DETAILS', payload)
    },

    getProduct({ commit }, query) {
        const queryPayload = `startDate=${query.startDate}&endDate=${query.endDate}`

        commit('SET_PRODUCT_LOADING')
        commit('SET_PRODUCT')
        Axios.get(`/api/v1/inventories/${query.id}?${queryPayload}`)
            .then(({ data }) => {
                // console.log(data)
                commit('SET_PRODUCT', data)
                commit('SET_PRODUCT_LOADING', false)

                if (data.status === 403) {
                    store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                }
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Products' })
                }
                console.error(err.response.data)
            })
    },
    getService({ commit }, id) {
        commit('SET_SERVICE_LOADING')
        commit('SET_SERVICE')
        Axios.get(`/api/v1/services/${id}`)
            .then(({ data }) => {
                // console.log('SET_SERVICE: ', data)
                commit('SET_SERVICE', data)
                commit('SET_SERVICE_LOADING', false)
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Products' })
                }
                console.error(err.response.data)
            })
    },

    resetProductIds({ commit }) {
        commit('RESET_PRODUCT_IDS')
    },

    getProductToUpdate: async ({ commit }, id) => {
        commit('SET_PRODUCT_TO_UPDATE')
        Axios.get(`/api/v1/inventories/update/${id}`)
            .then(({ data }) => {
                commit('SET_PRODUCT_TO_UPDATE', data?.productDetails)
                // console.log(data.productDetails)
            })
            .catch(err => {
                console.error(err.response.data)
            })
    },

    getInventorySummary: async ({ commit }) => {
        commit('SET_INVENTORY_SUMMARY')
        Axios.get('/api/v1/inventories/summary')
            .then(({ data }) => {
                commit('SET_INVENTORY_SUMMARY', data?.data)
                // console.log(data.data, 'summary')

                if (data.status === 403) {
                    store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                }
            })
            .catch(err => {
                console.error(err.response.data)
            })
    },

    getTopInventoryInStock: async ({ commit }) => {
        commit('SET_TOP_INVENTORY_IN_STOCK')
        Axios.get('/api/v1/inventories/top-in-stock')
            .then(({ data }) => {
                commit('SET_TOP_INVENTORY_IN_STOCK', data?.data)
                // console.log(data.data, 'top inventory in stock')
            })

            .catch(err => {
                console.error(err.response.data)
            })
    },

    getTopSellingProducts: async ({ commit }) => {
        commit('SET_TOP_SELLING_PRODUCTS')
        Axios.get('/api/v1/inventories/top-selling-products')
            .then(({ data }) => {
                commit('SET_TOP_SELLING_PRODUCTS', data?.data)
                // console.log(data.data, 'top selling stock')
            })

            .catch(err => {
                console.error(err.response.data)
            })
    },

    getRecentSales: async ({ commit }) => {
        commit('SET_RECENT_SALES')
        Axios.get('/api/v1/inventories/recent-sales')
            .then(({ data }) => {
                commit('SET_RECENT_SALES', data?.data)
                // console.log(data.data, 'recent sales')
            })

            .catch(err => {
                console.error(err.response.data)
            })
    },

    getLowStocks: async ({ commit }) => {
        commit('SET_LOW_STOCKS')
        Axios.get('/api/v1/inventories/low-in-stock')
            .then(({ data }) => {
                commit('SET_LOW_STOCKS', data?.data)
                // console.log(data.data, 'low stock')
            })

            .catch(err => {
                console.error(err.response.data)
            })
    },

    getProductSku: async ({ commit }, type) => {
        // console.log('type: ', type);
        return Axios.get(`/api/v1/inventories/sku?type=${type}`)
            .then(({ data }) => {
                commit('SET_PRODUCT_SKU', data?.data)
                // console.log('product Sku', data.data)
                return data?.data
            })
            .catch(err => {
                console.error(err.response.data)
                return err.response.data
            })
    },

    updateProduct: async (_, payload) => {
        const { id, formData } = payload
        return Axios.patch(`/api/v1/inventories/update/${id}`, formData)
            .then(async ({ data }) => {
                if (data.isService) {
                    await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                    await Router.replace({ name: 'Service', params: { id } })
                    return data
                }
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                await Router.replace({ name: 'Product', params: { id } })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                console.log(err.response)
                return data
            })
    },

    importProducts: async (_, payload) => {
        return Axios.post('/api/v1/inventories/import/csv', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                await Router.replace({ name: 'ImportProducts', query: { importId: data?.importId } })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                // console.log(err.response)
                return data
            })
    },

    downloadInventoryTemplate: async () => {
        return Axios.get('/api/v1/inventories/template/download')
            .then(async ({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                return data
            })
    },

    downloadInventoryImportTemplate: async () => {
        return Axios.get('/api/v1/inventories/import/template/download')
            .then(async ({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                return data
            })
    },

    importInventoryBulkUpdate: async (_, payload) => {
        return Axios.post('/api/v1/inventories/bulk-update', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                // console.log(err.response)
                return data
            })
    },

    inventoryPriceUpdate: async (_, payload) => {
        return Axios.post('/api/v1/inventories/price-update', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                // console.log(err.response)
                return data
            })
    },

    replicateProductsinBusinesses: async (_, payload) => {
        return Axios.post('/api/v1/inventories/replicate-products', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                // console.log(err.response)
                return data
            })
    },

    getImportedProducts: async ({ commit }, query) => {
        return Axios.get(`/api/v1/inventories/import/csv?importId=${query}`)
            .then(async ({ data }) => {
                commit('SET_IMPORTED_INVENTORY_DATA', data?.result)
                // console.log(data, 'data')

                // await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                await Router.replace({ name: 'Products' })
                console.log(err.response)
                return data
            })
    },

    processImportedProducts: async (_, payload) => {
        const { query, products } = payload

        return Axios.post(`/api/v1/inventories/import/csv/process?importId=${query}`, { products })
            .then(async ({ data }) => {
                // await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                await store.dispatch('Alert/setAlert', {
                    // eslint-disable-next-line
                    message: `Import success, please hold while we complete you request. This might take a while depending on your CSV size but we will notify you when done. Please do not retry as you will have duplicate entries`,
                    status: data.success
                })
                await Router.replace({ name: 'Products' })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                // await Router.replace({ name: 'Products' })
                console.log(err.response)
                return data
            })
    },

    getArchivedProducts: async ({ commit }) => {
        Axios.get(`/api/v1/inventories/archived/all`)
            .then(({ data }) => {
                commit('SET_ARCHIVED_PRODUCTS', data)
            })
            .catch(err => {
                console.error(err.response.data)
            })
    },

    archiveProduct: async ({ commit, dispatch }, id) => {
        commit('SET_LOADING_STATE', true)
        Axios.get(`/api/v1/inventories/${id}/archive`)
            .then(async ({ data }) => {
                dispatch('getAllProducts')
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING_STATE', false)
            })
            .catch(async err => {
                console.error(err.response.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING_STATE', false)
            })
    },

    restoreProduct: async ({ dispatch }, id) => {
        Axios.get(`/api/v1/inventories/${id}/restore`)
            .then(async ({ data }) => {
                dispatch('getArchivedProducts')
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
            .catch(async err => {
                console.error(err.response.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
    },
    getInventoryGraphData: async ({ commit }, query) => {
        return Axios.post(`/api/v1/inventory/graphdata`)
            .then(async ({ data }) => {
                commit('SET_INVENTORY_GRAPH_DATA', data?.data)
                // console.log(data, 'data')

                // await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                // await Router.replace({ name: 'Products' })
                console.log(err.response)
                return data
            })
    },
    toggleUpdateInventoryModal: ({ commit }, payload) => {
        commit('SET_SHOW_UPDATE_INVENTORY_MODAL', payload)
    },

    stockUpdate: async ({ dispatch }, payload) => {
        return Axios.patch(`/api/v1/inventory/stock-update`, payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                await dispatch('getInventorySummary')
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.log(err.response)
                return data
            })
    },


    toggleServiceBulkImportModal: ({ commit }, payload) => {
        commit('SET_SHOW_SERVICE_BULK_IMPORT_MODAL', payload)
    },

    downloadServiceTemplate: async () => {
        return Axios.get('/api/v1/inventories/service-bulk-upload-template/download')
            .then(async ({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                return data
            })
    },

    importServiceBulkUpload: async (_, payload) => {
        return Axios.post('/api/v1/inventories/service-bulk-upload', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                // console.log(err.response)
                return data
            })
    },

    getTransferStockDestinationQuantity: async (_, payload) => {
        return Axios.get(`/api/v1/inventory/get-transfer-stock-destination-quantity?productName=${payload.productName}&business=${payload.business}`)
            .then(async ({ data }) => {
                return data.quantity
            })
            .catch(err => {
                const { data } = err.response
                return data
            })
    },

    stockTransfers: async ({ commit }, payload) => {
        return Axios.get(`/api/v1/inventory/stock-transfers/list`)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_STOCK_TRANSFERS', data)
                commit('SET_LOADING_STATE', false)
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    actOnStockTransfer: async (_, payload) => {
        return Axios.patch(`/api/v1/inventory/transfer-stock/${payload.id}`, payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    transferStock: async (_, payload) => {
        return Axios.post(`/api/v1/inventory/transfer-stock`, payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    updateTransferStock: async (_, payload) => {
        const { id, data } = payload
        return Axios.put(`/api/v1/inventory/transfer-stock/${id}`, data)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    getTransferStock: async (_, payload) => {
        return Axios.get(`/api/v1/inventory/stock-transfers/${payload.id}`)
            .then(async ({ data }) => {
                if (!data.status) {
                    Router.replace({ name: 'TransferStock' })
                }

                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    duplicateStockTransfer: async (_, payload) => {
        return Axios.post(`/api/v1/inventory/transfer-stock/${payload.id}/duplicate`, {})
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    getPurchaseOrders: async ({ commit }, payload) => {
        return Axios.get(`/api/v1/inventory/purchase-orders/list`)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_PURCHASE_ORDERS', data)
                commit('SET_LOADING_STATE', false)
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    actOnPurchaseOrder: async (_, payload) => {
        return Axios.patch(`/api/v1/inventory/purchase-orders/${payload.id}`, payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    createPurchaseOrder: async (_, payload) => {
        return Axios.post(`/api/v1/inventory/purchase-orders`, payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    updatePurchaseOrder: async (_, payload) => {
        const { id, data } = payload
        return Axios.put(`/api/v1/inventory/purchase-orders/${id}`, data)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    getPurchaseOrder: async (_, payload) => {
        return Axios.get(`/api/v1/inventory/purchase-orders/${payload.id}`)
            .then(async ({ data }) => {
                if (!data.status) {
                    Router.replace({ name: 'PurchaseOrderCreate' })
                }

                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    getPurchaseOrderNumber: async (_, payload) => {
        return Axios.get(`/api/v1/inventory/purchase-orders/number`)
            .then(async ({ data }) => {
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    duplicatePurchaseOrder: async (_, payload) => {
        return Axios.post(`/api/v1/inventory/purchase-orders/${payload.id}/duplicate`, {})
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    toggleProductVariantsModal: ({ commit }, payload) => {
        commit('SET_SHOW_PRODUCT_VARIANT_MODAL', payload)
    },

    getProductVariants: async ({ commit }, id) => {
        return Axios.get(`/api/v1/inventory/${id}/variants`)
            .then(async ({ data }) => {
                commit('SET_PRODUCT_VARIANTS', data.data)
                return data
            })
            .catch(async (err) => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },
}

export const InventoryModule = {
    namespaced: true,
    actions,
    mutations,
    state
}
