// import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import vue3GoogleLogin from 'vue3-google-login'
import App from './App.vue'
import router from './router'
import store from './store'
import 'tachyons/css/tachyons.min.css'
import './assets/styles/main.css'
import './assets/styles/alert.css'
import './assets/styles/button.css'
import './assets/styles/table.css'
import './assets/styles/vue-search-input.css'
import './assets/styles/vue-color-kit.css'
import 'vue-search-input/dist/styles.css'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import Page from 'v-page'
import VueHtmlToPaper from './plugins/VueHtmlToPaper'
import VueHtmlToPdf from './plugins/VueHtmlToPdf'
import VuePosReceipt from './plugins/VuePosReceipt'
import './registerServiceWorker'
import clickOutside from './utils/clickOutside'
import FontAwesomeIcon from './utils/fontAwesomeIcons'
import Engage from '@engage_so/js'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Engage.init('66adde1054330a429088506cb203f955')

createApp(App)
    .use(vue3GoogleLogin, {
        clientId: `${process.env.VUE_APP_GOOGLE_CLIENT_ID}`
    })
    .use(store)
    .use(router)
    .use(VueTippy)
    .use(Engage)
    .use(VueSweetalert2)
    .use(VueHtmlToPaper)
    .use(VueHtmlToPdf)
    .use(VuePosReceipt)
    .use(Page, {
        language: 'en'
    })
    .use(VueTelInput)
    .directive('click-outside', clickOutside)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')

import 'bootstrap/dist/js/bootstrap.js'
