<template>
    <div class="flex flex-wrap">
        <Alert />
        <div
            class="flex items-center justify-center w-40-l w-100"
            style="background-size: cover; height: 100vh"
            :style="`background-image: url(${require('@/assets/images/login-bg.jpg')})`"
        >
            <div>
                <div class="pb2 dn-l db tc">
                    <img src="../../assets/images/simplebks-logo.png" style="width: 180px" alt="simplebks-logo" />
                </div>
                <h2 class="tc">#AccountingMadeEasy</h2>
                <div class="tc pb3">Your journey to a structured business and ease of accessing finance begins here</div>
                <form class="center pa5-l pa3" @submit.prevent="signUp" id="form">
                    <div class="flex flex-column pb3">
                        <label class="pb2">First Name *</label>
                        <input
                            class="form-sign"
                            v-model="state.firstName"
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            id="firstName"
                            required
                        />
                    </div>

                    <div class="flex flex-column pb3">
                        <label class="pb2">Last Name *</label>
                        <input
                            class="form-sign"
                            v-model="state.lastName"
                            type="text"
                            name="fullName"
                            placeholder="Last Name"
                            id="lastName"
                            required
                        />
                    </div>

                    <div class="flex flex-column pb3">
                        <label class="pb2">Email *</label>
                        <input
                            class="form-sign"
                            v-model="state.email"
                            type="email"
                            name="email"
                            placeholder="user@example.com"
                            id="email"
                            required
                        />
                    </div>
                    <div class="flex flex-column pb3">
                        <label class="pb2">Password *</label>
                        <div class="input-group">
                            <input
                                v-model="state.password"
                                class="form-sign w-100"
                                :type="passwordFieldType"
                                name="password"
                                placeholder="*************"
                                id="password"
                                required
                            />
                            <span class="input-group-label">
                                <font-awesome-icon :icon="passwordFieldIcon" @click="hidePassword = !hidePassword" class="fa-2x" />
                            </span>
                        </div>
                    </div>
                    <input type="hidden" v-model="state.plan" />
                    <input type="hidden" v-model="state.period" />
                    <input type="hidden" v-model="state.promoCode" />
                    <button class="mt3 w-100 submit-btn" type="submit">
                        Signup
                        <!-- <a href="Onboarding1.html" style="color: white">SIGNUP</a>-->
                    </button>
                    <div class="mt3 lh-copy">
                        By clicking Signup, you agree to our
                        <router-link :to="{ name: 'Terms' }"><b> Terms </b></router-link>and
                        <router-link :to="{ name: 'Privacy' }"><b> Privacy Policy. </b></router-link>
                    </div>
                    <div class="tc mt3">
                        Have an account? <router-link to="/sign-in"><b>Login</b></router-link>
                    </div>
                </form>
            </div>
        </div>
        <div
            class="flex items-center justify-center w-60-l signup-content"
            style="background-size: cover; height: 100vh"
            :style="`background-image: url(${require('@/assets/images/signup-background.png')})`"
        >
            <div class="tc">
                <div class="pb2">
                    <img src="../../assets/images/simplebks-logo-white.png" style="width: 180px" alt="simplebks-logo" />
                </div>
                <img src="../../assets/images/signup-img.png" alt="" />
                <div class="tc pa3 white">
                    <h2 class="lh-copy mt0">
                        Integrated with payment gateways <br />
                        (E-Invoicing) & billings
                    </h2>
                    <!-- <div class="lh-copy">
                        Aziest jordan is one of the biggest superstars to have immerged from <br />
                        the professional designer world
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Alert from '@/components/Alert'

export default {
    name: 'SignUp',
    components: { Alert },

    setup() {
        const store = useStore()
        const route = useRoute()

        const hidePassword = ref(true);

        const passwordFieldIcon = computed(() => hidePassword.value ? "fa-solid fa-eye-slash" : "fa-solid fa-eye");
        const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

        const state = reactive({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            plan: 'small',
            period: 'monthly',
            promoCode: ''
        })

        const signUp = () => {
            const { email, ...rest } = state
            const formData = { email: email.toLocaleLowerCase(), ...rest }
            store.dispatch('Auth/signUp', formData)
        }

        onBeforeMount(() => {
            const { code, referralCode } = route.query
            if (referralCode) {
                localStorage.setItem('referralCode', referralCode)
            }
            if (code === undefined || code === '') {
                window.location = 'https://simplebks.com/pricing.html'
            }
            state.promoCode = code
        })

        return {
            state,
            signUp,
            passwordFieldIcon,
            passwordFieldType,
            hidePassword
        }
    }
}
</script>

<style>
.input-group {
    position: relative;
}
.input-group-label {
    position: absolute;
    top: 16px;
    right: 15px;
    cursor: pointer;
}
.fa-2x {
    font-size: 1.3em;
}
</style>
