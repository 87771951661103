import { Axios } from '@/utils/axiosConfig'
import store from '@/store'
import Router from '@/router'

const state = {
    user: {},
    organization: {},
    transaction: {},
    userInitials: '',
    orgInitials: ''

}

const mutations = {
    SET_PUBLIC_RECEIPT(state, payload) {
        const user = payload?.user
        const org = payload?.organization?.name?.split(' ')

        if (user?.lastName) {
            const first = user?.firstName?.split('')[0]
            const second = user?.lastName?.split('')[0]
            state.userInitials = `${first} ${second}`
        } else {
            const first = user?.firstName?.split('')[0]
            const second = user?.firstName?.split('')[1]
            state.userInitials = `${first} ${second}`
        }
        if (org?.length > 1) {
            const first = org?.[0]?.split('')[0]
            const second = org?.[1]?.split('')[0]
            state.orgInitials = `${first} ${second}`
        } else {
            const first = org?.[0]?.split('')[0]
            const second = org?.[0]?.split('')[1]
            state.orgInitials = `${first} ${second}`
        }

        state.user = payload?.user
        state.organization = payload?.organization
        state.transaction = payload?.transaction
        state.userInitials = state.userInitials.toUpperCase()
        state.orgInitials = state.orgInitials.toUpperCase()
    }
}

const actions = {
    getOrganizationTransaction: ({ commit }, payload) => {
        return Axios.get(`/api/v1/${payload.orgId}/${payload.userId}/transaction/${payload.transactionRef}`)
            .then(({ data }) => {
                // console.log('getOrganizationTransaction: ', data)
                commit('SET_PUBLIC_RECEIPT', data?.data)

                return data
            })
            .catch(async err => {
                console.log('err.response: ', err)
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                console.error(data)
            })
    }
}

export const ReceiptModule = { namespaced: true, actions, mutations, state }
