import axios from 'axios'
import { Axios } from '@/utils/axiosConfig'
import store from '@/store'
import Router from '@/router'

const state = {
    profile: {},
    organization: {},
    bankAccounts: [],
    getStarted: null,
    members: [],
    pendingInvites: [],
    paymentPlan: null,
    isLoading: false,
    paymentHistory: [],
    cardDetails: {},
    showCreatePlan: false,
    showFreeTrialModal: false,
    showChangeCardModal: false,
    showAdditonalUserModal: false,
    showExtendPlanModal: false,
    selectedPlan: 'free',
    selectedPlanIndex: 4,
    selectedPlanCurrency: 'NGN',
    selectedPlanPeriod: 'monthly',
    currentBankAccount: null,
    showUpdateBankAccountModal: false,
    showPaystackSubaccountModal: false,
    accountStockLevel: 0,
    archives: [],
    customRoles: [],
    accountTaxDefault: 0,
    accountWipStatus: false,
    allowZeroStockLevel: false,
    accountFootnoteDefault: '',
    accountInvoiceCustomization: null,
    currency: 'NGN',
    integrations: {},
    showSendToTeamMateModal: false,
    isTransactionDeclined: false,
    showTakeATourModal: false,
    transactionTourStatus: false,
    dashboardTourStatus: false,
    createProductTourStatus: false,
    createServiceTourStatus: false,
    business: '',
    businesses: [],
    allBusinesses: [],
    closeAccountModal: false,
    closeAccountModalType: '',
    assetCategories: [],
}

const mutations = {
    SET_PROFILE(state, payload) {
        state.profile = payload
    },

    SET_SHOW_PLAN(state, payload = false) {
        state.showCreatePlan = payload
    },

    SET_SHOW_FREE_PLAN_MODAL(state, payload = false) {
        state.showFreeTrialModal = payload
    },

    SET_SHOW_CHANGE_CARD_MODAL(state, payload = false) {
        state.showChangeCardModal = payload
    },

    SET_SHOW_ADDITIONAL_USER_MODAL(state, payload = false) {
        state.showAdditonalUserModal = payload
    },

    SET_SHOW_EXTEND_PLAN_MODAL(state, payload = false) {
        state.showExtendPlanModal = payload
    },

    SET_SHOW_UPDATE_BANK_ACCOUNT_MODAL(state, payload = null) {
        state.showUpdateBankAccountModal = payload?.show
        state.currentBankAccount = payload?.bankAccount
    },

    SET_TAKE_A_TOUR_MODAL(state, status = false) {
        state.showTakeATourModal = status
    },

    SET_DASHBOARD_TOUR_STATUS(state, status = false) {
        state.dashboardTourStatus = status
    },

    SET_TRANSACTION_TOUR_STATUS(state, status = false) {
        state.transactionTourStatus = status
    },

    SET_CREATE_PRODUCT_TOUR_STATUS(state, status = false) {
        state.createProductTourStatus = status
    },

    SET_CREATE_SERVICE_TOUR_STATUS(state, status = false) {
        state.createServiceTourStatus = status
    },

    SET_ORGANIZATION(state, payload) {
        state.organization = payload
    },

    SET_BANK_ACCOUNTS(state, payload) {
        state.bankAccounts = payload
    },

    SET_MEMBERS(state, payload) {
        state.members = payload
    },

    SET_PENDIND_INVITES(state, payload) {
        state.pendingInvites = payload
    },

    SET_GET_STARTED(state, payload) {
        state.getStarted = payload
    },
    SET_PLAN(state, payload) {
        state.paymentPlan = payload
    },
    SET_LOADING(state, payload) {
        state.isLoading = payload
    },
    SET_PAYMENT_HISTORY(state, payload) {
        state.paymentHistory = payload
    },
    SET_CARD_DETAILS(state, payload) {
        state.cardDetails = payload
    },
    SET_SELECTED_PLAN(state, payload) {
        state.selectedPlan = payload
    },
    SET_SELECTED_PLAN_INDEX(state, payload) {
        state.selectedPlanIndex = payload
    },
    SET_SELECTED_PLAN_CURRENCY(state, payload) {
        state.selectedPlanCurrency = payload
    },
    SET_SELECTED_PLAN_PERIOD(state, payload) {
        state.selectedPlanPeriod = payload
    },

    SET_ACCOUNT_STOCK_LEVEL(state, payload) {
        state.accountStockLevel = payload
    },
    SET_CUSTOM_ROLES(state, payload) {
        state.customRoles = payload
    },

    SET_ACCOUNT_TAX_DEFAULT(state, payload) {
        state.accountTaxDefault = payload
    },

    SET_ACCOUNT_WIP_STATUS(state, payload) {
        state.accountWipStatus = payload
    },

    SET_ACCOUNT_ALLOW_ZERO_STOCK_LEVEL_STATUS(state, payload) {
        state.allowZeroStockLevel = payload
    },

    SET_ACCOUNT_FOOTNOTE_DEFAULT(state, payload) {
        state.accountFootnoteDefault = payload
    },

    SET_ACCOUNT_INVOICE_CUSTOMIZATION(state, payload) {
        state.accountInvoiceCustomization = payload
    },

    SET_ARCHIVES(state, payload) {
        state.archives = payload
    },

    SET_CURRENCY(state, payload) {
        state.currency = payload
    },

    SET_INTEGRATION_SETTINGS(state, payload) {
        state.integrations = payload
    },

    SET_PAYSTACK_SUBACCOUNT_MODAL(state, status) {
        state.showPaystackSubaccountModal = status
    },

    SET_SEND_TO_TEAMMATE_MODAL(state, status) {
        state.showSendToTeamMateModal = status
    },

    SET_IS_TRANSACTION_DECLINED(state, status) {
        state.isTransactionDeclined = status
    },

    SET_BUSINESS(state, data) {
        state.business = data
    },

    SET_BUSINESSES(state, data) {
        state.businesses = data
        // state.businesses = data.map((business) => {
        //     business.isSelected = true
        //     business.qty_in_stock = 0
        //     return business
        // })
    },

    SET_ALL_BUSINESSES(state, data) {
        state.allBusinesses = data
    },

    TOGGLE_CLOSE_BUSINESS_MODAL(state, payload) {
        state.closeAccountModal = payload.status
        state.closeAccountModalType = payload.type
    },

    SET_ASSET_CATEGORIES(state, data) {
        state.assetCategories = data
    },
}

const actions = {
    showCreatePlan: ({ commit }, payload) => {
        commit('SET_SHOW_PLAN', payload)
    },
    showFreeTrialModal: ({ commit }, payload) => {
        commit('SET_SHOW_FREE_PLAN_MODAL', payload)
    },
    showChangeCardModal: ({ commit }, payload) => {
        commit('SET_SHOW_CHANGE_CARD_MODAL', payload)
    },

    showAdditonalUserModal: ({ commit }, payload) => {
        console.log('omoooo', payload, commit)
        commit('SET_SHOW_ADDITIONAL_USER_MODAL', payload)
    },

    showExtendPlanModal: ({ commit }, payload) => {
        commit('SET_SHOW_EXTEND_PLAN_MODAL', payload)
    },

    showUpdateBankAccountModal: ({ commit }, payload) => {
        commit('SET_SHOW_UPDATE_BANK_ACCOUNT_MODAL', payload)
    },

    toggleTakeATourModal: ({ commit }, status) => {
        commit('SET_TAKE_A_TOUR_MODAL', status)
    },

    startDashboardTour: async ({ commit }) => {
        commit('SET_TAKE_A_TOUR_MODAL', false)
        commit('SET_DASHBOARD_TOUR_STATUS', true)
        await Router.replace({ name: 'Dashboard' })
    },

    stopDashboardTour: ({ commit }) => {
        commit('SET_DASHBOARD_TOUR_STATUS', false)
    },

    startTransactionTour: async ({ commit }) => {
        commit('SET_TAKE_A_TOUR_MODAL', false)
        commit('SET_TRANSACTION_TOUR_STATUS', true)
        await Router.replace({ name: 'AddTransactionNew' })
    },

    stopTransactionTour: ({ commit }) => {
        commit('SET_TRANSACTION_TOUR_STATUS', false)
    },

    startCreateProductTour: async ({ commit }) => {
        commit('SET_TAKE_A_TOUR_MODAL', false)
        commit('SET_CREATE_PRODUCT_TOUR_STATUS', true)
        // await store.dispatch('Inventory/showCreateProduct', { show: true, cat: 'PRODUCT' })
        await Router.replace({ name: 'Products' })
    },

    stopCreateProductTour: async ({ commit }) => {
        commit('SET_CREATE_PRODUCT_TOUR_STATUS', false)
        await store.dispatch('Inventory/showCreateProduct', { show: false, cat: 'PRODUCT' })
    },

    startCreateServiceTour: async ({ commit }) => {
        commit('SET_TAKE_A_TOUR_MODAL', false)
        commit('SET_CREATE_SERVICE_TOUR_STATUS', true)
        await store.dispatch('Inventory/showCreateProduct', { show: true, cat: 'SERVICE' })
        await Router.replace({ name: 'AllServices' })
    },

    stopCreateServiceTour: async ({ commit }) => {
        commit('SET_CREATE_SERVICE_TOUR_STATUS', false)
        await store.dispatch('Inventory/showCreateProduct', { show: false, cat: 'SERVICE' })
    },

    getProfile: ({ commit }) => {
        return Axios.get('/api/v1/accounts/profile')
            .then(({ data }) => {
                commit('SET_PROFILE', data?.user)

                return data?.user
            })
            .catch(err => {
                console.log(err?.response?.data || err.message)
            })
    },

    updateProfile: (_, payload) => {
        // eslint-disable-next-line
        delete payload?.firstName
        // eslint-disable-next-line
        delete payload?.lastName
        // eslint-disable-next-line
        delete payload?.email

        return Axios.patch('/api/v1/accounts/profile', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })

                return data
            })
            .catch(async err => {
                await store.dispatch('Alert/setAlert', {
                    message: err?.response?.data?.message || err?.message,
                    status: err?.response?.data?.success
                })
            })
    },

    getTeamMembers: ({ commit }) => {
        return Axios.get('/api/v1/accounts/teams')
            .then(({ data }) => {
                commit('SET_MEMBERS', data?.members)
                commit('SET_PENDIND_INVITES', data?.pendingInvites)

                return data?.members
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    inviteTeamMember: (_, payload) => {
        return Axios.post('/api/v1/accounts/teams/invite', payload)
            .then(async ({ data }) => {
                await store.dispatch('Settings/getTeamMembers')
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Settings/getTeamMembers')
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    resendTeamMemberInvite: (_, payload) => {
        return Axios.post('/api/v1/accounts/teams/invite/resend', payload)
            .then(async ({ data }) => {
                await store.dispatch('Settings/getTeamMembers')
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Settings/getTeamMembers')
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }
            })
    },

    changeTeamMemberRole: ({ dispatch }, payload) => {
        return Axios.patch('/api/v1/accounts/teams/members/role', payload)
            .then(async ({ data }) => {
                await store.dispatch('Settings/getTeamMembers')
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    removeTeamMember: ({ dispatch }, payload) => {
        return Axios.patch(`/api/v1/accounts/teams/members/remove/${payload}`)
            .then(async ({ data }) => {
                await store.dispatch('Settings/getTeamMembers')
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    changeTeamInviteRole: ({ dispatch }, payload) => {
        return Axios.patch('/api/v1/accounts/teams/invite/role', payload)
            .then(async ({ data }) => {
                dispatch('Settings/getTeamMembers')
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    removeTeamInvite: ({ dispatch }, payload) => {
        return Axios.patch(`/api/v1/accounts/teams/invite/${payload}/remove`)
            .then(async ({ data }) => {
                await store.dispatch('Settings/getTeamMembers')
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    validateTeamMemberInvite: (_, payload) => {
        const { email, hash } = payload
        const query = `?email=${email}&hash=${hash}`

        return Axios.get(`/api/v1/accounts/team/invite${query}`)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data.success })
                return data
            })
            .catch(async err => {
                await store.dispatch('Alert/setAlert', {
                    message: err?.response?.data?.message || err?.message,
                    status: err?.response?.data?.success
                })

                return Router.replace({ name: 'Error404' })
            })
    },

    createProfileAndJoinTeam: (_, payload) => {
        // eslint-disable-next-line
        const { email, hash } = payload?.query
        const query = `?email=${email}&hash=${hash}`
        const formData = payload?.formData

        return Axios.post(`/api/v1/accounts/team/invite${query}`, formData)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data.success })
                await Router.replace({ name: 'SignIn' })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                // if (err.response.status === 403) {
                //     await Router.replace({ name: 'Dashboard' })
                // }

                // console.error(data)
                // return Router.replace({ name: 'Error404' })
            })
    },

    getBankAccounts: ({ commit }) => {
        commit('SET_LOADING', true)
        return Axios.get('/api/v1/accounts/bank-accounts')
            .then(({ data }) => {
                const banks = data?.data.filter(bank => {
                    return bank?.bankAccountNumber !== ''
                })

                commit('SET_BANK_ACCOUNTS', banks)
                commit('SET_LOADING', false)
                return data
            })
            .catch(async err => {
                const data = err.response?.data
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })

                if (err.response?.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    createOrUpdateBankAccounts: ({ commit }, payload) => {
        return Axios.patch('/api/v1/accounts/bank-accounts', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success || true })
                await store.dispatch('Auth/getUserData')
                commit('SET_BANK_ACCOUNTS', data?.data)
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    getOrganization: ({ commit }) => {
        return Axios.get('/api/v1/accounts/organization')
            .then(({ data }) => {
                commit('SET_ORGANIZATION', data?.data)

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    updateOrganization: (_, payload) => {
        return Axios.patch('/api/v1/accounts/organization', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success || true })
                await store.dispatch('Auth/getUserData')
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    getGetStarted: ({ commit }) => {
        return Axios.get('/api/v1/get-started')
            .then(async ({ data }) => {
                commit('SET_GET_STARTED', data)
                await store.dispatch('Auth/getUserData')
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Settings' })
                }

                // console.error(data)
            })
    },

    setPlan: ({ commit }, payload) => {
        commit('SET_SELECTED_PLAN', payload)
    },

    setPlanIndex: ({ commit }, payload) => {
        commit('SET_SELECTED_PLAN_INDEX', payload)
    },

    setPlanCurrency: ({ commit }, payload) => {
        commit('SET_SELECTED_PLAN_CURRENCY', payload)
    },

    setPlanPeriod: ({ commit }, payload) => {
        commit('SET_SELECTED_PLAN_PERIOD', payload)
    },

    completeOrder: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        return await Axios.post('/api/v1/payment/complete-order', payload)
            .then(async ({ data }) => {
                commit('SET_PLAN', data?.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                window.location = data.payment.authorization_url
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },

    createPlan: async ({ commit, dispatch }, payload) => {
        commit('SET_LOADING', true)
        return await Axios.post('/api/v1/payment/plans', payload)
            .then(async ({ data }) => {
                dispatch('getPlan')
                await store.dispatch('Auth/getUserData')
                if (data?.data) {
                    commit('SET_PLAN', data.data)
                }
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                if (payload.businessPlan === '1') {
                    await Router.replace({ name: 'GetStarted' })
                } else if (data?.payment?.authorization_url) {
                    window.location = data.payment.authorization_url
                } else {
                    window.location.reload()
                }
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },

    generateTransactionId: async (_) => {
        return await Axios.get('/api/v1/payment/generate-transaction-id')
            .then(async ({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                return data
            })
    },

    createFreeTrial: async ({ commit, dispatch }, payload) => {
        commit('SET_LOADING', true)
        return await Axios.post('/api/v1/payment/plans/trial', payload)
            .then(async ({ data }) => {
                dispatch('getPlan')
                await store.dispatch('Auth/getUserData')
                if (data?.data) {
                    commit('SET_PLAN', data.data)
                }
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                await Router.replace({ name: 'GetStarted' })
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },

    changeCard: async (_, payload) => {
        return await Axios.post('/api/v1/payment/change-card', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    createPlanAdditionalUser: async ({ commit, dispatch }, payload) => {
        commit('SET_LOADING', true)
        return await Axios.post('/api/v1/payment/plans/additional-users', payload)
            .then(async ({ data }) => {
                dispatch('getPlan')
                await store.dispatch('Auth/getUserData')
                if (data?.data) {
                    commit('SET_PLAN', data.data)
                }
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                if (data?.payment?.authorization_url) {
                    window.location = data.payment.authorization_url
                } else {
                    window.location.reload()
                }
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
            })
    },

    getPlan: async ({ commit }) => {
        commit('SET_LOADING', true)
        return await Axios.get('/api/v1/payment/plan')
            .then(async ({ data }) => {
                commit('SET_PLAN', data?.data)
                commit('SET_LOADING', false)
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                commit('SET_LOADING', false)
            })
    },
    getPaymentHistory: async ({ commit }) => {
        commit('SET_LOADING', true)
        return await Axios.get('/api/v1/payment/history')
            .then(async ({ data }) => {
                commit('SET_PAYMENT_HISTORY', data)
                commit('SET_LOADING', false)
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: true })
                commit('SET_LOADING', false)
            })
    },
    getCardDetails: async ({ commit }) => {
        commit('SET_LOADING', true)
        return await Axios.get('/api/v1/payment/card')
            .then(async ({ data }) => {
                commit('SET_CARD_DETAILS', data)
                commit('SET_LOADING', false)
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                commit('SET_LOADING', false)
            })
    },
    verifyPayment: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        return await Axios.post('/api/v1/payment/verify', payload)
            .then(async ({ data }) => {
                commit('SET_LOADING', false)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                return data
            })
    },

    getAccountSettings: ({ commit }) => {
        return Axios.get('/api/v1/accounts/settings')
            .then(({ data }) => {
                commit('SET_ACCOUNT_STOCK_LEVEL', data?.data?.stockLevel)
                commit('SET_ACCOUNT_TAX_DEFAULT', data?.data?.tax)
                commit('SET_ACCOUNT_WIP_STATUS', data?.data?.wipStatus || false)
                commit('SET_ACCOUNT_ALLOW_ZERO_STOCK_LEVEL_STATUS', data?.data?.allowZeroStockLevel || false)
                commit('SET_ACCOUNT_FOOTNOTE_DEFAULT', data?.data?.footnote || '')
                commit('SET_CURRENCY', data?.data?.currency || 'NGN')
                return data
            })
            .catch(err => {
                // console.log(err?.response?.data || err.message)
            })
    },

    updateAccountStockLevel: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        await Axios.post('/api/v1/accounts/settings/stock-level', payload)
            .then(async ({ data }) => {
                commit('SET_ACCOUNT_STOCK_LEVEL', payload.stockLevel)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },

    updateAccountTaxDefault: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        await Axios.post('/api/v1/accounts/settings/tax', payload)
            .then(async ({ data }) => {
                commit('SET_ACCOUNT_TAX_DEFAULT', payload.tax)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },

    updateWipStatus: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        return await Axios.post('/api/v1/accounts/settings/wip', payload)
            .then(async ({ data }) => {
                commit('SET_ACCOUNT_WIP_STATUS', payload.wipStatus)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                return data.status
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
                return data.status
            })
    },

    updateAllowZeroStockLevelStatus: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        return await Axios.post('/api/v1/accounts/settings/inventory-zero-stock-level', payload)
            .then(async ({ data }) => {
                commit('SET_ACCOUNT_ALLOW_ZERO_STOCK_LEVEL_STATUS', payload.allowZeroStockLevel)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                return data.status
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
                return data.status
            })
    },

    updateAccountFootnoteDefault: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        await Axios.post('/api/v1/accounts/settings/footnote', payload)
            .then(async ({ data }) => {
                commit('SET_ACCOUNT_FOOTNOTE_DEFAULT', payload.footnote)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },

    updateInvoiceCustomization: async ({ commit }, options) => {
        const { payload, query } = options
        commit('SET_LOADING', true)
        await Axios.post('/api/v1/accounts/settings/invoice', payload)
            .then(async ({ data }) => {
                commit('SET_ACCOUNT_INVOICE_CUSTOMIZATION', payload.invoice)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                console.log('OPTIONS: ', options)
                if (query.id && query.p === 'preview') {
                    await Router.push({ name: 'InvoicePreview', params: { id: query.id } })
                } else {
                    await Router.replace({ name: 'InvoiceNew', query: { skipCustomize: true } })
                }
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },

    getArchives: ({ commit }) => {
        return Axios.get('/api/v1/archive')
            .then(({ data }) => {
                commit('SET_ARCHIVES', data?.data)
                return data
            })
            .catch(err => {
                console.log(err?.response?.data || err.message)
            })
    },

    restoreFromArchive: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        await Axios.post('/api/v1/archive', payload)
            .then(async ({ data }) => {
                dispatch('getArchives')
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
    },
    createRole: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        return await Axios.post('/api/v1/customroles', payload)
            .then(async ({ data }) => {
                // commit('', data?.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                //window.location = data.payment.authorization_url
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },

    getCustomRoles: async ({ commit }) => {
        commit('SET_LOADING', true)
        return await Axios.get('/api/v1/customroles')
            .then(async ({ data }) => {
                commit('SET_CUSTOM_ROLES', data?.data)
                //console.log('here', data)
                // await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                console.error(data)
            })
    },

    editRole: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        return await Axios.put('/api/v1/customroles', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                commit('SET_LOADING', false)
                //window.location.refresh()
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },
    pickCurrency: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        return await Axios.post('/api/v1/accounts/settings/currency', payload)
            .then(async ({ data }) => {
                commit('SET_LOADING', true)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                //window.location = data.payment.authorization_url
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },

    getIntegrationSettings: ({ commit }) => {
        return Axios.get('/api/v1/accounts/settings/integrations')
            .then(({ data }) => {
                commit('SET_INTEGRATION_SETTINGS', data.data)
                return data
            })
            .catch(err => {
                console.log(err?.response?.data || err.message)
            })
    },

    updatePaystackIntegration: (_, payload) => {
        return Axios.post('/api/v1/accounts/settings/integrations/paystack', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.status })
                return data
            })
            .catch(async err => {
                await store.dispatch('Alert/setAlert', {
                    message: err?.response?.data?.message || err?.message,
                    status: err?.response?.data?.status
                })

                return err?.response?.data
            })
    },

    disconnectPaystackIntegration: (_) => {
        return Axios.patch('/api/v1/accounts/settings/integrations/paystack/disconnect', {})
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.status })
                return data
            })
            .catch(async err => {
                await store.dispatch('Alert/setAlert', {
                    message: err?.response?.data?.message || err?.message,
                    status: err?.response?.data?.status
                })

                return err?.response?.data
            })
    },

    showPaystackSubaccountModal: async ({ commit }, payload) => {
        commit('SET_PAYSTACK_SUBACCOUNT_MODAL', payload)
    },

    showSendToTeamMateModal: async ({ commit }, payload) => {
        commit('SET_SEND_TO_TEAMMATE_MODAL', payload)
    },

    setIsTransactionDeclined: async ({ commit }, payload) => {
        commit('SET_IS_TRANSACTION_DECLINED', payload)
    },

    createPaystackSubaccount: async (_, payload) => {
        try {
            const { data } = await Axios.post(`/api/v1/accounts/create-paystack-subaccount`, payload)
            await store.dispatch('Auth/getUserData')
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            return data
        } catch (error) {
            console.error(error)
            const { data } = error.response
            console.error((data && data?.data) || error.message)
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            return data
        }
    },

    searchMembers: async ({ commit }) => {
        try {
            const { data } = await Axios.get(`/api/v1/accounts/members/search`)
            commit('SET_MEMBERS', data?.members)
            return data
        } catch (error) {
            console.error(error)
            const { data } = error.response
            console.error((data && data?.data) || error.message)
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            return data
        }
    },

    getPaystackBanks: async () => {
        try {
            const { data } = await axios.get(`https://api.paystack.co/bank`, {
                headers: {
                    Authorization: `Bearer ${process.env.VUE_APP_PAYSTACK_SECRET}`,
                },
            })

            return data.data;
        } catch (error) {
            console.error(error)
            const { data } = error.response
            console.error((data && data?.data) || error.message)
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            return data
        }
    },

    verifyAccountNumber: async (_, payload) => {
        try {
            const { data } = await axios.get(`https://api.paystack.co/bank/resolve?account_number=${payload.accountNumber}&bank_code=${payload.bankCode}`, {
                headers: {
                    Authorization: `Bearer ${process.env.VUE_APP_PAYSTACK_SECRET}`,
                },
            })

            return data;
        } catch (error) {
            const { data } = error.response
            console.error((data && data?.data) || error.message)
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            return data
        }
    },

    searchMembers: async ({ commit }) => {
        try {
            const { data } = await Axios.get(`/api/v1/accounts/members/search`)
            commit('SET_MEMBERS', data?.members)
            return data
        } catch (error) {
            console.error(error)
        }
    },

    updateBusinessLogo: async (_, payload) => {
        try {
            const { data } = await Axios.patch(`/api/v1/accounts/settings/update-logo`, payload)
            await store.dispatch('Auth/getUserData')
            return data
        } catch (error) {
            console.error(error)
        }
    },

    getAllBusinesses: async ({ commit }) => {
        return Axios.get(`/api/v1/accounts/businesses/all`)
            .then(async ({ data }) => {
                if (data?.status) {
                    commit('SET_ALL_BUSINESSES', data?.data)
                }
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
                return data
            })
    },

    getBusinesses: async ({ commit }, all = false) => {
        const url = all
            ? `/api/v1/accounts/businesses?all=${all}`
            : `/api/v1/accounts/businesses`
        return Axios.get(url)
            .then(async ({ data }) => {
                if (data?.status) {
                    commit('SET_BUSINESSES', data?.data)
                }

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
                return data
            })
    },

    addBusiness: async ({ dispatch }, formData) => {
        return Axios.post('/api/v1/accounts/businesses', formData)
            .then(async ({ data }) => {
                if (data?.status) {
                    dispatch('getBusinesses')
                    await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                }

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
                return data
            })
    },

    switchBusiness: async ({ dispatch }, orgId) => {
        return Axios.post(`/api/v1/accounts/businesses/${orgId}/switch`, {})
            .then(async ({ data }) => {
                if (data?.status) {
                    dispatch('getPlan')
                    await store.dispatch('Auth/setUserData')
                    dispatch('getBusinesses')
                    await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                    // await Router.replace({ name: 'Dashboard' })
                    window.setTimeout(Router.go(Router.currentRoute), 3000);
                }

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
                return data
            })
    },

    getBusiness: async ({ commit }, id) => {
        return Axios.get(`/api/v1/accounts/${id}/business`)
            .then(async ({ data }) => {
                if (data?.status) {
                    commit('SET_BUSINESS', data?.data)
                }
                return data
            })
            .catch(async err => {
                const { data } = err.response
                console.error(data)
                return data
            })
    },

    changePassword: async ({ commit }, payload) => {
        commit('SET_LOADING', true)
        await Axios.post('/api/v1/accounts/settings/change-password', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                commit('SET_LOADING', false)
                // console.error(data)
            })
    },

    factoryReset: async (_, payload) => {
        return Axios.post(`/api/v1/accounts/factory-reset`, payload)
            .then(async ({ data }) => {
                if (data?.status) {
                    await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                }

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
                return data
            })
    },

    transactionReset: async (_, payload) => {
        return Axios.post(`/api/v1/accounts/transaction-reset`, payload)
            .then(async ({ data }) => {
                if (data?.status) {
                    await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                }

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
                return data
            })
    },

    closeAccount: async (_, payload) => {
        return Axios.post(`/api/v1/accounts/close-account`, payload)
            .then(async ({ data }) => {
                if (data?.status) {
                    await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                    if (data?.logoutUser) {
                        await store.dispatch('Auth/signOut')
                    }
                }

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
                return data
            })
    },

    toggleCloseAccount: ({ commit }, payload) => {
        commit('TOGGLE_CLOSE_BUSINESS_MODAL', payload)
    },

    getAssetCategories: async ({ commit }) => {
        return Axios.get(`/api/v1/assets/categories`)
            .then(async ({ data }) => {
                if (data?.success) {
                    commit('SET_ASSET_CATEGORIES', data.categories)
                }

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
                return data
            })
    },

    updateAssetCategory: async (_, payload) => {
        return Axios.put(`/api/v1/assets/categories`, payload)
            .then(async ({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
                return data
            })
    },
}

export const SettingsModule = { namespaced: true, actions, mutations, state }
