<template>
    <app-wrapper>
        <template v-slot:child>
            <TransactionImportModal :closeModal="() => toggleTransactionImportModal(false)" />
            <!-- Main Box -->
            <div class="flex justify-between">
                <!-- Right box -->
                <div class="">
                    <div class="pa4-l pa3">
                        <div>
                            <h2 class="" style="color: #132c8c; font-size: 18px;">Sales & Invoicing</h2>
                            <p class="w-60-l lh-copy black">
                                Handling the money you earn and sending out bills to customers or clients. Each of these tabs
                                helps you manage different aspects of your sales and income. Whether you're making a quick sale,
                                sending out invoices, or analyzing your sales performance, Simplebks has you covered. It's all
                                about simplifying your sales and income tracking.
                            </p>
                        </div>

                        <div class="mt4 black" style="font-weight: 500">
                            <span class="bg-washed-yellow ph2">Tip: Click on card to perform specify actions </span>
                        </div>

                        <div class="mt4">
                            <div class="flex flex-wrap" style="gap: 15px">
                                <router-link
                                    :to="{ name: 'CreateSalesTransaction' }"
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(23)"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-sales.svg" style="width: 60px" alt="" />
                                    <h3>Quick Sales</h3>
                                    <p class="lh-copy">
                                        This is like a fast lane for recording sales transactions. Perfect for when you need to
                                        record a sale quickly without much fuss.
                                    </p>
                                </router-link>
                                <router-link
                                    :to="{ name: 'InvoiceNew' }"
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(31)"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-1.svg" style="width: 60px" alt="" />
                                    <h3>Create Invoice</h3>
                                    <p class="lh-copy">
                                        Creating bills for your customers or clients. Think of it as a way to ask them to pay you
                                        for goods or services.
                                    </p>
                                </router-link>
                                <router-link
                                    :to="{ name: 'InvoiceSummary' }"
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(21)"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-inv.svg" style="width: 60px" alt="" />
                                    <h3>View Invoices</h3>
                                    <p class="lh-copy">
                                        Here is an overview of all your invoices and their status. You can also send reminders or
                                        download invoices here
                                    </p>
                                </router-link>
                                <router-link
                                    :to="'#'"
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(141)"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    @click="routeTo('Pos')"
                                >
                                    <img src="@/assets/images/revamp-2.svg" style="width: 60px;" alt="" />
                                    <h3>Point of Sales</h3>
                                    <p class="lh-copy">
                                        If you have a physical store, this is like your digital cash register. You can use it to
                                        ring up sales and handle payments in person.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                    </span>
                                </router-link>
                                <div
                                   @click="filterTxnBySales();"
                                   v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(2)"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-invoice.svg" style="width: 60px;" alt="" />
                                    <h3>Sales Journal</h3>
                                    <p class="lh-copy">
                                        A digital ledger where you keep track of all the money you've earned from sales
                                    </p>
                                </div>
                                <router-link
                                    :to="'#'"
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(16)"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    @click="routeTo('CreateAQuote')"
                                >
                                    <img src="@/assets/images/revamp-report.svg" style="width: 60px" alt="" />
                                    <h3>Create a Quote</h3>
                                    <p class="lh-copy">
                                        Creating quotations for your customers or clients. 
                                        Think of it as a way to ask them to pay you for goods or services.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                    </span>
                                </router-link>
                                <router-link
                                    :to="'#'"
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(21)"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    @click="routeTo('Quotations')"
                                >
                                    <img src="@/assets/images/revamp-inv.svg" style="width: 60px" alt="" />
                                    <h3>View Quotes</h3>
                                    <p class="lh-copy">
                                        Here is an overview of all your quotations and their status. You can also
                                        download quotations here
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                    </span>
                                </router-link>
                                <router-link
                                    :to="'#'"
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(16)"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    @click="routeTo('SalesReport')"
                                >
                                    <img src="@/assets/images/revamp-report.svg" style="width: 60px" alt="" />
                                    <h3>Sales Report</h3>
                                    <p class="lh-copy">
                                        Think of this as a report card for your sales. It shows you how well your business is
                                        doing in terms of revenue.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                    </span>
                                </router-link>
                                <div
                                    @click="toggleTransactionImportModal(true)"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(52)"
                                >
                                    <img src="@/assets/images/revamp-upload.svg" style="width: 60px" alt="" />
                                    <h3>Bulk Sales Upload</h3>
                                    <p class="lh-copy">
                                        A shortcut for adding a lot of sales data all at once. Useful if you have many sales to
                                        record.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import AppWrapper from '../../layout/AppWrapper.vue'
import TransactionImportModal from '@/views/Transactions/widgets/TransactionImportModal'

export default {
    name: 'SalesAndInvoicing',
    components: { AppWrapper, TransactionImportModal },
    setup() {
        const router = useRouter()
        const store = useStore()
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const plan = computed(() => store.state?.Settings?.paymentPlan)
        const state = reactive({
            type: 'PRODUCT',
        })

        const toggleCreateProduct = (payload) => {
            state.type = payload?.cat || 'PRODUCT'
            store.dispatch('Inventory/showCreateProduct', payload)
        }
        const toggleTransactionImportModal = (status) => {
            if (plan.value.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `This feature is not available on free plan. Upgrade to unlock!`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                });
                return;
            }
            store.dispatch('Transaction/toggleTransactionImportModal', status)
        }
        const filterTxnBySales = () => {
            router.push({ path: '/transactions', query: { limit: 25, type: 'sales', name: "Sales-Journal"} })

        }
        const changeBusinessType = (type) => {
            store.dispatch('DashboardStats/getBusinessType', type)
        }

        const routeTo = (path) => {
            if (plan.value.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `This feature is not available on free plan. Upgrade to unlock!`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                });
                return;
            }
            router.push({ name: path })
        }

        onMounted(() => {
            store.dispatch('Settings/getPlan')
        })

        return {
            toggleCreateProduct,
            toggleTransactionImportModal,
            filterTxnBySales,
            role,
            rolePermissions,
            changeBusinessType,
            plan,
            routeTo,
        }
    },
}
</script>
<style>
.menu-card {
    width: 23.5%;
    margin-bottom: 20px;
}

.menu-card:hover {
    background-color: rgb(233, 237, 252);
    cursor: pointer;
}

/* mobile navigation */

@media screen and (max-width: 959px) {
    .menu-card {
        width: 48.5%;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 600px) {
    .menu-card {
        width: 100%;
        margin-bottom: 20px;
    }
}
</style>
