<template>
    <div>
        <InviteTeamMember :is-open="state2.isOpen" :close-modal="toggleAddTeamModal" />
        <CreateEntity :closeModal="() => toggleCreateEntity()"/>
        <div class="box-border content-card" style="width: 100%">
            <div class="pa3 box-border-bottom">
                <p class="b">Quick Shortcuts</p>
            </div>

            <div>
                <div class="pa3">
                
                        <div class="flex justify-between" style="flex-direction: column; color: #132c8c; cursor: pointer">
                            <div class="flex items-start pb2 pv3">
                                <div class="pl2">
                                    <a style=" color: #132c8c;"  @click.prevent="() => toggleCreateEntity(true)" class="pb1 b hyperLink">Add a customer</a>
                                </div>
                            </div>
                        </div>
            
                    <div class="flex justify-between" style="flex-direction: column;  color: #132c8c; cursor: pointer">
                        <div class="flex items-start pb2 pv3">
                            <div class="pl2">
                                <p style=" color: #132c8c;" class="pb1 b hyperLink" @click.prevent="toggleCreateProduct({ show: true, cat: 'PRODUCT' })">Add a Product</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between" style="flex-direction: column;  color: #132c8c; cursor: pointer">
                        <div class="flex items-start pb2 pv3">
                            <div class="pl2">
                                <a style=" color: #132c8c;" href="/create-invoice" class="pb1 b hyperLink">Create an invoice</a>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between" style="flex-direction: column;  color: #132c8c; cursor: pointer">
                        <div class="flex items-start pb2 pv3">
                            <div class="pl2">
                                <a style=" color: #132c8c;" class="pb1 b hyperLink" @click="toggleAddTeamModal">Invite a team member</a>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between" style="flex-direction: column;  color: #132c8c; cursor: pointer">
                        <div class="flex items-start pb2 pv3">
                            <div class="pl2">
                                <a style=" color: #132c8c;" href="https://calendly.com/simplebks/30min" class="pb1 b hyperLink">Get Professional accounting help</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive } from 'vue'
import { useStore } from 'vuex'
import CreateProduct from '@/components/CreateProduct'
import InviteTeamMember from '@/components/InviteTeamMember'
import CreateEntity from '@/components/CreateEntity'
export default {
     name: 'DashboardThingsToDo',
    components: { CreateProduct, InviteTeamMember, CreateEntity},
    setup() {
        const store = useStore()

         const state = reactive({
            type: 'PRODUCT',
            isAddTeamMember: false,
        })

          const toggleCreateProduct = payload => {
            state.type = payload?.cat || 'PRODUCT'
            store.dispatch('Inventory/showCreateProduct', payload)
        }

         const state2 = reactive({
            isOpen: false,
            isOpenImportModal: false
        })

        const toggleAddTeamModal = () => {
            if (state2.isOpen) {
                state2.isOpen = false
            } else {
                state2.isOpen = true
            }
        }
         const toggleCreateEntity = payload => {
            store.dispatch('Entity/toggleCreateEntity', payload)
        }
       
        return{
            state,
            state2,
            toggleCreateProduct,
            toggleAddTeamModal,
            toggleCreateEntity
        }
        
    },
}
</script>
<style>
.hyperLink:hover{
    text-decoration: underline;
}
</style>