<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="main-barx">
                <div class="w-90 center">
                    <div class="pb2 f3 b">Sell Assets</div>
                </div>
                <div class="w-70-l center mt4 mini-spacing box-border justify-responsivex">
                    <form ref="formRef" @submit.prevent="onSubmit" style="background: #fbfcfe">
                        <div class="flex flex-wrap justify-between pa3 box-border" style="font-size: 18px">
                            <div class="font-w2 pb3 pb0-l" style="color: #132c8c">Transaction ID: SA0001</div>
                        </div>
                        <div class="flex flex-wrap items-center justify-between">
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2">Date <span class="makeAstRed">*</span></label>
                                <div
                                    v-tippy="{
                                        content: '',
                                        placement: 'top',
                                        interactive: true,
                                    }"
                                >
                                    <input
                                        id="date"
                                        class="product-input"
                                        type="date"
                                        v-model="state.date"
                                        style="width: 100%"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2">Description</label>
                                <div
                                    v-tippy="{
                                        content: '',
                                        placement: 'top',
                                        interactive: true,
                                    }"
                                >
                                    <input
                                        class="product-input"
                                        type="text"
                                        v-model="state.description"
                                        placeholder="describe the asset"
                                        id="description"
                                        style="width: 100%"
                                    />
                                </div>
                            </div>
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2">Type</label>
                                <div
                                    v-tippy="{
                                        content: '',
                                        placement: 'top',
                                        interactive: true,
                                    }"
                                >
                                    <input
                                        value="Money-In"
                                        class="product-input"
                                        type="text"
                                        name="type"
                                        id="type"
                                        style="width: 100%"
                                        disabled
                                    />
                                </div>
                            </div>

                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2 entity-name">Category</label>
                                <input
                                    value="Asset Sales"
                                    class="product-input"
                                    type="text"
                                    name="category"
                                    id="category"
                                    style="width: 100%"
                                    disabled
                                />
                            </div>

                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2">Amount<span class="makeAstRed"> *</span></label>
                                <div
                                    v-tippy="{
                                        content: '',
                                        placement: 'top',
                                        interactive: true,
                                    }"
                                >
                                    <input
                                        class="product-input"
                                        type="number"
                                        name="amount"
                                        placeholder="0"
                                        id="amount"
                                        style="width: 100%"
                                        v-model="state.amount"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2 entity-name">Account <span class="makeAstRed">*</span></label>
                                <select class="product-input" v-model="state.account" style="width: 100%" required>
                                    <option value="Cash">Cash</option>
                                    <option value="credit">Credit</option>
                                    <option
                                        v-for="(account, index) in bankAccounts"
                                        v-bind:key="index"
                                        :value="account?.aliasName || account.bankName"
                                    >
                                        {{ account?.aliasName || account.bankName }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-wrap items-center justify-between">
                            <div class="flex flex-column pa3 w-100">
                                <div v-if="state.account === 'credit'">
                                    <label class="pb2">Balance/Refund Due Date<span class="required">*</span></label>
                                    <div
                                        v-tippy="{
                                            content:
                                                'Select the date the customer has promised the pay the amount left as full payment for the product or service.',
                                            placement: 'top',
                                            interactive: true,
                                        }"
                                    >
                                        <input
                                            class="product-input"
                                            type="date"
                                            name="due_date"
                                            placeholder=""
                                            id="due_date"
                                            style="width: 100%"
                                            :required="state.account === 'credit'"
                                        />
                                    </div>
                                </div>

                                <div class="pv3">
                                    <div class="flex flex-column">
                                        <label class="pb2 entity-name">Buyer Name<span class="makeAstRed"> *</span></label>
                                        <div class="justify-between items-center w-100" style="padding: 0">
                                            <div
                                                class="flex flex-column"
                                                :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                            >
                                                <input
                                                    id="add-customer"
                                                    autocomplete="off"
                                                    class="product-input searchBoxx pl2x w-100"
                                                    type="text"
                                                    name="entity"
                                                    placeholder="Search for buyer name"
                                                    @input="(element) => handleEntitySearchOnChange(element.target.value)"
                                                    @focusout="
                                                        () => {
                                                            showDropDownOnSelectEntity(false)
                                                            handleIsRelative(false)
                                                        }
                                                    "
                                                    @focusin="
                                                        () => {
                                                            showDropDownOnSelectEntity(true)
                                                            handleIsRelative(true)
                                                        }
                                                    "
                                                    v-model="state.entity"
                                                    required
                                                />
                                                <template v-if="state.entityDropDown && state.entity?.length">
                                                    <div
                                                        style="
                                                            position: absolute;
                                                            left: 0;
                                                            top: 100%;
                                                            height: auto;
                                                            width: 100%;
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            box-shadow: 0px 1px 4px 0px #2b59ff;
                                                            z-index: 1000;
                                                        "
                                                        class="bg-white flex flex-column items-start"
                                                    >
                                                        <div
                                                            class="mt2"
                                                            style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                            v-if="EntitySearchedList.length"
                                                        >
                                                            <ul class="w-100" style="margin: 0; padding: 10px 0">
                                                                <li
                                                                    v-for="ent of EntitySearchedList"
                                                                    v-bind:key="ent._id"
                                                                    class="pointer hover-bg-black-10 pa2 db"
                                                                    style="width: 100%; margin: 0"
                                                                    @click.prevent="handleEntityClickFromSearch(ent)"
                                                                >
                                                                    <span v-if="ent?.lastName !== undefined">
                                                                        {{ `${ent?.firstName} ${ent?.lastName}` || ent?.company }}
                                                                    </span>

                                                                    <span v-else>
                                                                        {{ `${ent?.firstName}` || ent?.company }}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div v-else class="pa3">Not found</div>
                                                        <div
                                                            v-if="
                                                                role === 'owner' ||
                                                                role === 'admin' ||
                                                                rolePermissions?.includes(30)
                                                            "
                                                            class="flex items-center"
                                                            style="
                                                                width: 100%;
                                                                gap: 0.5rem;
                                                                overflow: hidden;
                                                                text-overflow: ellipsis;
                                                            "
                                                        >
                                                            <button
                                                                class="mr2x pa2"
                                                                style="
                                                                    width: 100%;
                                                                    overflow: hidden;
                                                                    white-space: nowrap;
                                                                    text-overflow: ellipsis;
                                                                "
                                                                @click.prevent="toggleCreateEntity(true)"
                                                            >
                                                                <font-awesome-icon icon="fa fa-plus-circle" /> Create a new entity
                                                            </button>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="enter-item" v-for="(asset, index) of state.assets" v-bind:key="'asset-' + index">
                            <div class="pa3 flex flex-wrap items-center" style="gap: 0.5rem">
                                <div>
                                    <div
                                        class="flex flex-column products-card1"
                                        :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                    >
                                        <label class="pb2">Item(s)<span class="required">*</span></label>
                                        <div
                                            v-tippy="{
                                                content: 'Input/select the asset',
                                                placement: 'top',
                                                interactive: true,
                                            }"
                                            style="width: 100%"
                                        >
                                            <input
                                                autofocus
                                                autocomplete="off"
                                                class="product-input"
                                                type="text"
                                                placeholder="Input Product/Service name"
                                                :id="asset.name"
                                                v-model="asset.name"
                                                @input="(element) => onSearchAsset(element.target.value, index)"
                                                @focusout="
                                                    () => {
                                                        showDropDownOnSelectAsset(index, false)
                                                        handleIsRelative(false)
                                                    }
                                                "
                                                @focusin="
                                                    () => {
                                                        showDropDownOnSelectAsset(index, true)
                                                        handleIsRelative(true)
                                                    }
                                                "
                                                required
                                                :max="parseFloat(asset.current_stock)"
                                            />
                                        </div>
                                        <template v-if="asset?.assetDropDown && asset.name?.length">
                                            <div
                                                style="
                                                    position: absolute;
                                                    left: 0;
                                                    top: 100%;
                                                    height: auto;
                                                    width: 100%;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    box-shadow: 0px 1px 4px 0px #2b59ff;
                                                    background: #132c8c;
                                                    z-index: 1;
                                                    color: #fff;
                                                "
                                                class="bg-white flex flex-column items-start"
                                            >
                                                <div
                                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(32)"
                                                    class="flex items-center"
                                                    style="width: 100%; gap: 0.5rem; overflow: hidden; text-overflow: ellipsis"
                                                >
                                                    <button
                                                        class="mr2"
                                                        @click.prevent="toggleCreateAsset(true, index, asset.name, 'Asset')"
                                                        :ref="
                                                            (el) => {
                                                                createAssetBtn = el
                                                            }
                                                        "
                                                    >
                                                        Create New Asset
                                                    </button>
                                                </div>

                                                <div
                                                    class="mt2"
                                                    style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                    v-if="state.assetEntrySearchList?.length"
                                                >
                                                    <ul class="w-100 pa1" v-if="state.assetEntrySearchList.length">
                                                        <li
                                                            v-for="(item, i) of state.assetEntrySearchList"
                                                            v-bind:key="item._id"
                                                            :ref="
                                                                (el) => {
                                                                    retrievedAsset[i] = el
                                                                }
                                                            "
                                                            class="pointer hover-bg-black-10 pa2 db"
                                                            style="width: 100%"
                                                            @click.prevent="selectAsset(item, index)"
                                                        >
                                                            <div>{{ item.name }}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                                <div class="flex flex-column products-card">
                                    <label class="pb2">Selling Price<span class="required">*</span></label>

                                    <div
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(37)"
                                        v-tippy="{
                                            content: 'Input the selling amount',
                                            placement: 'top',
                                            interactive: true,
                                        }"
                                    >
                                        <CurrencyInput
                                            placeholder="0.00"
                                            style="width: 100%"
                                            class="product-input"
                                            :options="numberFormat"
                                            v-model="asset.price"
                                            required
                                        />
                                    </div>
                                </div>

                                <div class="flex flex-column products-card">
                                    <label class="pb2">Quantity*<span>: 0 In-stock</span></label>

                                    <div class="addMeasurement" style="width: 100%">
                                        <CurrencyInput
                                            class="product-input"
                                            style="width: 100%"
                                            placeholder="0"
                                            :options="numberFormat"
                                            v-model="asset.quantity"
                                            required
                                        />
                                    </div>
                                </div>
                                <div class="pt4">
                                    <img :src="require('@/assets/images/delete.svg')" alt="Delete" />
                                </div>
                                <div style="width: 98%; display: flex; justify-content: flex-end; font-weight: bold">
                                    <p class="pt1">
                                        Total Amount:
                                        {{ formatAmount(asset.price * asset.quantity, $store.state.Settings?.currency) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tr b pa3">
                            <div class="pb3 box-border-bottom">
                                <a id="more-items" href="" @click.prevent="addMoreAssets" style="color: #132c8c">
                                    + Add assets
                                </a>
                            </div>
                        </div>

                        <div class="flex justify-between" style="border-top: 1px solid #e2e8ee">
                            <div class="pa3 flex">
                                <button type="reset" class="btn2">Cancel</button>
                                <button 
                                    id="create-transaction" 
                                    type="submit" 
                                    class="ml2"
                                    :disabled="state.isProcessing"
                                >{{state.isProcessing ? 'Processing...' : 'Submit'}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment-timezone'
import ShortUniqueId from 'short-unique-id'
import AppWrapper from '@/layout/AppWrapper'
import CurrencyInput from '@/components/CurrencyInput'
import { formatAmount, formatQuantity } from '@/utils/lib'
export default {
    name: 'SellAsset',
    components: { AppWrapper, CurrencyInput },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true,
            },
        }
    },

    setup() {
        let timeOut = null
        const retrievedAsset = ref([])
        const createAssetBtn = ref(null)
        const store = useStore()
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)
        const incomingEntityId = computed(() => store.state.Entity?.incomingEntityId)
        const EntitySearchedList = computed(() => store.state.Entity?.entityListFromSearch)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)

        const initialState = () => ({
            date: moment().format('YYYY-MM-DD'),
            description: '',
            type: 'asset_sale',
            // subtype: 'sales',
            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
            amount: 0,
            account: 'Cash',
            entity: '',
            entityId: '',
            entityName: '',
            entityDropDown: false,
            entityData: {
                id: '',
                name: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                vat_number: '',
                tax_number: '',
                website: '',
            },
            assets: [
                {
                    name: '',
                    price: 0,
                    current_stock: 0,
                    depreciation: 0,
                    quantity: 1,
                    salvageValue: 0,
                    lifespan: 0,
                    tag: '',
                    serialNo: '',
                    id: '',
                },
            ],
            assetEntrySearchList: [],
            isRelative: false,
            isProcessing: false,
        })

        const state = reactive(initialState())

        watch(state.assets, (newValue) => {
            state.amount = newValue?.reduce((acc, item) => {
                return acc + item.price * item.quantity
            }, 0)
        })

        const onChangeEntityId = () => {
            state.entityId = incomingEntityId.value
        }

        const handleEntitySearchOnChange = (query) => {
            state.entity = query

            clearTimeout(timeOut)

            if (query.trim().length) {
                timeOut = setTimeout(() => {
                    store.dispatch('Entity/getEntityEntryBySearch', { query })
                }, 300)
            }
        }

        const showDropDownOnSelectEntity = (dropDownState = false) => {
            if (!dropDownState) {
                return setTimeout(() => {
                    state.entityDropDown = dropDownState
                }, 300)
            }

            state.entityDropDown = dropDownState
        }

        const handleEntityClickFromSearch = (value) => {
            // console.log(value, 'value')

            if (value?.lastName || value?.lastName === undefined) {
                state.entity = `${value?.firstName} ${value?.lastName || ''}` || value?.company
            } else {
                state.entity = `${value?.firstName}` || value?.company
            }

            state.entityName = value._id

            // update invoice customer
            if (value._id) {
                state.entityData.id = value._id
                state.entityData.name = value?.lastName ? `${value.firstName} ${value?.lastName}` : value.firstName
                state.entityData.address = value.address1
                state.entityData.city = value.city
                state.entityData.state = value.state
                state.entityData.zip = value.zip
                state.entityData.country = value.country
                state.entityData.phone = value.phone
                state.entityData.email = value.email

                state.IsCustomerSelected = true
            }

            store.dispatch('Entity/setEntityIdFromExistingEntities', value._id).then((resp) => {
                if (resp) onChangeEntityId()
            })
            showDropDownOnSelectEntity(false)
        }

        const toggleCreateEntity = (payload) => {
            store.dispatch('Entity/toggleCreateEntity', payload)
            if (payload && state.entity) store.dispatch('Entity/setEntityName', state.entity)

            const body = document.querySelector('body')
            if (payload) {
                body.style.overflow = 'hidden'
            } else {
                body.style.overflow = 'auto'
            }
        }

        const onSearchAsset = (search) => {
            if (search.length) {
                store.dispatch('Asset/searchAsset', { query: search }).then((data) => {
                    state.assetEntrySearchList = data.assets
                })
            }
        }

        const selectAsset = (item, index) => {
            state.assets[index] = {
                name: item.name,
                price: item?.purchasePrice || 0,
                current_stock: item?.qty_in_stock || 0,
                depreciation: parseFloat(
                    (((item?.purchasePrice || 0) - (item?.salvageValue || 0)) / (item?.lifespan || 0)).toFixed(2)
                ),
                quantity: 1,
                salvageValue: item?.salvageValue || 0,
                lifespan: item?.lifespan || 0,
                tag: item?.tag || '',
                serialNo: item?.serialNo || '',
                id: item._id,
            }
        }

        const addMoreAssets = () => {
            if (state.assets.length == 0) {
                state.amount = 0
            }
            state.assets.push({
                name: '',
                price: 0,
                current_stock: 0,
                depreciation: 0,
                quantity: 1,
                salvageValue: 0,
                lifespan: 0,
                tag: '',
                serialNo: '',
                id: '',
            })
        }

        const removeAsset = (index) => {
            const { assets } = state
            const assetToDelete = assets.splice(index, 1)
            state.assets = assets.filter((element) => element !== assetToDelete[0])
        }

        const showDropDownOnSelectAsset = (index, dropDownState = false) => {
            setTimeout(() => {
                state.assets[index].assetDropDown = dropDownState
            }, 300)
        }

        const handleIsRelative = (value = false) => {
            setTimeout(() => {
                state.isRelative = value
            }, 300)
        }

        const toggleCreateAsset = (payload, index, name, data) => {
            store.dispatch('Asset/showCreateAssetProduct', { show: payload, cat: 'Asset' })
        }

        const onSubmit = () => {
            state.isProcessing = true
            const {
                entityDropDown,
                assetEntrySearchList,
                isRelative,
                isProcessing,
                IsCustomerSelected,
                ...data
            } = state
            store.dispatch('Asset/sellAsset', data).finally(() => (state.isProcessing = false))
        }

        onMounted(() => {
            store.dispatch('Settings/getBankAccounts')
        })

        return {
            state,
            bankAccounts,
            rolePermissions,
            role,
            EntitySearchedList,
            handleEntitySearchOnChange,
            showDropDownOnSelectEntity,
            handleEntityClickFromSearch,
            toggleCreateEntity,
            onSearchAsset,
            selectAsset,
            addMoreAssets,
            removeAsset,
            handleIsRelative,
            toggleCreateAsset,
            showDropDownOnSelectAsset,
            retrievedAsset,
            createAssetBtn,
            formatAmount,
            formatQuantity,
            onSubmit,
        }
    },
}
</script>
<style scoped>
.makeAstRed {
    color: red;
}
</style>
