<template>
    <app-wrapper>
        <template v-slot:child>
            <CreateProduct :closeModal="() => toggleCreateProduct({ show: true, cat: 'PRODUCT' })" />
            <CreateAsset :closeModal="() => toggleCreateAsset()" :assetCategory="'ASSET'" />
            <!-- Main Box -->
            <div class="flex justify-between">
                <!-- Right box -->
                <div class="">
                    <div class="pa4-l pa3">
                        <div>
                            <h2 class="" style="color: #132c8c; font-size: 18px">Asset Management</h2>
                            <p class="w-60-l lh-copy black">
                                Handle and oversee your valuable assets efficiently. These tools assist you in managing your
                                assets effectively, whether you're acquiring, tracking, or selling them.
                            </p>
                        </div>

                        <div class="mt4 black" style="font-weight: 500">
                            <span class="bg-washed-yellow ph2">Tip: Click on card to perform specify actions </span>
                        </div>

                        <div class="mt4">
                            <div class="flex flex-wrap" style="gap: 15px">
                                <router-link
                                    :to="{ name: 'AssetManagementView' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-customers.svg" style="width: 60px" alt="" />
                                    <h3>View</h3>
                                    <p class="lh-copy">Review and examine your existing assets.</p>
                                </router-link>
                                <div
                                    @click.prevent="toggleCreateAsset(true, 'PRODUCT')"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-debtors.svg" style="width: 60px" alt="" />
                                    <h3>Create</h3>
                                    <p class="lh-copy">Add new assets to your inventory.</p>
                                </div>
                                <router-link
                                    :to="{ name: 'BuyAsset' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-debtors.svg" style="width: 60px" alt="" />
                                    <h3>Buy</h3>
                                    <p class="lh-copy">Record the acquisition of new assets.</p>
                                </router-link>
                                <router-link
                                    :to="{ name: 'SellAsset' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-debtors.svg" style="width: 60px" alt="" />
                                    <h3>Sell</h3>
                                    <p class="lh-copy">Document the sale or disposal of assets.</p>
                                </router-link>
                                <router-link
                                    :to="{ name: 'AssetTransactions' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-debtors.svg" style="width: 60px" alt="" />
                                    <h3>Transaction</h3>
                                    <p class="lh-copy">Record asset purchase in transactions</p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { useStore } from 'vuex'
import AppWrapper from '../../layout/AppWrapper.vue'
import CreateProduct from '@/components/CreateProduct.vue'
import CreateAsset from '@/components/CreateAssetNew.vue'

export default {
    name: 'AssetManagement',
    components: { AppWrapper, CreateAsset, CreateProduct },

    setup() {
        const store = useStore()
        const toggleCreateProduct = (status, payload) => {
            store.dispatch('Inventory/showCreateProduct', {show: status, cat: 'PRODUCT'})
        }

        const toggleCreateAsset = (payload, index, data) => {
            store.dispatch('Asset/showCreateAssetModal', { show: payload, cat: 'Asset' })
        }
        return {
            toggleCreateProduct,
            toggleCreateAsset,
        }
    },
}
</script>
