<template>
    <div class="flex items-center justify-center" style="background-image: url(); background-size: cover; height: 100vh">
        <Alert />
        <div class="w-50-l w-90">
            <!-- Simplebks logo -->
            <div class="tc pb4">
                <img src="../../assets/images/simplebks-logo.png" style="width: 180px" alt="simplebks-logo" />
            </div>
            <!-- Simplebks logo -->

            <!-- login/Input -->
            <div style="background-color: white">
                <form class="center pa5-l pa3" id="form" @submit.prevent="signIn">
                    <h2 class="tc">Welcome back</h2>
                    <div class="flex flex-column pb3">
                        <label class="pb2">Email</label>
                        <input
                            v-model="state.email"
                            class="form-sign"
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            id="email"
                            required
                        />
                    </div>
                    <div class="flex flex-column pb3">
                        <label class="pb2">Password</label>
                        <div class="input-group">
                            <input
                                v-model="state.password"
                                class="form-sign w-100"
                                :type="passwordFieldType"
                                name="password"
                                placeholder="Enter password"
                                id="password"
                                required
                            />
                            <span class="input-group-label">
                                <font-awesome-icon :icon="passwordFieldIcon" @click="hidePassword = !hidePassword" class="fa-2x" />
                            </span>
                        </div>
                    </div>
                    <div class="flex justify-between">
                        <label>
                            <input type="checkbox" checked="checked" />Remember me
                            <span class="checkmark"></span>
                        </label>

                        <div style="font-size: 14px">
                            <router-link :to="{ name: 'RecoverPassword' }">Forgot password?</router-link>
                        </div>
                    </div>

                    <!--          <div v-if="message" class="flex justify-center items-center w-100">-->
                    <!--            <span style="color: red; font-weight: 700">-->
                    <!--              {{ message }}-->
                    <!--            </span>-->
                    <!--          </div>-->

                    <button class="mt3 w-100 submit-btn" type="submit" :disabled="state.disableSubmitButton">
                        {{ state.disableSubmitButton ? 'Processing...' : 'Login' }}
                    </button>

                    <div class="flex items-center justify-center mt4">
                        <google-button text="Login with Google" class="w-100 btn2 pa2" />
                    </div>
                    
                    <div class="tc mt3">
                        Don't have account? <router-link :to="{ name: 'SignUp' }"><b>Sign up</b></router-link>
                    </div>
                </form>
                <div class="flex items-center justify-center">
                    <span>Follow us</span>
                    <span class="pa3"><a href="https://m.facebook.com/simplebks" target="_blank"><img src="../../assets/images/facebook3.svg"/></a></span>
                    <span class="pa3"><a href="https://twitter.com/simplebksinc" target="_blank"><img src="../../assets/images/twitter3.svg"/></a></span>
                    <span class="pa3"><a href="https://www.instagram.com/simplebks_inc/?igshid=YmMyMTA2M2Y%3D" target="_blank"><img src="../../assets/images/instagram3.svg"/></a></span>
                    <span class="pa3"><a href="https://www.linkedin.com/company/simplebks/" target="_blank"><img src="../../assets/images/linkedin3.svg"/></a></span>
                </div>
            </div>
            <!-- login/Input -->
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'
import Alert from '@/components/Alert'
import GoogleButton from '@/components/GoogleButton.vue'

export default {
    name: 'Login',
    components: { Alert, GoogleButton },

    setup() {
        const store = useStore()
        const message = computed(() => store?.state?.Auth?.message)

        const hidePassword = ref(true);

        const passwordFieldIcon = computed(() => hidePassword.value ? "fa-solid fa-eye-slash" : "fa-solid fa-eye");
        const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

        const state = reactive({
            disableSubmitButton: false,
            email: '',
            password: ''
        })

        const signIn = () => {
            state.disableSubmitButton = true

            const { email, disableSubmitButton, ...rest } = state
            const formData = { email: email.toLocaleLowerCase(), ...rest }
            store.dispatch('Auth/signIn', formData).then((resp) => {
                if (!resp) state.disableSubmitButton = false
            })
        }

        return {
            state,
            signIn,
            message,
            passwordFieldIcon,
            passwordFieldType,
            hidePassword
        }
    }
}
</script>

<style>
.input-group {
    position: relative;
}
.input-group-label {
    position: absolute;
    top: 16px;
    right: 15px;
    cursor: pointer;
}
.fa-2x {
    font-size: 1.3em;
}
</style>
