<template>
    <app-wrapper>
        <template v-slot:child>
            <div>
                <div class="pa4-l pa3">
                    <div>
                        <h2 class="" style="color: #132c8c; font-size: 18px">Production</h2>
                        <p class="w-60-l lh-copy black">
                            Manage the creation and assembly of your products from Raw materials to Finished goods. Under
                            "Production," you'll find the following options:
                        </p>
                    </div>
                    <div class="mt4 black" style="font-weight: 500">
                        <span class="bg-washed-yellow ph2">Tip: Click on card to perform specify actions </span>
                    </div>
                    <div class="mt4">
                        <div class="flex flex-wrap" style="gap: 15px">
                            <!-- <router-link
                                :to="{ name: 'CreateFinishedProduct' }"
                                class="pa3 menu-card"
                                style="border: 2px solid #f5f5f7; border-radius: 20px"
                            >
                                <img src="@/assets/images/create-finish.svg" style="width: 60px; height: 60px" alt="" />
                                <h3>Create Finished Products</h3>
                                <p class="lh-copy">
                                    Create new finished goods or products with default raw materials and quantity required for
                                    production to your inventory.
                                </p>
                            </router-link> -->
                            <router-link
                                :to="{ name: 'Produce' }"
                                class="pa3 menu-card"
                                style="border: 2px solid #f5f5f7; border-radius: 20px"
                            >
                                <img src="@/assets/images/production-batch.svg" style="width: 60px; height: 60px" alt="" />
                                <h3>Create Production Batch</h3>
                                <p class="lh-copy">
                                    Record the production process for batches of finished goods noting the raw materials used and
                                    production expenses.
                                </p>
                            </router-link>
                            <router-link
                                v-if="state.wipStatus && $route.name !== 'CreateWorkInProgress'"
                                :to="{ name: 'CreateWorkInProgress' }"
                                class="pa3 menu-card"
                                style="border: 2px solid #f5f5f7; border-radius: 20px"
                            >
                                <img src="@/assets/images/wip.svg" style="width: 60px; height: 60px" alt="" />
                                <h3>Move Raw Materials to WIP</h3>
                                <p class="lh-copy">
                                    Transfer products from the raw materials stage to "Work In Progress," indicating they are not
                                    yet finished but in the process of being made.
                                </p>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '../../layout/AppWrapper.vue'
export default {
    name: 'ProductionNew',
    components: { AppWrapper },
    setup() {

        const store = useStore()
        const initialState = () => ({
            wipStatus: false,
        })
        const state = reactive(initialState())

        onMounted(() => {
            store.dispatch('Settings/getPlan')
            // store.dispatch('Reporting/getReportingProfits')
            store.dispatch('Settings/getAccountSettings').then((data) => {
                if (data.status) {
                    state.wipStatus = data?.data?.wipStatus || false
                }
            })
        })
        return {
            state,
        }
    },
}
</script>
