<!-- eslint-disable max-len -->
<template>
    <div
        tabindex="0"
        @keydown.esc="
            () => {
                closeAllModals()
            }
        "
    >
        <!-- welcome message -->
        <!-- if notification is not short "mini-spacing" here -->
        <div class="w-90 center tc pv5-l pv3 mini-spacing">
            <h1>Welcome, {{ fullName?.split(' ')?.[0] }}</h1>
            <div class="lh-copy" style="font-size: 18px">What would you like to do with Simplebks today?</div>
        </div>
        <!-- welcome message -->

        <!-- read user guide -->
        <div class="w-90 center pt2 mb2">
            <div class="box-border">
                <div class="pa3 box-border-bottom">Transactions</div>
                <div class="flex flex-wrap justify-between items-center">
                    <div class="flex items-center pa3">
                        <div>
                            <img src="../assets/images/tour.svg" alt="" />
                        </div>

                        <div class="lh-copy pl3">
                            <div>
                                <p class="b">Record Daily Transactions</p>
                                <p>
                                    Getting insights out of your business activities with simplebks starts here
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="w-20-l w-60 pa3">
                        <router-link
                            v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(23)"
                            :to="{ name: 'AddTransactionNew' }"
                            class="btn2"
                        > Transact</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- take a tour -->
        <div class="w-90 center pt2 mb2">
            <div class="box-border">
                <div class="pa3 box-border-bottom">Inventory</div>
                <div class="flex flex-wrap justify-between items-center">
                    <div class="flex items-center pa3">
                        <div>
                            <img src="../assets/images/tour.svg" alt="" />
                        </div>

                        <div class="lh-copy pl3">
                            <div>
                                <p class="b">Know your Business</p>
                                <p>
                                    Your business is as good as your inventory, manage it best with Simplebks.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="w-20-l w-60 pa3">
                        <router-link
                            v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(50)"
                            :to="{ name: 'ProductsSummary' }"
                            class="btn2"
                        >
                            Manage
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable max-len -->

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '@/layout/AppWrapper'

export default {
    name: 'GuestDashboard',
    components: { AppWrapper },

    setup() {
        const store = useStore()
        const userData = computed(() => store?.state?.Auth?.userData)
        const fullName = computed(() => store.state?.Auth?.fullName)
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)

        const closeAllModals = () => {
            state2.isOpenImportModal = false
            state2.isOpen = false
        }

        return {
            fullName,
            userData,
            closeAllModals,
            role,
            rolePermissions
        }
    }
}
</script>

<style scoped>
a.btn2 {
    padding: 13px 20px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
}
.g-start.active {
    background-color: #fff;
}
</style>
