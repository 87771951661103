<template>
    <div v-if="showEntityBulkUploadModal">
        <div class="jquery-modal blocker current" @click="closeModalOnOutsideClick" ref="importRef">
            <div id="modal-bulk" class="modal-target2">
                <!-- <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>Customer Bulk Update</h3>
                        <div class="lh-copy">
                            Download bulk upload
                            <a href="" @click.prevent="downloadEntityTemplate" class="b text-info">template</a>, update and upload
                            the CSV file.
                        </div>
                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input
                                type="file"
                                name="imageFile"
                                id="file"
                                class="product-input"
                                @change="onFileChange"
                                accept="text/csv"
                                required
                            />
                            <button :disabled="state.buttonDisabled">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Upload' }}
                            </button>
                        </form>
                    </div>
                </div> -->

                <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>Customer Bulk Update</h3>
                        <div class="whiteBgModal">
                            <div>Use this to update and add customers at the same time by following the steps below</div>
                            <ol>
                                <li class="lh-copy">
                                    Download the
                                    <a href="" @click.prevent="downloadEntityTemplate" class="b text-info">template</a>
                                </li>
                                <li>
                                    Add the details of the customer you want to save on simplebks, customer description is
                                    supplier, buyer, debtor, creditor or investor
                                </li>
                                <li>Save the file in a CSV format</li>
                                <li>Click choose file and select the file you just edited in CSV format</li>
                                <li>Select Upload to add your customers</li>
                            </ol>
                            <p>Your transaction records will be updated.</p>
                            <br />
                        </div>
                         <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input
                                type="file"
                                name="imageFile"
                                id="file"
                                class="product-input"
                                @change="onFileChange"
                                accept="text/csv"
                                required
                            />
                            <button :disabled="state.buttonDisabled">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Upload' }}
                            </button>
                        </form>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'EntityBulkUploadModal',
    props: {
        closeModal: Function,
    },

    setup(props, { emit }) {
        const store = useStore()

        const showEntityBulkUploadModal = computed(() => store.state?.Entity?.showEntityBulkUploadModal)

        const state = reactive({
            buttonDisabled: false,
            imageFile: null,
        })

        const importRef = ref(null)

        const onFileChange = (e) => {
            state.imageFile = e.target.files?.[0]
        }

        const closeModalOnOutsideClick = (e) => {
            if (e.target === importRef.value) {
                props.closeModal()
            }
        }

        const downloadEntityTemplate = () => {
            store.dispatch('Entity/downloadEntityTemplate').then((response) => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `entity-bulk-upload-template.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                // state.downloading = false
            })
        }

        const onSubmit = () => {
            state.buttonDisabled = true
            const formData = new FormData()
            formData.append('imageFile', state.imageFile)
            store.dispatch('Entity/importEntityBulkUpload', formData).then((resp) => {
                if (resp) {
                    props.closeModal()
                    state.imageFile = null
                    state.buttonDisabled = false
                }
            })
        }

        const updateIncomingState = (incomingState, key) => {
            state[incomingState] = state[key]
        }

        return {
            state,
            emit,
            updateIncomingState,
            importRef,
            closeModalOnOutsideClick,
            downloadEntityTemplate,
            onFileChange,
            showEntityBulkUploadModal,
            onSubmit,
        }
    },
}
</script>

<style scoped></style>