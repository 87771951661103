<template>
    <!--  <div class="flex relative" >-->
    <div>
        <NewBanner class="stickyNew" />
        <div class="relative" :class="!isAuth ? 'dn' : 'flex'">
            <SideBar />
            <Alert />
            <div class="right-dashboard mb6" style="margin-left: auto">
                <!-- search, alert, and profile -->
                <div class="mobile-line">
                    <div class="flex justify-between center" style="padding: 10px 20px; background-color: #F2F6FC;">
                        <MobileMenu />

                        <div class="w-40">
                            <!-- To be removed when search container below is active-->
                            <!-- <algolia-global-search
                                v-if="userData?.orgId && $route.name !== 'PaymentProcess'"
                                placeholder="Search products, transactions, entities, ..."
                                :org="userData.orgId"
                            /> -->
                            <!-- userData.orgId 62b9a003ee94d456bbbedc4a -->
                        </div>
                        <div class="searchContainerx"></div>

                        <div class="flex flex-start  items-center dropdown-togglex" >
                            <div class="customerInitials">
                                <p v-if="userData?.companyImage === null">{{ userData.org[0] }}</p>
                                <img v-else :src="userData?.companyImage" style="border-radius: 50%; height: 32px; width: 32px;"/>
                            </div>
                            <!-- eslint-disable-next-line -->
                            <div class="profile-dropdown relative" @focusout="handleShowMenu()" >
                                <a class="db flex" style="gap: 8px" href="#" @click.prevent="handleShowMenu(true)">
                                    <div style="font-size: 15px; font-weight: 600;cursor: pointer; margin-right: 0px">
                                        {{ userData?.org || '' }} 
                                    </div>
                                    <img
                                        style="width: 15px; height: 20px"
                                        :src="require('@/assets/images/chevron-black.svg')"
                                        alt=""
                                    />
                                </a>
                                <div
                                    class="absolute"
                                    style="
                                        width: 230px;
                                        background: white;
                                        box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.08);
                                        border-radius: 8px;
                                        left: auto;
                                        right: 2px;
                                        padding: 16px
                                    "
                                    v-if="state.showMenu"
                                >
                                    <router-link
                                        :to="{ name: 'BusinessProfile' }"
                                        class="flex items-center pv3 ph3 overflow-hidden menu-hover"
                                    >
                                        <img :src="require('@/assets/images/profile2.svg')" style="width: 16px;" alt="" />
                                        <div class="font-w1 pl3">Profile</div>
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'AccountSettings' }"
                                        v-if="role === 'admin' || role === 'owner'"
                                        class="flex items-center pv3 ph3 overflow-hidden menu-hover"
                                    >
                                        <img :src="require('@/assets/images/settings-cog.svg')" style="width: 16px;" alt="" />
                                        <div class="font-w1 pl3">Settings</div>
                                    </router-link>
                                    <div class="box-border-top box-border-bottom">
                                        <div v-if="state.businesses.length">
                                            <div class="b font-w1 pv3 ph3">Switch Business</div>
                                            <router-link to="#"  v-for="business in state.businesses" :key="business._id"
                                                class="flex justify-start items-center pv3 ph3 overflow-hidden menu-hover" 
                                                @click="switchBusiness(business.org)"
                                            >
                                                <img :src="'https://ui-avatars.com/api/?name=' + business.name + '&size=16&rounded=true'" alt="bi" />
                                                <div class="pl3"> {{ business.name }} </div>
                                            </router-link>
                                        </div>
                                        <router-link v-if="!userData.isSubBusiness && (role === 'owner' || role === 'admin')" to="#"
                                            class="flex justify-start items-center pv3 ph3 ml1 overflow-hidden menu-hover" 
                                            @click.prevent="addBusiness()"
                                        >
                                            <font-awesome-icon 
                                                icon="fa fa-plus" 
                                                style="font-size:16px;" 
                                            />
                                            <span class="pl3"> Add Business </span>
                                        </router-link>
                                    </div>
                                    <router-link
                                        to="#"
                                        @click.prevent="signOutUser"
                                        class="flex items-center pv3 ph3 overflow-hidden menu-hover"
                                    >
                                        <img :src="require('@/assets/images/logout.svg')" style="width: 16px" alt="" />
                                        <div class="font-w1 pl3">Log Out</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="mini-spacing"></div> -->
                <div style="padding-top: 15px"></div>
                <!-- search, alert, and profile -->
                <!--Body Main Content-->
                <!-- <div v-if="$route.name !== 'VerifyEmail' && userData?.emailVerifiedAt === null" class="alert alert-warning w-90 center">
                    You are yet to verify your account, some features will not be available. Please check your mail. 
                    <strong><a href="#" @click.prevent="resendVerifyEmailLink">Resend link</a></strong>.
                </div> -->
                <div
                    v-if="
                        $route.name !== 'BusinessProfile' &&
                            (userData?.typeOfBusiness === null ||
                                userData?.bankAccountName === null ||
                                userData?.bankAccountNumber === null ||
                                userData?.bankName === null)
                    "
                >
                    <div class="alert alert-warning w-90 center">
                        You are seeing this because some vital <strong>Business Details</strong>
                        in your organization is/are missing. Click
                        <router-link :to="{ name: 'BusinessProfile' }">here</router-link>
                        to update.
                    </div>
                </div>

                <div v-if="userData?.isGracePeriod" class="alert alert-danger w-90 center">
                    You plan has expired and you will be downgraded to our free plan on <strong>{{ formatDate(userData.gracePeriodDate, 'ddd, Do MMM, YYYY') }}</strong>. <span v-if="!userData.isSubBusiness">Click
                    <router-link :to="{ name: 'Plan' }"><strong>here to renew</strong></router-link>.</span>
                </div>

                <!-- <webinar-banner /> -->
                <!-- <div v-if="!state.bannerClosed && state.showInRoutes.includes($route.name)" class="w-90 center">
                    <a href="https://bit.ly/simplebkswebinarjune" target="_blank">
                        <img :src="require('@/assets/images/webinar-banner.png')" alt="webinar-banner" style="width:100%;" />
                    </a>
                    <div class="close-webinar-banner" v-tippy="'Hide'">
                        <font-awesome-icon
                            icon="fa-solid fa-remove"
                            size="lg"
                            class="text-muted pointer"
                            @click="closeWebinarBanner"
                        />
                    </div>
                </div> -->
                <slot name="child"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import SideBar from '@/components/AppSideBar'
import MobileMenu from '@/components/MobileMenu'
import NewBanner from '@/components/NewBanner'
import Alert from '@/components/Alert'
import { formatDate } from '@/utils/lib'
// import AlgoliaGlobalSearch from '@/components/AlgoliaGlobalSearch'
import Engage from '@engage_so/js'

const bgBtn = require('@/assets/images/bg-btn.png')

export default {
    name: 'AppWrapper',
    components: { SideBar, MobileMenu, Alert, NewBanner /*, AlgoliaGlobalSearch*/ },

    setup() {
        const store = useStore()
        const router = useRouter()

        const isAuth = store?.state?.Auth?.isAuthenticated
        const role = computed(() => store?.state?.Auth?.role)
        const userData = computed(() => store?.state?.Auth?.userData)
        const myPlan = computed(() => store?.state?.Settings?.paymentPlan)
        const businesses = computed(() => store?.state?.Settings?.businesses)

        const openEngage = () => {
            Engage.open()
        }

        const state = reactive({
            showMenu: false,
            bannerClosed: false,
            showInRoutes: ['Dashboard', 'GetStarted', 'Transactions', 'InvoiceSummary'],
            businesses: []
        })

        const handleShowMenu = (payload = false) => {
            setTimeout(() => {
                state.showMenu = payload
            }, 300)
        }

        const closeWebinarBanner = () => {
            localStorage.setItem('bannerClosed', true)
            state.bannerClosed = true
        }

        const switchBusiness = orgId => {
            store.dispatch('Settings/switchBusiness', orgId)
        }

        const addBusiness = async () => {
            if (myPlan.value?.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `Multi-Business feature is only available for Paid plans`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                });
                return;
            }

            if (state.businesses.length >= (myPlan.value?.businesses || 1)) {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `You have exhausted your business limit, please upgrade.`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                });
                return;
            }

            await router.replace({ name: 'AddBusiness' })
        }

        onMounted(() => {
            store.dispatch('Settings/getAccountSettings')
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getBusinesses').then((resp) => {
                state.businesses = resp.data
            })
            if (localStorage.getItem('bannerClosed') !== null) {
                state.bannerClosed = Boolean(localStorage.getItem('bannerClosed'))
            }
        })

        const signOutUser = () => store.dispatch('Auth/signOut')
        const resendVerifyEmailLink = () => store.dispatch('Auth/resendVerifyEmailLink')

        return {
            role,
            isAuth,
            userData,
            bgBtn,
            signOutUser,
            state,
            handleShowMenu,
            resendVerifyEmailLink,
            openEngage,
            closeWebinarBanner,
            businesses,
            myPlan,
            addBusiness,
            switchBusiness,
            formatDate,
        }
    }
    // methods: {
    //     openEngage() {
    //         this.Engage.open()
    //     }
    // }
}
</script>

<style scoped>
.menu-hover:hover {
    background-color: rgb(233, 237, 252);
    border-radius: 8px;
    /* transform: scale(1.1); */
    transition: all 0.3s ease-in-out;
}
.stickyNew {
    position: sticky;
    top: 0;
    z-index: 53;
}
.mobile-line{
    position: sticky;
    top: 60px;
    z-index: 52;
}
.customerInitials {
    width: 32px;
    height: 32px;
    /* border: 4px solid red; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-items: center;
    background-color: #000000;
    margin-right: 5px;
}
.customerInitials p {
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
}
</style>
