import { Axios } from '@/utils/axiosConfig'
import { paginationOptions } from '@/utils/lib'
import store from '@/store'
import Router from '@/router'

const state = {
    stats: {},
    receivablesGraph: {},
    payablesGraph: {},
    receivables: null,
    payables: null,
    showDnCRecordPaymentModal: false,
    dnCType: 'debt_repayment',
    selectedEntity: null,
}
const mutations = {
    SET_STATS(state, payload) {
        state.stats = payload
    },

    SET_RECEIVABLES(state, payload) {
        state.receivables = payload
    },

    SET_PAYABLES(state, payload) {
        state.payables = payload
    },
    SET_PAYABLES_GRAPH(state, payload) {
        state.payablesGraph = payload
    },
    SET_RECEIVABLES_GRAPH(state, payload) {
        state.receivablesGraph = payload
    },

    SET_RECORD_DNC_MODAL(state, payload) {
        state.showDnCRecordPaymentModal = payload.status
        state.dnCType = payload.subtype
        state.selectedEntity = payload.entity
    },
}
const actions = {
    getStats: async ({ commit }, query) => {
        try {
            const { data } = await Axios.get(`/api/v1/payables-and-receivables/stats?business=${query.business}`)
            // console.log(data)
            commit('SET_STATS', data)
        } catch (err) {
            console.error(err.response.data)
        }
    },

    getReceivables: async ({ commit }, payload) => {
        try {
            const { data } = await Axios.get(`/api/v1/receivables?${paginationOptions(payload)}`)
            // console.log(data)
            commit('SET_RECEIVABLES', data)
        } catch (err) {
            const { data } = err.response
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

            if (err.response.status === 403) {
                await Router.replace({ name: 'Dashboard' })
            }

            console.error(data)
        }
    },

    getPayables: async ({ commit }, payload) => {
        try {
            const { data } = await Axios.get(`/api/v1/payables?${paginationOptions(payload)}`)

            // console.log(data)
            commit('SET_PAYABLES', data)
        } catch (err) {
            const { data } = err.response
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

            if (err.response.status === 403) {
                await Router.replace({ name: 'Dashboard' })
            }
            console.error(err.response.data)
        }
    },
    getPayablesGraphData: async ({ commit }, payload) => {
        try {
            const { data } = await Axios.post(`/api/v1/pnr/graphdata`, payload)
            commit('SET_PAYABLES_GRAPH', data)
        } catch (err) {
            const { data } = err.response
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            console.error(err.response.data)
        }
    },
    getReceivableGraphData: async ({ commit }, payload) => {
        try {
            const { data } = await Axios.post(`/api/v1/pnr/graphdata`, payload)
            commit('SET_RECEIVABLES_GRAPH', data)
        } catch (err) {
            const { data } = err.response
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.data })

            // if (err.response.status === 403) {
            //     await Router.replace({ name: 'Dashboard' })
            // }
            console.error(err.response.data)
        }
    },

    toggleDnCRecordPaymentModal: async ({ commit }, payload) => {
        commit('SET_RECORD_DNC_MODAL', payload)
    },

    recordPayment: async ({ commit, dispatch }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/pnr/record-payment`, payload)
            dispatch('getInvoiceSummary')
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            return data
        } catch (error) {
            console.error(error)
            commit('SET_LOADING_STATE', false)
            const { data } = error.response
            console.error((data && data?.data) || error.message)
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            return data
        }
    },
}

export const PayablesAndReceivablesModule = { namespaced: true, state, mutations, actions }
