import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard/Dashboard'
import SalesAndInvoicing from '../views/SalesAndInvoicing/SalesAndInvoicing'
import Expenses from '../views/Expenses/Expenses'
import Contacts from '../views/Contacts/Contacts'
import ProductsAndServices from '../views/ProductsAndServices/ProductsAndServices'
import AssetManagement from '../views/AssetManagement/AssetManagement'
import AssetManagementView from '../views/AssetManagement/AssetManagementView'
import SignIn from '@/views/Auth/SignIn'
import SignUp from '@/views/Auth/SignUp'
import SignUpPromo from '@/views/Auth/SignUpPromo'
import RecoverPassword from '@/views/Auth/RecoverPassword'
import ResetPassword from '@/views/Auth/ResetPassword'
import Terms from '@/views/TermsAndConditions/Terms'
import PrivacyPolicy from '@/views/TermsAndConditions/PrivacyPolicy'
import checkPlan from './middleware/checkPlan'
import BuyAsset from '@/views/AssetManagement/BuyAsset'
import AssetTransactions from '@/views/AssetManagement/AssetTransactions'
import EditAsset from '@/views/AssetManagement/EditAsset'
import SellAsset from '@/views/AssetManagement/SellAsset'
import AssetDetails from '@/views/AssetManagement/AssetDetails'
import Accounting from '@/views/Accounting/Accounting'
import ProductionNew from '@/views/Production/ProductionNew'
import PaymentPlans from '@/views/Settings/PaymentPlans'
// import Error404 from '@/views/Errors/Error404'
import store from '@/store'
// import Loader from '@/components/Spinner2'

// check if token exist in localstorage (though not fully save)
export const isLoggedIn = () => {
  return localStorage.getItem('token')
}

const routes = [
  // {
  //   path: '/loader',
  //   name: 'Loader',
  //   component: Loader,
  //   meta: { title: 'Loader', requiresAuth: true },
  // },

  // Bulk update routes

  {
    path: '/bulk-update',
    name: 'BulkUpdate',
    component: () => import('@/views/BulkUpdate/BulkUpdate'),
    meta: { title: 'Bulk Update', requiresAuth: true }
  },
  {
    path: '/bulk-update/product-services',
    name: 'BulkUpdateProductService',
    component: () => import('@/views/BulkUpdate/BulkUpdateProductsServices/BulkProductsServices'),
    meta: { title: 'Bulk Update', requiresAuth: true }
  },
  {
    path: '/bulk-update/product-services/view',
    name: 'ViewBulkUpdateProductService',
    component: () => import('@/views/BulkUpdate/BulkUpdateProductsServices/ViewBulkUpdateProductsServices'),
    meta: { title: 'Bulk Update', requiresAuth: true }
  },

  {
    path: '/bulk-update/sep',
    name: 'BulkUpdateSEP',
    component: () => import('@/views/BulkUpdate/BulkUpdateSEP/BulkUpdateSEP'),
    meta: { title: 'Bulk Update', requiresAuth: true }
  },
  {
    path: '/bulk-update/sep/view',
    name: 'ViewBulkUpdateSEP',
    component: () => import('@/views/BulkUpdate/BulkUpdateSEP/ViewBulkUpdate'),
    meta: { title: 'Bulk Update', requiresAuth: true }
  },
  {
    path: '/bulk-update/contacts',
    name: 'BulkUpdateContacts',
    component: () => import('@/views/BulkUpdate/BulkUpdateContacts/BulkUpdateContacts'),
    meta: { title: 'Bulk Update', requiresAuth: true }
  },
  // Price adjustment routes
  {
    path: '/price-adjust',
    name: 'PriceAdjustment',
    component: () => import('@/views/PriceAdjustment/priceAdjustment'),
    meta: { title: 'Price Adjustment Preview', requiresAuth: true }
  },
  {
    path: '/price-adjust/preview',
    name: 'PricePreview',
    component: () => import('@/views/PriceAdjustment/pricePreview'),
    meta: { title: 'Price Adjustment Preview', requiresAuth: true }
  },
  // new budgeting pages
  {
    path: '/budget/line-item',
    name: 'LineBudget',
    component: () => import('@/views/Budget/LineBudget'),
    meta: { title: 'Line Item Budget', requiresAuth: true }
  },
  {
    path: '/budget/line-item/view',
    name: 'LineView',
    component: () => import('@/views/Budget/LineView'),
    meta: { title: 'Line Item View', requiresAuth: true }
  },
  {
    path: '/budget/profit-loss',
    name: 'PnLBudget',
    component: () => import('@/views/Budget/PnLBudget'),
    meta: { title: 'PnL Budget', requiresAuth: true }
  },
  {
    path: '/budget/profit-loss/view',
    name: 'PnLView',
    component: () => import('@/views/Budget/PnLView'),
    meta: { title: 'PnL View', requiresAuth: true }
  },
  {
    path: '/budget/profit-loss/create',
    name: 'CreatePnLBudget',
    component: () => import('@/views/Budget/CreatePnLBudget'),
    meta: { title: 'PnL Update', requiresAuth: true }
  },

  // new budgeting pages end
  // {
  //     path: '/get-started',
  //     name: 'GetStarted',
  //     component: () => import('@/views/GetStarted/GetStarted'),
  //     meta: { title: 'GetStarted', requiresAuth: true }
  // },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
      middleware: [checkPlan]
    }
  },
  {
    path: '/sales-invoicing',
    name: 'SalesAndInvoicing',
    component: SalesAndInvoicing,
    meta: {
      title: 'SalesAndInvoicing',
      requiresAuth: true,
      middleware: [checkPlan]
    }
  },
  {
    path: '/expenses',
    name: 'Expenses',
    component: Expenses,
    meta: {
      title: 'Expenses',
      requiresAuth: true,
      middleware: [checkPlan]
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      title: 'Contacts',
      requiresAuth: true,
      middleware: [checkPlan]
    }
  },
  {
    path: '/products-services',
    name: 'ProductsAndServices',
    component: ProductsAndServices,
    meta: {
      title: 'ProductsAndServices',
      requiresAuth: true,
      middleware: [checkPlan]
    }
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: { title: 'Signin', requiresAuth: false }
  },

  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta: { title: 'Signup', requiresAuth: false }
  },

  {
    path: '/sign-up/promo/:code',
    name: 'SignUpPromo',
    component: SignUpPromo,
    meta: { title: 'Signup Promo', requiresAuth: false }
  },

  {
    path: '/terms-of-service',
    name: 'Terms',
    component: Terms,
    meta: { title: 'Terms Of Service', requiresAuth: false }
  },

  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: PrivacyPolicy,
    meta: { title: 'Privacy Policy', requiresAuth: false }
  },

  {
    path: '/recover-password',
    name: 'RecoverPassword',
    component: RecoverPassword,
    meta: { title: 'Recover Password', requiresAuth: false }
  },

  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { title: 'Reset Password', requiresAuth: false }
  },

  {
    path: '/on-boarding',
    name: 'OnBoarding',
    component: () => import('@/views/Auth/OnBoarding/OnBoarding'),
    meta: { title: 'OnBoarding', requiresAuth: false }
  },

  {
    path: '/account/verify/:token',
    name: 'VerifyEmail',
    component: () => import('@/views/Auth/VerifyEmail'),
    meta: { title: 'Verify Email', requiresAuth: false }
  },

  {
    path: '/account/verify-email',
    name: 'VerifyEmailOTP',
    component: () => import('@/views/Auth/VerifyEmailOTP'),
    meta: { title: 'Verify Email Via OTP', requiresAuth: true }
  },

  {
    path: '/account/complete-order',
    name: 'CompleteOrder',
    component: () => import('@/views/Auth/OnBoarding/CompleteOrder'),
    meta: { title: 'Complete Order', requiresAuth: true }
  },
  // Trial Balance
  {
    path: '/trial-balance',
    name: 'TrialBalance',
    component: () => import('@/views/TrialBalance/TrialBalance'),
    meta: { title: 'Trial Balance', requiresAuth: true }
  },
  {
    path: '/trial-account',
    name: 'TrialAccount',
    component: () => import('@/views/TrialBalance/Account'),
    meta: { title: 'Account Transaction', requiresAuth: true }
  },


  //Added journal entry
  {
    path: '/journal-entry',
    name: 'EditJournal',
    component: () => import('@/views/JournalEntry/EditJournal'),
    meta: { title: 'Journal Entry', requiresAuth: true }
  },

  //Added asset management routes

  {
    path: '/asset-management',
    name: 'AssetManagement',
    component: AssetManagement,
    meta: { title: 'Asset Management', requiresAuth: true }
  },
  {
    path: '/asset-management/view',
    name: 'AssetManagementView',
    component: AssetManagementView,
    meta: { title: 'Asset Management View', requiresAuth: true }
  },

  {
    path: '/asset-management/buy',
    name: 'BuyAsset',
    component: BuyAsset,
    meta: { title: 'Buy Asset', requiresAuth: true }
  },
  {
    path: '/asset-management/transactions',
    name: 'AssetTransactions',
    component: AssetTransactions,
    meta: { title: 'Asset Transactions', requiresAuth: true }
  },
  {
    path: '/asset-management/sell',
    name: 'SellAsset',
    component: SellAsset,
    meta: { title: 'Sell Asset', requiresAuth: true }
  },
  {
    path: '/asset-management/:id/detail',
    name: 'AssetDetails',
    component: AssetDetails,
    meta: { title: 'Asset Details', requiresAuth: true }
  },
  {
    path: '/asset-management/edit',
    name: 'EditAsset',
    component: EditAsset,
    meta: { title: 'Edit Asset' }
  },
  //asset management routes end


  // Accounting route
  {
    path: '/accounting',
    name: 'Accounting',
    component: Accounting,
    meta: { title: 'Accounting', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/manage/assets',
    name: 'ManageAssets',
    component: () => import('@/views/chartOfAccount/manageAccount/Assets'),
    meta: { title: 'Manage Accounts', requiresAuth: true }
  },
  {
    path: '/accounting/opening-balance',
    name: 'OpeningBalance',
    component: () => import('@/views/chartOfAccount/OpeningBalance'),
    meta: { title: 'Opening Balance', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/manage/equity',
    name: 'ManageEquity',
    component: () => import('@/views/chartOfAccount/manageAccount/Equity'),
    meta: { title: 'Chart of Accounts', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/manage/expenses',
    name: 'ManageExpenses',
    component: () => import('@/views/chartOfAccount/manageAccount/Expenses'),
    meta: { title: 'Manage Expenses - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/manage/income',
    name: 'ManageIncome',
    component: () => import('@/views/chartOfAccount/manageAccount/Income'),
    meta: { title: 'Manage Income - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/manage/liability',
    name: 'ManageLiability',
    component: () => import('@/views/chartOfAccount/manageAccount/Liabilities'),
    meta: { title: 'Manage Liabilitiy - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/liability/borrowing',
    name: 'LiabilitiesBorrowing',
    component: () => import('@/views/chartOfAccount/chartView/liabilities/Borrowing'),
    meta: { title: 'Liabilitiy Borrowing - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/liability/vat-payables',
    name: 'VatPayables',
    component: () => import('@/views/chartOfAccount/chartView/liabilities/VatPayables'),
    meta: { title: 'VAT Payables - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/liability/trade-payables',
    name: 'TradePayables',
    component: () => import('@/views/chartOfAccount/chartView/liabilities/TradePayables'),
    meta: { title: 'Trade Payables - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/sales',
    name: 'IncomeSales',
    component: () => import('@/views/chartOfAccount/chartView/income/Sales'),
    meta: { title: 'Income Sales - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/sales',
    name: 'IncomeOtherSales',
    component: () => import('@/views/chartOfAccount/chartView/income/OtherSales'),
    meta: { title: 'Income Sales - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/discount-received',
    name: 'IncomeDiscountReceived',
    component: () => import('@/views/chartOfAccount/chartView/income/DiscountReceived'),
    meta: { title: 'Discount Recieved - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/commission-earned',
    name: 'IncomeCommissionEarned',
    component: () => import('@/views/chartOfAccount/chartView/income/CommissionEarned'),
    meta: { title: 'Commission Earned - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/shipping',
    name: 'IncomeShipping',
    component: () => import('@/views/chartOfAccount/chartView/income/Shipping'),
    meta: { title: 'Shipping - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/bad-debt-recovered',
    name: 'IncomeBadDebtRecovered',
    component: () => import('@/views/chartOfAccount/chartView/income/BadDebtRecovered'),
    meta: { title: 'Bad Debt Recovered - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/interest-received',
    name: 'IncomeInterestReceived',
    component: () => import('@/views/chartOfAccount/chartView/income/InterestReceived'),
    meta: { title: 'Interest Received - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/gift-received',
    name: 'IncomeGiftReceived',
    component: () => import('@/views/chartOfAccount/chartView/income/GiftReceived'),
    meta: { title: 'Gift Received - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/service-charge',
    name: 'IncomeServiceCharge',
    component: () => import('@/views/chartOfAccount/chartView/income/ServiceCharge'),
    meta: { title: 'Service Charge - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/other-income',
    name: 'IncomeOtherIncome',
    component: () => import('@/views/chartOfAccount/chartView/income/OtherIncome'),
    meta: { title: 'Other Income - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/income/other-sales-income',
    name: 'IncomeOtherSalesIncome',
    component: () => import('@/views/chartOfAccount/chartView/income/OtherSalesIncome'),
    meta: { title: 'Other Sales Income - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/equity/owners-contribution',
    name: 'EquityOwnersContribution',
    component: () => import('@/views/chartOfAccount/chartView/equity/OwnersContribution'),
    meta: { title: 'Owners Contribution - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/equity/drawings',
    name: 'EquityDrawings',
    component: () => import('@/views/chartOfAccount/chartView/equity/Drawings'),
    meta: { title: 'Drawings - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/assets/bank',
    name: 'AssetsBank',
    component: () => import('@/views/chartOfAccount/chartView/assets/Bank'),
    meta: { title: 'Bank Assets - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/chart-accounts/assets/cash',
    name: 'AssetsCash',
    component: () => import('@/views/chartOfAccount/chartView/assets/Cash'),
    meta: { title: 'Cash Assets - Chart of Account', requiresAuth: true }
  },
  {
    path: '/accounting/view',
    name: 'ChartView',
    component: () => import('@/views/chartOfAccount/ChartView'),
    meta: { title: 'View chart', requiresAuth: true }
  },

  {
    path: '/entities',
    name: 'Entities',
    component: () => import('@/views/Entities/Entities'),
    meta: { title: 'Entities', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/entities/:id',
    name: 'Entity',
    component: () => import('@/views/Entities/Entity'),
    meta: { title: 'Entity', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/entities/:id/edit',
    name: 'EntityUpdate',
    component: () => import('@/views/Entities/UpdateEntity'),
    meta: { title: 'Edit & Update Entity', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import('@/views/Transactions/Transactions'),
    meta: { title: 'Transactions', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/transactions/new2',
    name: 'AddTransaction',
    component: () => import('@/views/Transactions/AddTransaction'),
    meta: { title: 'Add New Transaction', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/transactions/new',
    name: 'AddTransactionNew',
    component: () => import('@/views/Transactions/AddTransactionNew'),
    meta: { title: 'Add New Transaction', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/transactions/create-sales',
    name: 'CreateSalesTransaction',
    component: () => import('@/views/Transactions/CreateSalesTransaction'),
    meta: { title: 'Create Sales Transaction', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/transactions/create-purchase',
    name: 'CreatePurchaseTransaction',
    component: () => import('@/views/Transactions/CreatePurchaseTransaction'),
    meta: { title: 'Create Purchase Transaction', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/transactions/create-expense',
    name: 'CreateExpenseTransaction',
    component: () => import('@/views/Transactions/CreateExpenseTransaction'),
    meta: { title: 'Create Expense Transaction', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/transactions/create-money-in',
    name: 'CreateMoneyInTransaction',
    component: () => import('@/views/Transactions/CreateMoneyInTransaction'),
    meta: { title: 'Create Money In Transaction', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/transactions/create-money-out',
    name: 'CreateMoneyOutTransaction',
    component: () => import('@/views/Transactions/CreateMoneyOutTransaction'),
    meta: { title: 'Create Money Out Transaction', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/transactions/:id',
    name: 'Transaction',
    component: () => import('@/views/Transactions/Transaction'),
    meta: { title: 'Transaction', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/:orgId/:userId/transaction/:transactionRef/view',
    name: 'TransactionView',
    component: () => import('@/views/Transactions/TransactionView'),
    meta: { title: 'Receipt', requiresAuth: false }
  },

  {
    path: '/:orgId/:userId/transaction/:transactionRef/receipt',
    name: 'TransactionReceipt',
    component: () => import('@/views/Transactions/TransactionView'),
    meta: { title: 'Receipt', requiresAuth: false }
  },

  {
    path: '/receipt/:orgId/:id',
    name: 'Receipt',
    component: () => import('@/views/Transactions/Receipt'),
    meta: { title: 'Receipt', requiresAuth: false }
  },

  {
    path: '/inventories',
    name: 'ProductsSummary',
    component: () => import('@/views/Inventory/AllProductSummary'),
    meta: { title: 'Products Summary', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/all',
    name: 'Products',
    component: () => import('@/views/Inventory/AllProducts'),
    meta: { title: 'All Products', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/services/all',
    name: 'Services',
    component: () => import('@/views/Inventory/AllServices'),
    meta: { title: 'All Services', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/analysis',
    name: 'ProductsAnalysis',
    component: () => import('@/views/Inventory/AllProductAnalysis'),
    meta: { title: 'Products Analysis', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/import',
    name: 'ImportProducts',
    component: () => import('@/views/Inventory/ImportProducts'),
    meta: { title: 'Import Products', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/product/:id',
    name: 'Product',
    component: () => import('@/views/Inventory/Product'),
    meta: { title: 'Product Details', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/services/service/:id',
    name: 'Service',
    component: () => import('@/views/Inventory/Service'),
    meta: { title: 'Service Details', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/inventories/product/:id/edit',
    name: 'ProductUpdate',
    component: () => import('@/views/Inventory/UpdateProduct'),
    meta: { title: 'Edit Product Details', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/stock-transfer/list',
    name: 'StockTransfers',
    component: () => import('@/views/Inventory/StockTransfers'),
    meta: { title: 'Stock Transfers', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/stock-transfer/:id/view',
    name: 'StockTransferView',
    component: () => import('@/views/Inventory/StockTransferView'),
    meta: { title: 'Stock Transfer', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/stock-transfer',
    name: 'TransferStock',
    component: () => import('@/views/Inventory/TransferStock'),
    meta: { title: 'Transfer Stock', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/purchase-orders/list',
    name: 'PurchaseOrders',
    component: () => import('@/views/Inventory/PurchaseOrders'),
    meta: { title: 'Purchase Orders', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/purchase-orders/create',
    name: 'PurchaseOrderCreate',
    component: () => import('@/views/Inventory/PurchaseOrderCreate'),
    meta: { title: 'Create Purchase Order', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/purchase-orders/:id/view',
    name: 'PurchaseOrderView',
    component: () => import('@/views/Inventory/PurchaseOrderView'),
    meta: { title: 'Purchase Order', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/products/categories',
    name: 'ProductCategories',
    component: () => import('@/views/Inventory/ProductCategories'),
    meta: { title: 'Product Categories', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/inventories/products/brands',
    name: 'ProductBrands',
    component: () => import('@/views/Inventory/ProductBrands'),
    meta: { title: 'Product Brands', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/quotations',
    name: 'Quotations',
    component: () => import('@/views/Quotations/Quotations'),
    meta: { title: 'Quotations', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/quotations/create',
    name: 'CreateAQuote',
    component: () => import('@/views/Invoice/Invoice'),
    meta: { title: 'Create a Quote', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/quotations/:id/preview',
    name: 'QuotePreview',
    component: () => import('@/views/Invoice/InvoicePreviewAndSend'),
    meta: { title: 'Preview Quotation', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/quotations/:id/edit',
    name: 'EditQuote',
    component: () => import('@/views/Invoice/InvoiceEdit'),
    meta: { title: 'Edit Quote', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/quotations/:id',
    name: 'Quotation',
    component: () => import('@/views/Invoice/InvoiceView'),
    meta: { title: 'Quotation', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/quotations/:id/view',
    name: 'ViewQuote',
    component: () => import('@/views/Invoice/InvoiceViewPublic'),
    meta: { title: 'View Quotation', requiresAuth: false }
  },

  {
    path: '/p_n_r',
    name: 'PnR',
    component: () => import('@/views/PayablesAndReceiables/PayablesAndReceivables'),
    meta: { title: 'Debtors And Creditors', requiresAuth: true, middleware: [checkPlan] }
  },

  // report new
  {
    path: '/reports/new',
    name: 'ReportNew',
    component: () => import('@/views/Report/Reports'),
    meta: { title: 'Report New', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/reports/balance-sheet',
    name: 'BalanceSheet',
    component: () => import('@/views/Report/BalanceSheet'),
    meta: { title: 'BalanceSheet', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/reports/profit-and-loss',
    name: 'ProfitAndLoss',
    component: () => import('@/views/Report/ProfitAndLoss'),
    meta: { title: 'Profit And Loss', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/reports/sales-report',
    name: 'SalesReport',
    component: () => import('@/views/Report/SalesReport'),
    meta: { title: 'Sales Report', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/reports/sales-report/new',
    name: 'SalesReportNew',
    component: () => import('@/views/Report/SalesReportNew'),
    meta: { title: 'Sales Report', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/reports/expense-report/new',
    name: 'ExpenseReportNew',
    component: () => import('@/views/Report/ExpenseReportNew'),
    meta: { title: 'Expense Report', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/reports/inventory-report/new',
    name: 'InventoryReportNew',
    component: () => import('@/views/Report/InventoryReportNew'),
    meta: { title: 'Inventory Report', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/reports/expense-report',
    name: 'ExpenseReport',
    component: () => import('@/views/Report/ExpenseReport'),
    meta: { title: 'Expense Report ', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/inventoryreport',
    name: 'InventoryReport',
    component: () => import('@/views/Report/InventoryReportOld'),
    meta: { title: 'Inventory Report', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/reports/inventory-report/sales',
    name: 'SalesInventoryReport',
    component: () => import('@/views/Report/SalesInventoryReport'),
    meta: { title: 'Sales Inventory Report', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/reports/inventory-report/purchases',
    name: 'PurchaseInventoryReport',
    component: () => import('@/views/Report/PurchaseInventoryReport'),
    meta: { title: 'Purchase Inventory Report', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/views/Report/ReportNew'),
    meta: { title: 'Reports', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/reports/inventory',
    name: 'InventoryReportList',
    component: () => import('@/views/Report/InventoryReport'),
    meta: { title: 'Inventory Report', requiresAuth: true, middleware: [checkPlan] }
  },

  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings/Settings'),
    meta: { title: 'Settings', requiresAuth: true }
  },

  // Added new setting page
  {
    path: '/setting-dashboard',
    name: 'SettingNew',
    component: () => import('@/views/Settings/SettingNew'),
    meta: { title: 'Settings', requiresAuth: true }
  },

  {
    path: '/settings/business',
    name: 'BusinessProfile',
    component: () => import('@/views/Settings/BusinessProfile'),
    meta: { title: 'Settings', requiresAuth: true }
  },
  {
    path: '/settings/accountsettings',
    name: 'AccountSettings',
    component: () => import('@/views/Settings/AccountSettings'),
    meta: { title: 'Account Settings', requiresAuth: true }
  },
  {
    path: '/settings/change-password',
    name: 'ChangePassword',
    component: () => import('@/views/Settings/ChangePassword'),
    meta: { title: 'Change Password', requiresAuth: true }
  },
  {
    path: '/settings/lifespan',
    name: 'Lifespan',
    component: () => import('@/views/Settings/Lifespan'),
    meta: { title: 'Lifespan', requiresAuth: true }
  },
  {
    path: '/settings/bank-accounts',
    name: 'BankAccounts',
    component: () => import('@/views/Settings/BankAccounts'),
    meta: { title: 'Bank Accounts', requiresAuth: true }
  },
  {
    path: '/settings/manage-account',
    name: 'ManageAccount',
    component: () => import('@/views/Settings/ManageAccount'),
    meta: { title: 'Manage Account', requiresAuth: true }
  },

  {
    path: '/settings/notification',
    name: 'Notification',
    component: () => import('@/views/Settings/Notification'),
    meta: { title: 'Notification', requiresAuth: true }
  },

  {
    path: '/settings/billings',
    name: 'BillingNew',
    component: () => import('@/views/Settings/BillingNew'),
    meta: { title: 'Billings', requiresAuth: true }
  },

  //New Billing page
  {
    path: '/settings/billing/new',
    name: 'Billings',
    component: () => import('@/views/Settings/Billings'),
    meta: { title: 'Billings', requiresAuth: true }
  },

  //Added plan
  {
    path: '/plans',
    name: 'PaymentPlans',
    component: PaymentPlans,
    meta: { title: 'Plans', requiresAuth: true }
  },
  {
    path: '/plan',
    name: 'Plan',
    component: () => import('@/views/Settings/Plan'),
    meta: { title: 'Plans', requiresAuth: true }
  },


  {
    path: '/settings/plan',
    name: 'Plan',
    component: () => import('@/views/Settings/Plan'),
    meta: { title: 'Plan', requiresAuth: true }
  },

  {
    path: '/settings/teams',
    name: 'Teams',
    component: () => import('@/views/Settings/Teams'),
    meta: { title: 'Teams', requiresAuth: true }
  },
  {
    path: '/settings/roles',
    name: 'Roles',
    component: () => import('@/views/Settings/ManagedRoles'),
    meta: { title: 'Teams', requiresAuth: true }
  },
  {
    path: '/settings/archives/entities',
    name: 'EntitiesArchive',
    component: () => import('@/views/Settings/Archives/EntitiesArchive'),
    meta: { title: 'Entities Archive', requiresAuth: true }
  },

  {
    path: '/settings/archives/products',
    name: 'ProductsArchive',
    component: () => import('@/views/Settings/Archives/ProductsArchive'),
    meta: { title: 'Products Archive', requiresAuth: true }
  },

  {
    path: '/settings/archives/transactions',
    name: 'TransactionsArchive',
    component: () => import('@/views/Settings/Archives/TransactionsArchive'),
    meta: { title: 'Transaction Archive', requiresAuth: true }
  },

  {
    path: '/settings/teams/join',
    name: 'JoinTeam',
    component: () => import('@/views/Settings/JoinTeam'),
    meta: { title: 'Join Your Teams', requiresAuth: false }
  },

  {
    path: '/settings/integrations',
    name: 'Integrations',
    component: () => import('@/views/Settings/Integrations/Index'),
    meta: { title: 'Integrations', requiresAuth: false }
  },

  {
    path: '/settings/integrations-paystack-index',
    name: 'paystackIntegrationIndex',
    component: () => import('@/views/Settings/PaystackIndex'),
    meta: { title: 'Paystack Integration', requiresAuth: false }
  },

  {
    path: '/settings/integrations-paystack',
    name: 'paystackIntegrationMain',
    component: () => import('@/views/Settings/PaystackMain'),
    meta: { title: 'Paystack Integration', requiresAuth: false }
  },

  {
    path: '/settings/integrations/paystack',
    name: 'PaystackIntegration',
    component: () => import('@/views/Settings/Integrations/Paystack'),
    meta: { title: 'Paystack | Integrations', requiresAuth: false }
  },

  {
    path: '/settings/integrations/paystack/complete',
    name: 'PaystackWebhookDetails',
    component: () => import('@/views/Settings/Integrations/WebhookDetails'),
    meta: { title: 'Paystack | Integrations', requiresAuth: false }
  },

  {
    path: '/settings/add-business',
    name: 'AddBusiness',
    component: () => import('@/views/Settings/AddBusiness'),
    meta: { title: 'Add Business', requiresAuth: false }
  },

  {
    path: '/business/invite',
    name: 'AdminInvite',
    component: () => import('@/views/Admin/Setting/InviteSignup'),
    meta: { title: 'Use Simplebks', requiresAuth: false }
  },

  {
    path: '/:pathMatch(.*)*',
    // path: "/:catchAll(.*)",
    name: 'Error404',
    component: () => import('@/views/Errors/Error404'),
    meta: { title: 'Error404', requiresAuth: true }
  },
  {
    path: '/invoice/:id',
    name: 'Invoice',
    component: () => import('@/views/Invoice/InvoiceView'),
    meta: { title: 'Invoice', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/invoice/:id/view',
    name: 'InvoiceView',
    component: () => import('@/views/Invoice/InvoiceViewPublic'),
    meta: { title: 'Invoice', requiresAuth: false }
  },
  {
    path: '/invoice/edit/:id',
    // path: '/invoice/edit',
    name: 'InvoiceEdit',
    component: () => import('@/views/Invoice/InvoiceEdit'),
    meta: { title: 'Invoice', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/invoice/summary',
    name: 'InvoiceSummary',
    component: () => import('@/views/Invoice/InvoiceSummary'),
    meta: { title: 'Invoice', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/invoice/customize',
    name: 'InvoiceCustomize',
    component: () => import('@/views/Invoice/InvoiceCustomize'),
    meta: { title: 'Invoice Customize', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/invoice/:id/preview',
    name: 'InvoicePreview',
    component: () => import('@/views/Invoice/InvoicePreviewAndSend'),
    meta: { title: 'Preview Invoice', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/invoice/email/:id',
    name: 'InvoiceEmail',
    component: () => import('@/views/Invoice/InvoiceEmail'),
    meta: { title: 'Invoice', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/create-invoice',
    name: 'InvoiceNew',
    component: () => import('@/views/Invoice/Invoice'),
    meta: { title: 'Create Invoice', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/invoice/paystack/:reference/payment',
    name: 'PaystackPageCallback',
    component: () => import('@/views/Invoice/PaystackPageCallback'),
    meta: { title: 'Invocie | Paystack Page Payment' }
  },
  {
    path: '/invoice/paystack/:reference/payment-confirmation',
    name: 'PaystackPageConfirmation',
    component: () => import('@/views/Invoice/PaystackPageConfirmation'),
    meta: { title: 'Invocie | Paystack Page Payment Confirmation' }
  },
  {
    path: '/invoice/:id/notification/mark-as-paid',
    name: 'MarkAsPaidNotification',
    component: () => import('@/views/Invoice/MarkAsPaidNotification'),
    meta: { title: 'Invocie | Mark as Paid Notification' }
  },
  {
    path: '/invoice/:id/mark-as-paid',
    name: 'MarkAsPaid',
    component: () => import('@/views/Invoice/MarkAsPaid'),
    meta: { title: 'Invocie | Mark as Paid' }
  },
  {
    path: '/payment-invoice/:id',
    name: 'PaymentInvoice',
    component: () => import('@/views/Settings/PaymentInvoice'),
    meta: { title: 'Payment Invoice', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/payment-invoice',
    name: 'PaymentInvoice',
    component: () => import('@/views/Settings/PaymentInvoice'),
    meta: { title: 'Payment Invoice', requiresAuth: true }
  },
  {
    path: '/payment/process',
    name: 'PaymentProcess',
    component: () => import('@/views/Settings/PaymentProcess'),
    meta: { title: 'Payment Processing...', requiresAuth: true }
  },

  // Added new production route
  {
    path: '/production-dashboard',
    name: 'ProductionNew',
    component: ProductionNew,
    meta: { title: 'Production', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/production',
    component: () => import('@/views/Production/Parent'),
    meta: { title: 'Production', requiresAuth: true, middleware: [checkPlan] },
    children: [
      {
        path: '/production',
        name: 'Production',
        component: () => import('@/views/Production/Production'),
        meta: { title: 'Production', requiresAuth: true, middleware: [checkPlan] }
      },
      {
        path: '/production/raw-material',
        name: 'RawMaterial',
        component: () => import('@/views/Production/RawMaterial'),
        meta: { title: 'Raw Material', requiresAuth: true, middleware: [checkPlan] }
      },
      {
        path: '/production/work-in-progress',
        name: 'WorkInProgress',
        component: () => import('@/views/Production/WorkInProgress'),
        meta: { title: 'Work In Progress', requiresAuth: true, middleware: [checkPlan] }
      },
      {
        path: '/production/produce',
        name: 'Produce',
        component: () => import('@/views/Production/Produce/Parent'),
        meta: { title: 'Produce', requiresAuth: true, middleware: [checkPlan] }
      }
    ]
  },
  {
    path: '/production/create',
    component: () => import('@/views/Production/Create/Parent'),
    meta: { title: 'Create Product', requiresAuth: true, middleware: [checkPlan] },
    children: [
      {
        path: '/production/raw-material/create',
        name: 'CreateRawMaterial',
        component: () => import('@/views/Production/Create/RawMaterial'),
        meta: { title: 'Create Product Raw material', requiresAuth: true, middleware: [checkPlan] }
      },
      {
        path: '/production/wip/create',
        name: 'CreateWorkInProgress',
        component: () => import('@/views/Production/Create/WorkInProgress'),
        meta: { title: 'Create Work In Progress', requiresAuth: true, middleware: [checkPlan] }
      },
      // {
      //     path: '/production/product/create',
      //     name: 'CreateFinishedProduct',
      //     component: () => import('@/views/Production/Create/FinishedProduct'),
      //     meta: { title: 'Create Product', requiresAuth: true, middleware: [checkPlan] }
      // }
    ]
  },
  {
    path: '/pos',
    name: 'Pos',
    component: () => import('@/views/Pos/PosNew'),
    meta: { title: 'POS', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/pos/register',
    name: 'PosRegister',
    component: () => import('@/views/Pos/Register'),
    meta: { title: 'POS Register', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/pos/sales',
    name: 'PosSalesHistory',
    component: () => import('@/views/Pos/SalesHistory'),
    meta: { title: 'POS Sales History', requiresAuth: true, middleware: [checkPlan] }
  },
  {
    path: '/pos/sales/:id/receipt',
    name: 'PosReceipt',
    component: () => import('@/views/Pos/PosReceipt'),
    meta: { title: 'POS Receipt', requiresAuth: true, middleware: [checkPlan] }
  },
  // Admin routes starts here
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => import('@/views/Admin/Auth/AdminLogin'),
    meta: { title: 'Admin Login' }
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    component: () => import('@/views/Admin/Dashboard/adminDashboard'),
    meta: { title: 'Admin Dashboard', requireAdminAuth: true }
  },
  // admin account
  {
    path: '/admin/account',
    name: 'AdminAccount',
    component: () => import('@/views/Admin/Account/index'),
    meta: { title: 'Admin Account', requireAdminAuth: true }
  },
  {
    path: '/admin/:id/account-details',
    name: 'AdminAccountDetails',
    component: () => import('@/views/Admin/Account/AccountDetails'),
    meta: { title: 'Admin Account Details', requireAdminAuth: true }
  },
  {
    path: '/admin/:id/account-activities',
    name: 'AdminAccountActivities',
    component: () => import('@/views/Admin/Account/AccountActivities'),
    meta: { title: 'Admin Account Activities', requireAdminAuth: true }
  },
  // admin activities
  {
    path: '/admin/activities',
    name: 'AdminActivities',
    component: () => import('@/views/Admin/Activities'),
    meta: { title: 'Admin Activities', requireAdminAuth: true }
  },
  // admin setting
  {
    path: '/admin/setting',
    name: 'AdminSettingIndex',
    component: () => import('@/views/Admin/Setting/index'),
    meta: { title: 'Admin Setting', requireAdminAuth: true }
  },
  {
    path: '/admin/setting/create-partners',
    name: 'AdminSettingCreatePartner',
    component: () => import('@/views/Admin/Setting/create-partner'),
    meta: { title: 'Admin Create Partner', requireAdminAuth: true }
  },
  {
    path: '/admin/setting/:id/partner-details',
    name: 'AdminSettingPartnerDetails',
    component: () => import('@/views/Admin/Setting/partner-details'),
    meta: { title: 'Admin Partner Details', requireAdminAuth: true }
  },
  {
    path: '/admin/partners/:id/teams/join',
    name: 'AdminJoinTeam',
    component: () => import('@/views/Admin/Setting/JoinPartnerTeam'),
    meta: { title: 'Join Team' }
  },
  {
    path: '/admin/businesses/:id/pnl-report',
    name: 'AdminBusinessReport',
    component: () => import('@/views/Admin/Reports/PandL'),
    meta: { title: 'Profit And Loss Report' }
  },
  {
    path: '/admin/businesses/:id/sales-report',
    name: 'AdminSalesReport',
    component: () => import('@/views/Admin/Reports/salesReport'),
    meta: { title: 'Sales Report' }
  },
  {
    path: '/admin/businesses/:id/balance-sheet-report',
    name: 'AdminBalanceSheetReport',
    component: () => import('@/views/Admin/Reports/balanceSheetReport'),
    meta: { title: 'Balance Sheet Report' }
  },
  {
    path: '/admin/businesses/:id/inventory-report',
    name: 'AdminInventorySheetReport',
    component: () => import('@/views/Admin/Reports/inventorySheetReport'),
    meta: { title: 'Inventory Sheet Report' }
  },
  {
    path: '/admin/businesses/:id/expense-report',
    name: 'AdminExpenseSheetReport',
    component: () => import('@/views/Admin/Reports/expenseSheetReport'),
    meta: { title: 'Expense Sheet Report' }
  },
  {
    path: '/admin/businesses/:id/update',
    name: 'AdminBusinessUpdate',
    component: () => import('@/views/Admin/Account/EditBusiness'),
    meta: { title: 'Edit Business' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return {
        selector: to.hash
      }
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, _from, next) => {
  /* eslint-disable */
  if (!to.path.startsWith('/admin/')) {
    // console.log('path', to.path.startsWith('/admin'))
    const isAuth = store?.state?.Auth?.isAuthenticated

    if (to.meta.requiresAuth && !isAuth) {
      return next({ name: 'SignIn' })
    }

    if (to.name === 'Error404') next()

    if (to.name === 'JoinTeam') {
      if (!isAuth) {
        next()
      } else {
        next('/')
      }
    }

    if (to.name === 'SignUp' || to.name === 'SignUpPromo') {
      if (!isAuth) {
        return next()
      }
    }

    if (
      to.name !== 'SignIn' &&
      to.name !== 'SignUp' &&
      to.name !== 'SignUpPromo' &&
      to.name === 'recover-password' &&
      to.name !== 'reset-password' &&
      !isAuth
    ) {
      return next({ name: 'SignIn' })
    }

    if (
      (to.name === 'SignIn' ||
        to.name === 'SignUp' ||
        to.name === 'SignUpPromo' ||
        to.name === 'recover-password' ||
        to.name === 'reset-password') &&
      isAuth
    ) {
      return next({ name: 'Dashboard' })
    }

    if (!to.meta.middleware) {
      return next()
    }
    const middleware = to.meta.middleware

    const context = {
      to,
      _from,
      next,
      store
    }
    return middleware[0]({
      ...context
    })

    // return next()
  }

  // admin route middleware
  if (to.path.startsWith('/admin/')) {
    if (!isLoggedIn() && to.name !== 'AdminLogin' && to.name !== 'AdminJoinTeam') {
      return next('/admin/login')
    } else {
      return next()
    }
  }

  // if (to.meta.requireAdminAuth) next()
})

router.afterEach(async to => {
  await nextTick(() => {
    document.title = `${to.meta.title} - Simplebks` || 'Simplebks'
  })
})

export default router
