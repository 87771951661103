import axios from 'axios'
import { Axios } from '@/utils/axiosConfig'
import Router from '@/router'
import store from '../index'

const state = {
    message: '',
    showChangePasswordPage: false,
    isAuthenticated: false,
    userData: {
        user: '',
        org: '',
        phone: '',
        email: '',
        address: ''
    },
    role: '',
    userInitials: '',
    orgInitials: '',
    fullName: '',
    orgImage: '',
    subscription: null,
    rolePermissions: []
}

const mutations = {
    SET_SUBSCRIPTION(state, payload) {
        const { data } = payload
        state.subscription = {
            plan: data.businessPlan,
            isDisabled: data.isDisabled,
            hasExpired: data.hasExpired,
            isActive: data.isActive,
            isCancelled: data.isCancelled,
            isPaid: data.isPaid,
            users: data.users
        }
    },
    SET_USER_DATA(state, payload) {
        state.userData = payload
        state.orgImage = payload?.companyImage

        if (payload) {
            // eslint-disable-next-line
            let { user, org, role, rolePermissions } = payload
            state.role = role
            state.rolePermissions = rolePermissions

            state.fullName = user

            user = user?.split(' ')
            org = org?.split(' ')

            if (user?.length > 1) {
                const first = user?.[0]?.split('')[0]
                const second = user?.[1]?.split('')[0]
                state.userInitials = `${first} ${second}`
            } else {
                const first = user?.[0]?.split('')[0]
                const second = user?.[0]?.split('')[1]
                state.userInitials = `${first} ${second}`
            }
            if (org?.length > 1) {
                const first = org?.[0]?.split('')[0]
                const second = org?.[1]?.split('')[0]
                state.orgInitials = `${first} ${second}`
            } else {
                const first = org?.[0]?.split('')[0]
                const second = org?.[0]?.split('')[1]
                state.orgInitials = `${first} ${second}`
            }
            state.userInitials = state.userInitials.toUpperCase()
            state.orgInitials = state.orgInitials.toUpperCase()
        }
    },

    SET_AUTH_STATUS(state, payload) {
        state.isAuthenticated = payload
    },

    SET_AUTH_MESSAGE(state, payload) {
        state.message = payload
    },

    SHOW_CHANGE_PASSWORD_PAGE(state, payload = false) {
        state.showChangePasswordPage = payload
    }
}

const actions = {
    signIn: async ({ dispatch, commit }, formData) => {
        let resp
        return Axios.post('/api/v1/auth/sign-in', formData)
            .then(async ({ data }) => {
                if (data?.success) {
                    resp = data?.success
                    dispatch('getPlan')
                    dispatch('getUserData')
                    commit('SET_AUTH_STATUS', true)

                    if (data?.redirect) {
                        await Router.replace({ name: 'OnBoarding' })
                        return
                    }

                    const isAuth = store?.state.Auth.isAuthenticated
                    // eslint-disable-next-line
                    const isPreviousRoute = await Router.currentRoute?._rawValue?.redirectedFrom?.name

                    if (isAuth) {
                        if (isPreviousRoute) {
                            return Router.replace({ name: isPreviousRoute })
                            // return window.location.replace(isPreviousRoute)
                        }
                        await Router.replace({ name: 'Dashboard' })
                        // return window.location.replace(isPreviousRoute)
                    }
                }
                return resp
            })
            .catch(async err => {
                const data = err?.response?.data
                dispatch('getUserData')
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })

                console.error(err?.response?.data || err?.message)
                commit('SET_AUTH_MESSAGE', err?.response?.data?.message)
                setTimeout(() => {
                    commit('SET_AUTH_MESSAGE', '')
                }, 5000)
                return data?.success
            })
    },

    validateEmail: async (_, email) => {
        const { data } = await axios.get(`https://isvalid.email/${email}`)
        if (data.valid === 'yes') {
            return true
        }

        return false
    },

    signUp: async ({ dispatch, commit }, formData) => {
        Axios.post('/api/v1/auth/sign-up', formData)
            .then(async ({ data }) => {
                if (data?.success) {
                    // dispatch('getPlan')
                    dispatch('getUserData')
                    commit('SET_AUTH_STATUS', true)
                    const isAuth = store?.state.Auth.isAuthenticated
                    localStorage.removeItem('referralCode')
                    if (isAuth) {
                        await Router.replace({
                            name: 'OnBoarding',
                            query: {
                                plan: formData.plan,
                                period: formData.period
                            }
                        })
                        // await Router.push({ name: 'OnBoarding' })
                        // window.location.replace('/on-boarding')
                    }
                }
            })
            .catch(async err => {
                const data = err?.response?.data
                console.error(err)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                // dispatch('getUserData')
                // console.clear()
            })
    },

    googleAuth: async ({ commit }, formData) => {
        Axios.post('/api/v1/auth/google', formData)
            .then(async ({ data }) => {
                if (data?.status) {
                    // await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                    await store.dispatch('Auth/getUserData')
                    commit('SET_AUTH_STATUS', true)
                    const isAuth = store?.state.Auth.isAuthenticated
                    if (isAuth && data?.redirectToOnboarding) {
                        await Router.replace({ name: 'OnBoarding' })
                    } else {
                        await Router.replace({ name: 'Dashboard' })
                    }
                }
            })
            .catch(async err => {
                const data = err?.response?.data
                console.error(err)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
    },

    recoverPassword: async ({ commit }, formData) => {
        return Axios.post('/api/v1/auth/recover-password', formData)
            .then(async ({ data }) => {
                if (data?.success) {
                    await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                    // commit('SHOW_CHANGE_PASSWORD_PAGE', true)
                    // await Router.replace({ name: 'ResetPassword' })
                    setTimeout(() => {
                        Router.replace({ name: 'SignIn' }).then(() => '')
                    }, 5000)
                }

                return data
            })
            .catch(async err => {
                const data = err?.response?.data
                console.error(err.response.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                // commit('SET_AUTH_MESSAGE', err?.response?.data?.message)
                setTimeout(() => {
                    commit('SET_AUTH_MESSAGE', '')
                }, 5000)
                // console.clear()

                return data
            })
    },

    resetPassword: async (_, formData) => {
        const { email, ...rest } = formData
        const body = rest.rest

        return Axios.post(`/api/v1/auth/reset-password?email=${email}`, body)
            .then(async ({ data }) => {
                if (data?.success) {
                    // commit('SHOW_CHANGE_PASSWORD_PAGE', true)
                    await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                    setTimeout(() => {
                        Router.replace({ name: 'SignIn' }).then(() => '')
                    }, 5000)
                }

                return data
            })
            .catch(async err => {
                const data = err?.response?.data
                console.error(err.response.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                // commit('SET_AUTH_MESSAGE', err?.response?.data?.message)
                // setTimeout(() => {
                //   commit('SET_AUTH_MESSAGE', '')
                // }, 5000)
                // console.clear()

                return data
            })
    },

    getResetPassword: async (_, query) => {
        if (!query) {
            Router.replace({ name: 'SignIn' }).then(() => console.clear())
        }

        Axios.get(`/api/v1/auth/reset-password?email=${query}`)
            .then(({ data }) => {
                if (!data?.success) {
                    Router.replace({ name: 'SignIn' }).then(() => console.clear())
                }
                return data
            })
            .catch(err => {
                console.error(err.response.data)
                Router.replace({ name: 'SignIn' })
                    .then(() => console.clear())
                    .catch(() => console.log(err))

                return err?.response?.data
            })
    },

    showChangePasswordPage: ({ commit }, payload) => {
        commit('SHOW_CHANGE_PASSWORD_PAGE', payload)
    },

    setupOrg: async ({ dispatch, commit }, formData) => {
        return Axios.post('/api/v1/auth/setup-org', formData)
            .then(async ({ data }) => {
                if (data?.success) {
                    dispatch('getUserData')
                    commit('SET_AUTH_STATUS', true)
                    const isAuth = store?.state.Auth.isAuthenticated
                    if (isAuth) await Router.replace({ name: 'GetStarted' })
                }

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                if (err.response.status === 403) {
                    await Router.replace({ name: 'Dashboard' })
                }

                console.error(data)

                return data
            })
    },

    verifyEmailViaOTP: async ({ dispatch, commit }, formData) => {
        return Axios.post('/api/v1/accounts/email/verify', formData)
            .then(async ({ data }) => {
                if (data?.success) {
                    dispatch('getUserData')
                    await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                    // await Router.push({ name: 'Dashboard' })
                }
                window.location.href = '/'
                // await router.push({ name: 'Dashboard' })

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                // console.error(data)

                return data
            })
    },

    resendOTP: async _ => {
        return Axios.get('/api/v1/accounts/email/resend-otp')
            .then(async ({ data }) => {
                if (data?.success) {
                    await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                }

                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)

                return data
            })
    },

    setUserData: async ({ commit }) => {
        // eslint-disable-next-line no-underscore-dangle
        Axios.get('/api/v1/auth')
            .then(async ({ data }) => {
                if (data?.success && data?.data?.org !== null) {
                    commit('SET_USER_DATA', data.data)
                }
            })
            // eslint-disable-next-line
            .catch(async err => {
                commit('SET_USER_DATA', null)
            })
    },

    getUserData: async ({ commit }) => {
        // eslint-disable-next-line no-underscore-dangle
        const route = await Router.currentRoute
        Axios.get('/api/v1/auth')
            .then(async ({ data }) => {
                if (data?.data?.user === null) {
                    commit('SET_AUTH_STATUS', false)
                    commit('SET_USER_DATA', null)
                    if (route?._rawValue?.meta?.requiresAuth) {
                        await Router.replace({ name: 'SignIn' })
                    }
                }

                const isAuth = store?.state.Auth.isAuthenticated
                if (data?.success && data?.data?.org !== null) {
                    if (!isAuth) commit('SET_AUTH_STATUS', true)
                    commit('SET_USER_DATA', data.data)
                }

                if (data?.redirect) {
                    await Router.replace({ name: 'OnBoarding' })
                }

                const emailVerifiedAt = data?.data?.emailVerifiedAt
                const planExpired = data?.data?.isPlanExpired
                const firstTimeSubscription = data?.data?.firstTimeSubscription ? true : false
                const isSettingsPage = route?._rawValue.fullPath.startsWith('/settings')
                if (isAuth && (emailVerifiedAt === undefined || emailVerifiedAt === null)) {
                    await Router.replace({ name: 'VerifyEmailOTP' })
                } else if (isAuth && planExpired && !isSettingsPage && firstTimeSubscription) {
                    await Router.replace({ name: 'CompleteOrder' })
                } else if (isAuth && planExpired && !isSettingsPage && !firstTimeSubscription) {
                    await Router.replace({ name: 'Plan' })
                }
            })
            // eslint-disable-next-line
            .catch(async err => {
                commit('SET_AUTH_STATUS', false)
                commit('SET_USER_DATA', null)
                const route = await Router.currentRoute
                const currentRoute = route.value
                if (
                    // eslint-disable-next-line
                    currentRoute.name === 'JoinTeam' ||
                    // eslint-disable-next-line
                    currentRoute.name === 'SignUp' ||
                    // eslint-disable-next-line
                    currentRoute.name === 'RecoverPassword' ||
                    currentRoute.name === 'ResetPassword'
                ) {
                    return false
                }
                setTimeout(() => {
                    Router.replace({ name: 'SignIn' }).then(() => {})
                }, 500)
            })
    },

    signOut: async ({ commit }) => {
        Axios.get('/logout')
            .then(async () => {
                commit('SET_AUTH_STATUS', false)
                commit('SET_USER_DATA', null)
                const isAuth = store?.state.Auth.isAuthenticated
                if (!isAuth) await Router.replace({ name: 'SignIn' })
                // if (!isAuth) window.location.replace('/')
            })
            .catch(err => {
                console.error(err.response.data)
            })
    },
    getPlan: async ({ commit }) => {
        return await Axios.get('/api/v1/payment/plan')
            .then(async ({ data }) => {
                commit('SET_SUBSCRIPTION', data)
                // commit('SET_LOADING', false)
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                // commit('SET_LOADING', false)
                console.error(data)
            })
    },

    verifyEmail: async ({ commit }, payload) => {
        Axios.get(`/api/v1/accounts/email/verify/${payload}`)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                window.location = '/'
            })
            .catch(async err => {
                const data = err.response.data
                // console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                window.location = '/'
            })
    },

    resendVerifyEmailLink: async () => {
        Axios.get(`/api/v1/accounts/email/resend-verification-link`)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            })
            .catch(async err => {
                const data = err.response.data
                // console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            })
    }
}

export const AuthModule = {
    namespaced: true,
    actions,
    mutations,
    state
}
