<template>
    <!--  -->
    <!-- modal manuel -->
    <!--  :class="!state.isDelete ? 'modal2' : 'modal-target2'"-->
    <!-- modal bulk -->
    <div v-if="showImportInventoryModal">
        <div class="jquery-modal blocker current" @click="closeModalOnOutsideClick" ref="importRef">
            <div id="modal-bulk" class="modal-target2">
                <!-- <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>Import inventories</h3>
                        <div class="lh-copy">
                            You can use this to upload a list of your inventories, to get started. Download the <a
                                href="" @click.prevent="downloadInventoryTemplate" class="b text-info">template</a>
                            here, add as much inventory as you want, and upload the file in a CSV format.
                        </div>
                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input type="file" name="imageFile" id="file" class="product-input" @change="onFileChange"
                                accept="text/csv" />
                            <button :disabled="state.buttonDisabled">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Upload CSV' }}
                            </button>
                        </form>
                    </div>
                </div> -->
                <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>Import inventories</h3>
                        <div>Download inventories import <a href="" @click.prevent="downloadInventoryTemplate" class="b text-info">template</a></div>
                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input type="file" name="imageFile" id="file" class="product-input" @change="onFileChange"
                                accept="text/csv" />
                            <button :disabled="state.buttonDisabled">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Upload Template' }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { computed, reactive, ref } from 'vue'
    import { useStore } from 'vuex'

    export default {
        name: 'UploadInventoryModal',
        props: {
            closeModal: Function,
        },

        setup(props, { emit }) {
            const store = useStore()

            const orgData = computed(() => store?.state?.Auth?.userData)
            const showImportInventoryModal = computed(() => store.state?.Inventory?.showImportInventoryModal)

            const state = reactive({
                buttonDisabled: false,
                imageFile: null,
            })

            const importRef = ref(null)

            const onFileChange = (e) => {
                state.imageFile = e.target.files?.[0]
            }

            const closeModalOnOutsideClick = (e) => {
                if (e.target === importRef.value) {
                    props.closeModal()
                }
            }

            const downloadInventoryTemplate = () => {
                store.dispatch('Inventory/downloadInventoryImportTemplate').then((response) => {
                    if (response?.data?.error) {
                        console.error(response.data.error)
                    }

                    const fileURL = window.URL.createObjectURL(new Blob([response]))
                    const fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    const fileName = `${orgData.value?.org}-inventory-import-template.csv`
                    fileLink.setAttribute('download', fileName)
                    fileLink.setAttribute('target', '_blank')
                    document.body.appendChild(fileLink)
                    fileLink.click()
                    fileLink.remove()
                    // state.downloading = false
                })
            }
            
            const onSubmit = () => {
                state.buttonDisabled = true
                const formData = new FormData()
                formData.append('imageFile', state.imageFile)
                store.dispatch('Inventory/importProducts', formData).then((resp) => {
                    if (resp) {
                        props.closeModal()
                        state.imageFile = null
                        state.buttonDisabled = false
                    }
                })
            }

            const updateIncomingState = (incomingState, key) => {
                state[incomingState] = state[key]
            }

            return {
                state,
                emit,
                updateIncomingState,
                importRef,
                closeModalOnOutsideClick,
                onFileChange,
                showImportInventoryModal,
                onSubmit,
                downloadInventoryTemplate
            }
        },
    }
</script>

<style scoped></style>