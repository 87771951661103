<template>
    <button type="button" @click="callback" class="flex items-center justify-center">
        <img :src="require('@/assets/images/google.png')" alt="Google Logo" width="30" />&nbsp;
        {{ text }}
    </button>
</template>

<script>
import { useStore } from 'vuex'
import { googleAuthCodeLogin } from 'vue3-google-login'
export default {
    name: 'GoogleButton',
    props: {
        text: String,
    },
    setup() {
        const store = useStore()

        const callback = () => {
            googleAuthCodeLogin().then(response => {
                console.log('Handle the response', response)
                store.dispatch('Auth/googleAuth', { token: response.code })
            })
        }

        return { callback }
    }
}
</script>
