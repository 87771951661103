import { Axios } from '@/utils/axiosConfig'
import store from '@/store'
import { paginationOptions } from '@/utils/lib'

const state = {
    isLoading: false,
    canAccessPos: false,
    showRetrieveSaleModal: false,
    showPaySaleModal: false,
    productItems: [],
    sales: [],
    sale: null,
    parkedSales: [],
    items: [],
    customer: {
        id: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        vat_number: '',
        tax_number: '',
        website: '',
    },
    totalItems: 0,
    subtotal: 0,
    discount: 0,
    discountType: 'percent',
    tax: 0,
    amount: 0,
    glAccounts: [],
    amountCollected: 0,
    amountRemaining: 0,
    amountToPay: 0,

    latestRegister: {},
    cashPaymentsReceived: {},
    bankPaymentsReceived: [],
    latestPaymentsCounted: 0,
    latestRegisterTotals: 0,

    activeRegister: {},
    activeCashPaymentsReceived: {},
    activeBankPaymentsReceived: [],
    activePaymentsCounted: 0,
    activeRegisterTotals: 0
}

const mutations = {
    SET_LOADING_STATE(state, payload) {
        state.isLoading = payload
    },

    SET_POS_ACCESS(state, payload) {
        state.canAccessPos = payload
    },

    SET_SHOW_RETRIEVE_SALE_MODAL(state, payload) {
        state.showRetrieveSaleModal = payload
    },

    SET_SHOW_PAY_SALE_MODAL(state, payload) {
        state.showPaySaleModal = payload
    },

    SET_PRODUCTS(state, payload) {
        state.productItems = payload
    },

    UPDATE_PRODUCTS(state, payload) {
        const data = {
            _id: payload._id,
            org: payload.org,
            name: payload.name,
            sku: payload.sku,
            buyingPrice: payload.buyingPrice,
            sellingPrice: payload.sellingPrice,
            type: payload.type,
            createdAt: payload.createdAt,
            updatedAt: payload.updatedAt,
        }
        state.productItems.push(data)
    },

    SET_POS_SALES_HISTORY(state, payload) {
        state.sales = payload
    },

    SET_POS_SALE_HISTORY(state, payload) {
        state.sale = payload
    },

    SET_PARKED_SALES(state, payload) {
        state.parkedSales = payload
    },

    SET_PARKED_SALE(state, payload) {
        state.items = payload?.items
        state.subtotal = payload?.subtotal
        state.discount = payload?.discount
        state.tax = payload?.tax
        state.amount = payload?.totalAmount
        state.totalItems = payload?.items?.length
    },

    UPDATE_GL_ACCOUNTS(state, payload) {
        state.glAccounts.push(payload)
    },

    UPDATE_AMOUNT_COLLECTED(state, payload) {
        state.amountCollected += parseFloat(payload)
    },

    UPDATE_AMOUNT_REMAINING(state) {
        state.amountRemaining = state.amount - state.amountCollected
    },

    GET_AMOUNT_REMAINING(state) {
        state.amountRemaining = state.amount - state.amountCollected
        state.amountToPay = state.amountRemaining
    },

    UPDATE_PAYMENT_OPTIONS(state, payload) {
        state.amountRemaining += parseFloat(payload.amount)
        state.amountToPay = state.amountRemaining
        state.amountCollected -= parseFloat(payload.amount)

        state.glAccounts.splice(payload.index, 1)
    },

    UPDATE_POS_ITEMS(state, payload) {
        const existingIndex = state.items.findIndex(
            (item) => item.id === payload._id
        )
        if (existingIndex >= 0) {
            state.items[existingIndex] = {
                ...state.items[existingIndex],
                quantity: state.items[existingIndex].quantity + 1,
            }
        } else {
            const item = {
                id: payload._id,
                sku: payload?.sku,
                name: payload.name,
                price: payload.sellingPrice,
                oldPrice: payload.sellingPrice,
                quantity: 1,
                measurement: payload.measurement, // added measurement to the payload
                discount: 0,
                note: '',
                total: payload.sellingPrice,
                instock: payload?.qty_in_stock || 0,
                type: 'PRODUCT'
            }
            state.items.push(item);
        }
    },

    SET_CUSTOMER(state, payload) {
      let entityFullName = payload?.firstName || payload.name
      entityFullName = payload?.lastName ? `${entityFullName} ${payload.lastName}` : entityFullName

        console.log("entityFullName", entityFullName)
        state.customer.id = payload._id
        state.customer.name = entityFullName
        state.customer.address = payload.address1
        state.customer.city = payload.city
        state.customer.state = payload.state
        state.customer.zip = payload.zip
        state.customer.country = payload.country
        state.customer.phone = payload.phone
      state.customer.email = payload.email

      console.log("SET_CUSTOMER", state.customer)
    },

    POS_SET_ITEM(state, payload) {
        state.items = payload
    },

    POS_ITEM_CHANGE(state, payload) {
        const { index, field } = payload

        if (field === 'price') {
            if (parseFloat(state.items[index].price) !== parseFloat(state.items[index].oldPrice)) {
                const priceChange = state.items[index].oldPrice - state.items[index].price
                state.items[index].discount = parseFloat((priceChange / state.items[index].oldPrice) * 100).toFixed(2)
            } else {
                state.items[index].discount = ''
            }
        }

        if (field === 'discount') {
            state.items[index].price = state.items[index].oldPrice - ((state.items[index].discount / 100) * state.items[index].oldPrice).toFixed(2)
        }

        const itemAmount = state.items[index].price * state.items[index].quantity
        state.items[index] = {
            ...state.items[index],
            total: itemAmount
        }
    },

    POS_TAX_CHANGE(state, payload) {
        state.tax = payload
    },

    POS_DISCOUNT_CHANGE(state, payload) {
        state.discount = payload
    },

    POS_DISCOUNT_TYPE_CHANGE(state, payload) {
        state.discountType = payload
    },

    REMOVE_POS_ITEM(state, payload) {
        state.items.splice(payload, 1)
    },

    CLEAR_POS_ITEMS(state) {
        state.isLoading = false
        state.sales = []
        state.items = []
        state.customer = {
            id: '',
            name: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            vat_number: '',
            tax_number: '',
            website: ''
        }
        state.totalItems = 0
        state.subtotal = 0
        state.discount = 0
        state.tax = 0
        state.amount = 0
        state.glAccounts = []
        state.amountCollected = 0
        state.amountRemaining = 0
        state.amountToPay = 0
    },

    GET_POS_TOTALS(state) {
        const { subtotal, quantity } = state.items.reduce(
            (totals, item) => {
                const { oldPrice, price, quantity, discount } = item
                const itemAmount = price * quantity
                // const itemAmount = oldPrice * quantity
                // const itemTotal = itemAmount - ((discount / 100) * itemAmount)
                const itemTotal = itemAmount

                totals.subtotal += itemTotal
                totals.quantity += quantity

                return totals;
            },
            {
                subtotal: 0,
                quantity: 0,
            }
        );
        state.totalItems = quantity;
        state.subtotal = subtotal;

        const discountedCost = (state.discountType === 'percent')
            ? subtotal - ((state.discount / 100) * subtotal)
            : subtotal - state.discount

        const tax = (state.tax / 100) * discountedCost
        state.amount = discountedCost + tax;
    },

    SET_LATEST_REGISTER(state, payload) {
        state.latestRegister = payload
        const paymentSummary = payload?.paymentSummary ? payload.paymentSummary : []
        const index = paymentSummary.findIndex(
            (item) => item.glAccount === 'Cash'
        )

        if (index >= 0) {
            state.cashPaymentsReceived = paymentSummary[index]
            state.cashPaymentsReceived.counted = state.cashPaymentsReceived?.counted || 0
        }

        state.bankPaymentsReceived = paymentSummary.filter(function (item) {
            item.counted = item?.counted || 0
            return item.glAccount !== 'Cash'
        })

        const bankPaymentsReceived = state.bankPaymentsReceived.reduce((prevVal, currValue) => {
            prevVal += parseFloat(currValue.glTotalSale)
            return prevVal
        }, 0)

        state.latestRegisterTotals = parseFloat(state.latestRegister?.openingFloat) + parseFloat(state.cashPaymentsReceived?.glTotalSale || 0) + parseFloat(bankPaymentsReceived)

        const bankPaymentsCounted = state.bankPaymentsReceived.reduce((prevVal, currValue) => {
            prevVal += parseFloat(currValue?.counted)
            return prevVal
        }, 0)

        state.latestPaymentsCounted = parseFloat(state.cashPaymentsReceived?.counted || 0) + parseFloat(bankPaymentsCounted)
    },

    SET_ACTIVE_REGISTER(state, payload) {
        state.activeRegister = payload
        const paymentSummary = payload?.paymentSummary ? payload.paymentSummary : []
        const index = paymentSummary.findIndex(
            (item) => item.glAccount === 'Cash'
        )

        if (index >= 0) {
            state.activeCashPaymentsReceived = paymentSummary[index]
            state.activeCashPaymentsReceived.counted = state.activeCashPaymentsReceived?.counted || 0
        }

        state.activeBankPaymentsReceived = paymentSummary.filter(function (item) {
            item.counted = item?.counted || 0
            return item.glAccount !== 'Cash'
        })

        const activeBankPaymentsReceived = state.activeBankPaymentsReceived.reduce((prevVal, currValue) => {
            prevVal += parseFloat(currValue.glTotalSale)
            return prevVal
        }, 0)

        state.activeRegisterTotals = parseFloat(state.activeRegister?.openingFloat) + parseFloat(state.activeCashPaymentsReceived?.glTotalSale || 0) + parseFloat(activeBankPaymentsReceived)
    },

    SET_PAYMENTS_COUNTED(state) {
        const activeBankPaymentsCounted = state.activeBankPaymentsReceived.reduce((prevVal, currValue) => {
            prevVal += parseFloat(currValue?.counted)
            return prevVal
        }, 0)

        state.activePaymentsCounted = parseFloat(state.activeCashPaymentsReceived?.counted || 0) + parseFloat(activeBankPaymentsCounted)
    },

    RESET_ACTIVE_PAYMENT_SUMMARY(state) {
        state.activeCashPaymentsReceived = {}
        state.activeBankPaymentsReceived = []
    },
}

const actions = {
    setPosAccess: ({ commit }, payload) => {
        commit('SET_POS_ACCESS', payload)
    },

    showRetrieveSalesModal: ({ commit }, payload) => {
        commit('SET_SHOW_RETRIEVE_SALE_MODAL', payload)
    },

    showPaySalesModal: ({ commit }, payload) => {
        commit('SET_SHOW_PAY_SALE_MODAL', payload)
    },

    updateGlAccounts: ({ commit }, payload) => {
        commit('UPDATE_GL_ACCOUNTS', payload)
        commit('UPDATE_AMOUNT_COLLECTED', payload?.glAmount)
        commit('UPDATE_AMOUNT_REMAINING')
    },

    getAmountRemaining: ({ commit }) => {
        commit('GET_AMOUNT_REMAINING')
    },

    removePaymentOption: ({ commit }, payload) => {
        commit('UPDATE_PAYMENT_OPTIONS', payload)
    },

    getProducts: ({ commit }) => {
        return Axios.get(`/api/v1/products/recent`)
            .then(({ data }) => {
                // console.log('getRecentProducts: ', data?.products)
                commit('SET_PRODUCTS', data?.products)
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
            })
    },

    updateProducts: ({ commit }, payload) => {
        commit('UPDATE_PRODUCTS', payload)
    },

    getProductsEntryBySearch({ commit }, payload) {
        // check for asset payload
        if (!payload.type === 'asset_product') payload.assetCategory = 'product'
        const url = (payload.query.trim().length > 0)
            ? `/api/v1/inventories/s/${payload.query}/${payload.type}/${payload.assetCategory}`
            : `/api/v1/products/recent`

        Axios.get(url)
            .then(({ data }) => {
                commit('SET_PRODUCTS', data?.products)
            })
            .catch(err => console.error(err.response.data))
    },

    addPosItem({ commit }, payload) {
        commit('UPDATE_POS_ITEMS', payload)
        const index = state.items.findIndex(
            (item) => item.id === payload._id
        )

        if (index >= 0) commit('POS_ITEM_CHANGE', { index, field: null })
        commit('GET_POS_TOTALS')
    },

    setCustomer({ commit }, payload) {
        commit('SET_CUSTOMER', payload)
    },

    itemChange({ commit }, payload) {
        commit('POS_ITEM_CHANGE', payload)
        commit('GET_POS_TOTALS')
    },

    setPosItem({ commit }, payload) {
        commit('POS_SET_ITEM', payload)
        commit('GET_POS_TOTALS')
    },

    taxChange({ commit }, payload) {
        commit('POS_TAX_CHANGE', payload)
        commit('GET_POS_TOTALS')
    },

    discountChange({ commit }, payload) {
        commit('POS_DISCOUNT_CHANGE', payload)
        commit('GET_POS_TOTALS')
    },

    discountTypeChange({ commit }, payload) {
        commit('POS_DISCOUNT_TYPE_CHANGE', payload)
        commit('GET_POS_TOTALS')
    },

    removePosItem({ commit }, payload) {
        commit('REMOVE_POS_ITEM', payload)
        commit('GET_POS_TOTALS')
    },

    removeAllPosItems({ commit }) {
        commit('CLEAR_POS_ITEMS')
    },

    createSale: async ({ commit }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/pos/create`, payload)
            // console.log(data, 'data');
            commit('SET_LOADING_STATE', false)
            commit('CLEAR_POS_ITEMS')
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            return data
        } catch (error) {
            const { data } = error.response
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            console.error(data)
            return data
        }
    },

    createSaleNew: async ({ commit }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/pos/create/new`, payload)
            // console.log(data, 'data');
            commit('SET_LOADING_STATE', false)
            commit('CLEAR_POS_ITEMS')
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            return data
        } catch (error) {
            const { data } = error.response
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            console.error(data)
            return data
        }
    },

    getSalesHistory: ({ commit }, payload) => {
        console.log("payloaad", payload)
        return Axios.get(`/api/v1/pos/sales/history?${paginationOptions(payload)}`)
            .then(({ data }) => {
                console.log("payloaad data", data)
                commit('SET_POS_SALES_HISTORY', data?.data)
                return data?.data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
            })
    },

    getSaleHistory: ({ commit }, payload) => {
        return Axios.get(`/api/v1/pos/sales/${payload.id}/history?business=${payload.business}`)
            .then(({ data }) => {
                commit('SET_POS_SALE_HISTORY', data?.data)
                return data?.data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
            })
    },

    getParkedSales: ({ commit }) => {
        return Axios.get(`/api/v1/pos/sales/parked`)
            .then(({ data }) => {
                const sale = data?.data
                sale.id = sale._id
                commit('SET_PARKED_SALES', sale)
                return sale
            })
            .catch(async err => {
                const { data } = err.response
                console.error(data)
                return []
            })
    },

    retrieveSale: ({ commit }, payload) => {
        return Axios.get(`/api/v1/pos/sales/${payload}/retrieve`)
            .then(async ({ data }) => {
                // console.log('SALE: ', data?.data)
                commit('SET_PARKED_SALE', data?.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data?.data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
            })
    },

    deleteParkedSale: ({ dispatch }, payload) => {
        return Axios.delete(`/api/v1/pos/sales/${payload}`)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data?.data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
            })
    },

    getLatestRegister: ({ commit }) => {
        Axios.get(`/api/v1/pos/latest-register`)
            .then(({ data }) => {
                commit('SET_LATEST_REGISTER', data?.data)
            })
            .catch(async err => {
                console.error(err)
            })
    },

    getActiveRegister: ({ commit }) => {
        Axios.get(`/api/v1/pos/active-register`)
            .then(({ data }) => {
                commit('SET_ACTIVE_REGISTER', data?.data)
            })
            .catch(async err => {
                console.error(err)
            })
    },


    createRegister: async ({ commit, dispatch }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/pos/register`, payload)
            commit('SET_LOADING_STATE', false)
            dispatch('getLatestRegister')
            dispatch('getActiveRegister')
            // await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            return data
        } catch (error) {
            const { data } = error.response
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            console.error(data)
            return data
        }
    },

    closeRegister: async ({ commit, dispatch }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.put(`/api/v1/pos/register`, payload)
            commit('RESET_ACTIVE_PAYMENT_SUMMARY')
            dispatch('getLatestRegister')
            dispatch('getActiveRegister')
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            return data
        } catch (error) {
            const { data } = error.response
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            console.error(data)
            return data
        }
    },

    updateRegisterPaymentsCounted: async ({ commit }) => {
        commit('SET_PAYMENTS_COUNTED')
    },
}

export const PosModule = { namespaced: true, actions, mutations, state }
