<!-- eslint-disable -->
<template>
  <div class="flex items-center justify-center" style="background-image: url(); background-size: cover; height: 100vh">
    <Alert />
    <div class="w-50-l w-90">
      <!-- Simplebks logo -->
      <div class="tc pb4">
        <img src="../../assets/images/simplebks-logo.png" style="width: 180px" alt="simplebks-logo" />
      </div>
      <!-- Simplebks logo -->

      <!-- login/Input -->
      <div style="background-color: white">
        <form class="center pa5-l pa3" id="form" @submit.prevent="onSubmit">
          <div class="flex flex-column pb3">
            <label class="pb2">New Password</label>
            <input
              v-model="state.password"
              class="form-sign"
              type="password"
              name="password"
              placeholder="Enter password"
              id="password"
              required
            />
          </div>

          <div class="flex flex-column pb3">
            <label class="pb2">Confirm Password</label>
            <input
              v-model="state.confirmPassword"
              class="form-sign"
              type="password"
              name="password"
              placeholder="Enter password"
              id="confirmPassword"
              required
            />
          </div>

          <div v-if="message" class="flex justify-center items-center w-100">
            <span style="color: red; font-weight: 700">
              {{ message }}
            </span>
          </div>

          <button class="mt3 w-100 submit-btn" type="submit" :disabled="state.disableSubmitButton">
            {{ state.disableSubmitButton ? 'PROCESSING...' : 'RESET PASSWORD' }}
          </button>
          <!--          <div class="tc mt3">-->
          <!--            Don't have account? <router-link :to="{ name: 'SignUp' }"><b>Register</b></router-link>-->
          <!--          </div>-->
        </form>
      </div>
      <!-- login/Input -->
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { computed, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Alert from '@/components/Alert'

export default {
    name: 'ResetPassword',
    components: { Alert },

    setup() {
        const store = useStore()
        const route = useRoute()
        const message = computed(() => '')
        const { email, token } = route.query
        const state = reactive({
          disableSubmitButton: false,
            resetToken: '',
            password: '',
            confirmPassword: '',
        })

        const onSubmit = () => {
            state.disableSubmitButton = true
            state.resetToken = token

            const { disableSubmitButton, ...rest } = state

            // console.log(rest)

            store.dispatch('Auth/resetPassword', { rest, email }).then((result) => {
                if (result?.success) {
                    state.disableSubmitButton = false
                } else {
                    state.disableSubmitButton = false
                }
            })
        }

        onMounted(() => {
            const { email } = route.query
            store.dispatch('Auth/getResetPassword', email)
        })

        return { state, message, onSubmit }
    },
}
</script>

<style scoped></style>
