<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-100 center pt0-l mini-spacing">
                <div class="w-90 pv2 center box-border" style="background: white">
                    <div class="flex justify-between pa3">
                        <div></div>
                        <div></div>
                    </div>
                    <form class="entity-form">
                        <div class="box-border-bottom f3 pa3 b">Asset</div>
                        <div class="pa3">
                            <div class="flex flex-wrap justify-between pt2">
                                <div class="w-50-l w-100 pr2-l">
                                    <div class="pv2 font-w1">
                                        <label for="assetName">Asset Name</label>
                                    </div>
                                    <input type="text" id="assetName" name="assetName" placeholder="Benz GLK" />
                                </div>
                                <div class="w-50-l w-100 pl2-l">
                                    <div class="pv2 font-w1">
                                        <label for="assetTag">Asset Tag</label>
                                    </div>
                                    <input required type="text" id="assetTag" name="assetTag" placeholder="A-1234" />
                                </div>
                            </div>

                            <div class="flex flex-wrap justify-between pt2">
                                <div class="w-50-l w-100 pr2-l">
                                    <div class="pv2 font-w1">
                                        <label for="serialNumber">Serial Number</label>
                                    </div>
                                    <input type="text" id="serialNumber" name="serialNumber" placeholder="0123ABC" />
                                </div>
                                <div class="w-100 w-50-l pl2-l">
                                    <div class="pv2 font-w1"><label for="name">Condition</label></div>
                                    <select class="w-100" v-tippy="{ content: '', placement: 'top', interactive: true }">
                                        <option value="Brand New">Brand New</option>
                                        <option value="Used">Used</option>
                                    </select>
                                </div>
                            </div>

                            <div class="flex flex-wrap justify-between">
                                <div class="pt2 w-100 w-50-l pr2-l">
                                    <div class="pv2 font-w1"><label for="name">Group</label></div>
                                    <select class="w-100" v-tippy="{ content: '', placement: 'top', interactive: true }">
                                        <option value="Machinery and Equpment">Machinery and Equpment</option>
                                        <option value="Licensed Vehicle">Licensed Vehicle</option>
                                    </select>
                                </div>

                                <div class="pt2 w-100 w-50-l pl2-l">
                                    <div class="pv2 font-w1"><label for="name">Category</label></div>
                                    <select class="w-100" v-tippy="{ content: '', placement: 'top', interactive: true }">
                                        <option value="Books and Multimedia Materials">Books and Multimedia Materials</option>
                                        <option value="Computer Equipment">Computer Equipment</option>
                                        <option value="Science and Engineering Equipment">
                                            Science and Engineering Equipment
                                        </option>
                                        <option value="Audiovisual EquipMent">Audiovisual EquipMent</option>
                                        <option value="Athletic Equipment">Athletic Equipment</option>
                                        <option value="Grounds and Maintenance Equipment">
                                            Grounds and Maintenance Equipment
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div
                                class="flex flex-wrap justify-between items-center pt3"
                                @drop.prevent
                                @dragover.prevent
                                @dragenter.prevent
                            >
                                <div class="w-20-l w-90 pb3 b f4 pb0-l box-border1 br-100 overflow-hidden">
                                    <img
                                        :src="require('@/assets/images/inventory-default.png')"
                                        alt="Org Image"
                                        class="h-90 w-90 pointer"
                                        style="object-position: center; object-fit: cover"
                                    />
                                </div>
                                <div class="box-border pa4 w-80-l w-90">
                                    <span class="dn">
                                        <input ref="imageElRef" type="file" name="imageFile" id="imageFile" accept="image/*" />
                                    </span>
                                    <span class="font-w1 lh-copy pointer" style="color: #132c8c">Upload </span>
                                    or drag and drop your company logo PNG, JPG, GIF up to 10MB
                                </div>
                            </div>
                            <div class="pt2">
                                <div class="pv2 font-w1">
                                    <label>Description</label>
                                </div>

                                <textarea
                                    placeholder="Type here"
                                    name="description"
                                    id="description"
                                    cols="20"
                                    rows="5"
                                ></textarea>
                            </div>
                            <div class="flex flex-wrap justify-between pt2">
                                <div class="w-50-l w-100 pr2-l">
                                    <div class="pv2 font-w1">
                                        <label for="manufacturer">Manufacturer </label>
                                    </div>
                                    <input type="text" id="manufacturer" name="manufacturer" placeholder="Mercedes Benz" />
                                </div>
                                <div class="w-50-l w-100 pl2-l">
                                    <div class="pv2 font-w1">
                                        <label for="model"> Model </label>
                                    </div>
                                    <input type="text" id="model" name="model" placeholder="GLK" />
                                </div>
                            </div>

                            <div class="flex flex-wrap justify-between pt2">
                                <div class="w-50-l w-100 pr2-l">
                                    <div class="pv2 font-w1">
                                        <label for="usefulLife">Useful Life</label>
                                    </div>
                                    <input type="text" id="usefulLife" name="usefulLife" value="3" />
                                </div>
                                <div class="w-50-l w-100 pl2-l">
                                    <div class="pv2 font-w1">
                                        <label for="salvageValue">Salvage Value</label>
                                    </div>
                                    <input type="text" id="salvageValue" name="salvageValue" placeholder="0" />
                                </div>
                            </div>

                            <div class="flex flex-wrap justify-between pt2">
                                <div class="w-50-l w-100 pr2-l">
                                    <div class="pv2 font-w1">
                                        <label for="model"> Purchase price </label>
                                    </div>
                                    <input type="text" id="model" name="purchasePrice" placeholder="0" />
                                </div>
                                <div class="w-50-l w-100 pl2-l">
                                    <div class="pv2 font-w1">
                                        <label for="forceSale">Force Sale Value</label>
                                    </div>
                                    <input type="text" id="forceSale" name="forceSale" placeholder="25,000" />
                                </div>
                            </div>
                            <div class="flex flex-wrap" style="gap: 1rem">
                                <button class="mt3" type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import AppWrapper from '../../layout/AppWrapper.vue'
export default {
    name: 'EditAsset',
    components: { AppWrapper },
    setup() {
        return {}
    },
}
</script>
