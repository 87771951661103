<template>
    <app-wrapper>
        <template v-slot:child>
            <GetStarted />
            <VOnboardingWrapper ref="wrapper" :steps="steps" />
            <div v-if="plan?.businessPlan === 'Micro Business Plan'">
                <guest-dashboard v-cloak class="v-cloak--hidden" />
            </div>
            <div v-else-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(150)">
                <!--Greeting, stats, overview -->

                <div class="flex flex-wrap justify-between items-center w-90 center pv2">
                    <div class="pb3">
                        <p class="pb2 font-w2 f3">Hi, {{ userData?.firstName || userData?.user.split(' ')[0] || '' }}</p>
                        <p>Here's a quick overview of {{ userData?.org || '' }}.</p>
                    </div>

                    <div class="toDoWrapper">
                        <!-- <div class="flex justify-end" style="margin: 20px 0; " id="toDo"> <DashboardCTA class="ctaMobile"/></div> -->
                        <div class="flex justify-between items-center scrollmenu">
                            <form id="filter-dashbord" action="" class="mr2">
                                <select
                                    name="stats"
                                    id="stats"
                                    style="padding: 13px 0; width: 100%"
                                    v-model="state.period"
                                    @change.prevent="onChangeStatsFilter"
                                >
                                    <option value="today">Show stats: Today</option>
                                    <option value="thisWeek">Show stats: This Week</option>
                                    <option value="thisMonth">Show stats: This Month</option>
                                    <option value="thisYear">Show stats: This Year</option>
                                </select>
                                <!-- &nbsp; -->

                                <!--            <select name="stats" id="stats" style="padding: 5px">-->
                                <!--              <option value="">Overview: Bob Wear..</option>-->
                                <!--              <option value="">Overview: Bob Orders</option>-->
                                <!--            </select>-->
                            </form>
                            <div>
                                <select
                                    v-if="state.hasMultiBusiness"
                                    v-model="state.business"
                                    name="business"
                                    id="business"
                                    style="padding: 13px 0; width: 100%"
                                >
                                    <option value="all">All</option>
                                    <option v-for="business in state.businesses" :key="business._id" :value="business.org">
                                        {{ business.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Greeting, stats, overview -->

                <!--  total revenue, total expenses, inventory, profitability & account balance -->
                <div id="dashboard-stats" class="w-90 center flex flex-wrap pt2">
                    <!-- total revenue -->
                    <div
                        class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text"
                        @click="clickableCardTotalRevenue"
                        style="cursor: pointer"
                    >
                        <div class="flex items-center">
                            <img :src="require('@/assets/images/revenue.png')" alt="" />
                            <div class="pl2 font-w1">
                                Total Revenue
                                <span>
                                    <font-awesome-icon
                                        icon="fa-solid fa-question-circle"
                                        class="dashboard-icon-modal"
                                        @mouseover="revenueShowModal = true"
                                        @mouseleave="revenueShowModal = false"
                                    />
                                </span>
                                <!-- Popup modal-->
                                <div class="dashboard-inquiry-modal" v-if="revenueShowModal">
                                    This is the sum of all income generated by your business over a specific period. This includes
                                    revenue from sales, services, investments, and other income sources.
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center pt3">
                            <!--                        <div class="flex items-center">-->
                            <!--                            <img src="../../assets/images/increase.svg" alt="" />-->
                            <!--                            <p class="pl2">21.9%</p>-->
                            <!--                        </div>-->
                            <!--                        <div class="pl2">+$67k today</div>-->
                        </div>
                        <div style="color: #132c8c">
                            <h3 class="mb0">{{ formatAmount(summary?.totalRevenue || 0, $store.state.Settings?.currency) }}</h3>
                        </div>
                    </div>

                    <!-- total revenue -->

                    <!-- total expenses -->
                    <div
                        class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text"
                        @click="clickableCardTotalExpenses"
                        style="cursor: pointer"
                    >
                        <div class="flex items-center">
                            <!-- <img src="../../assets/images/expense.svg" alt="" /> -->
                            <img :src="require('@/assets/images/expense.svg')" alt="" />
                            <div class="pl2 font-w1">
                                Total Expenses
                                <span>
                                    <font-awesome-icon
                                        icon="fa-solid fa-question-circle"
                                        class="dashboard-icon-modal"
                                        @mouseover="expenseShowModal = true"
                                        @mouseleave="expenseShowModal = false"
                                    />
                                </span>
                                <!-- Popup modal-->
                                <div class="dashboard-inquiry-modal" v-if="expenseShowModal">
                                    This refers to the total amount of money your business spends over the specified period,
                                    including all costs and expenses incurred to generate your total revenue
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center pt3">
                            <!--                        <div class="flex items-center">-->
                            <!--                            <img src="../../assets/images/increase.svg" alt="" />-->
                            <!--                            <p class="pl2">21.9%</p>-->
                            <!--                        </div>-->
                            <!--                        <div class="pl2">+$67k today</div>-->
                        </div>
                        <div style="color: #132c8c">
                            <h3 class="mb0">{{ formatAmount(summary?.totalExpense || 0, $store.state.Settings?.currency) }}</h3>
                        </div>
                    </div>

                    <!-- total expenses -->

                    <!-- inventory value -->
                    <div
                        class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text"
                        @click="clickableCardInventory"
                        style="cursor: pointer"
                    >
                        <div class="flex items-center">
                            <!-- <img src="../../assets/images/value.svg" alt="" /> -->
                            <img :src="require('@/assets/images/value.svg')" alt="" />
                            <div class="pl2 font-w1">
                                Inventory
                                <span>
                                    <font-awesome-icon
                                        icon="fa-solid fa-question-circle"
                                        class="dashboard-icon-modal"
                                        @mouseover="inventoryShowModal = true"
                                        @mouseleave="inventoryShowModal = false"
                                    />
                                </span>
                                <!-- Popup modal-->
                                <div class="dashboard-inquiry-modal" v-if="inventoryShowModal">
                                    This is the total amount of goods/products or services your business has in stock and
                                    available for sale to customers.
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center pt3">
                            <!--                        <div class="flex items-center">-->
                            <!--                            <img src="../../assets/images/increase.svg" alt="" />-->
                            <!--                            <p class="pl2">21.9%</p>-->
                            <!--                        </div>-->
                            <!--                        <div class="pl2">+$67k today</div>-->
                        </div>
                        <div style="color: #132c8c">
                            <h3 class="mb0">{{ formatAmount(summary?.totalInventory || 0, $store.state.Settings?.currency) }}</h3>
                        </div>
                    </div>
                    <!-- inventory value -->

                    <!-- profitability -->
                    <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text" @click="clickableCardPL" style="cursor: pointer">
                        <div class="flex items-center">
                            <img :src="require('@/assets/images/profit.svg')" alt="" />
                            <div class="pl2 font-w1">
                                {{ summary?.profitability > 0 ? 'Net Profit' : 'Net Loss' }}
                                <span>
                                    <font-awesome-icon
                                        icon="fa-solid fa-question-circle"
                                        class="dashboard-icon-modal"
                                        @mouseover="netPLShowModal = true"
                                        @mouseleave="netPLShowModal = false"
                                    />
                                </span>
                                <!-- Popup modal-->
                                <div class="dashboard-inquiry-modal" v-if="netPLShowModal">
                                    This is when the total expenses of your business exceed your total revenue, for the specified
                                    period.
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center pt3">
                            <!--                        <div class="flex items-center">-->
                            <!--                            <img src="../../assets/images/desend.svg" alt="" />-->
                            <!--                            <p class="pl2">21.9%</p>-->
                            <!--                        </div>-->
                            <!--                        <div class="pl2">+$67k today</div>-->
                        </div>
                        <div style="color: #132c8c">
                            <h3 class="mb0" v-if="role === 'owner' || role === 'admin'">
                                {{ formatAmount(summary?.profitability || 0, $store.state.Settings?.currency) }}
                            </h3>
                            <h3 class="mb0" v-else>
                                {{ formatAmount(summary?.profitability || 0, $store.state.Settings?.currency) }}
                            </h3>
                        </div>
                    </div>
                    <!-- profitability -->

                    <!-- account balance -->
                    <div
                        class="pv4 pl3-l pl2 w-20-l w-100 box-border1 adj-text"
                        @click="clickableCardOutstandingDebt"
                        style="cursor: pointer"
                    >
                        <div class="flex items-center">
                            <img :src="require('@/assets/images/account.svg')" alt="account" />
                            <div class="pl2 font-w1">
                                Outstanding debts
                                <span>
                                    <font-awesome-icon
                                        icon="fa-solid fa-question-circle"
                                        class="dashboard-icon-modal"
                                        @mouseover="debtShowModal = true"
                                        @mouseleave="debtShowModal = false"
                                    />
                                </span>
                                <div class="outstanding-modal" v-if="debtShowModal">
                                    This is the total amount of money owed to your business by your customers.
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center pt3">
                            <!--                        <div class="flex items-center">-->
                            <!--                            <img src="../../assets/images/desend.svg" alt="" />-->
                            <!--                            <p class="pl2">21.9%</p>-->
                            <!--                        </div>-->
                            <!--                        <div class="pl2">+$67k today</div>-->
                        </div>
                        <div style="color: #132c8c">
                            <h3 class="mb0">
                                {{ formatAmount(stats?.receivablesAmount || 0, $store.state.Settings?.currency) }}
                            </h3>
                        </div>
                    </div>
                    <!-- account balance -->
                </div>

                <!--  Old-->

                <div class="w-100 center flex flex-wrap pt4">
                    <!-- &lt;!&ndash; TODO: Graphs&ndash;&gt; -->
                    <!-- <div class="pt4">
                        <div class="w-90 center">
                            <div class="flex justify-between items-center pa3 box-border">
                                <p class="b">Revenue Graph</p>
                                <p>
                                    <select name="stats" id="stats" style="padding: 5px">
                                        <option value="">Jan2021 - Mar 2021</option>
                                        <option value="">Jan2020 - Mar 2020</option>
                                    </select>
                                </p>
                            </div>
                        </div>
                    </div> -->

                    <div id="revenue-graph" class="w-90 center" ref="graphTypeRef" v-click-outside="closeDropDown">
                        <div class="flex items-center justify-between pa3 box-border1 mbRev">
                            <div class="w-30-l w-100 b">Revenue Graph</div>

                            <div class="c mb-rv-flex">
                                <div class="makeFlexResponsible mb-sales-daily mb3">
                                    <div class="mt3">
                                        <div>
                                            <span @click="showDropdown">
                                                <div
                                                    class="box-border flex justify-between pa2"
                                                    style="box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12); padding: 13px 2px"
                                                >
                                                    <div class="pr2 dashboradDropDown">{{ state.graphLabel }}</div>
                                                    <img src="../../assets/images/down-o.svg" alt="" />
                                                </div>
                                            </span>
                                        </div>
                                        <div
                                            id="mydropdown"
                                            v-if="state.showDropdown"
                                            class="dashboradDropDown"
                                            style="background: #eef0f5; z-index: 20"
                                        >
                                            <span class="flex pa2">
                                                <input type="checkbox" :value="salesType" v-model="state.graphType" />
                                                <span class="pl2">Sales</span>
                                            </span>
                                            <span class="flex pa2">
                                                <input type="checkbox" :value="expensesType" v-model="state.graphType" />
                                                <span class="pl2">Expense</span>
                                            </span>
                                            <span class="flex pa2">
                                                <input type="checkbox" :value="purchasesType" v-model="state.graphType" />
                                                <span class="pl2">Purchases</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="dropdownx mt3 ml3 mr3 removeleftAndRightmargin">
                                        <span @click="handleReportDropdown">
                                            <div
                                                class="box-border flex justify-between pa2"
                                                style="box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12); padding: 13px 2px"
                                            >
                                                <div class="pr2 dashboradDropDown">{{ state.reportType }}</div>
                                                <img src="../../assets/images/down-o.svg" alt="" />
                                            </div>
                                        </span>
                                        <div id="mydropdown" v-if="state.showReportType" class="" style="background: #eef0f5">
                                            <span class="flex pa2 mb-daily">
                                                <input type="radio" id="daily" value="daily" v-model="state.reportType" />
                                                <label for="daily" class="pl2">Daily</label>
                                            </span>
                                            <span class="flex pa2">
                                                <input type="radio" id="monthly" value="monthly" v-model="state.reportType" />
                                                <label for="monthly" class="pl2">Monthly</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="makeFlexResponsible marginTopOnMobile mb-sdate-edate">
                  <div class="inputWrapper marginTopOnMobile dashboradDropDown2">
                    <label for="" style="font-size: 10.9px;">Start Date</label>
                    <input v-model="state.startDate" type="date" name="" class="input-style" />
                  </div>
                  <div class="inputWrapper ml3 removeleftAndRightmargin marginTopOnMobile dashboradDropDown2">
                    <label for="" style="font-size: 10.9px;">End Date</label>
                    <input v-model="state.endDate" type="date" name="" class="input-style" />
                  </div>
                </div> -->
                            </div>
                        </div>
                        <div class="pa3 flex justify-between flex-wrap box-border">
                            <div class="w-30-l w-100">
                                <p style="font-weight: 500">Daily summary</p>
                                <div class="w-100">
                                    <div class="w-100 pt4 flex justify-between">
                                        <p>Cash balance</p>
                                        <p>{{ formatAmount(state.cashBalance, $store.state.Settings?.currency) }}</p>
                                    </div>
                                    <div class="w-100 pt3 flex justify-between">
                                        <p>Today's sales</p>
                                        <p>{{ formatAmount(state.todaySales, $store.state.Settings?.currency) }}</p>
                                    </div>
                                    <div class="w-100 pt3 flex justify-between">
                                        <p>Today's expense</p>
                                        <p>{{ formatAmount(state.todayExpense, $store.state.Settings?.currency) }}</p>
                                    </div>
                                    <div class="w-100 pt3 flex justify-between">
                                        <p>Due Debt</p>
                                        <p>{{ formatAmount(state.OverdueDebt, $store.state.Settings?.currency) }}</p>
                                    </div>
                                    <div class="w-100 pt3 flex justify-between">
                                        <p>Due Credit</p>
                                        <p>{{ formatAmount(state.overDueCredit, $store.state.Settings?.currency) }}</p>
                                    </div>
                                </div>
                                <div class="position: relative">
                                    <!-- <div v-if="getStarted">
                                        <GetStarted :closeModal="closeGetStarted" />
                                    </div> -->
                                    <!-- <div @click="openGetStarted" class="pt2" style="margin-left: -13px; z-index; 20; position: absolute">
                                        <img src="../../assets/images/hand.svg" alt="" />
                                    </div> -->
                                </div>
                            </div>
                            <div v-if="loading">
                                <Spinner />
                            </div>

                            <div v-if="!loading" class="w-60-l w-100">
                                <div>
                                    <Bar
                                        :chart-options="chartOptions"
                                        :chart-data="lineData"
                                        :chart-id="lineId"
                                        :plugins="plugins"
                                        :css-classes="cssClasses"
                                        :styles="styles"
                                        :width="width"
                                        :height="height"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-between w-90 center">
                        <DashboardThingsToDo class="content-card" />
                        <div id="transaction-history" class="box-border content-card">
                            <div class="pa3 box-border-bottom">
                                <p class="b">Transaction History</p>
                            </div>

                            <div v-if="recentTransactions.length">
                                <div class="pa3" v-for="transaction of recentTransactions" v-bind:key="transaction.id">
                                    <div class="flex justify-between" style="border-bottom: 1px solid #e3e8ee">
                                        <div class="flex items-start pb2">
                                            <img src="../../assets/images/file.svg" alt="" />
                                            <div class="pl2">
                                                <p class="b pb1">{{ transaction.itemsNames || transaction.description }}</p>
                                                <p>{{ formatDateInLocal(transaction.date) }}({{ transaction.staffName }})</p>
                                            </div>
                                        </div>
                                        <div class="tr">
                                            <p class="b pb1">
                                                {{ formatAmount(transaction.amount, $store.state.Settings?.currency) }}
                                            </p>
                                            <p :class="getTransactionClass(transactionTypes[transaction.type])">
                                                {{ transactionTypes[transaction.type] || 'Others' }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="pa3 tc" style="border-top: 1px solid #e3e8ee">
                                    <router-link :to="{ name: 'Transactions' }">
                                        <p class="">View all transactions &#8594;</p>
                                    </router-link>
                                </div>
                            </div>

                            <TableEmptyState
                                v-else
                                :data-array="recentTransactions"
                                message="No transactions"
                                rout-name="AddTransaction"
                                route-text="+ Add one"
                            />
                        </div>
                    </div>

                    <div class="w-90 center flex flex-wrap justify-between pt3">
                        <div id="top-customers" class="top-customer-card box-border">
                            <div class="pa3 box-border-bottom">
                                <p class="b">Top Customers</p>
                            </div>
                            <div v-if="topCustomers.length">
                                <div class="pa3" v-for="customer of topCustomers" v-bind:key="customer._id">
                                    <div class="flex justify-between pb2" style="border-bottom: 1px solid #e3e8ee">
                                        <div>
                                            <div class="flex">
                                                <!-- <img src="@/assets/images/Avatar.svg" alt="" /> -->
                                                <div class="customerInitials" v-if="customer.firstName && customer.lastName">
                                                    <p style="text-transform: uppercase">
                                                        <span>{{ customer.firstName[0] }}</span
                                                        ><span>{{ customer.lastName[0] }}</span>
                                                    </p>
                                                </div>
                                                <div class="customerInitials" v-if="customer.firstName && !customer.lastName">
                                                    <p style="text-transform: uppercase">
                                                        <span>{{ customer.firstName[0] }}</span
                                                        ><span style="text-transform: uppercase">{{
                                                            customer.firstName[1]
                                                        }}</span>
                                                    </p>
                                                </div>
                                                <div class="customerInitials" v-if="!customer.firstName && !customer.lastName">
                                                    <p>TC</p>
                                                </div>
                                                <div class="pl2">
                                                    <p class="pb1 b hyperLink">
                                                        <a style="color: #132c8c" :href="'/entities/' + customer._id"
                                                            >{{ customer.firstName || 'Unknown' }} {{ customer.lastName }}</a
                                                        >
                                                    </p>
                                                    <p>Last Transition: {{ formatDateInLocal(customer.updatedAt) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>{{ formatAmount(customer.sales_amount, $store.state.Settings?.currency) }}</div>
                                    </div>
                                </div>
                                <div class="pa3 tc" style="border: 1px solid #e3e8ee">
                                    <router-link :to="{ name: 'Entities' }">
                                        <p class="">View all customers &#8594;</p>
                                    </router-link>
                                </div>
                            </div>

                            <TableEmptyState
                                v-else
                                :data-array="recentTransactions"
                                message="No top customer"
                                rout-name="AddTransaction"
                                route-text="+ Create transaction with a customer"
                            />
                        </div>
                        <div id="top-selling-products" class="top-selling-card box-border w-100 w-50-l scrollmenu">
                            <div class="pa3 box-border-bottom topCusScroll">
                                <p class="b">Top Selling Products</p>
                            </div>

                            <div v-if="topProducts.length">
                                <div class="pa3 topCusScroll">
                                    <div class="" style="border-bottom: 1px solid #e3e8ee">
                                        <div class="flex justify-between pb3">
                                            <div class="w-34 b">Items</div>
                                            <div class="b">Qty Sold</div>
                                            <div class="b">Avg Price</div>
                                            <div class="s b">Sales</div>
                                        </div>
                                    </div>
                                    <div
                                        v-for="product of topProducts"
                                        v-bind:key="product._id"
                                        class="pt3"
                                        style="border-bottom: 1px solid #e3e8ee"
                                    >
                                        <div class="flex justify-between items-center pb3">
                                            <div class="w-34 flex items-center">
                                                <!-- <img src="../../assets/images/picture1.jpg" class="" alt="" /> -->
                                                <p class="b hyperLink" style="width: 100px; text-align: left">
                                                    <a style="color: #132c8c" :href="'/inventories/product/' + product._id">{{
                                                        product.name
                                                    }}</a>
                                                </p>
                                            </div>
                                            <div class="" style="width: 100px; text-align: right">{{ product.sales_qty }}</div>
                                            <!-- <div class="flex">
                                                <img src="../../assets/images/increase.svg" alt="" />
                                                <p>5.7%</p>
                                            </div> -->
                                            <div class="" style="width: 100px; text-align: right">
                                                {{ formatAmount(product.sales_avg, $store.state.Settings?.currency) }}
                                            </div>
                                            <div class="" style="width: 80px; text-align: right">
                                                {{ formatAmount(product.sales_cost, $store.state.Settings?.currency) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pa3 tc w-150 w-100-l topCusScroll" style="border: 1px solid #e3e8ee">
                                    <router-link :to="{ name: 'Products' }">
                                        <p class="">View all products &#8594;</p>
                                    </router-link>
                                </div>
                            </div>

                            <TableEmptyState
                                v-else
                                :data-array="topProducts"
                                message="No product sold"
                                rout-name="AddTransaction"
                                route-text="+ Start selling"
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap justify-between w-90 center pt4 pb4">
                        <div id="top-expenses" class="box-border top-customer-card top-expense-cardx">
                            <div>
                                <div class="flex justify-between pa3 box-border-bottom">
                                    <p class="b w-40">Top Expenses</p>
                                </div>
                                <!-- <div class="pa3" v-for="expense of topExpenses" v-bind:key="expense.subtype">
                    <div class="flex justify-between pb3">
                        <p class="w-40">{{ expenseSidebarOptions[expense.subtype] }}</p>
                        <p>{{ formatAmount(expense.amount, $store.state.Settings?.currency) }}</p>
                    </div>
                </div> -->
                                <div v-if="pieChartSeries.length">
                                    <apexchart
                                        type="donut"
                                        width="380"
                                        :options="pieChartOptions"
                                        :series="pieChartSeries"
                                    ></apexchart>
                                </div>
                                <TableEmptyState
                                    v-else
                                    :data-array="pieChartSeries"
                                    message="No expenses recorded"
                                    rout-name="AddTransactionNew"
                                    route-text="+ Record one"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- &lt;!&ndash; revenue distribution & top expense &ndash;&gt; -->
                </div>
            </div>
            <div v-else>
                <guest-dashboard v-cloak class="v-cloak--hidden" />
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { computed, onMounted, reactive, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
import 'v-onboarding/dist/style.css'
import {
    formatAmount,
    formatDate,
    expenseSidebarOptions,
    getDaysDate,
    formatDateInLocal,
    transactionTypes,
    getDifferenceInDates,
    getDifferenceInDatesInDays,
    colorSelector,
} from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper'
import Spinner from '@/components/Spinner'
import GuestDashboard from '@/components/GuestDashboard'
// import DashboardCTA from '@/components/DashboardCTA'
import DashboardThingsToDo from '@/components/DashboardThingsToDo'
import TableEmptyState from '@/components/TableEmptyState'
import WebinarBanner from '@/components/WebinarBanner'
import VueApexCharts from 'vue3-apexcharts'
import { useRoute, useRouter } from 'vue-router'
import GetStarted from '@/components/GetStarted'

import { Bar, Line } from 'vue-chartjs'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Plugin,
    Filler,
} from 'chart.js'

ChartJS.register(Filler, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement)

export default {
    name: 'Dashboard',
    components: {
        AppWrapper,
        Spinner,
        Bar,
        Line,
        GuestDashboard,
        // DashboardCTA,
        DashboardThingsToDo,
        TableEmptyState,
        apexchart: VueApexCharts,
        VOnboardingWrapper,
        WebinarBanner,
        GetStarted,
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart',
        },
        lineId: {
            type: String,
            default: 'line-chart',
        },
        datasetIdKey: {
            type: String,
            default: 'label',
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 200,
        },
        cssClasses: {
            default: '',
            type: String,
        },
        styles: {
            type: Object,
            default: () => {},
        },
        plugins: {
            type: Object,
            default: () => {},
        },
    },
    setup() {
        const store = useStore()
        const graphTypeRef = ref(null)
        // const getStarted = ref(false)

        const summary = computed(() => store?.state.DashboardStats.summary)
        const userData = computed(() => store?.state?.Auth?.userData)
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const stats = computed(() => store?.state.PnRModule.stats)
        const overview = computed(() => store?.state.DashboardStats.overview)
        const overviewStat = computed(() => store?.state.DashboardStats.overviewstat)
        const orgData = computed(() => store?.state?.Auth?.userData)
        const plan = computed(() => store.state?.Settings?.paymentPlan)

        const route = useRoute()
        const router = useRouter()
        const dashboardTourStatus = computed(() => store.state?.Settings?.dashboardTourStatus)
        const wrapper = ref(null)
        const { start } = useVOnboarding(wrapper)
        const steps = [
            {
                attachTo: { element: '#dashboard-stats' },
                content: {
                    title: 'Stats',
                    description: 'View transactions for a particular period on the dashboard',
                },
            },
            {
                attachTo: { element: '#revenue-graph' },
                content: {
                    title: 'Revenue Graph',
                    description: 'Monitor your revenue periodically on Simplebks',
                },
            },
            {
                attachTo: { element: '#transaction-history' },
                content: {
                    title: 'Transaction History',
                    description: 'Get insight on your transaction history at a glance',
                },
            },
            {
                attachTo: { element: '#status-overview' },
                content: {
                    title: 'Status/Overview',
                    description: 'Here is an overview of what is happening with your business',
                },
            },
            {
                attachTo: { element: '#top-customers' },
                content: {
                    title: 'Top Customers',
                    description: 'Know your top customers at a glance',
                },
            },
            {
                attachTo: { element: '#top-selling-products' },
                content: {
                    title: 'Top Selling Products',
                    description: 'Get insight on your inventory by knowing your top selling products',
                },
            },
            {
                attachTo: { element: '#top-expenses' },
                content: {
                    title: 'Top Expenses',
                    description: 'Your top expenses in infographics',
                },
            },
            {
                attachTo: { element: '#filter-dashbord' },
                content: {
                    title: 'Filter',
                    description: 'View chart of selected transactions for a particular period',
                },
                on: {
                    afterStep: function () {
                        stopDashboardTour()
                    },
                },
            },
        ]

        const topCustomers = computed(() => store?.state.DashboardStats.topCustomers)
        const topProducts = computed(() => store?.state.DashboardStats.topProducts)
        const recentTransactions = computed(() => store?.state.DashboardStats.recentTransactions)
        const loading = computed(() => store?.state.DashboardStats.loading)
        const topExpenses = computed(() => store?.state.DashboardStats.topExpenses)
        const topEvents = computed(() => store?.state.DashboardStats.topEvents)
        const pieChartSeries = computed(() => topExpenses.value.map(({ amount }) => amount))
        const pieChartOptions = computed(() => {
            return {
                chart: {
                    width: '100%',
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270,
                    },
                },
                fill: {
                    type: 'gradient',
                },
                labels: topExpenses.value.map(({ subtype }) => expenseSidebarOptions[subtype]), // ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
                legend: {
                    position: 'bottom',
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return formatAmount(value, store.state.Settings?.currency)
                        },
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: '100%',
                            },
                            legend: {
                                position: 'bottom',
                            },
                        },
                    },
                ],
            }
        })

        // click to redirect from total Revenue to sales
        function clickableCardTotalRevenue() {
            router.push({ path: '/transactions', query: { limit: 25, type: 'sales' } })
        }
        // click to redirect from total Revenue to sales
        const clickableCardTotalExpenses = () => {
            router.push({ path: '/transactions', query: { limit: 25, type: 'expenses' } })
        }
        // click to redirect from inventory to inventory page
        const clickableCardInventory = () => {
            router.push({ path: '/inventories' })
        }
        // click to redirect from Net profit or loss to PL report
        const clickableCardPL = () => {
            router.push({ path: '/reports/profit-and-loss' })
        }
        // click to redirect from Outstandind debt to debit and creditors page
        const clickableCardOutstandingDebt = () => {
            router.push({ path: '/p_n_r' })
        }

        watch(
            () => overviewStat.value,
            (next, prev) => {
                state.cashBalance = next.cashBalance
                state.overDueCredit = next.creditDueDate
                state.OverdueDebt = next.debtDueDate
                state.todayExpense = next.todayExpense
                state.todaySales = next.todaySales
            }
        )
        //

        // modal for dashboard
        const revenueShowModal = ref(false)
        const expenseShowModal = ref(false)
        const netPLShowModal = ref(false)
        const debtShowModal = ref(false)
        const inventoryShowModal = ref(false)

        const salesType = { id: 0, value: 'Sales' }
        const expensesType = { id: 1, value: 'Expenses' }
        const purchasesType = { id: 2, value: 'Purchases' }

        const initialState = () => ({
            period: 'thisMonth',
            filterType: 'customrange',
            business: 'all',
            businesses: '',
            start: defaultStart,
            end: defaultEnd,
            startDate: defaultStart,
            endDate: defaultEnd,
            overviewData: {},
            cashBalance: 0,
            overDueCredit: 0,
            OverdueDebt: 0,
            todayExpense: 0,
            todayInflows: 0,
            todayOutFlows: 0,
            todayPurchase: 0,
            todaySales: 0,

            graphType: [salesType],
            graphLabel: 'Sales',
            numberOfDays: 12,
            showDropdown: false,
            showReportType: false,
            reportType: 'daily',
            initialOverviewData: {
                numberOfDays: 15, // limited the number of days on the graph to 15 days for daily report
                reportType: 'daily',
                startDate: new Date(year, month, day),
                endDate: new Date(),
            },
            hasMultiBusiness: false,
        })

        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()
        const day = today.getDate()

        const defaultStart = new Date(year, month, 1)
        const defaultEnd = new Date()

        const state = reactive(initialState({}))

        const getAllTransactions = () => store.dispatch('DashboardStats/getDashboardStats', state)
        const getGraphOverview = async (data) =>
            store.dispatch('DashboardStats/getGraphOverview', { data, business: state.business })
        const getTodayOverviewStat = async () => store.dispatch('DashboardStats/getTodayOverview', { business: state.business })
        const getRecentTransactions = async (limit) =>
            store.dispatch('DashboardStats/getRecentTransactions', { limit, business: state.business })
        const getTopCustomers = async (limit) =>
            store.dispatch('DashboardStats/getTopCustomers', { limit, business: state.business })
        const getTopExpenses = async (data) =>
            store.dispatch('DashboardStats/getTopExpenses', { ...data, business: state.business })
        const getTopEvents = async (limit) => store.dispatch('DashboardStats/getTopEvents', { limit, business: state.business })
        const getTopProducts = async (limit) =>
            store.dispatch('DashboardStats/getTopProducts', { limit, business: state.business })

        const onChangeStatsFilter = async () => {
            if (state.period === 'thisMonth') {
                state.numberOfDays = 30
                state.reportType = 'daily'
                state.start = new Date(year, month, 1)
                state.end = new Date()
            } else if (state.period === 'thisYear') {
                state.numberOfDays = 12
                state.reportType = 'monthly'
                state.start = new Date(year, 0, 1)
                state.end = new Date()
            } else if (state.period === 'thisWeek') {
                state.reportType = 'daily'
                state.numberOfDays = 7
                state.start = new Date(year, month, day - 7)
                state.end = new Date()
            } else if (state.period === 'today') {
                state.reportType = 'daily'
                state.numberOfDays = 1
                state.start = new Date(year, month, day)
                state.end = new Date()
            }
            const overviewData = {
                startDate: state.start,
                endDate: state.end,
                numberOfDays: state.numberOfDays,
                reportType: state.reportType,
            }
            await getGraphOverview(overviewData)
            await getTopExpenses({
                limit: 5,
                startDate: formatDate(state.start, 'YYYY-MM-DD'),
                endDate: formatDate(state.end, 'YYYY-MM-DD'),
            })
            return getAllTransactions()
        }

        const lineData = computed(() => state.overviewData)

        const showDropdown = () => {
            state.showDropdown = !state.showDropdown
        }

        const closeDropDown = () => {
            state.showDropdown = false
            state.showReportType = false
        }

        const handleReportDropdown = () => {
            state.showReportType = !state.showReportType
        }

        const handleOnClickOutsideDropdown = (e) => {
            // console.log(e.target, graphTypeRef.value, "onClickOutsideDropdown");
            // if(e.target)
            //     state.showDropdown = false
        }

        // const closeGetStarted = () => {
        //     getStarted.value = false
        // }
        // const openGetStarted = () => {
        //     getStarted.value = true
        // }

        watch(
            () => [overview.value, state.graphType],
            (prevValue, currValue) => {
                if (prevValue !== currValue) {
                    const dataLabels = Object.keys(overview.value.data)
                    const overviewValues = Object.values(overview.value.data)
                    const overviewDays = Object.values(overview.value.monthsLabel)
                    // const randomColor = () => Math.floor(Math.random()*16777215).toString(16);
                    state.graphLabel = state.graphType.map((acc) => acc.value).join(',')
                    const datasets = state.graphType.map((graphType) => {
                        // console.log('graph', graphType.id)

                        return {
                            label: dataLabels[graphType.id],
                            borderColor: [
                                graphType.id === 0
                                    ? '#3ae877'
                                    : graphType.id === 1
                                    ? '#eb3c0c'
                                    : graphType.id === 2
                                    ? '#fcca03'
                                    : '',
                            ],
                            // borderWidth: 1,
                            // fill: true,
                            // borderColor: 'rgb(75, 192, 192)',
                            // borderColor: dataLabels[('rgb(75, 192, 192)', '#AAB9F2', '#f8fafe')]
                            backgroundColor: [
                                graphType.id === 0
                                    ? '#3ae877'
                                    : graphType.id === 1
                                    ? '#eb3c0c'
                                    : graphType.id === 2
                                    ? '#fcca03'
                                    : '',
                            ],
                            // backgroundColor: colorSelector[graphType.id[0]('red'), graphType.id[1]('green'), graphType.id[2]('blue')],
                            // pointStyle: 'circle',
                            // pointRadius: 5,
                            // pointHoverRadius: 5,
                            // tension: 0.5,
                            // data: overviewValues[graphType.id]
                            label: dataLabels[graphType.id],
                            // backgroundColor: '#f8fafe',
                            // borderColor: colorSelector[graphType.id],
                            borderWidth: 1,
                            fill: true,
                            pointStyle: 'circle',
                            pointRadius: 5,
                            pointHoverRadius: 5,
                            tension: 0.5,
                            data: overviewValues[graphType.id],
                        }
                    })
                    state.overviewData = {
                        labels: overviewDays,
                        datasets,
                    }
                }
            }
        )

        watch(
            () => state.endDate,
            () => {
                // state.numberOfDays = getDifferenceInDates(state.startDate, state.endDate)
                if (state.reportType === 'monthly') {
                    state.numberOfDays = getDifferenceInDates(state.startDate, state.endDate)
                } else if (state.reportType === 'daily') {
                    state.numberOfDays = getDifferenceInDatesInDays(state.startDate, state.endDate)
                }
                const overviewData = {
                    startDate: state.startDate,
                    endDate: state.endDate,
                    numberOfDays: state.numberOfDays || 30,
                    reportType: state.numberOfDays === 30 ? 'daily' : state.reportType,
                }
                getGraphOverview(overviewData)
            }
        )
        watch(
            () => state.reportType,
            () => {
                if (state.reportType === 'monthly') {
                    state.numberOfDays = getDifferenceInDates(state.startDate, state.endDate)
                } else if (state.reportType === 'daily') {
                    ;(state.startDate = new Date(year, month, day)), (state.numberOfDays = 15)
                }
                const overviewData = {
                    startDate: state.startDate,
                    endDate: state.endDate,
                    numberOfDays: state.numberOfDays || 12, // limited the number of days on the graph to 12 months for monthly report
                    reportType: state.numberOfDays === 30 ? 'daily' : state.reportType,
                }

                if (!state.startDate || !state.endDate) {
                    state.initialOverviewData = {
                        ...state.initialOverviewData,
                        reportType: state.reportType,
                    }
                    //console.log(initialOverviewData, 'initialOverviewData')
                    getGraphOverview(state.initialOverviewData)
                }
                getGraphOverview(overviewData)
            }
        )

        watch(
            () => state.business,
            (prevValue, currValue) => {
                if (prevValue !== currValue) {
                    initDashboard(currValue)
                }
            }
        )

        const chartOptions = computed(() => {
            return {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            // fontColor: 'rgba(0, 0, 0, 0.6)',
                            fontFamily: 'Circular Std Book',
                            fontSize: 12,
                            fontStyle: 'normal',
                            fontWeight: 200,
                            fontStretch: 'normal',
                            lineHeight: 1,
                        },
                    },
                },
                responsive: true,
                scales: {
                    x: {
                        grid: {
                            display: false,
                            //   drawBorder: BORDER,
                            //   drawOnChartArea: CHART_AREA,
                            //   drawTicks: TICKS,
                        },
                    },
                    y: {
                        grid: {
                            display: true,
                            borderWidth: 1,
                            color: '#e9eaee',

                            //   drawBorder: false,
                            //   color: function(context) {
                            //     if (context.tick.value > 0) {
                            //       return Utils.CHART_COLORS.green;
                            //     } else if (context.tick.value < 0) {
                            //       return Utils.CHART_COLORS.red;
                            //     }

                            //     return '#000000';
                            //   },
                        },
                    },
                },
            }
        })

        const startDashboardTour = () => {
            if (dashboardTourStatus.value) {
                start()
            }
        }

        const stopDashboardTour = () => {
            store.dispatch('Settings/stopDashboardTour')
        }

        const initDashboard = () => {
            store.dispatch('PnRModule/getStats', { business: state.business })
            getAllTransactions()
            getGraphOverview(state.initialOverviewData)
            getTodayOverviewStat()
            getRecentTransactions(5)
            getTopCustomers(5)
            getTopProducts(5)
            getTopExpenses({
                limit: 5,
                startDate: formatDate(state.start, 'YYYY-MM-DD'),
                endDate: formatDate(state.end, 'YYYY-MM-DD'),
            })
            getTopEvents(5)
        }

        const getTransactionClass = (type) => {
            const typeToClass = {
                Sales: 'transaction-sales',
                Expenses: 'transaction-expenses',
                Purchases: 'transaction-purchases',
                Others: 'transaction-others',
                'Money In': 'transaction-sales',
                'Money Out': 'transaction-expenses',
            }

            return typeToClass[type] || ''
        }

        // onMounted(() => (getStarted.value = true))

        onMounted(() => {
            initDashboard()
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getBusinesses', true).then((resp) => {
                if (resp.status) {
                    state.hasMultiBusiness = resp.data.length > 1
                    state.businesses = resp.data
                    state.business = orgData.value?.orgId
                }
            })
            startDashboardTour()
        })

        return {
            state,
            userData,
            orgData,
            summary,
            formatAmount,
            expenseSidebarOptions,
            role,
            rolePermissions,
            onChangeStatsFilter,
            stats,
            chartOptions,
            // chartData,
            lineData,
            loading,
            recentTransactions,
            topCustomers,
            topProducts,
            topEvents,
            topExpenses,
            formatDateInLocal,
            transactionTypes,
            showDropdown,
            handleReportDropdown,
            salesType,
            expensesType,
            purchasesType,
            handleOnClickOutsideDropdown,
            closeDropDown,
            pieChartSeries,
            pieChartOptions,
            revenueShowModal,
            expenseShowModal,
            netPLShowModal,
            debtShowModal,
            inventoryShowModal,
            clickableCardInventory,
            clickableCardOutstandingDebt,
            clickableCardPL,
            clickableCardTotalExpenses,
            clickableCardTotalRevenue,
            wrapper,
            steps,
            getTransactionClass,
            plan,
            // getStarted,
            // closeGetStarted,
            // openGetStarted,
        }
    },
}
</script>

<style>
.makerelative {
    position: relative !important;
}

#mydropdown {
    position: absolute;
}

.inputWrapper label {
    display: block;
}

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
    display: block !important;
}
@media screen and (max-width: 599px) {
    #toDo {
        width: 100%;
        margin: 0;
    }
    .toDoWrapper {
        width: 100%;
    }
    .ctaMobile {
        width: 100%;
    }
    .topCusScroll {
        width: 500px;
    }
}
.customerInitials {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-items: center;
    background-color: #d7defa;
}
.customerInitials p {
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #132c8c;
}
.transaction-sales {
    color: green;
}

.transaction-expenses,
.transaction-purchases {
    color: red;
}

.transaction-others {
    color: blue;
}
.hyperLink:hover {
    text-decoration: underline;
}
.hand{
    left: 0px;
    /* z-index: 20; */
    position: fixed;
    cursor: pointer;
}
.closeBtn {
    left: 0px;
    /* z-index: 20; */
    position: fixed;
    cursor: pointer;
}
</style>
