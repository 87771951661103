<template>
    <app-wrapper>
        <template v-slot:child>
            <div>
                <div class="pa4-l pa3">
                    <div>
                        <h2 class="" style="color: #132c8c; font-size: 18px">Accounting</h2>
                        <p class="w-60-l lh-copy black">
                            Your financial control center where you manage all your money-related data. Under "Accounting," you'll
                            find the following sections:
                        </p>
                    </div>
                    <div class="mt4 black" style="font-weight: 500">
                        <span class="bg-washed-yellow ph2">Tip: Click on card to perform specify actions </span>
                    </div>
                    <div class="mt4">
                        <div class="flex flex-wrap" style="gap: 15px">
                            <router-link
                                :to="{ name: 'Transactions' }"
                                class="pa3 menu-card"
                                style="border: 2px solid #f5f5f7; border-radius: 20px"
                            >
                                <img src="@/assets/images/accountTransaction.svg" style="width: 60px" alt="" />
                                <h3>Transactions</h3>
                                <p class="lh-copy">A record of all the money moving in and out of your accounts.</p>
                            </router-link>
                            <div
                                @click="routeTo('ProfitAndLoss')"
                                class="pa3 menu-card relative"
                                style="border: 2px solid #f5f5f7; border-radius: 20px"
                                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(19)"
                            >
                                <img src="@/assets/images/ProfitLoss.svg" style="width: 60px" alt="" />
                                <h3>Profit & Loss Account</h3>
                                <p class="lh-copy">A summary of your income, expenses, and profit over a specific time. {{ plan.businessPlan }}ss</p>
                                
                                <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                    <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                </span>
                                
                            </div>
                            
                            <div
                                @click="routeTo('BalanceSheet')"
                                class="pa3 menu-card relative"
                                style="border: 2px solid #f5f5f7; border-radius: 20px"
                                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(20)"
                            >
                                <img src="@/assets/images/Balance.svg" style="width: 60px" alt="" />
                                <h3>Balance Sheet</h3>
                                <p class="lh-copy">A snapshot of your financial health at a specific point in time.</p>
                                <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                    <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                </span>
                            </div>
                            <router-link
                                :to="{ name: 'TrialBalance' }"
                                class="pa3 menu-card"
                                style="border: 2px solid #f5f5f7; border-radius: 20px"
                            >
                                <img src="@/assets/images/TrialBalance.svg" style="width: 60px" alt="" />
                                <h3>Trial Balance</h3>
                                <p class="lh-copy">A snapshot of your accounts to ensure they're in balance.</p>
                                <!-- <p class="comingSoon">Coming Soon...</p> -->
                            </router-link>
                            <router-link
                                :to="{ name: 'ManageAssets' }"
                                class="pa3 menu-card"
                                style="border: 2px solid #f5f5f7; border-radius: 20px"
                            >
                                <img src="@/assets/images/ChartOfAccount.svg" style="width: 60px" alt="" />
                                <h3>Chart of Accounts</h3>
                                <p class="lh-copy">
                                    A list of all your financial categories or "buckets" where you allocate money.
                                </p>
                                <!-- <p class="comingSoon">Coming Soon...</p> -->
                            </router-link>

                            <router-link
                                :to="{ name: 'EditJournal' }"
                                class="pa3 menu-card"
                                style="border: 2px solid #f5f5f7; border-radius: 20px"
                            >
                                <img src="@/assets/images/JournalEntry.svg" style="width: 60px" alt="" />
                                <h3>Journal Entry</h3>
                                <p class="lh-copy">Manually record financial events in detail.</p>
                                <p></p>
                                <!-- <p class="comingSoon">Coming Soon...</p> -->
                            </router-link>
                            <router-link :to="'#'" class="pa3 menu-card" style="border: 2px solid #f5f5f7; border-radius: 20px">
                                <img src="@/assets/images/CashflowStatement.svg" style="width: 60px" alt="" />
                                <h3>Cashflow Statement</h3>
                                <p class="lh-copy">Tracks how cash flows in and out of your business.</p>
                                <p class="comingSoon">Coming Soon...</p>
                            </router-link>
                            <router-link :to="'#'" class="pa3 menu-card" style="border: 2px solid #f5f5f7; border-radius: 20px">
                                <img src="@/assets/images/rev-expense.svg" style="width: 60px" alt="" />
                                <h3>Bank Transfers</h3>
                                <p class="lh-copy">Move money between different bank accounts.</p>
                                <p class="comingSoon">Coming Soon...</p>
                            </router-link>
                            <router-link :to="'#'" class="pa3 menu-card" style="border: 2px solid #f5f5f7; border-radius: 20px">
                                <img src="@/assets/images/BankStatement.svg" style="width: 60px" alt="" />
                                <h3>Bank Statement</h3>
                                <p class="lh-copy">A summary of all transactions that have impacted your bank account.</p>
                                <p class="comingSoon">Coming Soon...</p>
                            </router-link>
                            <router-link :to="'#'" class="pa3 menu-card" style="border: 2px solid #f5f5f7; border-radius: 20px">
                                <img src="@/assets/images/rev-expense.svg" style="width: 60px" alt="" />
                                <h3>Bank Reconciliation</h3>
                                <p class="lh-copy">Make sure your bank records match your accounting records.</p>
                                <p class="comingSoon">Coming Soon...</p>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import AppWrapper from '../../layout/AppWrapper.vue'

export default {
    name: 'Acoounting',
    components: { AppWrapper },
    setup() {
        const store = useStore()
        const router = useRouter()
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const plan = computed(() => store.state?.Settings?.paymentPlan)

        const routeTo = (path) => {
            if (plan.value.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `This feature is not available on free plan. Upgrade to unlock!`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                })
                return
            }
            router.push({ name: path })
        }
        return {
            role,
            rolePermissions,
            routeTo,
            plan,
        }
    },
}
</script>
<style>
.comingSoon {
    background-color: #132c8c;
    width: 120px;
    margin-top: 20px;
    padding: 3px 3px 3px 15px;
    border-radius: 40px;
    color: white;
}

</style>
