<template>
    <!-- form modal -->
    <div
        tabindex="0"
        @keydown.esc="toggleCreateBrand()"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showCreateBrand && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-30-l w-90 pv2 center box-border" style="background: white" ref="createBrandRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Create Brand</div>
                <div>
                    <router-link to="#" @click.prevent="toggleCreateBrand()">
                        <img :src="require('@/assets/images/Close-icon.svg')" alt="account" />
                    </router-link>
                </div>
            </div>
            <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                <div class="pa3">
                    <div class="pt2">
                        <div class="pv2 font-w1"><label for="name">Brand Name</label></div>
                        <input required type="text" id="name" name="name" placeholder="Enter Brand Name" v-model="state.name" />
                    </div>
                    <div class="pt2">
                        <div class="pv2 font-w1">
                            <label for="name">Description</label>
                        </div>
                        <textarea
                            id="description"
                            name="description"
                            placeholder="Enter description (optional)"
                            v-model="state.description"
                        ></textarea>
                    </div>

                    <div class="flex items-center justify-center w-100">
                        <button class="mt4 w-100" type="submit" :disabled="state.disableSubmitButton">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'CreateBrand',
    props: {
        brandName: String
    },

    setup() {
        const createBrandRef = ref(null)
        const store = useStore()
        const showCreateProduct = computed(() => store?.state.Inventory.showCreateProduct)
        const showCreateBrand = computed(() => store?.state.Inventory.showCreateBrand)
        const incomingBrandName = computed(() => store?.state.Inventory.incomingBrandName)
        const brandNameRef = ref(null)
        const formRef = ref(null)

        const state = reactive({
            disableSubmitButton: false,
            name: '',
            description: '',
        })

        const toggleCreateBrand = (payload = false) => {
            store.dispatch('Inventory/showCreateBrand', payload)
        }

        const onSubmit = () => {
            state.disableSubmitButton = true
            const { disableSubmitButton, ...data } = state
            store.dispatch('Inventory/createBrand', data).then(resp => {
                if (resp) {
                    store.dispatch('Inventory/getAllBrands')
                    state.disableSubmitButton = false
                    state.name = ''
                    state.description = ''
                    toggleCreateBrand()
                } else {
                    state.disableSubmitButton = false
                }
            })
        }

        const handleOnClickOutsideModal = e => {
            if (createBrandRef.value && !createBrandRef.value.contains(e.target)) {
                toggleCreateBrand()
            }
        }

        onMounted(() => store.dispatch('Inventory/getAllBrands'))

        return {
            state,
            showCreateProduct,
            showCreateBrand,
            incomingBrandName,
            toggleCreateBrand,
            brandNameRef,
            formRef,
            onSubmit,
            createBrandRef,
            handleOnClickOutsideModal
        }
    }
}
</script>

<style scoped></style>
