<template>
    <app-wrapper>
        <template v-slot:child>
            <CreateProduct :closeModal="() => toggleCreateProduct({ show: true, cat: 'PRODUCT' })" />
            <EntityBulkUploadModal :closeModal="() => toggleEntityBulkImportModal(false)" />
            <!-- Main Box -->
            <div class="flex justify-between">
                <CreateEntity :closeModal="() => toggleCreateEntity()" />
                <!-- Right box -->
                <div class="">
                    <div class="pa4-l pa3">
                        <div>
                            <h2 class="" style="color: #132c8c; font-size: 18px">Contacts</h2>
                            <p class="w-60-l lh-copy black">
                                Your digital address book for the people or businesses you deal with.
                            </p>
                        </div>

                        <div class="mt4 black" style="font-weight: 500">
                            <span class="bg-washed-yellow ph2">Tip: Click on card to perform specify actions </span>
                        </div>

                        <div class="mt4">
                            <div class="flex flex-wrap" style="gap: 15px">
                                <div
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(30)"
                                    @click.prevent="() => toggleCreateEntity(true)"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/contact-rev.svg" style="width: 60px" alt="" />
                                    <h3>Create a Contact</h3>
                                    <p class="lh-copy">Add a new person or business to your digital address book.</p>
                                </div>
                                <router-link
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(13)"
                                    :to="'#'"
                                    @click="routeTo('Entities')"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/cus-group.svg" style="width: 60px" alt="" />
                                    <h3>All Contacts</h3>
                                    <p class="lh-copy">
                                        View contacts from all groups such as Investors, dedbtors, creditors,
                                        Miscellaneous contacts etc
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                    </span>
                                </router-link>
                                <router-link
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(13)"
                                    :to="{ name: 'Entities', query: { group: 'customers' } }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-customers.svg" style="width: 60px" alt="" />
                                    <h3>Customers</h3>
                                    <p class="lh-copy">View people or businesses who buy from you. Your loyal buyers.</p>
                                </router-link>
                                <router-link
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(10)"
                                    :to="{ name: 'PnR', query: { group: 'debtors' } }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-debtors.svg" style="width: 60px" alt="" />
                                    <h3>Debtors</h3>
                                    <p class="lh-copy">
                                        View people or businesses who owe you money, like unpaid bills, credit sales and details
                                        of their debt.
                                    </p>
                                </router-link>
                                <router-link
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(13)"
                                    :to="'#'"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    @click="routeTo('Entities', { group: 'suppliers' })"
                                >
                                    <img src="@/assets/images/revamp-vendor.svg" style="width: 60px" alt="" />
                                    <h3>Suppliers</h3>
                                    <p class="lh-copy">View people or businesses you buy from. Your suppliers.</p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                    </span>
                                </router-link>
                                <router-link
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(11)"
                                    :to="{ name: 'PnR', query: { group: 'creditors' } }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-creditor.svg" style="width: 60px" alt="" />
                                    <h3>Creditors</h3>
                                    <p class="lh-copy">
                                        People or businesses you owe money to, such as outstanding invoices, credit purchase etc.
                                        and details of the credit.
                                    </p>
                                </router-link>

                                <router-link
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(13)"
                                    :to="'#'"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    @click="routeTo('Entities', { group: 'others' })"
                                >
                                    <img src="@/assets/images/rev-others.svg" style="width: 60px" alt="" />
                                    <h3>Others</h3>
                                    <p class="lh-copy">
                                        View any other contacts that don't fit into the above groups such as Investors,
                                        Miscellaneous contacts.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                    </span>
                                </router-link>
                                <div
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(66)"
                                    @click="bulkCreateContacts()"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/rev-create.svg" style="width: 60px" alt="" />
                                    <h3>Bulk Create Contacts</h3>
                                    <p class="lh-copy">
                                        Add multiple new contacts all at once, which is helpful if you have a list to import.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                    </span>
                                </div>
                                <div
                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(67)"
                                    @click="exportEntities"
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/revamp-upload.svg" style="width: 60px" alt="" />
                                    <h3>Export Contacts</h3>
                                    <p class="lh-copy">
                                        Save your contact list as a file that you can use elsewhere or keep as a backup.
                                    </p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                    </span>
                                </div>
                                <div
                                    class="pa3 menu-card position-relative"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/cus-group.svg" style="width: 60px" alt="" />
                                    <h3>Customer Groups</h3>
                                    <p class="lh-copy">
                                        Organize and create customer groups with virtual labels based on criteria, simplifying
                                        management and allowing customized interactions. You can also set price markup or
                                        discounts for these groups.
                                    </p>
                                    <p class="comingSoon">Coming Soon...</p>
                                    <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                        <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import AppWrapper from '../../layout/AppWrapper.vue'
import CreateEntity from '@/components/CreateEntity'
import EntityBulkUploadModal from '@/views/Entities/widgets/EntityBulkUploadModal'
import CreateProduct from '@/components/CreateProduct.vue'
export default {
    name: 'Contacts',
    components: { AppWrapper, CreateEntity, EntityBulkUploadModal, CreateProduct },
    setup() {
        const store = useStore()
        const router = useRouter()
        const orgData = computed(() => store?.state?.Auth?.userData)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const plan = computed(() => store.state?.Settings?.paymentPlan)

        const toggleCreateEntity = (payload) => {
            store.dispatch('Entity/toggleCreateEntity', payload)
        }
        const toggleEntityBulkImportModal = (status) => {
            store.dispatch('Entity/toggleEntityBulkImportModal', status)
        }

        const toggleCreateProduct = (status, payload) => {
            store.dispatch('Inventory/showCreateProduct', { show: status, cat: 'PRODUCT' })
        }

        const checkFreePlan = () => {
            if (plan.value.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `This feature is not available on free plan. Upgrade to unlock!`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                })
                return true
            }

            return false
        }

        const routeTo = (path, query = {}) => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            router.push({ name: path, query })
        }

        const bulkCreateContacts = () => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            toggleEntityBulkImportModal(true)
        }

        const exportEntities = () => {
            if (plan.value?.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `Exporting of data is only available for Paid plans`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                })
                return
            }

            store.dispatch('Entity/exportEntities', {}).then((response) => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `${orgData.value?.org || ''}-entities-export.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
            })
        }

        onMounted(() => {
            store.dispatch('Settings/getPlan')
        })

        return {
            toggleCreateEntity,
            toggleEntityBulkImportModal,
            rolePermissions,
            role,
            plan,
            checkFreePlan,
            routeTo,
            bulkCreateContacts,
            toggleCreateProduct,
            exportEntities,
        }
    },
}
</script>
<style>
.comingSoon {
    background-color: #132c8c;
    width: 120px;
    margin-top: 20px;
    padding: 3px 3px 3px 15px;
    border-radius: 40px;
    color: white;
    margin-left: auto;
}
</style>
