import { Axios } from '@/utils/axiosConfig'
import store from '@/store'
// import Router from '@/router'

const state = {
  isLoading: false,
  currentAssets: { cashOnHand: 0, cashAtBank: 0, inventories: 0 },
  inventories: { start: 0, end: 0 },
  liabilities: {},
  equity: {},
  products: { start: 0, end: 0 },
  purchases: { start: 0, end: 0 },
  shipping: { start: 0, end: 0 },
  vat: { start: 0, end: 0 },
  commission: { start: 0, end: 0 },
  additionalCosts: { start: 0, end: 0 },
  openingInventory: { start: 0, end: 0 },
  closingInventory: { start: 0, end: 0 },
  grossProfit: { start: 0, end: 0 },
  transportation: { start: 0, end: 0 },
  charges: { start: 0, end: 0 },
  ads: { start: 0, end: 0 },
  packaging: { start: 0, end: 0 },
  rent: { start: 0, end: 0 },
  assets: { start: 0, end: 0 },
  utility: { start: 0, end: 0 },
  others: { start: 0, end: 0 },
  interestExpense: { start: 0, end: 0 },
  incomeDiscount: { start: 0, end: 0 },
  incomeShipping: { start: 0, end: 0 },
  incomeGift: { start: 0, end: 0 },
  incomeInterest: { start: 0, end: 0 },
  salary: { start: 0, end: 0 },
  total: { start: 0, end: 0 },
  totalOperationCost: { start: 0, end: 0 },
  netOperatingCost: { start: 0, end: 0 },
  operatingProfit: { start: 0, end: 0 },
  netFinanceIncome: { start: 0, end: 0 },
  drawings: 0,
  profits: 0,
  expenses: 0,
  sales: 0,
  report: [],
  profitAndLossReports: {},
  dataIsLoading: false,
  inventoryReports: [],
  inventoryReportsList: [],
  salesInventoryReportList: [],
  purchaseInventoryReportList: [],
  loadingInventoryReports: false,
  expenseReports: [],
  loadingExpenseReports: false,
  showEmailToModal: false,
}

const mutations = {
  SET_LOADING(state, isLoading = false) {
    state.isLoading = isLoading
  },

  SET_PROFITS(state, profits) {
    state.profits = profits
  },

  SET_EXPENSES(state, expenses) {
    state.expenses = expenses
  },

  SET_SALES(state, sales) {
    state.sales = sales
  },

  SET_INVENTORIES(state, inventories) {
    state.inventories = inventories
  },

  SET_CURRENT_ASSETS(state, payload) {
    state.currentAssets = payload
  },

  SET_LIABILITIES(state, payload) {
    state.liabilities = payload
  },

  SET_SALARY(state, salary) {
    state.salary = salary
  },

  SET_EQUITY(state, payload) {
    state.equity = payload
  },

  SET_P_N_L_SALES(state, products) {
    state.products = products
  },

  SET_P_N_L_COGS_PURCHASES(state, purchases) {
    state.purchases = purchases
  },

  SET_P_N_L_COGS_ADDITIONAL_COSTS(state, additionalCosts) {
    state.additionalCosts = additionalCosts
  },

  SET_P_N_L_COGS_OPENING_INVENTORY(state, openingInventory) {
    state.openingInventory = openingInventory
  },

  SET_P_N_L_COGS_CLOSING_INVENTORY(state, closingInventory) {
    state.closingInventory = closingInventory
  },

  SET_P_N_L_COGS_GROSS_PROFITS(state, grossProfit) {
    state.grossProfit = grossProfit
  },

  SET_P_N_L_COGS_TOTAL(state, total) {
    state.total = total
  },

  SET_P_N_L_COGS_GROSS_TOTAL_OPERATION_COST(state, totalOperationCost) {
    state.totalOperationCost = totalOperationCost
  },

  SET_P_N_L_COGS_OPERATING_PROFIT(state, operatingProfit) {
    state.operatingProfit = operatingProfit
  },

  SET_P_N_L_COGS_NET_FINANCE_INCOME(state, netFinanceIncome) {
    state.netFinanceIncome = netFinanceIncome
  },

  SET_P_N_L_COGS_NET_OPERATING_COST(state, netOperatingCost) {
    state.netOperatingCost = netOperatingCost
  },

  SET_P_N_L_COGS_TRANSPORTATION(state, transportation) {
    state.transportation = transportation
  },

  SET_P_N_L_COGS_COMMISSION(state, commission) {
    state.commission = commission
  },

  SET_P_N_L_COGS_CHARGES(state, charges) {
    state.charges = charges
  },

  SET_P_N_L_COGS_ADS(state, ads) {
    state.ads = ads
  },

  SET_P_N_L_COGS_PACKAGING(state, packaging) {
    state.packaging = packaging
  },

  SET_P_N_L_COGS_RENT(state, rent) {
    state.rent = rent
  },

  SET_P_N_L_COGS_ASSETS(state, assets) {
    state.assets = assets
  },

  SET_P_N_L_COGS_UTILITY(state, utility) {
    state.utility = utility
  },

  SET_P_N_L_COGS_OTHERS(state, others) {
    state.others = others
  },

  SET_P_N_L_COGS_INTEREST_EXPENSE(state, interestExpense) {
    state.interestExpense = interestExpense
  },

  SET_P_N_L_COGS_INCOME_DISCOUNT(state, incomeDiscount) {
    state.incomeDiscount = incomeDiscount
  },

  SET_P_N_L_COGS_INCOME_SHIPPING(state, incomeShipping) {
    state.incomeShipping = incomeShipping
  },

  SET_P_N_L_COGS_INCOME_GIFT(state, incomeGift) {
    state.incomeGift = incomeGift
  },

  SET_P_N_L_COGS_INCOME_INTEREST(state, incomeInterest) {
    state.incomeInterest = incomeInterest
  },
  SET_SALES_REPORT(state, report) {
    state.report = report
  },
  SET_P_N_L_REPORT(state, profitAndLossReports) {
    state.profitAndLossReports = profitAndLossReports
  },
  SET_REPORT_LOADING(state, payload) {
    state.dataIsLoading = payload
  },
  SET_INVENTORY_REPORT(state, inventoryReports) {
    state.inventoryReports = inventoryReports
  },
  SET_INVENTORY_REPORT_LIST(state, inventoryReports) {
    state.inventoryReportsList = inventoryReports
  },
  SET_SALES_INVENTORY_REPORT_LIST(state, report) {
    state.salesInventoryReportList = report
  },
  SET_PURCHASE_INVENTORY_REPORT_LIST(state, report) {
    state.purchaseInventoryReportList = report
  },
  SET_EXPENSE_REPORT(state, expenseReports) {
    state.expenseReports = expenseReports
  },

  SET_EMAIL_TO_MODAL(state, status) {
    state.showEmailToModal = status
  },
}

const actions = {
  getReportingProfits: async ({ commit }) => {
    Axios.get('/api/v1/reports/summary/profits')
      .then(({ data }) => {
        commit('SET_PROFITS', data.profits)
      })
      .catch(err => {
        console.log(err)
      })
  },

  getReportingSales: async ({ commit }) => {
    Axios.get('/api/v1/reports/summary/sales')
      .then(({ data }) => {
        commit('SET_SALES', data.sales)
      })
      .catch(err => {
        console.log(err)
      })
  },

  getReportingExpenses: async ({ commit }) => {
    Axios.get('/api/v1/reports/summary/expense')
      .then(({ data }) => {
        commit('SET_EXPENSES', data.expenses)
      })
      .catch(err => {
        console.log(err)
      })
  },

  getReportingCurrentAssets: async ({ commit }, payload) => {
    Axios.get(`/api/v1/reports/balance-sheet/current-assets?startDate1=${payload.startDate1}&startDate2=${payload.startDate2}&endDate1=${payload.endDate1}&endDate2=${payload.endDate2}`)
      .then(({ data }) => {
        commit('SET_CURRENT_ASSETS', data)
      })
      .catch(err => {
        console.log(err)
      })
  },

  getReportingLiabilities: async ({ commit }, payload) => {
    Axios.get(`/api/v1/reports/balance-sheet/liabilities?startDate1=${payload.startDate1}&startDate2=${payload.startDate2}&endDate1=${payload.endDate1}&endDate2=${payload.endDate2}`)
      .then(({ data }) => {
        commit('SET_LIABILITIES', data)
      })
      .catch(err => {
        console.log(err)
      })
  },

  getReportingEquity: async ({ commit }, payload) => {
    Axios.get(`/api/v1/reports/balance-sheet/equity?startDate1=${payload.startDate1}&startDate2=${payload.startDate2}&endDate1=${payload.endDate1}&endDate2=${payload.endDate2}`)
      .then(({ data }) => {
        commit('SET_EQUITY', data)
      })
      .catch(err => {
        console.log(err)
      })
  },

  getSalesReports: async ({ commit }, query) => {
    commit('SET_SALES_REPORT')
    try {

      const { data } = await Axios.post('/api/v1/reports/sales', query)
      // console.log('SALES REPORT: ', data.result)
      commit('SET_SALES_REPORT', data.result)
    } catch (e) {
      console.log(e.response.data)
    }
  },

  getProfitAndLossReport: async ({ commit }, query) => {
    commit('SET_REPORT_LOADING', true)
    Axios.get(`/api/v1/reports/profit-and-loss/${query}`)
      .then(({ data }) => {
        // console.log('SET_P_N_L_REPORT', data)
        commit('SET_P_N_L_REPORT', data)
        commit('SET_REPORT_LOADING', false)
      })
      .catch(err => {
        commit('SET_REPORT_LOADING', false)
        console.log(err)
      })
  },

  generateProfitAndLossReportPdf: async ({ commit }, query) => {
    return Axios.get(`/api/v1/reports/profit-and-loss/pdf/${query}`,
      { responseType: 'blob' }
    ).then(({ data }) => {
      return data
    }).catch((err) => console.log(err?.response))

  },

  generateProfitAndLossReportPdfAndSend: async ({ commit }, query) => {
    return Axios.get(`/api/v1/reports/profit-and-loss/pdf/send/${query}`)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
        return data
      })
      .catch(async (err) => {
        const { data } = err.response
        console.log(data)
        await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
      })

  },

  getInventoryReport: async ({ commit }, query) => {
    commit('SET_REPORT_LOADING', true)
    Axios.post('/api/v1/reports/inventory', query)
      .then(({ data }) => {
        console.log(data, "inventory data")
        commit('SET_INVENTORY_REPORT', data)
        commit('SET_REPORT_LOADING', false)
      })
      .catch(err => {
        commit('SET_REPORT_LOADING', false)
        console.log(err)
      })
  },
  getInventoryReportList: async ({ commit }, query) => {
    commit('SET_REPORT_LOADING', true)
    Axios.post('/api/v1/reports/inventory/list', query)
      .then(({ data }) => {
        commit('SET_INVENTORY_REPORT_LIST', data)
        commit('SET_REPORT_LOADING', false)
        console.log('getInventoryReportList: ', data)
      })
      .catch(err => {
        commit('SET_REPORT_LOADING', false)
        console.log(err)
      })
  },
  getSalesInventoryReportList: async ({ commit }, query) => {
    commit('SET_REPORT_LOADING', true)
    Axios.get(`/api/v1/reports/transactions/sales?startDate=${query.startDate}&endDate=${query.endDate}`)
      .then(({ data }) => {
        commit('SET_SALES_INVENTORY_REPORT_LIST', data.data)
        commit('SET_REPORT_LOADING', false)
        // console.log('getSalesInventoryReportList: ', data)
      })
      .catch(err => {
        commit('SET_REPORT_LOADING', false)
        console.log(err)
      })
  },
  getPurchaseInventoryReportList: async ({ commit }, query) => {
    commit('SET_REPORT_LOADING', true)
    Axios.get(`/api/v1/reports/transactions/purchases?startDate=${query.startDate}&endDate=${query.endDate}`)
      .then(({ data }) => {
        commit('SET_PURCHASE_INVENTORY_REPORT_LIST', data.data)
        commit('SET_REPORT_LOADING', false)
        // console.log('getPurchaseInventoryReportList: ', data)
      })
      .catch(err => {
        commit('SET_REPORT_LOADING', false)
        console.log(err)
      })
  },

  getExpenseReport: async ({ commit }, query) => {
    commit('SET_REPORT_LOADING', true)
    Axios.post('/api/v1/reports/expense', query)
      .then(({ data }) => {
        console.log(data, "expense data")
        commit('SET_EXPENSE_REPORT', data)
        commit('SET_REPORT_LOADING', false)
      })
      .catch(err => {
        commit('SET_REPORT_LOADING', false)
        console.log(err)
      })
  },

  showEmailToModal: ({ commit }, payload) => {
    commit('SET_EMAIL_TO_MODAL', payload)
  },
}

export const ReportingModule = { namespaced: true, state, mutations, actions }
