<template>
    <div v-if="showInventoryBulkUpdateModal">
        <div class="jquery-modal blocker current" @click="closeModalOnOutsideClick" ref="importRef">
            <div id="modal-bulk" class="modal-target2">
                <!-- <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>Inventory Bulk Update</h3>
                        <div class="lh-copy">
                            Use this to update multiple inventories at the same time. Download the <a href=""
                                @click.prevent="downloadInventoryTemplate" class="b text-info">template</a> here, update
                            as much inventory as you want, and upload the file in a CSV format.

                        </div>
                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input type="file" name="imageFile" id="file" class="product-input" @change="onFileChange"
                                accept="text/csv" required />
                            <button :disabled="state.buttonDisabled">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Upload Template' }}
                            </button>
                        </form>
                    </div>
                </div> -->
                <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>Inventory Bulk Update</h3>
                        <div class="whiteBgModal">
                            <div class="lh-copy">
                                Here's a simple step-by-step guide on how to update product information on Simplebks in bulk:
                            </div>
                            <ol>
                                <li>
                                    Download the product list
                                    <a href="" @click.prevent="downloadInventoryTemplate" class="b text-info">here</a>:This is a
                                    file that has information about all your products in CSV (It's like a table that you can open
                                    in programs like Microsoft Excel)
                                </li>
                                <li>
                                    Make changes to the product information: Find the product you want to update and change its
                                    name, quantity in stock, buying price, or selling price. You can do this for as many products
                                    as you want
                                </li>
                                <li>
                                    Save the changes: After making the updates, save the file with the changes you made in CSV
                                    format
                                    <ol type="a">
                                        <li style="color: red">NB: Do not change the column labelled “_id”</li>
                                    </ol>
                                </li>
                                <li>
                                    Upload the updated file: After saving the updated file. come back here and click on the
                                    "Choose file" button below. When you click on it, select the file you just edited from your
                                    computer. Make sure it's saved in CSV format
                                </li>
                                <li>
                                    Update your inventory: Look for an option that says "Update Inventory" and click on it. This
                                    will process the file you uploaded and update your inventory with the new product information
                                </li>
                            </ol>
                            <div>That's it! Now you've successfully updated the product information on SimpleBks in bulk</div>
                        </div>
                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input
                                type="file"
                                name="imageFile"
                                id="file"
                                class="product-input"
                                @change="onFileChange"
                                accept="text/csv"
                                required
                            />
                            <button :disabled="!state.imageFile">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Update Inventory' }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'UploadInventoryModal',
    props: {
        closeModal: Function,
    },

    setup(props, { emit }) {
        const store = useStore()

        const orgData = computed(() => store?.state?.Auth?.userData)
        const showInventoryBulkUpdateModal = computed(() => store.state?.Inventory?.showInventoryBulkUpdateModal)

        const state = reactive({
            buttonDisabled: false,
            imageFile: null,
        })

        const importRef = ref(null)

        const onFileChange = (e) => {
            state.imageFile = e.target.files?.[0]
        }

        const closeModalOnOutsideClick = (e) => {
            if (e.target === importRef.value) {
                props.closeModal()
            }
        }

        const downloadInventoryTemplate = () => {
            store.dispatch('Inventory/downloadInventoryTemplate').then((response) => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `${orgData.value?.org}-inventory-template.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                // state.downloading = false
            })
        }

        const onSubmit = () => {
            if (!state.imageFile) {
                return
            }
            state.buttonDisabled = true
            const formData = new FormData()
            formData.append('imageFile', state.imageFile)
            store.dispatch('Inventory/importInventoryBulkUpdate', formData).then((resp) => {
                if (resp) {
                    props.closeModal()
                    state.imageFile = null
                    state.buttonDisabled = false
                }
            })
        }

        const updateIncomingState = (incomingState, key) => {
            state[incomingState] = state[key]
        }

        return {
            state,
            emit,
            updateIncomingState,
            importRef,
            closeModalOnOutsideClick,
            downloadInventoryTemplate,
            onFileChange,
            showInventoryBulkUpdateModal,
            onSubmit,
        }
    },
}
</script>

<style scoped></style>
