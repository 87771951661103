<template>
    <div v-if="showTransactionImportModal">
        <div class="jquery-modal blocker current" @click="closeModalOnOutsideClick" ref="importRef">
            <div id="modal-bulk" class="modal-target2">
                <!-- <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>Import Transactions</h3>
                        <div class="lh-copy">
                            Download this <a href="" @click.prevent="downloadBulkTransactionTemplate"
                                class="b text-info">template</a> and edit the fields with your own information. When you
                            are done, click on import and upload the updated sheet. Your transaction records will be
                            updated.
                        </div>
                        <div class="alert alert-info tl"><strong>Note the following on the template </strong>
                            <div>- <strong>date</strong> must be in this format <code>mm/dd/yyyy</code></div>
                            <div>- <strong>type</strong> should only be your <code>sales</code>, <code>purchases</code>,
                                <code>expenses</code>
                            </div>
                            <div>- <strong>Mode of payment</strong> should be either<code>Cash</code> or the bank name
                                you received your payment</div>
                            <div>- for <code>expenses</code> transaction, <strong>the category should be written as
                                    a number,</strong> find the listed codes
                                <a href="https://help.simplebks.com/en-gb/a/644a21abe991b63e59c1dfc4-expense-categories-and-their-id-for-bulk-upload"
                                    target="_blank" class="b">HERE</a>
                            </div>
                        </div>
                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input type="file" name="imageFile" id="file" class="product-input" @change="onFileChange"
                                accept="text/csv" required />
                            <button :disabled="state.buttonDisabled">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Upload' }}
                            </button>
                        </form>
                    </div>
                </div> -->
                <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <div class="whiteBgModal">
                            <div>To Import your transactions,please follow the steps below
                            </div>
                            <ol>
                                <li class="lh-copy">
                                    Download this <a href="" @click.prevent="downloadBulkTransactionTemplate"
                                        class="b text-info">template</a>
                                </li>
                                <li> Edit the fields with your own information using the following format

                                    <ul class="alert alert-info tl">
                                        <li>the <strong>date</strong> field must be entered in this format
                                            <code>dd/mm/yyyy</code>
                                        </li>
                                        <li><strong>type</strong> should only be entered as <code>sales</code>,
                                            <code>purchases</code>,
                                            <code>expenses</code>
                                        </li>
                                        <li><strong>Mode of payment</strong> should be either <code>Cash</code> or the
                                            bank name you received your payment
                                        </li>
                                        <li>for <code>expenses</code>, <strong>the category should be
                                                written as a number,</strong> find the listed codes
                                            <a href="https://help.simplebks.com/en-gb/a/644a21abe991b63e59c1dfc4-expense-categories-and-their-id-for-bulk-upload"
                                                target="_blank" class="b" style="color: red;">HERE</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>When you are done, select Choose file and select the file you just edited in CSV
                                    format.
                                </li>
                                <li> Click on upload to update your transactions
                                </li>


                            </ol>
                            <p>Your transaction records will be updated.
                            </p><br>

                        </div>

                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input type="file" name="imageFile" id="file" class="product-input" @change="onFileChange"
                                accept="text/csv" required />
                            <button :disabled="state.buttonDisabled">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Upload' }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { computed, reactive, ref } from 'vue'
    import { useStore } from 'vuex'

    export default {
        name: 'TransactionImportModal',
        props: {
            closeModal: Function,
        },

        setup(props, { emit }) {
            const store = useStore()
            const showTransactionImportModal = computed(() => store.state?.Transaction?.showTransactionImportModal)

            const state = reactive({
                buttonDisabled: false,
                imageFile: null,
            })

            const importRef = ref(null)

            const onFileChange = (e) => {
                state.imageFile = e.target.files?.[0]
            }

            const closeModalOnOutsideClick = (e) => {
                if (e.target === importRef.value) {
                    props.closeModal()
                }
            }

            const downloadBulkTransactionTemplate = () => {
                store.dispatch('Transaction/downloadBulkTransactionTemplate').then((response) => {
                    if (response?.data?.error) {
                        console.error(response.data.error)
                    }

                    const fileURL = window.URL.createObjectURL(new Blob([response]))
                    const fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    const fileName = `import-transaction-template.csv`
                    fileLink.setAttribute('download', fileName)
                    fileLink.setAttribute('target', '_blank')
                    document.body.appendChild(fileLink)
                    fileLink.click()
                    fileLink.remove()
                    // state.downloading = false
                })
            }

            const onSubmit = () => {
                state.buttonDisabled = true
                const formData = new FormData()
                formData.append('imageFile', state.imageFile)
                store.dispatch('Transaction/importBulkTransactions', formData).then((resp) => {
                    if (resp) {
                        props.closeModal()
                        state.imageFile = null
                        state.buttonDisabled = false
                    }
                })
            }

            const updateIncomingState = (incomingState, key) => {
                state[incomingState] = state[key]
            }

            return {
                state,
                emit,
                updateIncomingState,
                importRef,
                closeModalOnOutsideClick,
                downloadBulkTransactionTemplate,
                onFileChange,
                showTransactionImportModal,
                onSubmit,
            }
        },
    }
</script>

<style scoped></style>