<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-100">
                <div class="f3 b w-90 center pv4" style="padding-right: 2px">Asset Management</div>
                <div class="w-90 center pv2">
                    <div class="flex flex-wrap justify-between items-center scrollmenu">
                        <div class="flex">
                            <div class="mr4">
                                <router-link active-class="active-menu custom-active" :to="{ name: 'AssetManagementView' }"
                                    >Summary</router-link
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-90 center mv4 adj-text">
                    <div class="flex flex-wrap">
                        <div class="w-33-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2">Quantity</div>
                            </div>
                            <div class="f5 f4-l font-w2">{{ formatQuantity(assetsSummary?.totalQuantity || 0) }}</div>
                        </div>
                        <div class="w-33-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2">Purchase value of Assets</div>
                            </div>
                            <div class="f5 f4-l font-w2">{{ formatAmount(assetsSummary?.totalPurchaseCost || 0, $store.state.Settings?.currency || 'NGN') }}</div>
                        </div>
                        <div class="w-33-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2">Net book value of Asset</div>
                            </div>
                            <div class="f5 f4-l font-w2">{{ formatAmount(assetsSummary?.totalPurchaseValue || 0, $store.state.Settings?.currency || 'NGN') }}</div>
                        </div>
                    </div>
                </div>

                <div class="scrollmenu w-90 center">
                    <table class="w-100 mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 pr5 box-border-bottom tl w-25">
                                    <div>
                                        <span>Asset tag</span>
                                    </div>
                                </th>
                                <th class="pb3 box-border-bottom tl w-25">
                                    <div>
                                        <span>Name</span>
                                    </div>
                                </th>
                                <th class="pb3 box-border-bottom tl w-25">
                                    <div>
                                        <span>Qty</span>
                                    </div>
                                </th>
                                <th class="pb3 pr4 box-border-bottom w-25 tr">
                                    <div>
                                        <span>Purchase value</span>
                                    </div>
                                </th>
                                <!-- <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>NBV</span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Location</span>
                                    </span>
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(asset, index) in assets" :key="'row-'+index">
                                <td class="pv3 pr4 box-border-bottom tl b">
                                    <router-link :to="{ name: 'AssetDetails', params: { id: asset._id } }">
                                        {{ asset.tag }}
                                    </router-link>
                                </td>
                                <td class="pv3 w-25 pr4 box-border-bottom tl">{{ asset.name }}</td>
                                <td class="pv3 w-25 pr4 box-border-bottom tl">{{ formatQuantity(asset?.qty_in_stock || 0) }}</td>
                                <td class="pv3 w-25 pr4 box-border-bottom tr">{{ formatAmount(asset.purchasePrice, $store.state.Settings?.currency || 'NGN') }}</td>
                                <!-- <td class="pv3 pr4 box-border-bottom tl"></td>
                                <td class="pv3 pr4 box-border-bottom tl"></td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { formatQuantity, formatAmount } from '@/utils/lib'
import AppWrapper from '../../layout/AppWrapper.vue'
export default {
    name: 'AssetManagementView',
    components: { AppWrapper },

    setup() {
        const store = useStore()
        const assets = computed(() => store?.state.Asset.assets)
        const assetsSummary = computed(() => store?.state.Asset.assetsSummary)

        onMounted(() => {
            store.dispatch('Asset/getAssetsSummary')
            store.dispatch('Asset/getAssets')
        })

        return {
            assets,
            assetsSummary,
            formatAmount,
            formatQuantity
        }
    },
}
</script>
<style>
.custom-active {
    /* color: #132c8c; */
    color: #132c8c;
    display: inline-block;
}
.active-menu {
    color: #132c8c;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px !important;
    border-bottom: 3px solid #132c8c;
    font-weight: 500;
}
.w-25{
    width: 25% !important;
}
</style>
