<template>
    <div class="tc pv3" v-if="!dataArray?.data?.length">
        <img :src="require('@/assets/images/No-Logs-Found.svg')" alt="" />
        <div class="d-block f5 pv1" style="color: #132c8c">{{ message ? message : 'Aww! There is nothing here!' }}</div>
        <div class="d-block pt4 pb4">
            <router-link v-if="routName" class="router-link" :to="{ name: routName }">{{
                routeText ? routeText : '+ Add New Transaction'
            }}</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableEmptyState',
    props: {
        message: String,
        dataArray: Array,
        routName: String,
        routeText: String
    }
}
</script>

<style scoped></style>
