import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { AuthModule } from '@/store/auth/authentication'
import { DashboardModule } from '@/store/dashboard'
import { EntityModule } from '@/store/entities'
import { InventoryModule } from '@/store/inventories'
import { PayablesAndReceivablesModule } from '@/store/pNr'
import { TransactionModule } from '@/store/transactions'
import { AlertModule } from '@/store/alert'
import { SettingsModule } from '@/store/settings'
import { GeneralModule } from '@/store/general'
import { ReportingModule } from '@/store/reporting'
import { InvoiceModule } from '@/store/invoice'
import { ReceiptModule } from '@/store/receipts'
import { PosModule } from '@/store/pos'
import { ProductionModule } from '@/store/production'
import { AssetModule } from '@/store/assets'
import { AdminModule } from '@/store/admin'
import { TagModule } from '@/store/tag'
import { AccountingModule } from '@/store/accounting'

export default createStore({
    plugins: [
        createPersistedState(
            {
                key: 'auth',
                paths: ['Auth.isAuthenticated']
            }
            // {
            //     key: 'admin',
            //     paths: ['Admin.adminUserData']
            // }
        )
    ],

    state: {},
    mutations: {},
    actions: {},
    modules: {
        Auth: AuthModule,
        DashboardStats: DashboardModule,
        Entity: EntityModule,
        Inventory: InventoryModule,
        PnRModule: PayablesAndReceivablesModule,
        Transaction: TransactionModule,
        Alert: AlertModule,
        Settings: SettingsModule,
        General: GeneralModule,
        Reporting: ReportingModule,
        Invoice: InvoiceModule,
        Receipt: ReceiptModule,
        Pos: PosModule,
        Production: ProductionModule,
        Asset: AssetModule,
        Admin: AdminModule,
        Tag: TagModule,
        Accounting: AccountingModule,
    }
})
