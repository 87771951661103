<template>
    <div>
        <CreateCategory />
        <CreateBrand />
        <div
            v-if="isCreateAsset"
            tabindex="0"
            @keydown.esc="closeModal"
            class="w-100 center increaseZI"
            id="add-product-form"
            :class="isCreateAsset && 'jquery-modal blocker current'"
            @click="handleOnClickOutsideModal"
        >
            <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="createAssetRef">
                <div class="flex justify-between pa3 box-border-bottom">
                    <div class="f3 b">Create Asset</div>
                    <div>
                        <span class="pointer">
                            <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                        </span>
                    </div>
                </div>
                <form class="entity-form pa3 center" ref="formRef" @submit.prevent="onSubmit" enctype="multipart/form-data">
                    <div class="">
                        <div class="flex flex-wrap pt2 justify-between pv2" style="border-width: 2px">
                            <div id="add-product-sku" class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="sku">Asset Tag<span class="required">*</span></label>
                                </div>
                                <input class="w-100" required type="text" v-model="state.tag" placeholder="Asset Tag" />
                            </div>
                            <div id="serialNumberCon" class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="serialNumber">Serial Number </label>
                                </div>
                                <input type="text" id="serialNumber" v-model="state.serialNo" placeholder="0123" />
                            </div>

                            <!-- Product name -->
                            <div class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="name">Asset Name <span class="required">*</span></label>
                                </div>
                                <input required type="text" id="name" v-model="state.name" placeholder="Asset Name" />
                            </div>
                            <div class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2">
                                    <label for="size">Condition</label>
                                </div>
                                <select class="w-100" v-model="state.condition">
                                    <option value="">Select</option>
                                    <option value="new">Brand New</option>
                                    <option value="used">Used</option>
                                </select>
                            </div>

                            <div id="manufacturerCon" class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="manufacturer">Manufacturer </label>
                                </div>
                                <input type="text" id="manufacturer" v-model="state.manufacturer" placeholder="Mercedes Benz" />
                            </div>
                            <div id="modelCon" class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="model"> Model </label>
                                </div>
                                <input type="text" id="model" v-model="state.model" placeholder="GLK" />
                            </div>
                            <div class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2">
                                    <label for="size">Group</label>
                                </div>
                                <select class="w-100" v-model="state.group" @change="filterAssetCategory">
                                    <option value="">Select</option>
                                    <option value="Machinery and Equipment">Machinery and Equipment</option>
                                    <option value="Licensed Vehicles">Licensed Vehicles</option>
                                </select>
                            </div>
                            <div class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2">
                                    <label for="size">Category <span class="required">*</span></label>
                                </div>
                                <select class="w-100" v-model="state.category" required>
                                    <option value="">Select</option>
                                    <option v-for="(category, index) in state.categories" :value="category" :key="'opt-'+index">{{ category.name }}</option>
                                </select>
                            </div>

                            <div id="usefulLifeCon" class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="usefulLife">Useful Life</label>
                                </div>
                                <input type="number" min="0" id="usefulLife" v-model="state.lifespan" placeholder="0" disabled />
                            </div>
                            <div id="salvageValueCon" class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="salvageValue">Salvage Value</label>
                                </div>
                                <input type="number" min="0" id="salvageValue" v-model="state.salvageValue" placeholder="0" />
                            </div>
                            <div id="purchasePriceCon" class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="model">
                                        Purchase price <span class="required">*</span>
                                        <small v-if="state.purchasePrice > 0">(Monthly Depreciation: {{ formatQuantity((state.purchasePrice - state.salvageValue) / state.lifespan) }})</small>
                                    </label>
                                </div>

                                <input type="number" min="0" id="model" v-model="state.purchasePrice" placeholder="0" required />
                            </div>
                            <div id="forceSaleCon" class="pt2 w-100 w-50-l pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="forceSale">Force Sale Value</label>
                                </div>
                                <input type="number" min="0" id="forceSale" v-model="state.forceSaleValue" placeholder="25,000" />
                            </div>

                            <!-- <div
                                class="flex flex-wrap justify-between items-center pt3"
                                @drop.prevent
                                @dragover.prevent
                                @dragenter.prevent
                            >
                                <div class="w-20-l w-90 pb3 b f4 pb0-l box-border1">
                                    <img
                                        src="@/assets/images/inventory-default.png"
                                        alt="Org Image"
                                        class="h-90 w-90 pointer"
                                        style="object-position: center; object-fit: cover"
                                    />
                                </div>
                                <div class="box-border pa4 w-80-l w-90">
                                    <span class="dn">
                                        <input type="file" v-model="state.imageFile" id="imageFile" accept="image/*" @change.prevent />
                                    </span>
                                    <span class="font-w1 lh-copy pointer" style="color: #132c8c" @click.prevent>Upload </span>
                                    or drag and drop your asset image PNG, JPG, GIF up to 10MB
                                </div>
                            </div> -->

                            <div
                                        class="flex flex-wrap justify-between items-center pt3"
                                        @drop.prevent="handleDragImage"
                                        @dragover.prevent
                                        @dragenter.prevent
                                    >
                                        <div class="w-20-l w-90 pb3 b f4 pb0-l box-border1">
                                            <img
                                                :src="
                                                    state?.image ||
                                                    state?.imageUrl ||
                                                    require('@/assets/images/inventory-default.png')
                                                "
                                                alt="Org Image"
                                                class="h-90 w-90 pointer"
                                                style="object-position: center; object-fit: cover"
                                                @click.prevent="handleClickUpload"
                                            />
                                        </div>
                                        <div class="box-border pa4 w-80-l w-90">
                                            <span class="dn">
                                                <input
                                                    ref="imageElRef"
                                                    type="file"
                                                    name="imageFile"
                                                    id="imageFile"
                                                    accept="image/*"
                                                    @change.prevent="handleSelectImage"
                                                />
                                            </span>
                                            <span
                                                class="font-w1 lh-copy pointer"
                                                style="color: #132c8c"
                                                @click.prevent="handleClickUpload"
                                                >Upload
                                            </span>
                                            or drag and drop your asset image in
                                            PNG, JPEG, JPG, GIF up to 10MB
                                        </div>
                                    </div>

                            <div class="pt2 w-100 pr2-l">
                                <div class="pv2 font-w1">
                                    <label>Description</label>
                                </div>

                                <textarea
                                    v-tippy="{
                                        content: '',
                                        placement: 'top',
                                        interactive: true,
                                    }"
                                    placeholder="Please enter asset description"
                                    v-model="state.description"
                                    id="description"
                                    cols="20"
                                    rows="5"
                                ></textarea>
                            </div>

                            <div class="flex flex-wrap" style="gap: 1rem">
                                <button class="mt3" type="submit" :disabled="state.disableSubmitButton">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { formatQuantity } from '@/utils/lib'
import CreateBrand from '@/components/CreateBrand'
import CreateCategory from '@/components/CreateCategory'

export default {
    name: 'CreateAsset',
    props: {
        closeModal: Function,
    },
    components: { CreateCategory, CreateBrand },
    setup() {
        const store = useStore()
        const formRef = ref(null)
        const createAssetRef = ref(null)
        const imageElRef = ref(null)
        const isCreateAsset = computed(() => store?.state.Asset.showCreateAssetModal)
        const createProductType = computed(() => store?.state.Inventory.createProductType)
        const categories = computed(() => store?.state.Settings.assetCategories)

        const initialState = () => ({
            tag: '',
            serialNo: '',
            name: '',
            condition: '',
            manufacturer: '',
            model: '',
            lifespan: '',
            salvageValue: '',
            group: '',
            category: '',
            categories: [],
            purchasePrice: '',
            forceSaleValue: '',
            image: '',
            imageUrl: '',
            imageFile: '',
            description: '',
            disableSubmitButton: false,
        })

        const state = reactive(initialState())

        watch(
            () => state.category,
            (newVal) => {
                state.lifespan = newVal.lifespan
            }
        )

        const filterAssetCategory = () => {
            state.categories = categories.value.filter(category => category.group === state.group)
        }

        const handleClickUpload = () => {
            imageElRef?.value?.click()
        }

        const handleSelectImage = (e) => {
            if (e?.target?.files?.length) {
                const file = e.target.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleDragImage = (e) => {
            if (e?.dataTransfer?.files?.length) {
                const file = e.dataTransfer.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.dataTransfer.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const onSubmit = () => { 
            state.disableSubmitButton = true
            const { disableSubmitButton, categories, ...data } = state

            const formData = new FormData()

            const dataKeys = Object.keys(data)

            dataKeys.forEach((dataKey) => {
                if (dataKey === 'category') {
                    data[dataKey] = data[dataKey].name
                }
                
                formData.append(dataKey, data[dataKey])
            })

            store.dispatch('Asset/createAsset', formData).then((resp) => { 
                if (resp.success) {
                    Object.assign(state, initialState())
                    formRef.value.reset()
                    state.disableSubmitButton = false
                    closeCreateAssetModal()
                }
            }).finally(() => state.disableSubmitButton = false);
            
        }

        const closeCreateAssetModal = () => {
            store.dispatch('Asset/showCreateAssetModal', {show: false, cat: 'ASSET'})
        }

        const handleOnClickOutsideModal = (e) => {
            if (createAssetRef.value && !createAssetRef.value.contains(e.target)) {
                closeCreateAssetModal()
            }
        }

        onMounted(() => {
            store.dispatch('Settings/getAssetCategories')
            store.dispatch('Inventory/getProductSku', 'ASSET').then((data) => {
                state.tag = data
            })
        })

        return {
            formRef,
            createAssetRef,
            imageElRef,
            state,
            filterAssetCategory,
            handleClickUpload,
            handleDragImage,
            handleSelectImage,
            handleOnClickOutsideModal,
            isCreateAsset,
            createProductType,
            onSubmit,
            formatQuantity,
        }
    },
}
</script>
