const state = {
    message: '',
    status: null,
    showMobileMenu: false //yes, not supposed to be here, but let's leave it here
}

const mutations = {
    SET_MESSAGE(state, payload = '') {
        state.message = payload
    },

    SET_STATUS(state, payload) {
        state.status = payload
    },

    SET_SHOW_MENU(state, payload) {
        state.showMobileMenu = payload
    }
}

const actions = {
    setAlert: ({ commit }, payload) => {
        commit('SET_MESSAGE', payload.message)
        commit('SET_STATUS', payload.status)

        setTimeout(() => {
            commit('SET_MESSAGE', '')
            commit('SET_STATUS', null)
        }, 5000)
    },
    setShowMenu: ({ commit }, payload) => {
        commit('SET_SHOW_MENU', payload)
    }
}

export const AlertModule = { namespaced: true, actions, state, mutations }
