import { Axios } from '@/utils/axiosConfig'
import { paginationOptions } from '@/utils/lib'
import store from '@/store'
import Router from '@/router'

const state = {
  isLoading: false,
  transactions: {},
  transaction: {},
  transactionReceipt: '',
  sales: {},
  purchases: {},
  expenses: {},
  others: {},
  summary: {
    balance: 0,
    expenses: 0,
    incomeSale: 0,
    purchases: 0
  },
  categories: [],
  brands: [],
  categoryId: '',
  brandId: '',
  sortOrder: 'DESC',
  sort: 'date',
  oldSort: '',
  prodsFromTransactionReference: null,
  assetsCategories: [],
  showAccountModal: false,
  formInformation: {},
  assetsCat: '',
  showBalanceBox: false,
  accountDetails: {},
  accountBalance: 0,
  expensesGraphData: {},
  salesGraphData: {},
  purcahasesGraphData: {},
  showTransferModal: false,
  showTransactionImportModal: false,
  transactionErrors: [],
  showRecordPaymentModal: false,
  transactionForPayment: null,
  showSendReceiptModal: false,
  receiptReference: null,
  showEditTransactionModal: false,
  transaction: null,
}

const mutations = {
  SET_ACCOUNT_DETAILS(state, payload) {
    state.accountDetails = payload
  },
  SET_ACCOUNT_BALANCE(state, payload) {
    state.accountBalance = payload
  },

  SET_SHOW_ACCOUNT(state, payload = false) {
    state.showAccountModal = payload
  },
  SET_BALANCE_BOX(state, payload = false) {
    state.showBalanceBox = payload
  },
  SET_SHOW_TRANSFER_MODAL(state, payload = false) {
    state.showTransferModal = payload
  },
  SET_SHOW_SEND_RECEIPT_MODAL(state, payload) {
    state.showSendReceiptModal = payload?.status || false
    state.receiptReference = payload?.reference || null
  },
  SET_SHOW_EDIT_TRANSACTION_MODAL(state, payload) {
    state.showEditTransactionModal = payload?.status || false
    state.transaction = payload?.transaction || null
  },
  SET_SHOW_TRANSACTION_IMPORT_MODAL(state, payload = false) {
    state.showTransactionImportModal = payload
  },
  SET_SHOW_TRANSACTION_IMPORT_ERRORS(state, data) {
    state.transactionErrors = data
  },
  SET_ACCOUNT_INFORMATION(state, payload = {}) {
    state.formInformation = payload
  },
  SET_IS_LOADING(state, payload = false) {
    state.isLoading = payload
  },

  SET_SORT(state, payload = { sortOrder: 'DESC', sort: 'date' }) {
    const sort = store.state?.Transaction?.sort
    state.oldSort = sort
    state.sortOrder = payload?.sortOrder
    state.sort = payload?.sort
  },

  SET_CATEGORY_ID(state, payload) {
    state.categoryId = payload
  },

  SET_BRAND_ID(state, payload) {
    state.brandId = payload
  },

  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },

  SET_BRANDS(state, payload) {
    state.brands = payload
  },

  SET_ALL_TRANSACTIONS(state, payload) {
    // eslint-disable-next-line
    const sort = store.state?.Transaction?.sort
    // eslint-disable-next-line no-unused-vars
    const sortOrder = store.state?.Transaction?.sortOrder
    // eslint-disable-next-line no-unused-vars
    const sortedPayload = null

    state.transactions = payload
  },

  SET_SALES(state, payload = null) {
    state.sales = payload
  },

  SET_PURCHASES(state, payload = null) {
    state.purchases = payload
  },

  SET_EXPENSES(state, payload = null) {
    state.expenses = payload
  },

  SET_OTHERS(state, payload = null) {
    state.others = payload
  },

  SET_TRANSACTION(state, payload) {
    state.transaction = payload
  },

  SET_TRANSACTION_RECEIPT(state, payload) {
    state.transactionReceipt = payload
  },

  SET_TRANSACTION_SUMMARY(state, payload = null) {
    state.summary = payload
  },

  SET_PRODUCTS_FROM_TRANSACTION_REFERENCE(state, payload) {
    state.prodsFromTransactionReference = payload
  },

  SET_ASSET_CATEGORIES(state, payload) {
    state.assetsCategories = payload
  },

  SET_ASSET_CAT(state, payload) {
    state.assetsCat = payload
  },
  SET_EXPENSES_GRAPH_DATA(state, payload) {
    state.expensesGraphData = payload
  },
  SET_SALES_GRAPH_DATA(state, payload) {
    state.salesGraphData = payload
  },
  SET_PURCHASES_GRAPH_DATA(state, payload) {
    state.purcahasesGraphData = payload
  },

  SET_RECORD_PAYMENT_MODAL(state, payload) {
    state.showRecordPaymentModal = payload.status
    state.transactionForPayment = payload.transaction
  },
}

const actions = {
  setIsLoading({ commit }, payload) {
    commit('SET_IS_LOADING', payload)
  },
  showAccountModal: ({ commit }, payload) => {
    commit('SET_SHOW_ACCOUNT', payload)
  },
  showBalanceBox: ({ commit }, payload) => {
    commit('SET_BALANCE_BOX', payload)
  },
  toggleTransferModal: ({ commit }, payload) => {
    commit('SET_SHOW_TRANSFER_MODAL', payload)
  },
  toggleSendReceiptModal: ({ commit }, payload) => {
    commit('SET_SHOW_SEND_RECEIPT_MODAL', payload)
  },
  toggleEditTransactionModal: ({ commit }, payload) => {
    commit('SET_SHOW_EDIT_TRANSACTION_MODAL', payload)
  },
  toggleTransactionImportModal: ({ commit }, payload) => {
    commit('SET_SHOW_TRANSACTION_IMPORT_MODAL', payload)
  },
  setFormInfomation: ({ commit }, payload) => {
    commit('SET_ACCOUNT_INFORMATION', payload)
  },
  sortTransactions({ dispatch }, payload) {
    // commit('SET_SORT', payload)
    dispatch(payload?.action, payload?.query)
  },

  setBrandId({ commit }, payload) {
    commit('SET_BRAND_ID', payload)
  },

  setCategoryId({ commit }, payload) {
    commit('SET_CATEGORY_ID', payload)
  },

  setProductsFromTransactionRef: async ({ commit }, payload) => {
    const { reference, type } = payload
    if (!reference || reference.length < 6 || !type) {
      return store.dispatch('Alert/setAlert', {
        message: 'Please enter a valid transaction reference number',
        status: false
      })
    }

    commit('SET_IS_LOADING', true)
    commit('SET_PRODUCTS_FROM_TRANSACTION_REFERENCE', null)

    return Axios(`/api/v1/transactions/products/${payload.reference}/${payload.type}`)
      .then(async ({ data }) => {
        commit('SET_PRODUCTS_FROM_TRANSACTION_REFERENCE', data?.data)
        commit('SET_IS_LOADING', false)

        return data
      })
      .catch(async err => {
        const { data } = err.response
        console.error(data)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  createBrand: async ({ commit }, formData) => {
    return Axios.post('/api/v1/brands', formData)
      .then(async ({ data }) => {
        commit('SET_BRAND_ID', data.brandId)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

        return data?.success
      })
      .catch(async err => {
        const { data } = err.response
        console.error(data)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },
  createAssetCategory: async ({ commit }, formData) => {
    return Axios.post('/api/v1/assets/create', formData)
      .then(async ({ data }) => {
        commit('SET_ASSET_CAT', data.asset.category)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

        return data?.success
      })
      .catch(async err => {
        const { data } = err.response
        console.error(data)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  createCategory: async ({ commit }, formData) => {
    return Axios.post('/api/v1/categories', formData)
      .then(async ({ data }) => {
        commit('SET_CATEGORY_ID', data.categoryId)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

        return data?.success
      })
      .catch(async err => {
        const { data } = err.response
        console.error(data)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  createNewTransaction: async (_, formData) => {
    await store.dispatch('Inventory/toggleLoadingState', true)
    return Axios.post('/api/v1/transactions', formData)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        await store.commit('Entity/SET_NEW_ENTITY_ID')
        // window.location.reload()
        // return data?.success
        return data
      })
      .catch(async err => {
        const { data } = err.response
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  createSalesTransaction: async (_, formData) => {
    await store.dispatch('Inventory/toggleLoadingState', true)
    return Axios.post('/api/v1/transactions/sales', formData)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        await store.commit('Entity/SET_NEW_ENTITY_ID')
        // window.location.reload()
        // return data?.success
        return data
      })
      .catch(async err => {
        const { data } = err.response
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  createPurchaseTransaction: async (_, formData) => {
    await store.dispatch('Inventory/toggleLoadingState', true)
    return Axios.post('/api/v1/transactions/purchases', formData)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        await store.commit('Entity/SET_NEW_ENTITY_ID')
        return data
      })
      .catch(async err => {
        const { data } = err.response
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  createExpenseTransaction: async (_, formData) => {
    await store.dispatch('Inventory/toggleLoadingState', true)
    return Axios.post('/api/v1/transactions/expenses', formData)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        await store.commit('Entity/SET_NEW_ENTITY_ID')
        return data
      })
      .catch(async err => {
        const { data } = err.response
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  createMoneyInTransaction: async (_, formData) => {
    await store.dispatch('Inventory/toggleLoadingState', true)
    return Axios.post('/api/v1/transactions/money-in', formData)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        await store.commit('Entity/SET_NEW_ENTITY_ID')
        return data
      })
      .catch(async err => {
        const { data } = err.response
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  createMoneyOutTransaction: async (_, formData) => {
    await store.dispatch('Inventory/toggleLoadingState', true)
    return Axios.post('/api/v1/transactions/money-out', formData)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        await store.commit('Entity/SET_NEW_ENTITY_ID')
        return data
      })
      .catch(async err => {
        const { data } = err.response
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  editTransaction: async ({ commit }, payload) => {
    return Axios.patch(`/api/v1/transactions/${payload.id}`, payload.data)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data?.success
      })
      .catch(async err => {
        const { data } = err.response
        console.error(data)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  // /transactions/expense/receipt

  saveExpenseReceipt: async (_, formData) => {
    return Axios.post('/api/v1/transactions/expense/receipt', formData)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        //await store.commit('Entity/SET_NEW_ENTITY_ID')
        // return data?.success
        return data
      })
      .catch(async err => {
        const { data } = err.response
        console.log(data)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        // return data?.success
        return data
      })
  },

  getAllTransactions: async ({ commit }, payload) => {
    // console.log(paginationOptions(payload))
    // commit('SET_ALL_TRANSACTIONS', null)
    try {
      const { data } = await Axios.get(`/api/v1/transactions?${paginationOptions(payload)}`)
      commit('SET_ALL_TRANSACTIONS', data)
      if (data.status === 403) {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
      }
    } catch (e) {
      console.log(e.response.data)
    }
  },

  getAllSalesTransactions: async ({ commit }, payload) => {
    commit('SET_SALES')
    try {
      const { data } = await Axios.get(`/api/v1/transactions/sales?${paginationOptions(payload)}`)
      commit('SET_SALES', data)
      if (data.status === 403) {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
      }
    } catch (e) {
      console.log(e.response.data)
    }
  },

  getAllPurchaseTransactions: async ({ commit }, payload) => {
    commit('SET_PURCHASES')
    try {
      const { data } = await Axios.get(`/api/v1/transactions/purchases?${paginationOptions(payload)}`)
      commit('SET_PURCHASES', data)
      if (data.status === 403) {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
      }
    } catch (e) {
      console.log(e.response.data)
    }
  },

  getAllExpenseTransactions: async ({ commit }, payload) => {
    commit('SET_EXPENSES')
    try {
      const { data } = await Axios.get(`/api/v1/transactions/expenses?${paginationOptions(payload)}`)
      commit('SET_EXPENSES', data)
      if (data.status === 403) {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
      }
    } catch (e) {
      console.log(e.response.data)
    }
  },

  getAllOtherTransactions: async ({ commit }, payload) => {
    commit('SET_OTHERS')
    try {
      const { data } = await Axios.get(`/api/v1/transactions/others?${paginationOptions(payload)}`)
      commit('SET_OTHERS', data)
      if (data.status === 403) {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
      }
    } catch (e) {
      console.log(e.response.data)
    }
  },

  getTransactionReceiptPdf: async ({ commit }, payload) => {
    const { reference, orgId, userId } = payload
    return Axios.get(`/api/v1/${orgId}/${userId}/transaction/${reference}/pdf`, { responseType: 'blob' })
      .then(({ data }) => {
        commit('SET_TRANSACTION_RECEIPT', data)
        return data
      })
      .catch(err => console.log(err?.response?.data))
  },

  getExpenseReceipt: async ({ commit }, payload) => {
    return Axios.get(`/api/v1/transactions/expense/receipt/download?reference=${payload}`, { responseType: 'blob' })
      .then(({ data }) => {
        return data
      })
      .catch(err => console.log(err?.response?.data))
  },

  getTransaction: async ({ commit }, payload) => {
    commit('SET_TRANSACTION', null)
    const { reference, prepaid, business } = payload
    const url =
      typeof payload === 'object' && reference !== undefined && prepaid !== null
        ? `/api/v1/transactions/${reference}?prepaid=${prepaid}&business=${business}`
        : `/api/v1/transactions/${reference}?business=${business}`
    return Axios.get(url)
      .then(({ data }) => {
        commit('SET_TRANSACTION', data)

        return data
      })
      .catch(err => console.log(err?.response?.data))
  },

  deleteSalesTransaction: async ({ dispatch }, payload) => {
    const routeName = Router.currentRoute.value.name

    return Axios.delete(`/api/v1/transactions/${payload}/sales`)
      .then(async ({ data }) => {
        dispatch('getAllTransactions')
        dispatch('getAllSalesTransactions')
        dispatch('getAllPurchaseTransactions')
        dispatch('getAllExpenseTransactions')
        dispatch('getAllOtherTransactions')
        dispatch('getTransactionSummary')

        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

        if (routeName !== 'Transactions') {
          Router.back()
        }

        // Router.replace({ name: 'Transactions' })

        return data
      })
      .catch(async err => {
        const data = err?.response?.data
        console.log(err?.response?.data)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  deleteSalesRepaymentTransaction: async ({ dispatch }, payload) => {
    const routeName = Router.currentRoute.value.name

    return Axios.delete(`/api/v1/transactions/${payload}/sales-repayment`)
      .then(async ({ data }) => {
        dispatch('getAllTransactions')
        dispatch('getAllSalesTransactions')
        dispatch('getAllPurchaseTransactions')
        dispatch('getAllExpenseTransactions')
        dispatch('getAllOtherTransactions')
        dispatch('getTransactionSummary')

        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

        if (routeName !== 'Transactions') {
          Router.back()
        }

        // Router.replace({ name: 'Transactions' })

        return data
      })
      .catch(async err => {
        const data = err?.response?.data
        console.log(err?.response?.data)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  deleteTransaction: async ({ dispatch }, payload) => {
    // eslint-disable-next-line no-underscore-dangle

    const routeName = Router.currentRoute.value.name

    return Axios.delete(`/api/v1/transactions/${payload}`)
      .then(async ({ data }) => {
        dispatch('getAllTransactions')
        dispatch('getAllSalesTransactions')
        dispatch('getAllPurchaseTransactions')
        dispatch('getAllExpenseTransactions')
        dispatch('getAllOtherTransactions')
        dispatch('getTransactionSummary')

        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

        if (routeName !== 'Transactions') {
          Router.back()
        }

        // Router.replace({ name: 'Transactions' })

        return data
      })
      .catch(async err => {
        const data = err?.response?.data
        console.log(err?.response?.data)
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        return data
      })
  },

  getReceipt: async ({ commit }, payload) => {
    commit('SET_TRANSACTION', null)
    return Axios.get(`/api/v1/receipt/${payload.orgId}/${payload.reference}`)
      .then(async ({ data }) => {
        await store.commit('Auth/SET_USER_DATA', { org: data?.companyName })
        commit('SET_TRANSACTION', data)

        // store.commit('Auth/SET_USER_DATA', { org: data.companyName })

        return data
      })
      .catch(err => console.log(err?.response?.data || console.log(err)))
  },

  getTransactionSummary: async ({ commit }, payload) => {
    commit('SET_TRANSACTION_SUMMARY')
    try {
      const { data } = await Axios.get(`/api/v1/transactions/summary?${paginationOptions(payload)}`)

      commit('SET_TRANSACTION_SUMMARY', data)
    } catch (e) {
      console.log(e.response.data)
    }
  },

  exportTransactions: async (_, payload) => {
    return Axios.get(`/api/v1/transactions/export/csv?${paginationOptions(payload)}`)
      .then(async ({ data }) => {
        return data
      })
      .catch(async err => {
        const { data } = err.response
        return data
      })
  },

  getAssetCategories: async ({ commit }, payload) => {
    commit('SET_ASSET_CATEGORIES')
    try {
      const { data } = await Axios.get(`/api/v1/assets/getAssets`)
      commit('SET_ASSET_CATEGORIES', data)
    } catch (e) {
      console.log(e.response.data)
    }
  },
  getAccountDetails: async ({ commit }) => {
    return Axios.get(`/api/v1/accounts/balancedetails`)
      .then(({ data }) => {
        commit('SET_ACCOUNT_DETAILS', data.data)
        commit('SET_ACCOUNT_BALANCE', data.accountBalance)
        return data.data
      })
      .catch(err => {
        console.error(err.response.data)
        return err.response.data
      })
  },

  getSalesGraphData: async ({ commit }, payload) => {
    try {
      const { data } = await Axios.post(`/api/v1/transaction/graphdata`, payload)
      commit('SET_SALES_GRAPH_DATA', data)
    } catch (err) {
      const { data } = err.response
      await store.dispatch('Alert/setAlert', { message: data.message, status: data.data })

      // if (err.response.status === 403) {
      //     await Router.replace({ name: 'Dashboard' })
      // }
      console.error(err.response.data)
    }
  },

  getPurchasesGraphData: async ({ commit }, payload) => {
    try {
      const { data } = await Axios.post(`/api/v1/transaction/graphdata`, payload)
      commit('SET_PURCHASES_GRAPH_DATA', data)
    } catch (err) {
      const { data } = err.response
      await store.dispatch('Alert/setAlert', { message: data.message, status: data.data })

      // if (err.response.status === 403) {
      //     await Router.replace({ name: 'Dashboard' })
      // }
      console.error(err.response.data)
    }
  },

  getExpensesGraphData: async ({ commit }, payload) => {
    try {
      const { data } = await Axios.post(`/api/v1/transaction/graphdata`, payload)
      commit('SET_EXPENSES_GRAPH_DATA', data)
    } catch (err) {
      const { data } = err.response
      await store.dispatch('Alert/setAlert', { message: data.message, status: data.data })

      // if (err.response.status === 403) {
      //     await Router.replace({ name: 'Dashboard' })
      // }
      console.error(err.response.data)
    }
  },

  accountTransfer: async (_, payload) => {
    return Axios.post('/api/v1/transaction/account-transfer', payload)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { status: data.status, message: data.message })
        await store.commit('Entity/SET_NEW_ENTITY_ID')
        return data
      })
      .catch(async err => {
        const { data } = err.response
        console.log(data)
        await store.dispatch('Alert/setAlert', { status: data.status, message: data.message })
        return data
      })
  },

  downloadBulkTransactionTemplate: async () => {
    return Axios.get('/api/v1/transactions/bulk-template/download')
      .then(async ({ data }) => {
        return data
      })
      .catch(async err => {
        const { data } = err.response
        return data
      })
  },

  importBulkTransactions: async ({ commit }, payload) => {
    return Axios.post('/api/v1/transactions/bulk-import', payload)
      .then(async ({ data }) => {
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        commit('SET_SHOW_TRANSACTION_IMPORT_ERRORS', data?.errors)
        return data
      })
      .catch(async err => {
        const { data } = err.response
        await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
        // console.log(err.response)
        return data
      })
  },

  showRecordPaymentModal: async ({ commit }, payload) => {
    commit('SET_RECORD_PAYMENT_MODAL', payload)
  },

  recordPayment: async ({ commit }, payload) => {
    try {
      const { data } = await Axios.post(`/api/v1/transactions/record-payment`, payload)
      await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
      return data
    } catch (error) {
      console.error(error)
      const { data } = error.response
      await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
      return data
    }
  },

  resendReceipt: async (_, payload) => {
    try {
      const { data } = await Axios.post(`/api/v1/transactions/${payload}/resend-receipt`, {})
      await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
      return data
    } catch (error) {
      console.error(error)
      const { data } = error.response
      await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
      return data
    }
  },

  sendReceipt: async (_, payload) => {
    try {
      const { reference, name, email } = payload
      const { data } = await Axios.post(`/api/v1/transactions/${reference}/resend-receipt`, { name, email })
      await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
      return data
    } catch (error) {
      console.error(error)
      const { data } = error.response
      await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
      return data
    }
  },
}

export const TransactionModule = { namespaced: true, actions, mutations, state }
