<template>
    <InviteTeamMember :is-open="state2.isOpen" :close-modal="toggleAddTeamModal" />
    <UploadInventoryModal :close-modal="toggleImportInventoryModal" />
    <div class="getStartedWrapper" v-if="showGetStarted" ref="getStartedModal" @click="closeModalOnOutsideClick">
        <div class="getStartedCon" v-if="getStartedd">
            <div>
                <div class="sec1">Welcome to Simplebks Inc.</div>
                <div class="sec2">Let’s help you get set up with these quick steps below:</div>
            </div>
            <section class="sec3" style="cursor: pointer">
                <router-link :to="{ name: 'BusinessProfile' }">
                    <div class="flex items-center pv3" style="gap: 16px">
                        <div v-if="userData?.address !== null"><img src="@/assets/images/green-circle.svg" alt="" /></div>
                        <div v-else><img src="@/assets/images/red-circle.svg" alt="" /></div>
                        <div class="tl w-70">Complete business profile</div>
                    </div>
                </router-link>
                <div @click.prevent="toggleImportInventoryModal">
                    <div class="flex items-center pv3" style="gap: 16px">
                        <div v-if="productsCount > 0"><img src="@/assets/images/green-circle.svg" alt="" /></div>
                        <div v-else><img src="@/assets/images/red-circle.svg" alt="" /></div>
                        <div class="tl w-70">Upload inventory list</div>
                    </div>
                </div>
                <router-link :to="{ name: 'BankAccounts' }">
                    <div class="flex items-center pv3" style="gap: 16px">
                        <div v-if="userData?.bankName"><img src="@/assets/images/green-circle.svg" alt="" /></div>
                        <div v-else><img src="@/assets/images/red-circle.svg" alt="" /></div>
                        <div class="tl w-70">Add Bank Account</div>
                    </div>
                </router-link>
                <router-link :to="{ name: 'AddTransactionNew' }">
                    <div class="flex items-center pv3" style="gap: 16px">
        
                        <div v-if="salesCount > 0"><img src="@/assets/images/green-circle.svg" alt="" /></div>
                        <div v-else><img src="@/assets/images/red-circle.svg" alt="" /></div>
                        <div class="tl w-70">Record First Sales</div>
                    </div>
                </router-link>
                <div @click="toggleAddTeamModal">
                    <div class="flex items-center pv3" style="gap: 16px">
                        <div v-if="membersCount > 0"><img src="@/assets/images/green-circle.svg" alt="" /></div>
                        <div v-else><img src="@/assets/images/red-circle.svg" alt="" /></div>
                        <div class="tl w-70">Add team member</div>
                    </div>
                </div>

                <div class="flex items-center">
                    <progress max="100" style="margin-left: 0" :value="wizardStatus"></progress><span>{{ wizardStatus}}%</span>
                </div>
            </section>
            <p class="sec4" style="cursor: pointer">Dont show this again</p>
        </div>
        <div  v-if="getStartedd" class="closeBtn" @click="closeGetStarted"><img src="@/assets/images/close.svg" /></div>
        <div v-else @click="openGetStarted" class="pt2 hand closeBtn">
            <img src="@/assets/images/Open.svg" alt="" />
        </div>
    </div>
</template>
<script>
import { ref, computed, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import UploadInventoryModal from '@/components/UploadInventoryModal'
import InviteTeamMember from '@/components/InviteTeamMember'
export default {
    name: 'NewGetStarted',
    components: {
        UploadInventoryModal,
        InviteTeamMember,
    },

    // props: {
    //     closeModal: Function,
    // },
    setup(props) {
        const getStartedModal = ref(null)
        const getStartedd = ref(false)
        const store = useStore()
        const getStarted = computed(() => store?.state?.Settings?.getStarted)
        const productsCount = computed(() => store?.state?.Settings?.getStarted?.productsCount)
        const membersCount = computed(() => store?.state?.Settings?.getStarted?.membersCount)
        const bankAccounts = computed(() => store?.state?.Settings?.getStarted?.bankAccounts)
        const profileCount = computed(() => store?.state?.Settings?.getStarted?.profileCount)
        const readUserGuideCount = computed(() => store?.state?.Settings?.getStarted?.readUserGuideCount)
        const isAuthenticated = computed(() => store?.state?.Auth?.isAuthenticated)
        const userData = computed(() => store?.state?.Auth?.userData)
        const summary = computed(() => store?.state.DashboardStats.summary)
        const showGetStarted =  computed(() => store?.state?.Settings?.getStarted?.showGetStarted)
        // const sales = computed(() => store?.state?.Transaction.sales)
        const salesCount = computed(() => store?.state?.Settings?.getStarted?.salesCount)

        const state = reactive({
            isCompleteBusinessProfile: true,
            isAddTeamMember: false,
            isUploadInventory: false,
            hasBankDetails: false,
            isReadUserGuide: false,
            currentWizard: 'isCompleteBusinessProfile',
            hasMoreThanOneSale: false,
        })

        watch(
            () => getStarted.value,
            (prev, next) => {
                if (prev !== next) {
                    if (getStarted.value?.membersCount > 0) {
                        state.isAddTeamMember = false
                        state.isUploadInventory = true
                    }

                    if (getStarted.value?.productsCount > 0) {
                        state.isUploadInventory = false
                        state.isCompleteBusinessProfile = true
                    }

                    if (getStarted.value?.bankAccounts > 0) {
                        state.isCompleteBusinessProfile = false
                        state.hasBankDetails = true
                    }

                    if (getStarted.value?.profileCount > 0) {
                        state.hasBankDetails = false
                        state.isReadUserGuide = true
                    }

                    if (getStarted.value?.readUserGuideCount > 0) {
                        state.isReadUserGuide = true
                    }
                    if (getStarted.value?.salesCount > 0) {
                         state.hasMoreThanOneSale = true
                    }
                }
            }
        )

        const toggleImportInventoryModal = () => {
            store.dispatch('Inventory/toggleImportInventoryModal')
        }

        const state2 = reactive({
            isOpen: false,
            isOpenImportModal: false,
        })

        const toggleAddTeamModal = () => {
            if (state2.isOpen) {
                state2.isOpen = false
            } else {
                state2.isOpen = true
            }
        }

        const closeGetStarted = () => {
            getStartedd.value = false
        }
        const openGetStarted = () => {
            getStartedd.value = true
        }

        const wizardStatus = computed(() => {
            let count = 0
            if (store?.state?.Settings?.getStarted?.productsCount > 0) {
                count += 20
            }

            if (store?.state?.Settings?.getStarted?.membersCount > 0) {
                count += 20
            }

            if (store?.state?.Settings?.getStarted?.profileCount > 0) {
                count += 20
            }
            if (store?.state?.Settings?.getStarted?.salesCount > 0) {
                count += 20
            }

            if (userData.value?.bankAccountNumber) {
                count += 20
            }

            return count
        })

        onMounted(() => {
            if(isAuthenticated.value) {
                getStartedd.value = true
                store.dispatch('Settings/getGetStarted')
                store.dispatch('DashboardStats/getDashboardStats')
            }
        })
        return {
            getStartedModal,
            // closeModalOnOutsideClick,
            state,
            toggleImportInventoryModal,
            state2,
            toggleAddTeamModal,
            getStarted,
            productsCount,
            membersCount,
            bankAccounts,
            profileCount,
            readUserGuideCount,
            userData,
            salesCount,
            getStartedd,
            closeGetStarted,
            openGetStarted,
            wizardStatus,
            showGetStarted 
        }
    },
}
</script>
<style>
.getStartedCon {
    border-radius: 8px 8px 0px 0px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(28, 27, 32, 0.08);
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box !important;
    width: 260px;
}
.sec1 {
    font-weight: 700;
    font-size: 16px;
}
.sec2 {
    font-size: 12px;
    padding: 10px 0;
}
.sec3 {
    padding: 10px 0;
}

.closeBtn {
    position: absolute;
    right: 23px;
    bottom: 0px;
    cursor: pointer;
    width: 40px;
    z-index: 25 !important;
}
.getStartedWrapper {
    box-sizing: border-box !important;
    width: 316px;
    z-index: 23 !important;
    bottom: 50px;
    /* bottom: 50px; */
    left: 0px;
    position: fixed;
}
.sec4 {
    font-size: 12px;
    padding: 10px 0;
    color: #a9a9a9;
}
progress[value] {
    --color: #10d55f;
    --background: #d9d9d9;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 200px;
    height: 5px;
    margin: 0 10px;
    border-radius: 10em !important;
    background: var(--background);
}
progress[value]::-webkit-progress-bar {
    border-radius: 10em;
    background: var(--background);
}

progress[value]::-webkit-progress-value {
    border-radius: 10em;
    background: var(--color);
}

progress[value]::-moz-progress-bar {
    border-radius: 10em;
    background: var(--color);
}

</style>
