import moment from 'moment-timezone'

const state = {
    // todaysDate: moment().tz("America/New_York").format("YYYY-MM-DD"),
    todaysDate: moment(new Date()).format('YYYY-MM-DD'),
}

const mutations = {}

const actions = {}

export const GeneralModule = { namespaced: true, state, mutations, actions }
