<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-100 center">
                <div class="w-100">
                    <div class="f3 b w-90 center pv4" style="padding-right: 2px">Asset Transaction</div>
                </div>

                <div class="scrollmenu w-90 center">
                    <table class="w-100 tl mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Date</span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>ID</span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Type</span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tc ph3">
                                    <span class="flex items-center">
                                        <span>Entity Name</span>
                                    </span>
                                </th>
                                <th class="pb3 pr5 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Description</span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Amount</span>
                                    </span>
                                </th>
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Actions</span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="transaction in transactions" :key="transaction._id">
                                <td class="pv3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>{{ formatDate(transaction.date, 'DD/MM/YYYY')}} </span>
                                    </span>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl b">
                                    <router-link :to="{ name: 'Transaction', params: { id: transaction?.reference } }">
                                        <span class="flex items-center">
                                            <span>{{ transaction?.number || transaction?.reference }}</span>
                                        </span>
                                    </router-link>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span v-if="transaction.type === 'asset_buy'">Asset Buy</span>
                                        <span v-if="transaction.type === 'asset_sale'">Asset Sale</span>
                                    </span>
                                </td>
                                <td class="pv3 box-border-bottom tc ph3">
                                    <span class="flex items-center">
                                        <span>{{ transaction.entityName }}</span>
                                    </span>
                                </td>
                                <td class="pv3 pr5 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>{{ transaction?.description || ''}}</span>
                                    </span>
                                </td>
                                <td class="pv3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>{{ formatAmount(transaction.amount, $store.state.Settings?.currency) }}</span>
                                    </span>
                                </td>
                                <td class="justify-between items-center pv3 pr3 bb b--black-20 tl">
                                    <router-link :to="{ name: 'Transaction', params: { id: transaction?.reference } }">
                                        <span v-tippy="'View receipt'">
                                            <img :src="require('@/assets/images/enable.svg')" class="pr2" alt=""
                                        /></span>
                                    </router-link>
                                    <!-- <span v-tippy="'Delete transaction'">
                                        <img :src="require('@/assets/images/delete.svg')" class="pr2" alt=""
                                    /></span>
                                    <span v-tippy="'Receive'"
                                        ><img :src="require('@/assets/images/Reload.svg')" class="pr2" alt=""
                                    /></span> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template></app-wrapper
    >
</template>
<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { formatAmount, formatDate } from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper'
export default {
    name: 'AssetTransactions',
    components: { AppWrapper },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const transactions = computed(() => store?.state.Asset.transactions)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)

        onMounted(() => {
            store.dispatch('Asset/getAssetTransactions')
        })

        return {
            transactions,
            rolePermissions,
            role,
            formatAmount,
            formatDate,
        }
    },
}
</script>
