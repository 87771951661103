<template>
    <div class="scrollmenu flex pv2 ph2">
        <button
            style="background-color: #febf12; color: #132C8C; padding: 10px 20px;"
            class="dropdown-togglex"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
            <div class="flex items-center">
                <span style="margin-right: 10px; font-size: 14px; font-weight: 600;">
                  Quick Action
                  </span>
                <span style="height: 20px"> <img src="../assets/images/chevron-down-revamp.svg" alt="" /></span>
            </div>
        </button>
        <ul class="dropdown-menu mbDrop" aria-labelledby="dropdownMenuLink" style="cursor: pointer">
            <router-link
                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(23)"
                :to="{ name: 'CreateSalesTransaction' }"
                @click="changeBusinessType('sales')"
                class="flex items-center pv3 ph3 overflow-hidden menu-hover"
            >
                <div>Sales record</div>
            </router-link>
            <router-link
                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(23)"
                :to="{ name: 'CreatePurchaseTransaction' }"
                @click="changeBusinessType('purchase')"
                class="flex items-center pv3 ph3 overflow-hidden menu-hover"
            >
                <div>Purchase record</div>
            </router-link>
            <router-link
                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(24)"
                :to="{ name: 'CreateExpenseTransaction', query: { type: 'outflows', direct: true } }"
                @click="changeBusinessType('expenses')"
                class="flex items-center pv3 ph3 overflow-hidden menu-hover"
            >
                <div>Expense</div>
            </router-link>
            <router-link
                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(24)"
                :to="{ name: 'CreateMoneyInTransaction', query: { type: 'inflows', subtype: 'capital', direct: true } }"
                @click="changeBusinessType('inflows')"
                class="flex items-center pv3 ph3 overflow-hidden menu-hover"
            >
                <div>Money In</div>
            </router-link>
            <router-link
                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(24)"
                :to="{ name: 'CreateMoneyOutTransaction', query: { type: 'outflows', subtype: 'credit_repayment', direct: true } }"
                @click="changeBusinessType('outflows')"
                class="flex items-center pv3 ph3 overflow-hidden menu-hover"
            >
                <div>Money Out</div>
            </router-link>
            <router-link
                v-if="
                    plan?.businessPlan !== 'Micro Business Plan' &&
                    (role === 'owner' || role === 'admin' || rolePermissions?.includes(57))
                "
                :to="{ name: 'PurchaseOrderCreate' }"
               class="flex items-center pv3 ph3 overflow-hidden menu-hover"
            >
                <div>Purchase order</div>
            </router-link>
            <a
                v-if="
                    plan?.businessPlan !== 'Micro Business Plan' &&
                    (role === 'owner' || role === 'admin' || rolePermissions?.includes(30))
                "
                @click.prevent="() => toggleCreateEntity(true)"
               class="flex items-center pv3 ph3 overflow-hidden menu-hover"

                ><div>Customer</div></a
            >
            <router-link
                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(31)"
                :to="{ name: 'InvoiceNew' }"
            class="flex items-center pv3 ph3 overflow-hidden menu-hover"
            >
                <div>Invoice</div>
            </router-link>
            <a
                v-if="
                    plan?.businessPlan !== 'Micro Business Plan' &&
                    (role === 'owner' || role === 'admin' || rolePermissions?.includes(32))
                "
                @click.prevent="toggleCreateProduct({ show: true, cat: 'PRODUCT' })"
               class="flex items-center pv3 ph3 overflow-hidden menu-hover"
            >
                <div>Product or Services</div></a
            >
            <router-link
                v-if="
                    plan?.businessPlan !== 'Micro Business Plan' &&
                    (role === 'owner' || role === 'admin' || rolePermissions?.includes(142))
                "
                :to="{ name: 'Produce' }"
                class="flex items-center pv3 ph3 overflow-hidden menu-hover"
                ><div>Production Batch</div></router-link
            >
        </ul>
        <CreateEntity :closeModal="() => toggleCreateEntity()" />
    </div>
</template>
<script>
import { computed, reactive, ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import CreateEntity from '@/components/CreateEntity'
import CreateProduct from '@/components/CreateProduct'
export default {
    name: 'DashboardCTA',
    components: { CreateEntity, CreateProduct },
    setup() {
        const store = useStore()
        const isSmallScreen = ref(false)
        const toggleClass = ref('')
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const plan = computed(() => store.state?.Settings?.paymentPlan)

        const checkScreenSize = () => {
            isSmallScreen.value = window.innerWidth <= 768 // Adjust the breakpoint as needed
            toggleClass.value = isSmallScreen.value ? 'small-screen' : 'large-screen'
        }

        onMounted(() => {
            checkScreenSize()
            window.addEventListener('resize', checkScreenSize)
            store.dispatch('Settings/getPlan')
        })

        onBeforeUnmount(() => {
            window.removeEventListener('resize', checkScreenSize)
        })
        const state = reactive({
            type: 'PRODUCT',
            isAddTeamMember: false,
        })

        const toggleCreateProduct = (payload) => {
            state.type = payload?.cat || 'PRODUCT'
            store.dispatch('Inventory/showCreateProduct', payload)
        }
        const changeBusinessType = (type) => {
            store.dispatch('DashboardStats/getBusinessType', type)
        }
        const toggleCreateEntity = (payload) => {
            store.dispatch('Entity/toggleCreateEntity', payload)
        }
        return {
            changeBusinessType,
            toggleCreateEntity,
            toggleCreateProduct,
            isSmallScreen,
            toggleClass,
            role,
            rolePermissions,
            plan,
        }
    },
}
</script>
<style>
.mbDrop {
    width: 160px;
}
.menu-hover:hover {
    background-color: rgb(233, 237, 252);
    border-radius: 8px;
    /* transform: scale(1.1); */
    transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 599px) {
    #dropdownMenuLink {
        width: 100%;
    }
    /* .mbDrop {
        width: 120px;
    } */
}
</style>
