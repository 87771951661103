<template>
    <div class="w-90 center pt5 pb4" style="margin: auto">
        <section class="planSec1">
            <div class="b font-w3 planHeader pb2">Choose the right plan that fits your business</div>
            <div class="planSub">Find the perfect plan for your business and keep your finances in check</div>
            <div class="planBtnCon pt2">
                <span :class="['planBtn', { planBtnActive: activeBtn === 'nairaBtn' }]" @click="handleActiveBtn('nairaBtn')"
                    >Naira</span
                >
                <span :class="['planBtn', { planBtnActive: activeBtn === 'dollarBtn' }]" @click="handleActiveBtn('dollarBtn')"
                    >Dollar</span
                >
            </div>
        </section>
        <section class="planSec2">
            <div>
                <span class="b">Monthly</span>
                <span><Toggle v-model="toggleValue" @click="handlePlanPer()" class="togglePlan" id="toggle" /></span>
                <span class="b">Yearly</span>
            </div>
        </section>
        <section class="planSec3">
            <div class="planSec3Con">
                <div>
                    <div class="allHeader basicHeader">Basic</div>
                    <div class="subHeader">Designed for Freelancer, Contractors and Small Businesses.</div>
                    <div class="priceCon">
                        <span class="planPrice"
                            ><span class="planPrice">{{ nairaDollar }}</span
                            >{{ prices[0] }}</span
                        ><span class="planPer">/{{ monthYear }}</span>
                    </div>
                    <div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">1 User</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">All benefits of Free</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Send custom invoices & quotes</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px;">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Inventory management</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Contact/Customer Management</span>
                        </div>
                        <div class="b readMore">Read more</div>
                    </div>
                </div>
                <div>
                    <button class="w-100">Start 14 days free trial</button>
                </div>
            </div>
            <div class="planSec3Con">
                <div>
                    <div class="allHeader basicHeader" style="color: #e07221">Store</div>
                    <div class="subHeader">Perfect for store owners with advanced needs.</div>
                    <div class="priceCon">
                        <span class="planPrice" ><span class="planPrice">{{ nairaDollar }}</span>{{ prices[1] }}</span><span class="planPer">/{{ monthYear }}</span>
                    </div>
                    <div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Point of Sale</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Advanced Inventory Management</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Multi-store</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span
                                 class="w-5"><img src="../../assets/images/Tick.svg" style="flex" width="15" height="15" alt=""
                            /></span>
                            <span class="w-90">Multi user (3 Free, $5/N5,000 pm for additional user)</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Accounting: P&L, Sales, Expenses, Inventory Management</span>
                        </div>
                        <div class="b readMore">Read more</div>
                    </div>
                </div>
                <div>
                    <button class="w-100">Start 14 days free trial</button>
                </div>
            </div>
            <div class="planSec3Con">
                <div>
                    <div class="allHeader basicHeader" style="color: #24e9ba">Professional</div>
                    <div class="subHeader">Suitable for large enterprises with complex needs and multiple businesses</div>
                    <div class="priceCon">
                        <span class="planPrice"
                            ><span class="planPrice">{{ nairaDollar }}</span
                            >{{ prices[2] }}</span
                        ><span class="planPer">/{{ monthYear }}</span>
                    </div>
                    <div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Multi-business</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Multi user (3 Free, $5/N5,000 pm for additional user)</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Production Management</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Paystack Integration</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Paid In-office Launch/Onboarding service</span>
                        </div>
                        <div class="b readMore">Read more</div>
                    </div>
                </div>
                <div>
                    <button class="w-100">Start 14 days free trial</button>
                </div>
            </div>
            <div class="planSec3Con">
                <div>
                    <div class="allHeader basicHeader" style="color: #f42828">Enterprise</div>
                    <div class="subHeader">Suitable for large enterprises with complex needs and multiple businesses.</div>
                    <div class="priceCon">
                        <span class="planPrice"
                            ><span class="planPrice">{{ nairaDollar }}</span
                            >{{ prices[3] }}</span
                        ><span class="planPer planPrice">/{{ monthYear }}</span>
                    </div>
                    <div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">10 Free User ($5/N5,000 pm for additional user)</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Point of Sale</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Advanced Inventory Management</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Purchase Order</span>
                        </div>
                        <div class="flex items-center pv1" style="gap: 5px">
                            <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                            <span class="w-90">Inventory Transfer</span>
                        </div>
                        <div class="b readMore">Read more</div>
                    </div>
                </div>
                <div>
                    <button class="w-100">Start 14 days free trial</button>
                </div>
            </div>
        </section>
        <section class="planSec4">
            <div class="freeSec1">
                <div class="free">Free</div>
                <div class="pv2">Ideal for new new businesses, online ventures, and side hustles.</div>
                <div><button class="getStartedBtn">Get Started</button></div>
            </div>
            <div class="mbFree">
                <div class="freeSec2">
                    <div class="flex items-center pv1" style="gap: 5px">
                        <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                        <span class="w-90">1 User</span>
                    </div>
                    <div class="flex items-center pv1" style="gap: 5px">
                        <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                        <span class="w-90">Send outstanding reminders and get paid faster</span>
                    </div>
                </div>
                <div class="freeSec2">
                    <div class="flex items-center pv1" style="gap: 5px">
                        <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                        <span>Receipt generation</span>
                    </div>
                    <div class="flex items-center pv1" style="gap: 5px">
                        <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                        <span class="w-90">Unlimited Invoicing</span>
                    </div>
                </div>
                <div class="freeSec2">
                    <div class="flex items-center pv1" style="gap: 5px">
                        <span class="w-5"><img src="../../assets/images/Tick.svg" width="15" height="15" alt="" /></span>
                        <span class="w-90">Track Income & Expenses</span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { ref } from 'vue'
import Toggle from '@vueform/toggle'
export default {
    name: 'PaymentPlans',
    components: { Toggle },
    setup() {
        const activeBtn = ref('nairaBtn')
        const nairaDollar = ref('N')
        const prices = ref([5000, 50000, 50000, 100000])
        const toggleValue = ref(false)
        const monthYear = ref('Monthly')

        const handlePrices = () => {
            if (!toggleValue.value) {
                prices.value = activeBtn.value === 'nairaBtn' ? [5000, 50000, 50000, 100000] : [10, 100, 100, 200]
            } else {
                prices.value = activeBtn.value === 'nairaBtn' ? [50000, 500000, 500000, 1000000] : [100, 1000, 1000, 2000]
            }
        }

        const handleActiveBtn = (button) => {
            activeBtn.value = button
            nairaDollar.value = activeBtn.value === 'nairaBtn' ? 'N' : '$'
            handlePrices()
        }

        const handlePlanPer = () => {
            monthYear.value = toggleValue.value === false ? 'Monthly' : 'Annum'
            handlePrices()
        }
        return {
            handleActiveBtn,
            activeBtn,
            nairaDollar,
            toggleValue,
            prices,
            monthYear,
            handlePlanPer,
            handlePrices,
        }
    },
}
</script>
<style src="@vueform/toggle/themes/default.css"></style>
<style>
.togglePlan {
    --toggle-width: 50px;
    --toggle-height: 25px;
    --toggle-bg-on: #febf12;
    --toggle-bg-off: #fff;
    --toggle-border-on: #febf12;
    --toggle-border-off: #c8b6b6;
    --toggle-ring-width: 3px;
    --toggle-ring-color: transparent;
    --toggle-handle-enabled: #febf12;
    --toggle-handle-disabled: #fff;
    --toggle-border: 1px;
}
input[id='toggle']:checked + .toggle .toggle-handle {
    background-color: #fff;
}

.planSec1 {
    /* width: 599px; */
    padding-bottom: 28px;
}
.planHeader {
    font-size: 20px;
}
.planSub {
    color: #525356;
    font-size: 16px;
}
.planBtnCon {
    display: flex;
    gap: 3px;
}
.planBtn {
    border: none;
    padding: 8px 16px;
    border-radius: 12px;
    font-weight: bold;
    background-color: #f5f5f5;
    color: black;
    cursor: pointer;
}
.planBtnActive {
    background-color: #132c8c;
    color: #fff7f7;
}
.planSec2 {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
}
.planSec2 > div {
    display: flex;
    gap: 5px;
    align-items: center;
}
.planSec3 {
    display: flex;
    gap: 20px;
}
.planSec3 > div {
    padding: 36px 16px;
}
.allHeader {
    font-size: 16px;
    font-weight: bold;
}
.basicHeader {
    color: #3853bc;
}
.subHeader {
    color: #616161;
    font-size: 12px;
    padding-top: 5px;
}
.priceCon {
    padding: 15px 0 25px 0;
}
.planPrice {
    font-size: 16px;
    color: #0a0a0a;
    font-weight: bold;
}
.planPer {
    font-size: 12px;
    color: #050505;
    font-weight: bold;
}
.planSec3Con {
    border: 1px solid #f5f5f5;
    background-color: #fbfbfc;
    width: 30%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
}
.readMore {
    font-size: 10px;
    color: #132c8c;
    padding-top: 7px;
}
.planSec4 {
    border: 1px solid #f5f5f5;
    margin-top: 48px;
    padding: 24px 16px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.freeSec1 {
    width: 40%;
}
.free {
    color: #0a0a0a;
    font-size: 16px;
    font-weight: bold;
}
.getStartedBtn {
    color: #5e5a5a !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px !important;
    background-color: white !important;
    width: 202px;
}
.mbFree{
    width: 70%;
    display: flex;
    justify-content: space-between;
}
.freeSec2{
    width: 33%;

}
@media screen and (max-width: 900px) {
    .planSec3 {
        flex-wrap: wrap;
        gap: 0px;
        justify-content: space-between;
    }
    .planSec3Con {
        width: 48%;
        margin-bottom: 20px;
    }
    .mbFree{
        display: block;
        width: 55% !important;
    }
    .getStartedBtn{
        width: 100%;
    }
   
    .freeSec1 {
        width: 50%;
    }
    .freeSec2{
        width: auto;
    }
}
@media screen and (max-width: 506px) {
    .planSec3 {
        flex-wrap: wrap;
        gap: 0px;
        justify-content: space-between;
    }
    .planSec3Con {
        width: 100%;
        margin-bottom: 20px;
    }
    .getStartedBtn {
        width: 100%;
        margin-bottom: 20px;
    }
    .planSec4 {
        flex-wrap: wrap;
        padding: 24px 16px;
        gap: 0;
    }
    .freeSec1 {
        width: 100%;
    }
    .mbFree{
        width: 100% !important;
    }
}
</style>
