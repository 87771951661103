<template>
    <div>
        <router-view />
        <floating-action-buttons />
    </div>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import FloatingActionButtons from '@/components/FloatingActionButtons'

export default {
    name: 'App',
    components: { FloatingActionButtons },

    setup() {
        const store = useStore()
        onMounted(() => store?.dispatch('Auth/getUserData'))
    }
}
</script>

<style></style>
