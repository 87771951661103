<template>
    <div class="flex justify-between items-center" style="background-color: #132c8c; padding: 5px 20px">
        <div><img src="../assets/images/revampLogo.svg" alt="" /></div>
        <div class="flex justify-between items-center">
            <router-link v-if="!isSmallScreen" :to="{name: 'Pos'}" style="margin-right: 20px; color:white;">
                POS
            </router-link>
            <a v-if="isSmallScreen" href="https://help.simplebks.com/" target="_blank" style="margin-right: 20px; color:white;"
                >Help</a>
            <a v-else href="https://help.simplebks.com/" target="_blank"  style="margin-right: 20px">
                <span style="color:white;">Help Centre</span
                >
            </a>
            <!-- <div
                v-if="
                    role === 'owner' ||
                        role === 'admin' ||
                        rolePermissions?.includes(23) ||
                        rolePermissions?.includes(25) ||
                        rolePermissions?.includes(24) ||
                        rolePermissions?.includes(27) ||
                        rolePermissions?.includes(26)
                "
                class="dropdown"
            >
                <router-link :to="{ name: 'AddTransaction' }" style="opacity: 1; color: white; font-size: 15px; width:100%;">
                    <button
                        class=" flex items-center lh-copy w-100 no-wrap"
                        :class="toggleClass"
                        style="color: #132c8c; padding: 10px "
                        @click="changeBusinessType('sales')"
                    >
                        <span style="font-size: 14px; font-weight: 600; margin-right: 10px;">Add New Record</span
                        ><span v-if="!isSmallScreen" style="height: 20px;"
                            ><img src="../assets/images/chevron-down-revamp.svg" alt=""
                        /></span>
                    </button>
                </router-link>
                <div class="dropdown-content">
                    <router-link :to="{ name: 'AddTransaction' }" @click="changeBusinessType('sales')">
                        <p active-class="active">Sales record</p>
                    </router-link>

                    <router-link :to="{ name: 'AddTransaction' }" @click="changeBusinessType('expenses')" class="w-100"
                        ><p active-class="active">Expense</p></router-link
                    >

                    <a href="/inventories/purchase-orders/create" class="w-100"><p active-class="active">Purchase order</p></a>

                    <a href="#" @click.prevent="() => toggleCreateEntity(true)" class="w-100"
                        ><p active-class="active">Customer</p></a
                    >
                    <a href="/create-invoice" class="w-100"><p active-class="active">Invoice</p></a>
                    <a href="#" @click.prevent="toggleCreateProduct({ show: true, cat: 'PRODUCT' })" class="w-100"
                        ><p active-class="active">Product or Services</p></a
                    >
                    <a href="/production/produce"><p active-class="active">Production batch</p></a>
                </div>
            </div> -->
            <DashboardCTA />
        </div>
    </div>
</template>
<script>
import { computed, ref, onMounted, onBeforeUnmount, reactive } from 'vue'
import { useStore } from 'vuex'
import CreateEntity from '@/components/CreateEntity'
import CreateProduct from '@/components/CreateProduct'
import DashboardCTA from '@/components/DashboardCTA'
export default {
    name: 'NewBanner',
    components: { CreateEntity, CreateProduct,  DashboardCTA},
    setup() {
        const store = useStore()
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const isSmallScreen = ref(false)
        const toggleClass = ref('')

        const changeBusinessType = type => {
            store.dispatch('DashboardStats/getBusinessType', type)
        }
        const checkScreenSize = () => {
            isSmallScreen.value = window.innerWidth < 767
            toggleClass.value = isSmallScreen.value ? 'small-screen' : 'large-screen'
        }

        const state = reactive({
            type: 'PRODUCT',
            isAddTeamMember: false
        })

        const toggleCreateProduct = payload => {
            state.type = payload?.cat || 'PRODUCT'
            store.dispatch('Inventory/showCreateProduct', payload)
        }

        const toggleCreateEntity = payload => {
            store.dispatch('Entity/toggleCreateEntity', payload)
        }

        onMounted(() => {
            checkScreenSize()
            window.addEventListener('resize', checkScreenSize)
        })

        onBeforeUnmount(() => {
            window.removeEventListener('resize', checkScreenSize)
        })

        return {
            changeBusinessType,
            role,
            rolePermissions,
            isSmallScreen,
            toggleClass,
            toggleCreateEntity,
            toggleCreateProduct
        }
    }
}
</script>
<style>
.no-wrap {
    white-space: nowrap;
}
.small-screen {
    background-color: #fcfcfc;
}

.large-screen {
    background-color: #febf12;
}
</style>
