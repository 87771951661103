<template>
    <!-- short message alert -->
    <div
        v-if="message"
        class="flex items-center justify-around pa2-l pa3 br3 mr5-l w-34-l w-90 center mini-spacing"
        style="position: fixed; top: 5%; right: 5%; z-index: 9999999999"
        :style="status ? 'background: #193ab8; color: white' : 'background: #ee0000; color: white'"
    >
        <div>
            <!--      <img src="../assets/images/gift.png" alt="" />-->
        </div>
        <div class="pl4 lh-copy">{{ message }}</div>
        <div class="pl4">&#8594;</div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
    name: 'Alert',

    setup() {
        const store = useStore()
        const message = computed(() => store?.state?.Alert?.message)
        const status = computed(() => store?.state?.Alert?.status)

        return { message, status }
    }
}
</script>

<style scoped></style>
