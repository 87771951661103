import { Axios } from '@/utils/axiosConfig'
import store from '@/store'
import Router from '@/router'

const state = {
    productionSummary: [],
    items: [],
    createProductNotifier: null,
    productIds: [],
    productPrices: [],
    produce: {},
    rawMaterials: [],
    wipRawMaterials: [],
}

const mutations = {
    SET_PRODUCTIONS_SUMMARY(state, payload) {
        state.productionSummary = payload
    },

    SET_PRODUCTIONS(state, payload) {
        state.items = payload
    },

    SET_CREATE_PRODUCT_NOTIFIER(state, payload) {
        state.createProductNotifier = payload
    },

    SET_NEW_PRODUCT_ID(state, payload) {
        if (payload?.index !== null || payload?.index !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            state.productIds[payload?.index] = payload?.value?._id
        } else {
            // eslint-disable-next-line no-underscore-dangle
            state.productIds.push(payload?.value?._id)
        }
    },

    SET_NEW_PRODUCT_PRICES(state, payload) {
        if (payload?.index !== null || payload?.index !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            state.productPrices[payload?.index] = payload?.value
        } else {
            // eslint-disable-next-line no-underscore-dangle
            state.productPrices.push(payload?.value)
        }

    },

    SET_PRODUCTIONS_SKU(state, payload = null) {
        state.productSku = payload
    },

    SET_WIP_RAW_MATERIALS(state, payload) {
        state.wipRawMaterials = payload
    },

    SET_PRODUCE(state, payload = {}) {
        state.produce = payload
    },

    SET_PRODUCE_OUTPUT(state, payload) {
        state.produce.output = payload
    },

    SET_PRODUCE_INPUT(state, payload) {
        state.produce.input = payload
    },

    IMPACT_PRODUCE_INPUT(state, payload) {
        state.produce.input.rawMaterials = state.produce?.input?.rawMaterials?.map(item => {
            item.quantity = parseFloat(item.initial_quantity || 0) * parseFloat(payload)
            return item
        });
    },

    SET_PRODUCE_OUTPUT_TOTAL(state) {
        state.produce.output.total = state.produce.output.products.reduce((prevVal, currValue) => {
            prevVal += parseInt((currValue?.quantity || 0) * (currValue?.price || 0))
            return prevVal
        }, 0)
    },

    SET_PRODUCE_INPUT_TOTAL(state, payload) {
        state.produce.input.rawMaterials = payload.rawMaterials
        state.produce.input.total = state.produce.input.rawMaterials.reduce((prevVal, currValue) => {
            prevVal += parseInt((currValue?.quantity || 0) * (currValue?.price || 0))
            return prevVal
        }, 0)
    },

    SET_PRODUCE_ADDITIONAL_COSTS(state, payload) {
        state.produce.additionalCosts = payload
    },

    SET_PRODUCE_ADDITIONAL_COSTS_TOTAL(state) {
        state.produce.additionalCosts.total = state.produce.additionalCosts.items.reduce((prevVal, currValue) => {
            prevVal += parseInt((currValue?.amount || 0))
            return prevVal
        }, 0)
    },

    SET_RAW_MATERIALS(state, payload) {
        state.rawMaterials = payload
    },
}

const actions = {
    getProductionSummary: async ({ commit }, payload) => {
        commit('SET_PRODUCTIONS_SUMMARY')
        Axios.get(`/api/v1/production/summary?type=${payload}`)
            .then(({ data }) => {
                commit('SET_PRODUCTIONS_SUMMARY', data?.data)
            })
            .catch(err => {
                console.error(err.response.data)
            })
    },

    getProduction: ({ commit }, type) => {
        return Axios.get(`/api/v1/production?type=${type}`)
            .then(({ data }) => {
                commit('SET_PRODUCTIONS', data?.data)
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
            })
    },

    archiveProduct: async ({ dispatch }, payload) => {
        Axios.get(`/api/v1/inventories/${payload.id}/archive`)
            .then(async ({ data }) => {
                dispatch('getProduction', payload.type)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
            .catch(async (err) => {
                console.error(err.response.data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
    },
    createRawMaterial: async ({ commit }, formData) => {
        return Axios.post('/api/v1/production/raw-material', formData)
            .then(async ({ data }) => {
                commit('SET_NEW_PRODUCT_ID', data?.product?.id)
                commit('SET_NEW_PRODUCT_PRICES', data?.product)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })

                commit('SET_CREATE_PRODUCT_NOTIFIER', data?.productDetails)

                return data
            })
            .catch(async err => {
                const { data } = err.response
                console.error(data)
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data
            })
    },
    searchProduction: (_, payload) => {
        let url = `/api/v1/production/search?searchQuery=${payload.query}&type=${payload.type}`
        if (payload?.business) {
            url = `${url}&business=${payload.business}`
        }
        return Axios.get(url)
            .then(({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                console.error(data)
            })
    },

    getSku: async ({ commit }, type) => {
        commit('SET_PRODUCTIONS_SKU')
        return Axios.get(`/api/v1/production/sku?type=${type}`)
            .then(({ data }) => {
                commit('SET_PRODUCTIONS_SKU', data?.data)
                return data?.data
            })

            .catch(err => {
                console.error(err.response.data)
            })
    },

    getWipRawMaterials: async ({ commit }) => {
        return Axios.get(`/api/v1/production/wip-raw-materials`)
            .then(({ data }) => {
                commit('SET_WIP_RAW_MATERIALS', data?.data)
                return data?.data
            })

            .catch(err => {
                console.error(err.response.data)
            })
    },

    setProduce: async ({ commit }, payload) => {
        commit('SET_PRODUCE', payload)
    },

    setProduceOutput: async ({ commit }, payload) => {
        commit('SET_PRODUCE_OUTPUT', payload)
    },

    setProduceInput: async ({ commit }, payload) => {
        commit('SET_PRODUCE_INPUT', payload)
    },

    impactProduceInputQuantities: async ({ commit }, payload) => {
        commit('IMPACT_PRODUCE_INPUT', payload)
    },

    setProduceInputTotal: async ({ commit }, payload) => {
        commit('SET_PRODUCE_INPUT_TOTAL', payload)
    },

    setProduceOutputTotal: async ({ commit }) => {
        commit('SET_PRODUCE_OUTPUT_TOTAL')
    },

    setProduceAdditionalCost: async ({ commit }, payload) => {
        commit('SET_PRODUCE_ADDITIONAL_COSTS', payload)
    },

    setProduceAdditionalCostsTotal: async ({ commit }) => {
        commit('SET_PRODUCE_ADDITIONAL_COSTS_TOTAL')
    },

    saveProduce: async ({ commit }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/production/produce`, payload)
            // console.log('data', data);
            commit('SET_LOADING_STATE', false)
            commit('SET_PRODUCE', payload)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            Router.push({ name: 'Products' })
            return data
        } catch (error) {
            const { data } = error.response
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            console.error(data)
            return data
        }
    },

    createWorkInProgress: async ({ commit }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/production/create-wip`, payload)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            Router.push({ name: 'WorkInProgress' })
            return data
        } catch (error) {
            const { data } = error.response
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            console.error(data)
            return data
        }
    },
}

export const ProductionModule = {
    namespaced: true,
    actions,
    mutations,
    state
}
