import { Axios } from '@/utils/axiosConfig'
import store from '@/store'
import Router from '@/router'

const state = {
    loading: false,
}

const mutations = {
    SET_LOADING_STATE(state, payload) {
        state.loading = payload
    },
}

const actions = {
    getChartOfAccount: async (_, payload) => {
        const url = (payload?.path && payload.path !== '')
            ? `/api/v1/accounting/${payload.type}/type?subtype=${payload.subtype}&`
            : `/api/v1/accounting/${payload.type}/${payload.subtype}?`
        return Axios.get(`${url}bank=${payload.bank}&page=${payload.page}limit=${payload.limit}&startDate=${payload.startDate}&endDate=${payload.endDate}`)
            .then(({ data }) => {
                return data
            })

            .catch(async (err) => {
                console.error(err.response.data)
                await store.dispatch('Alert/setAlert', { message: data?.message, status: data?.success })
            })
    },
}

export const AccountingModule = {
    namespaced: true,
    actions,
    mutations,
    state
}
