import { Axios } from '@/utils/axiosConfig'
import store from '@/store'
import { paginationOptions } from '@/utils/lib'
import Router from '@/router'

const state = {
    isLoading: false,
    invoiceNumber: 0,
    invoiceToCreate: {},
    invoice: {},
    invoiceSummary: [],
    quotationsSummary: [],
    paymentInvoice: {},
    invoiceReceipt: '',
    orgInitials: '',
    showRecordPaymentModal: false,
    invoiceForPayment: null,
    getNewlyCreatedProduct: null,
    getQtyInStock: null
}

const mutations = {
    SET_LOADING_STATE(state, payload) {
        state.isLoading = payload
    },

    GET_INVOICE_NUMBER(state, payload) {
        const invoiceNumber = parseFloat(payload++)
        state.invoiceNumber = invoiceNumber
    },
    SET_INVOICE_TO_CREATE(state, payload) {
        state.invoiceToCreate = payload
    },
    SET_INVOICE(state, payload) {
        state.invoice = payload
    },
    SET_INVOICE_SUMMARY(state, payload) {
        state.invoiceSummary = payload
    },
    SET_QUOTATIONS_SUMMARY(state, payload) {
        state.quotationsSummary = payload
    },
    SET_PAYMENT_INVOICE(state, payload) {
        state.paymentInvoice = payload
    },
    SET_INVOICE_RECEIPT(state, payload) {
        state.invoiceReceipt = payload
    },
    SET_PUBLIC_INVOICE(state, payload) {
        const org = payload?.split(' ')

        if (org?.length > 1) {
            const first = org?.[0]?.split('')[0]
            const second = org?.[1]?.split('')[0]
            state.orgInitials = `${first} ${second}`
        } else {
            const first = org?.[0]?.split('')[0]
            const second = org?.[0]?.split('')[1]
            state.orgInitials = `${first} ${second}`
        }

        state.orgInitials = state.orgInitials.toUpperCase()
    },

    SET_RECORD_PAYMENT_MODAL(state, payload) {
        state.showRecordPaymentModal = payload.status
        state.invoiceForPayment = payload.invoice
    },
    SET_NEWLY_CREATED_PRODUCT(state, getNewlyCreatedProduct) {
        state.getNewlyCreatedProduct = getNewlyCreatedProduct
    },

}

const actions = {
    setLoadingState({ commit }, payload) {
        commit('SET_LOADING_STATE', payload)
    },
    getInvoiceNumber({ commit }) {
        commit('SET_LOADING_STATE', true)
        commit('GET_INVOICE_NUMBER')
        Axios.get(`/api/v1/invoice/count`)
            .then(({ data }) => {
                commit('GET_INVOICE_NUMBER', data)
                commit('SET_LOADING_STATE', false)
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    storeInvoice({ commit }, payload) {
        // commit('SET_INVOICE_TO_CREATE', payload)
        commit('SET_LOADING_STATE', true)
        Axios.post(`/api/v1/invoice/create`, payload)
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                Router.push({ name: 'InvoicePreview', params: { id: data } })
            })
            .catch(err => {
                commit('SET_LOADING_STATE', false)
                console.error((err?.response && err?.response?.data) || err.message)
                store.dispatch('Alert/setAlert', {
                    message: (err?.response && err?.response?.data.message) || err.message,
                    status: false
                })
            })
    },

    createAndSendInvoice({ commit, dispatch }, payload) {
        commit('SET_LOADING_STATE', true)
        dispatch('sendInvoiceAsPdf', { id: payload._id, template: 'letterhead' })
            .then(() => {
                commit('SET_LOADING_STATE', false)
                Router.push({ name: 'InvoiceSummary' })
            })
            .catch(err => {
                commit('SET_LOADING_STATE', false)
                console.error((err?.response && err?.response?.data) || err.message)
                store.dispatch('Alert/setAlert', {
                    message: (err?.response && err?.response?.data.message) || err.message,
                    status: false
                })
            })
    },

    createAndSendInvoice2({ commit, dispatch }, payload) {
        commit('SET_LOADING_STATE', true)
        Axios.put(`/api/v1/invoice/update`, payload)
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                dispatch('sendInvoiceAsPdf', { id: payload._id, template: 'letterhead' })
                // Router.push({ name: 'InvoiceEmail', params: { id: data } })
                Router.push({ name: 'InvoiceSummary' })
            })
            .catch(err => {
                commit('SET_LOADING_STATE', false)
                console.error((err?.response && err?.response?.data) || err.message)
                store.dispatch('Alert/setAlert', {
                    message: (err?.response && err?.response?.data.message) || err.message,
                    status: false
                })
            })
    },

    createAndDownloadInvoice({ commit, dispatch }, payload) {
        commit('SET_LOADING_STATE', true)
        try {
            // const { data } = Axios.put(`/api/v1/invoice/update`, payload)
            commit('SET_LOADING_STATE', false)
            const blob = dispatch('getInvoiceReceiptPdf', { id: payload._id, template: 'letterhead' })
            return blob
        } catch (error) {
            commit('SET_LOADING_STATE', false)
            console.error((err?.response && err?.response?.data) || err.message)
            store.dispatch('Alert/setAlert', {
                message: (err?.response && err?.response?.data.message) || err.message,
                status: false
            })
            return error
        }
    },

    saveInvoice({ commit }, payload) {
        const { duplicate } = payload
        delete payload.duplicate
        commit('SET_LOADING_STATE', true)
        Axios.post(`/api/v1/invoice/create`, payload)
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                if (payload.status === 'draft' && duplicate) Router.push({ name: 'InvoiceEdit', params: { id: data } })
                else if (payload.status === 'draft') Router.push({ name: 'InvoiceSummary' })
                else Router.push({ name: 'InvoiceEmail', params: { id: data } })
            })
            .catch(err => {
                commit('SET_LOADING_STATE', false)
                console.error((err?.response && err?.response?.data) || err.message)
                store.dispatch('Alert/setAlert', {
                    message: (err?.response && err?.response?.data.message) || err.message,
                    status: false
                })
            })
    },

    generatePaystackLink(_, payload) {
        return Axios.post(`/api/v1/invoice/generate-paystack-link`, payload)
            .then(({ data }) => {
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                console.error(data)
            })
    },

    getInvoiceByReference({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        return Axios.get(`/api/v1/invoice/transaction/${payload}`)
            .then(({ data }) => {
                commit('SET_INVOICE', data)
                commit('SET_LOADING_STATE', false)
                return data
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    getInvoicePublic({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        Axios.get(`/api/v1/invoice/${payload}/public`)
            .then(({ data }) => {
                console.log('DATA', data)
                commit('SET_INVOICE', data)
                commit('SET_PUBLIC_INVOICE', data?.business?.name)
                commit('SET_LOADING_STATE', false)
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    getInvoice({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        return Axios.get(`/api/v1/invoice/${payload}`)
            .then(({ data }) => {
                commit('SET_INVOICE', data)
                commit('SET_LOADING_STATE', false)
                return data
            })
            .catch(err => {
                console.error((err?.response && err?.response?.data) || err.message)
                return err
            })
    },

    updateInvoice({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        Axios.put(`/api/v1/invoice/update`, payload)
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                commit('SET_INVOICE', data)
                if (payload.status === 'draft') {
                    Router.push({ name: 'InvoicePreview', params: { id: payload._id } })
                } else {
                    if (payload.status.includes('paid')) {
                        store.dispatch('Alert/setAlert', { message: 'Invoice paid successfully', status: true })
                    }
                    // added alert for voided invoice
                    else if (payload.status.includes('void')) {
                        store.dispatch('Alert/setAlert', { message: 'Invoice has been voided successfully', status: false })
                    } else {
                        store.dispatch('Alert/setAlert', { message: 'Invoice approved successfully', status: true })
                    }
                    // Router.push({ name: 'InvoiceSummary' })
                }
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    deleteDraftInvoice: ({ dispatch }, payload) => {
        return Axios.delete(`/api/v1/invoice/${payload}`)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data?.data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
            })
    },

    getInvoiceReceiptPdf: async ({ commit }, payload) => {
        if (payload.id === undefined || payload.template === undefined) return
        return Axios.get(`/api/v1/invoice/${payload.id}/pdf/download?template=${payload.template}`, { responseType: 'blob' })
            .then(({ data }) => {
                commit('SET_INVOICE_RECEIPT', data)
                return data
            })
            .catch(err => console.log(err?.response?.data))
    },

    sendInvoice({ commit }, payload) {
        const id = payload.get('id')
        commit('SET_LOADING_STATE', true)
        Axios.post(`/api/v1/invoice/sendupload`, payload)
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                store.dispatch('Alert/setAlert', { message: 'Invoice send successfully', status: true })
                // Router.push({ name: 'Invoice', params: { id } })
                Router.push({ name: 'InvoiceSummary' })
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    sendInvoiceAsPdf({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        Axios.post(`/api/v1/invoice/send-invoice`, payload)
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                store.dispatch('Alert/setAlert', { message: 'Invoice sent successfully', status: true })
                Router.push({ name: 'InvoiceSummary' })
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    getInvoiceSummary({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        Axios.post(`/api/v1/invoice/summary`, payload)
            .then(({ data }) => {
                commit('SET_INVOICE_SUMMARY', data)
                commit('SET_LOADING_STATE', false)
                return data
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    getPaymentInvoice: async ({ commit }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/invoice/payment`, { reference: payload })
            // console.log(data, 'data');
            commit('SET_LOADING_STATE', false)
            commit('SET_PAYMENT_INVOICE', data)
            return data
        } catch (error) {
            console.error(error)
        }
    },

    showRecordPaymentModal: async ({ commit }, payload) => {
        commit('SET_RECORD_PAYMENT_MODAL', payload)
    },

    recordPayment: async ({ commit, dispatch }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/invoice/record-payment`, payload)
            dispatch('getInvoiceSummary')
            commit('SET_LOADING_STATE', false)
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            return data
        } catch (error) {
            console.error(error)
            commit('SET_LOADING_STATE', false)
            const { data } = error.response
            console.error((data && data?.data) || error.message)
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
            return data
        }
    },

    getNewProduct({ commit }, payload) {
        commit('SET_NEWLY_CREATED_PRODUCT', payload)
    },

    verifyPayment: async (_, payload) => {
        return await Axios.post('/api/v1/payment/verify-invoice-payment', payload)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
                return data
            })
    },

    sendMarkAsPaidNotification: async ({ commit }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.get(`/api/v1/invoice/${payload.id}/mark-as-paid`)
            // await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            return data
        } catch (error) {
            const { data } = error.response
            // await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            console.error(data)
            return data
        }
    },

    markAsPaid: async ({ commit }, payload) => {
        try {
            commit('SET_LOADING_STATE', true)
            const { data } = await Axios.post(`/api/v1/invoice/${payload}/mark-as-paid`, {})
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            if (data.status) {
                await Router.push({ name: 'InvoiceEmail', params: { id: payload } })
            }
            return data
        } catch (error) {
            const { data } = error.response
            await store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            console.error(data)
            return data
        }
    },

    getInvoiceCount: async (_) => {
        try {
            const { data } = await Axios.get(`/api/v1/invoice/get-count`)
            // console.log(data, 'data');
            return data
        } catch (error) {
            console.error(error)
        }
    },

    getQuotationsSummary({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        Axios.get(`/api/v1/quotes`, payload)
            .then(({ data }) => {
                commit('SET_QUOTATIONS_SUMMARY', data)
                commit('SET_LOADING_STATE', false)
                return data
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    getQuotation({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        return Axios.get(`/api/v1/quotes/${payload}`)
            .then(({ data }) => {
                commit('SET_INVOICE', data)
                commit('SET_LOADING_STATE', false)
                return data
            })
            .catch(err => {
                console.error((err?.response && err?.response?.data) || err.message)
                return err
            })
    },

    storeQuote({ commit }, payload) {
        // commit('SET_INVOICE_TO_CREATE', payload)
        commit('SET_LOADING_STATE', true)
        Axios.post(`/api/v1/quotes`, payload)
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                Router.push({ name: 'QuotePreview', params: { id: data } })
            })
            .catch(err => {
                commit('SET_LOADING_STATE', false)
                console.error((err?.response && err?.response?.data) || err.message)
                store.dispatch('Alert/setAlert', {
                    message: (err?.response && err?.response?.data.message) || err.message,
                    status: false
                })
            })
    },

    updateQuote({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        Axios.put(`/api/v1/quotes/${payload._id}`, payload)
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                commit('SET_INVOICE', data)
                if (payload.status === 'pending') {
                    Router.push({ name: 'QuotePreview', params: { id: payload._id } })
                } else {
                    if (payload.status === 'approved') {
                        store.dispatch('Alert/setAlert', { message: 'Quotation approved successfully', status: true })
                    }
                    // added alert for voided invoice
                    else if (payload.status.includes('void')) {
                        store.dispatch('Alert/setAlert', { message: 'Quotation has been voided successfully', status: false })
                    }
                }
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    confirmQuote({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        payload.status = 'pending'
        Axios.put(`/api/v1/quotes/${payload._id}`, payload)
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                commit('SET_INVOICE', data.data)
                store.dispatch('Alert/setAlert', { message: data.message, status: data.status })
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    approveQuote({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        return Axios.post(`/api/v1/quotes/${payload}/approve`, {})
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                if (data.status) {
                    store.dispatch('Alert/setAlert', { message: 'Quotation approved successfully', status: true })
                }

                return data
            })
            .catch(err => {
                commit('SET_LOADING_STATE', false)
                console.error((err?.response && err?.response?.data) || err.message)
                store.dispatch('Alert/setAlert', {
                    message: (err?.response && err?.response?.data.message) || err.message,
                    status: false
                })

                return err?.response?.data
            })
    },

    deletePendingInvoice: (_, payload) => {
        return Axios.delete(`/api/v1/quotes/${payload}`)
            .then(async ({ data }) => {
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                return data?.data
            })
            .catch(async err => {
                const { data } = err.response
                await store.dispatch('Alert/setAlert', { message: data.message, status: data.success })
                console.error(data)
            })
    },

    getQuotationReceiptPdf: async ({ commit }, payload) => {
        if (payload.id === undefined || payload.template === undefined) return
        return Axios.get(`/api/v1/quotes/${payload.id}/pdf/download?template=${payload.template}`, { responseType: 'blob' })
            .then(({ data }) => {
                commit('SET_INVOICE_RECEIPT', data)
                return data
            })
            .catch(err => console.log(err?.response?.data))
    },

    sendQuotationAsPdf({ commit }, payload) {
        commit('SET_LOADING_STATE', true)
        Axios.post(`/api/v1/quotes/send`, payload)
            .then(({ data }) => {
                commit('SET_LOADING_STATE', false)
                store.dispatch('Alert/setAlert', { message: 'Quotation sent successfully', status: true })
                Router.push({ name: 'Quotations' })
            })
            .catch(err => console.error((err?.response && err?.response?.data) || err.message))
    },

    createAndSendQuotation({ commit, dispatch }, payload) {
        commit('SET_LOADING_STATE', true)
        dispatch('sendQuotationAsPdf', { id: payload._id, template: 'letterhead' })
            .then(() => {
                commit('SET_LOADING_STATE', false)
                Router.push({ name: 'Quotations' })
            })
            .catch(err => {
                commit('SET_LOADING_STATE', false)
                console.error((err?.response && err?.response?.data) || err.message)
                store.dispatch('Alert/setAlert', {
                    message: (err?.response && err?.response?.data.message) || err.message,
                    status: false
                })
            })
    },

    createAndDownloadQuotation({ commit, dispatch }, payload) {
        commit('SET_LOADING_STATE', true)
        try {
            commit('SET_LOADING_STATE', false)
            const blob = dispatch('getQuotationReceiptPdf', { id: payload._id, template: 'letterhead' })
            return blob
        } catch (error) {
            commit('SET_LOADING_STATE', false)
            console.error((err?.response && err?.response?.data) || err.message)
            store.dispatch('Alert/setAlert', {
                message: (err?.response && err?.response?.data.message) || err.message,
                status: false
            })
            return error
        }
    },

}

export const InvoiceModule = {
    namespaced: true,
    state,
    mutations,
    actions
}
