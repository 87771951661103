<template>
    <div class="flex items-center justify-center" style="background-image: url(); background-size: cover; height: 100vh">
        <Alert />

        <div class="w-50-l w-90">
            <!-- Simplebks logo -->
            <div class="tc pb4">
                <img src="../../assets/images/simplebks-logo.png" style="width: 180px" alt="simplebks-logo" />
            </div>
            <!-- Simplebks logo -->

            <!-- ForgetPassword Input -->
            <div style="background-color: white">
                <form class="center pa5-l pa3" id="form" @submit.prevent="onSubmit">
                    <p class="tc pb3">Enter your primary email address and we’ll send you instructions on how to reset your password, or contact our <a href="mailto: hello@simplebks.com">support team</a> if you need further assistance.</p>
                    <div class="flex flex-column pb3">
                        
                        <input
                            v-model="state.email"
                            class="form-sign"
                            type="email"
                            name="email"
                            placeholder="Enter your registered email"
                            id="email"
                            required
                        />
                    </div>

                    <div v-if="message" class="flex justify-center items-center w-100">
                        <span style="color: red; font-weight: 700">
                            {{ message }}
                        </span>
                    </div>

                    <button class="mt3 w-100 submit-btn" type="submit" :disabled="state.disableSubmitButton">
                        {{ state.disableSubmitButton ? 'Processing...' : 'Send me reset instructions' }}
                    </button>
                    <div>
                        <div class="tc mt3">
                            Don't need to reset? <router-link :to="{ name: 'SignIn' }"><b>Login</b></router-link>
                        </div>

                        <div class="tc mt3">
                            Don't have account? <router-link :to="{ name: 'SignUp' }"><b>Register</b></router-link>
                        </div>
                    </div>
                </form>
            </div>
            <!-- ForgetPassword Input -->
        </div>
    </div>
</template>

<script>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import Alert from '@/components/Alert'

export default {
    name: 'ForgetPassword',
    components: { Alert },

    setup() {
        const store = useStore()
        const message = computed(() => store.state?.Auth?.message)
        const state = reactive({
            disableSubmitButton: false,
            email: ''
        })

        const onSubmit = () => {
            state.disableSubmitButton = true

            const { disableSubmitButton, ...rest } = state

            store.dispatch('Auth/recoverPassword', rest).then((result) => {
                if (result?.success) {
                    state.disableSubmitButton = false
                } else {
                    state.disableSubmitButton = false
                }
            })
        }

        return { state, message, onSubmit }
    }
}
</script>

<style scoped></style>
