<template>
    <div>
        <InventoryBulkUpdateModal :close-modal="toggleInventoryBulkUpdateModal" />
        <UploadInventoryModal :close-modal="toggleImportInventoryModal" />
        <CreateProduct v-if="state.showCreateProductModal" :closeModal="() => toggleCreateProduct({ show: false, cat: type })" :transactionCategory="state.type" :variantProduct="variantProduct" />
        <app-wrapper>
            <template v-slot:child>
                <!-- Main Box -->
                <div class="flex justify-between">
                    <!-- Right box -->
                    <div class="">
                        <div class="pa4-l pa3">
                            <div>
                                <h2 class="" style="color: #132c8c; font-size: 18px">Products & Services</h2>
                                <p class="w-60-l lh-copy black">
                                    Your hub for managing everything you offer to customers. Under "Products/Services," you'll
                                    find the following options
                                </p>
                            </div>

                            <div class="mt4 black" style="font-weight: 500">
                                <span class="bg-washed-yellow ph2">Tip: Click on card to perform specify actions </span>
                            </div>

                            <div class="mt4">
                                <div class="flex flex-wrap" style="gap: 15px">
                                    <router-link
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(5)"
                                        :to="'#'"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                        @click="routeTo('ProductsSummary')"
                                    >
                                        <img src="@/assets/images/Summary.svg" style="width: 60px" alt="" />
                                        <h3>Summary</h3>
                                        <p class="lh-copy">An overview of your products, services, and their performance.</p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </router-link>
                                    <div
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(32)"
                                        @click.prevent="toggleVariant('regular')"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    >
                                        <img src="@/assets/images/createProduct.svg" style="width: 60px" alt="" />
                                        <h3>Create Product</h3>
                                        <p class="lh-copy">Make changes to multiple products at once.</p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </div>
                                    <div
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(54)"
                                        @click.prevent="toggleCreateProduct({ show: true, cat: 'SERVICE' })"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    >
                                        <img src="@/assets/images/createService.svg" style="width: 60px" alt="" />
                                        <h3>Create Service</h3>
                                        <p class="lh-copy">Make changes to multiple products at once.</p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </div>
                                    <div
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(54)"
                                        @click.prevent="toggleVariant('variants')"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    >
                                        <img src="@/assets/images/rev-create.svg" style="width: 60px" alt="" />
                                        <h3>Create Product Variant</h3>
                                        <p class="lh-copy">Make changes to multiple products at once.</p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </div>
                                    <router-link
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(6)"
                                        :to="'#'"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                        @click="routeTo('Products')"
                                    >
                                        <img src="@/assets/images/ProductTB.svg" style="width: 60px" alt="" />
                                        <h3>Products</h3>
                                        <p class="lh-copy">Manage physical items you sell, like gadgets or clothing.</p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </router-link>
                                    <router-link
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(9)"
                                        :to="'#'"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                        @click="routeTo('Services')"
                                    >
                                        <img src="@/assets/images/Services.svg" style="width: 60px" alt="" />
                                        <h3>Services</h3>
                                        <p class="lh-copy">
                                            Handle intangible offerings, such as consultations or digital services.
                                        </p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </router-link>
                                    <router-link
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(61)"
                                        :to="'#'"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                        @click="routeTo('StockTransfers')"
                                    >
                                        <img src="@/assets/images/Transfer.svg" style="width: 60px" alt="" />
                                        <h3>Transfer</h3>
                                        <p class="lh-copy">Move product inventory/stock between branches or businesses.</p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </router-link>
                                    <router-link
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(56)"
                                        :to="'#'"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                        @click="routeTo('PurchaseOrders')"
                                    >
                                        <img src="@/assets/images/PurchaseOrder.svg" style="width: 60px" alt="" />
                                        <h3>Purchase Orders</h3>
                                        <p class="lh-copy">Create orders for buying products or services from suppliers.</p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </router-link>

                                    <router-link
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(18)"
                                        :to="{ name: 'InventoryReport' }"
                                        class="pa3 menu-card"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    >
                                        <img src="@/assets/images/rev-others.svg" style="width: 60px" alt="" />
                                        <h3>Inventory Report</h3>
                                        <p class="lh-copy">Get a snapshot of your current product inventory.</p>
                                    </router-link>
                                    <div
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(52)"
                                        @click.prevent="toggleImportInventoryModal"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    >
                                        <img src="@/assets/images/export.svg" style="width: 60px" alt="" />
                                        <h3>Import</h3>
                                        <p class="lh-copy">Bring in product or service data from csv file</p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </div>
                                    <div
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(55)"
                                        @click.prevent="downloadInventoryTemplate"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    >
                                        <img src="@/assets/images/revamp-upload.svg" style="width: 60px" alt="" />
                                        <h3>Export</h3>
                                        <p class="lh-copy">Save your product or service data as a csv file for use elsewhere.</p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </div>
                                    <div
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(54)"
                                        @click="toggleInventoryBulkUpdateModal"
                                        class="pa3 menu-card position-relative"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    >
                                        <img src="@/assets/images/BulkUpdate.svg" style="width: 60px" alt="" />
                                        <h3>Bulk Update</h3>
                                        <p class="lh-copy">Make changes to multiple products at once.</p>
                                        <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
                                            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
                                        </span>
                                    </div>
                                    <router-link
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(68)"
                                        :to="{ name: 'ProductCategories' }"
                                        class="pa3 menu-card"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    >
                                        <img src="@/assets/images/Category.svg" style="width: 60px" alt="" />
                                        <h3>Categories</h3>
                                        <p class="lh-copy">
                                            Group products or services into logical categories like "Electronics" or "Apparel."
                                        </p>
                                    </router-link>
                                    <router-link
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(72)"
                                        :to="{ name: 'ProductBrands' }"
                                        class="pa3 menu-card"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    >
                                        <img src="@/assets/images/Brand.svg" style="width: 60px" alt="" />
                                        <h3>Brands</h3>
                                        <p class="lh-copy">Assign brands or manufacturers to products for easy identification.</p>
                                    </router-link>
                                    <router-link
                                        v-if="role === 'owner' || role === 'admin'"
                                        :to="{ name: 'PriceAdjustment' }"
                                        class="pa3 menu-card"
                                        style="border: 2px solid #f5f5f7; border-radius: 20px"
                                    >
                                        <img src="@/assets/images/price-adj.svg" style="width: 60px" alt="" />
                                        <h3>Price Adjustmnet</h3>
                                        <p class="lh-copy">Make update to Prices for all Product and Service.</p>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </app-wrapper>
    </div>
</template>
<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import AppWrapper from '../../layout/AppWrapper.vue'
import UploadInventoryModal from '@/components/UploadInventoryModal'
import InventoryBulkUpdateModal from '@/views/Inventory/widgets/InventoryBulkUpdateModal'
import CreateProduct from '@/components/CreateProduct'
export default {
    name: 'ProductsAndServices',
    components: { AppWrapper, UploadInventoryModal, InventoryBulkUpdateModal, CreateProduct },
    setup() {
        const store = useStore()
        const router = useRouter()
        const orgData = computed(() => store?.state?.Auth?.userData)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const plan = computed(() => store.state?.Settings?.paymentPlan)

        const variantProduct = ref('regular')

        const state = reactive({
            type: 'PRODUCT',
            isAddTeamMember: false,
            showCreateProductModal: false,
        })

        const toggleCreateProduct = (payload) => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            state.type = payload?.cat || 'PRODUCT'
            state.showCreateProductModal = payload?.show || false
            store.dispatch('Inventory/showCreateProduct', payload)
        }

        const toggleVariant = (type) => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }

            toggleCreateProduct({ show: true })
            variantProduct.value = type;
        }
        const toggleInventoryBulkUpdateModal = () => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }

            store.dispatch('Inventory/toggleInventoryBulkUpdateModal')
        }
        const toggleImportInventoryModal = () => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }

            store.dispatch('Inventory/toggleImportInventoryModal')
        }

        const downloadInventoryTemplate = () => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }

            store.dispatch('Inventory/downloadInventoryTemplate').then((response) => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `${orgData.value?.org}-inventory-template.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                // state.downloading = false
            })
        }

        const checkFreePlan = () => {
            if (plan.value.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `This feature is not available on free plan. Upgrade to unlock!`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                })
                return true
            }

            return false
        }

        const routeTo = (path, query={}) => {
            const isFreePlan = checkFreePlan()
            if (isFreePlan) {
                return
            }
            router.push({ name: path, query })
        }

        onMounted(() => {
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getBusinesses')
        })

        return {
            state,
            toggleInventoryBulkUpdateModal,
            toggleImportInventoryModal,
            downloadInventoryTemplate,
            rolePermissions,
            role,
            toggleCreateProduct,
            toggleVariant,
            variantProduct,
            plan,
            routeTo,
        }
    },
}
</script>
