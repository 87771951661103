import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faEye,
    faEyeSlash,
    faFolderOpen,
    faSpinner,
    faEllipsisVertical,
    faCopy,
    faPlusMinus,
    faClipboard,
    faQuestionCircle,
    faExclamationCircle,
    faCheck,
    faRemove,
    faCircleNotch,
    faPlusCircle,
    faPencil,
    faCamera,
    faCaretDown,
    faPlus,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faSearch,
    faLock,
    faEnvelope,
    faEdit,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faEye,
    faEyeSlash,
    faSpinner,
    faFolderOpen,
    faEllipsisVertical,
    faPlusMinus,
    faCopy,
    faClipboard,
    faQuestionCircle,
    faExclamationCircle,
    faCheck,
    faRemove,
    faCircleNotch,
    faPlusCircle,
    faPlus,
    faPencil,
    faCamera,
    faCaretDown,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faSearch,
    faLock,
    faEnvelope,
    faEdit,
)

export default FontAwesomeIcon
